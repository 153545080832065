import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"
import { getClassification } from "../../../../utils/misc"

const Profile = ({ data }) => {
  const classes = useStyles()

  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Profile</div>
                <div className={classes["sub-header"]}>
                  Profile of the company
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Classification
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "market_cap_classification",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {getClassification(data?.marketCap)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Sector
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "industry_classification",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {data?.sector}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Industry
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "industry_classification",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {data?.industry}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Shares Outstanding
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "shares_outstanding_diluted",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.sharesOutstanding, "", true)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Profile

Profile.propTypes = {
  data: PropTypes.object.isRequired,
}
