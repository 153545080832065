import { createContext } from "react"

export default createContext({
  recentSearches: [],
  setRecentSearches: () => {
    // this is intentional
  },
  overview: {},
  setOverview: () => {
    // this is intentional
  },
  incomeStatment: {},
  setIncomeStatment: () => {
    // this is intentional
  },
  balanceSheet: {},
  setBalanceSheet: () => {
    // this is intentional
  },
  cashFlowStatment: {},
  setCashFlowStatment: () => {
    // this is intentional
  },
  ratios: {},
  setRatios: () => {
    // this is intentional
  },
  keyFinancials: {},
  setKeyFinancials: () => {
    // this is intentional
  },
  prosAndCons: {},
  setProsAndCons: () => {
    // this is intentional
  },
  keyInsights: {},
  setKeyInsights: () => {
    // this is intentional
  },
  valuationRef: {},
  setValuationRef: () => {
    // this is intentional
  },
  checkList: {},
  setCheckList: () => {
    // this is intentional
  },
  profile: {},
  setProfile: () => {
    // this is intentional
  },
  secFilings: {},
  setSecFilings: () => {
    // this is intentional
  },
  shareholders: {},
  setShareholders: () => {
    // this is intentional
  },
  insiderTransactions: {},
  setInsiderTransactions: () => {
    // this is intentional
  },
  dividends: {},
  setDividends: () => {
    // this is intentional
  },
  analysts: {},
  setAnalysts: () => {
    // this is intentional
  },
  strapiTerms: {},
  setStrapiTerms: () => {
    // this is intentional
  },
  quotes: {},
  setQuotes: () => {
    // this is intentional
  },
  peers: {},
  setPeers: () => {
    // this is intentional
  },
  revenueSegments: {},
  setRevenueSegments: () => {
    // this is intentional
  },
  earningsCallTranscripts: {},
  setEarningsCallTranscripts: () => {
    // this is intentional
  },
  fingraphs: {},
  setFingraphs: () => {
    // this is intentional
  },
  indexData: {},
  setIndexData: () => {
    // this is intentional
  },
})
