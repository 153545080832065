import { isNumber } from "lodash"
import { format } from "date-fns"

const calculateYoyChange = (key, index, currentVal, incomeStatement) => {
  const prevData = incomeStatement[index + 1]
  if (prevData && isNumber(prevData[key])) {
    const change = (currentVal / prevData[key] - 1) * 100
    if (!isFinite(change)) return ""
    if (currentVal < 0 && prevData[key] < 0) return change * -1
    if (currentVal > 0 && prevData[key] < 0) return change * -1
    return change
  }
  return ""
}

export const getUsdValues = (incomeStatementData) => {
  // default data
  const defaultData = {
    revenue: Array.apply(null, Array(10)),
    revenueGrowthYoy: Array.apply(null, Array(10)),
    costOfRevenue: Array.apply(null, Array(10)),
    grossProfit: Array.apply(null, Array(10)),
    operatingIncome: Array.apply(null, Array(10)),
    operatingIncomeYoy: Array.apply(null, Array(10)),
    operatingExpenses: Array.apply(null, Array(10)),
    nonOperatingExpenses: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    otherIncomeExpensesNonOperating: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    researchAndDevelopmentExpenses: Array.apply(null, Array(10)),
    generalAndAdministrativeExpenses: Array.apply(null, Array(10)),
    sellingAndMarketingExpenses: Array.apply(null, Array(10)),
    sellingGeneralAndAdministrativeExpenses: Array.apply(null, Array(10)),
    interestIncome: Array.apply(null, Array(10)),
    interestExpense: Array.apply(null, Array(10)),
    netInterestIncomeExpense: Array.apply(null, Array(10)),
    incomeBeforeTax: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    netIncomeGrowth: Array.apply(null, Array(10)),
    incomeTaxExpense: Array.apply(null, Array(10)),
    weightedAverageShsOut: Array.apply(null, Array(10)),
    weightedAverageShsOutYoy: Array.apply(null, Array(10)),
    weightedAverageShsOutDil: Array.apply(null, Array(10)),
    weightedAverageShsOutDilYoy: Array.apply(null, Array(10)),
    earningPerShare: Array.apply(null, Array(10)),
    eps: Array.apply(null, Array(10)),
    epsDiluted: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  incomeStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      incomeStatementData.length > 10 ? incomeStatementData.length - 1 : 9
    defaultData.revenue[maxIndex - index] = data?.revenue / 1000000
    defaultData.revenueGrowthYoy[maxIndex - index] = calculateYoyChange(
      "revenue",
      index,
      data?.revenue,
      incomeStatementData
    )
    defaultData.costOfRevenue[maxIndex - index] = data?.costOfRevenue / 1000000
    defaultData.grossProfit[maxIndex - index] = data?.grossProfit / 1000000
    defaultData.operatingExpenses[maxIndex - index] = 0
    defaultData.researchAndDevelopmentExpenses[maxIndex - index] =
      data?.researchAndDevelopmentExpenses / 1000000
    defaultData.generalAndAdministrativeExpenses[maxIndex - index] =
      data?.generalAndAdministrativeExpenses / 1000000
    defaultData.sellingAndMarketingExpenses[maxIndex - index] =
      data?.sellingAndMarketingExpenses / 1000000
    defaultData.sellingGeneralAndAdministrativeExpenses[maxIndex - index] =
      data?.sellingGeneralAndAdministrativeExpenses / 1000000
    defaultData.totalOtherIncomeExpensesNet[maxIndex - index] =
      data.totalOtherIncomeExpensesNet / 1000000
    defaultData.otherIncomeExpensesNonOperating[maxIndex - index] =
      (data?.totalOtherIncomeExpensesNet -
        (data?.interestIncome - data?.interestExpense)) /
      1000000
    defaultData.totalOperatingExpenses[maxIndex - index] =
      data.operatingExpenses / 1000000
    defaultData.operatingIncome[maxIndex - index] =
      data?.operatingIncome / 1000000
    defaultData.operatingIncomeYoy[maxIndex - index] = calculateYoyChange(
      "operatingIncome",
      index,
      data?.operatingIncome,
      incomeStatementData
    )
    defaultData.interestIncome[maxIndex - index] =
      data?.interestIncome / 1000000
    defaultData.interestExpense[maxIndex - index] =
      data?.interestExpense / 1000000
    defaultData.netInterestIncomeExpense[maxIndex - index] =
      (data?.interestIncome - data?.interestExpense) / 1000000

    defaultData.incomeBeforeTax[maxIndex - index] =
      data?.incomeBeforeTax / 1000000
    defaultData.netIncome[maxIndex - index] = data?.netIncome / 1000000
    defaultData.netIncomeGrowth[maxIndex - index] = calculateYoyChange(
      "netIncome",
      index,
      data?.netIncome,
      incomeStatementData
    )
    defaultData.incomeTaxExpense[maxIndex - index] =
      data?.incomeTaxExpense / 1000000
    defaultData.weightedAverageShsOut[maxIndex - index] =
      data?.weightedAverageShsOut / 1000000
    defaultData.weightedAverageShsOutYoy[maxIndex - index] = calculateYoyChange(
      "weightedAverageShsOut",
      index,
      data?.weightedAverageShsOut,
      incomeStatementData
    )
    defaultData.weightedAverageShsOutDil[maxIndex - index] =
      data?.weightedAverageShsOutDil / 1000000
    defaultData.weightedAverageShsOutDilYoy[maxIndex - index] =
      calculateYoyChange(
        "weightedAverageShsOutDil",
        index,
        data?.weightedAverageShsOutDil,
        incomeStatementData
      )
    defaultData.earningPerShare[maxIndex - index] = 0
    defaultData.eps[maxIndex - index] = data?.eps
    defaultData.epsDiluted[maxIndex - index] = data?.epsdiluted
  })
  return { tempHeaders, defaultData }
}

export const getUsdPercentValues = (incomeStatementData) => {
  // default data
  const defaultPerData = {
    revenue: Array.apply(null, Array(10)),
    revenueGrowthYoy: Array.apply(null, Array(10)),
    costOfRevenue: Array.apply(null, Array(10)),
    grossProfit: Array.apply(null, Array(10)),
    operatingIncome: Array.apply(null, Array(10)),
    operatingIncomeYoy: Array.apply(null, Array(10)),
    operatingExpenses: Array.apply(null, Array(10)),
    nonOperatingExpenses: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    otherIncomeExpensesNonOperating: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    researchAndDevelopmentExpenses: Array.apply(null, Array(10)),
    generalAndAdministrativeExpenses: Array.apply(null, Array(10)),
    sellingAndMarketingExpenses: Array.apply(null, Array(10)),
    sellingGeneralAndAdministrativeExpenses: Array.apply(null, Array(10)),
    interestIncome: Array.apply(null, Array(10)),
    interestExpense: Array.apply(null, Array(10)),
    netInterestIncomeExpense: Array.apply(null, Array(10)),
    incomeBeforeTax: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    netIncomeGrowth: Array.apply(null, Array(10)),
    incomeTaxExpense: Array.apply(null, Array(10)),
    weightedAverageShsOut: Array.apply(null, Array(10)),
    weightedAverageShsOutYoy: Array.apply(null, Array(10)),
    weightedAverageShsOutDil: Array.apply(null, Array(10)),
    weightedAverageShsOutDilYoy: Array.apply(null, Array(10)),
    earningPerShare: Array.apply(null, Array(10)),
    eps: Array.apply(null, Array(10)),
    epsDiluted: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  incomeStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    const maxIndex = incomeStatementData.length - 1
    // data in percentages
    defaultPerData.revenue[maxIndex - index] = 100
    defaultPerData.costOfRevenue[maxIndex - index] =
      (data?.costOfRevenue / data?.revenue) * 100
    defaultPerData.grossProfit[maxIndex - index] =
      (data?.grossProfit / data?.revenue) * 100
    defaultPerData.operatingExpenses[maxIndex - index] = 0
    defaultPerData.researchAndDevelopmentExpenses[maxIndex - index] =
      (data?.researchAndDevelopmentExpenses / data?.revenue) * 100
    defaultPerData.generalAndAdministrativeExpenses[maxIndex - index] =
      (data?.generalAndAdministrativeExpenses / data?.revenue) * 100
    defaultPerData.sellingAndMarketingExpenses[maxIndex - index] =
      (data?.sellingAndMarketingExpenses / data?.revenue) * 100
    defaultPerData.sellingGeneralAndAdministrativeExpenses[maxIndex - index] =
      (data?.sellingGeneralAndAdministrativeExpenses / data?.revenue) * 100
    defaultPerData.totalOtherIncomeExpensesNet[maxIndex - index] =
      (data.totalOtherIncomeExpensesNet / data?.revenue) * 100
    defaultPerData.otherIncomeExpensesNonOperating[maxIndex - index] =
      ((data.totalOtherIncomeExpensesNet -
        (data?.interestIncome - data?.interestExpense)) /
        data?.revenue) *
      100
    defaultPerData.totalOperatingExpenses[maxIndex - index] =
      (data.operatingExpenses / data?.revenue) * 100
    defaultPerData.operatingIncome[maxIndex - index] =
      (data?.operatingIncome / data?.revenue) * 100
    defaultPerData.interestIncome[maxIndex - index] =
      (data?.interestIncome / data?.revenue) * 100
    defaultPerData.interestExpense[maxIndex - index] =
      (data?.interestExpense / data?.revenue) * 100
    defaultPerData.netInterestIncomeExpense[maxIndex - index] =
      ((data?.interestIncome - data?.interestExpense) / data?.revenue) * 100
    defaultPerData.incomeBeforeTax[maxIndex - index] =
      (data?.incomeBeforeTax / data?.revenue) * 100
    defaultPerData.netIncome[maxIndex - index] =
      (data?.netIncome / data?.revenue) * 100
    defaultPerData.incomeTaxExpense[maxIndex - index] =
      (data?.incomeTaxExpense / data?.revenue) * 100
    defaultPerData.weightedAverageShsOut[maxIndex - index] =
      data?.weightedAverageShsOut / 1000000
    defaultPerData.weightedAverageShsOutDil[maxIndex - index] =
      data?.weightedAverageShsOutDil / 1000000
    defaultPerData.earningPerShare[maxIndex - index] = 0
    defaultPerData.eps[maxIndex - index] = data?.eps
    defaultPerData.epsDiluted[maxIndex - index] = data?.epsdiluted
  })
  return { tempHeaders, defaultPerData }
}

export const getConversionValues = (incomeStatementData, price) => {
  // default data
  const defaultData = {
    revenue: Array.apply(null, Array(10)),
    revenueGrowthYoy: Array.apply(null, Array(10)),
    costOfRevenue: Array.apply(null, Array(10)),
    grossProfit: Array.apply(null, Array(10)),
    operatingIncome: Array.apply(null, Array(10)),
    operatingIncomeYoy: Array.apply(null, Array(10)),
    operatingExpenses: Array.apply(null, Array(10)),
    nonOperatingExpenses: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    otherIncomeExpensesNonOperating: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    researchAndDevelopmentExpenses: Array.apply(null, Array(10)),
    generalAndAdministrativeExpenses: Array.apply(null, Array(10)),
    sellingAndMarketingExpenses: Array.apply(null, Array(10)),
    sellingGeneralAndAdministrativeExpenses: Array.apply(null, Array(10)),
    interestIncome: Array.apply(null, Array(10)),
    interestExpense: Array.apply(null, Array(10)),
    netInterestIncomeExpense: Array.apply(null, Array(10)),
    incomeBeforeTax: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    netIncomeGrowth: Array.apply(null, Array(10)),
    incomeTaxExpense: Array.apply(null, Array(10)),
    weightedAverageShsOut: Array.apply(null, Array(10)),
    weightedAverageShsOutYoy: Array.apply(null, Array(10)),
    weightedAverageShsOutDil: Array.apply(null, Array(10)),
    weightedAverageShsOutDilYoy: Array.apply(null, Array(10)),
    earningPerShare: Array.apply(null, Array(10)),
    eps: Array.apply(null, Array(10)),
    epsDiluted: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  incomeStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    const maxIndex = incomeStatementData.length - 1
    // data in currency
    defaultData.revenue[maxIndex - index] = (data?.revenue / 1000000) * price
    defaultData.revenueGrowthYoy[maxIndex - index] = calculateYoyChange(
      "revenue",
      index,
      data?.revenue,
      incomeStatementData
    )
    defaultData.costOfRevenue[maxIndex - index] =
      (data?.costOfRevenue / 1000000) * price
    defaultData.grossProfit[maxIndex - index] =
      (data?.grossProfit / 1000000) * price
    defaultData.operatingExpenses[maxIndex - index] = 0
    defaultData.researchAndDevelopmentExpenses[maxIndex - index] =
      (data?.researchAndDevelopmentExpenses / 1000000) * price
    defaultData.generalAndAdministrativeExpenses[maxIndex - index] =
      (data?.generalAndAdministrativeExpenses / 1000000) * price
    defaultData.sellingAndMarketingExpenses[maxIndex - index] =
      (data?.sellingAndMarketingExpenses / 1000000) * price
    defaultData.sellingGeneralAndAdministrativeExpenses[maxIndex - index] =
      (data?.sellingGeneralAndAdministrativeExpenses / 1000000) * price
    defaultData.totalOtherIncomeExpensesNet[maxIndex - index] =
      (data.totalOtherIncomeExpensesNet / 1000000) * price
    defaultData.otherIncomeExpensesNonOperating[maxIndex - index] =
      (data?.totalOtherIncomeExpensesNet -
        (data?.interestIncome - data?.interestExpense)) /
      1000000
    defaultData.totalOperatingExpenses[maxIndex - index] =
      (data.operatingExpenses / 1000000) * price
    defaultData.operatingIncome[maxIndex - index] =
      (data?.operatingIncome / 1000000) * price
    defaultData.operatingIncomeYoy[maxIndex - index] = calculateYoyChange(
      "operatingIncome",
      index,
      data?.operatingIncome,
      incomeStatementData
    )
    defaultData.interestIncome[maxIndex - index] =
      (data?.interestIncome / 1000000) * price
    defaultData.interestExpense[maxIndex - index] =
      (data?.interestExpense / 1000000) * price
    defaultData.netInterestIncomeExpense[maxIndex - index] =
      ((data?.interestIncome - data?.interestExpense) / 1000000) * price
    defaultData.incomeBeforeTax[maxIndex - index] =
      (data?.incomeBeforeTax / 1000000) * price
    defaultData.netIncome[maxIndex - index] =
      (data?.netIncome / 1000000) * price
    defaultData.netIncomeGrowth[maxIndex - index] = calculateYoyChange(
      "netIncome",
      index,
      data?.netIncome,
      incomeStatementData
    )
    defaultData.incomeTaxExpense[maxIndex - index] =
      (data?.incomeTaxExpense / 1000000) * price
    defaultData.weightedAverageShsOut[maxIndex - index] =
      data?.weightedAverageShsOut / 1000000
    defaultData.weightedAverageShsOutYoy[maxIndex - index] = calculateYoyChange(
      "weightedAverageShsOut",
      index,
      data?.weightedAverageShsOut,
      incomeStatementData
    )
    defaultData.weightedAverageShsOutDil[maxIndex - index] =
      data?.weightedAverageShsOutDil / 1000000
    defaultData.weightedAverageShsOutDilYoy[maxIndex - index] =
      calculateYoyChange(
        "weightedAverageShsOutDil",
        index,
        data?.weightedAverageShsOutDil,
        incomeStatementData
      )
    defaultData.earningPerShare[maxIndex - index] = 0
    defaultData.eps[maxIndex - index] = data?.eps
    defaultData.epsDiluted[maxIndex - index] = data?.epsdiluted
  })
  return { tempHeaders, defaultData }
}
