export const getHeaders = (setFlags) => {
  return [
    {
      title: "Revenue",
      key: "revenue",
      class: "bold-text",
      dataClass: "bold-text",
      defToolTip: {
        key: "revenue",
        type: "Income statement",
      },
    },
    {
      title: "% Growth (YoY)",
      key: "revenueGrowthYoy",
      class: "collapse-row",
      suffix: "%",
      defToolTip: {
        key: "yoy_percent_growth",
        type: "Income statement",
      },
    },
    {
      title: "Cost of Revenue",
      key: "costOfRevenue",
      class: "collapse-row",
      defToolTip: {
        key: "cost_of_revenue",
        type: "Income statement",
      },
    },
    {
      title: "Gross Profit",
      key: "grossProfit",
      class: "bold-text",
      dataClass: "bold-text",
      defToolTip: {
        key: "gross_profit",
        type: "Income statement",
      },
    },
    {
      title: "Operating Expenses",
      isCollapsable: true,
      key: "operatingExpenses",
      flagMethod: setFlags,
      flag: "showOperatingExpenses",
      defToolTip: {
        key: "operating_expenses",
        type: "Income statement",
      },
    },
    {
      title: "Research & Development",
      key: "researchAndDevelopmentExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      defToolTip: {
        key: "research_and_development",
        type: "Income statement",
      },
    },
    {
      title: "General & Admin",
      key: "generalAndAdministrativeExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      defToolTip: {
        key: "general_and_admin",
        type: "Income statement",
      },
    },
    {
      title: "Sales & Marketing",
      key: "sellingAndMarketingExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      defToolTip: {
        key: "sales_and_marketing",
        type: "Income statement",
      },
    },
    {
      title: "Sales, General & Admin",
      key: "sellingGeneralAndAdministrativeExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      defToolTip: {
        key: "selling_general_admin",
        type: "Income statement",
      },
    },
    {
      title: "Total Operating Expenses",
      key: "totalOperatingExpenses",
      class: "bold-text",
      dataClass: "bold-text",
      isNegValue: true,
    },
    {
      title: "Operating Income",
      key: "operatingIncome",
      class: "bold-text",
      dataClass: "bold-text",
      defToolTip: {
        key: "operating_income",
        type: "Income statement",
      },
    },
    {
      title: "% Change YoY",
      key: "operatingIncomeYoy",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Non-Operating Income/Expenses",
      isCollapsable: true,
      key: "nonOperatingExpenses",
      flagMethod: setFlags,
      flag: "showNonOperatingExpenses",
      defToolTip: {
        key: "non_operating_income_expenses",
        type: "Income statement",
      },
    },
    {
      title: "Interest Income",
      key: "interestIncome",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      defToolTip: {
        key: "interest_income",
        type: "Income statement",
      },
    },
    {
      title: "Interest Expense",
      key: "interestExpense",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      defToolTip: {
        key: "interest_expense",
        type: "Income statement",
      },
    },
    {
      title: "Net Interest Income/Expense",
      key: "netInterestIncomeExpense",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      defToolTip: {
        key: "net_interest_income_expense",
        type: "Income statement",
      },
    },
    {
      title: "Other Income/Expense, Non Operating",
      key: "otherIncomeExpensesNonOperating",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      defToolTip: {
        key: "other_income_expense_non_operating",
        type: "Income statement",
      },
    },
    {
      title: "Total Non Operating Income/Expense",
      key: "totalOtherIncomeExpensesNet",
      class: "bold-text",
      dataClass: "bold-text",
      defToolTip: {
        key: "non_operating_income_expense_total",
        type: "Income statement",
      },
    },
    {
      title: "Income Before Taxes",
      key: "incomeBeforeTax",
      defToolTip: {
        key: "income_before_tax_or_ebt",
        type: "Income statement",
      },
    },
    {
      title: "Income Tax",
      key: "incomeTaxExpense",
      class: "collapse-row",
      defToolTip: {
        key: "income_taxes_paid",
        type: "Income statement",
      },
    },
    {
      title: "Net Income",
      key: "netIncome",
      class: "bold-text",
      dataClass: "bold-text",
      defToolTip: {
        key: "net_income",
        type: "Income statement",
      },
    },
    {
      title: "% Growth (YoY)",
      key: "netIncomeGrowth",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Shares Outstanding (Basic)",
      key: "weightedAverageShsOut",
      isNumber: true,
      defToolTip: {
        key: "shares_outstanding_basic",
        type: "Income statement",
      },
    },
    {
      title: "% Change YoY (Basic)",
      key: "weightedAverageShsOutYoy",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Shares Outstanding (Diluted)",
      key: "weightedAverageShsOutDil",
      isNumber: true,
      defToolTip: {
        key: "shares_outstanding_diluted",
        type: "Income statement",
      },
    },
    {
      title: "% Change YoY (Diluted)",
      key: "weightedAverageShsOutDilYoy",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Earning per share (EPS)",
      isCollapsable: true,
      key: "earningPerShare",
      flagMethod: setFlags,
      flag: "showEarningsPerShare",
    },
    {
      title: "Basic",
      key: "eps",
      flag: "showEarningsPerShare",
      class: "collapse-row",
      defToolTip: {
        key: "eps_basic",
        type: "Income statement",
      },
    },
    {
      title: "Diluted",
      key: "epsDiluted",
      flag: "showEarningsPerShare",
      class: "collapse-row",
      defToolTip: {
        key: "eps_diluted",
        type: "Income statement",
      },
    },
  ]
}

export const getPercentageHeaders = (setFlags) => {
  return [
    {
      title: "Revenue",
      key: "revenue",
      class: "bold-text",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Cost of Revenue",
      key: "costOfRevenue",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Gross Profit",
      key: "grossProfit",
      class: "bold-text",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Operating Expenses",
      isCollapsable: true,
      key: "operatingExpenses",
      flagMethod: setFlags,
      flag: "showOperatingExpenses",
      suffix: "%",
    },
    {
      title: "Research & Development",
      key: "researchAndDevelopmentExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      suffix: "%",
    },
    {
      title: "General & Admin",
      key: "generalAndAdministrativeExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      suffix: "%",
    },
    {
      title: "Sales & Marketing",
      key: "sellingAndMarketingExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      suffix: "%",
    },
    {
      title: "Sales, General & Admin",
      key: "sellingGeneralAndAdministrativeExpenses",
      flag: "showOperatingExpenses",
      class: "collapse-row",
      isNegValue: true,
      suffix: "%",
    },
    {
      title: "Total Operating Expenses",
      key: "totalOperatingExpenses",
      flag: "showOperatingExpenses",
      class: "total-expenses",
      dataClass: "bold-text",
      isNegValue: true,
      suffix: "%",
    },
    {
      title: "Operating Income",
      key: "operatingIncome",
      class: "bold-text",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Non-Operating Income/Expenses",
      isCollapsable: true,
      key: "nonOperatingExpenses",
      flagMethod: setFlags,
      flag: "showNonOperatingExpenses",
      defToolTip: {
        key: "non_operating_income_expenses",
        type: "Income statement",
      },
    },
    {
      title: "Interest Income",
      key: "interestIncome",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Interest Expense",
      key: "interestExpense",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Net Interest Income/Expense",
      key: "netInterestIncomeExpense",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      suffix: "%",
      defToolTip: {
        key: "net_interest_income_expense",
        type: "Income statement",
      },
    },
    {
      title: "Other Income/Expense, Non Operating",
      key: "otherIncomeExpensesNonOperating",
      flag: "showNonOperatingExpenses",
      class: "collapse-row",
      suffix: "%",
      defToolTip: {
        key: "other_income_expense_non_operating",
        type: "Income statement",
      },
    },
    {
      title: "Total Non Operating Income/Expense",
      key: "totalOtherIncomeExpensesNet",
      class: "bold-text",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Income Before Taxes",
      key: "incomeBeforeTax",
      suffix: "%",
    },
    {
      title: "Income Tax",
      key: "incomeTaxExpense",
      class: "collapse-row",
      suffix: "%",
    },
    {
      title: "Net Income",
      key: "netIncome",
      class: "bold-text",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Shares Outstanding (Basic)",
      key: "weightedAverageShsOut",
      isNumber: true,
    },
    {
      title: "Shares Outstanding (Diluted)",
      key: "weightedAverageShsOutDil",
      isNumber: true,
    },
    {
      title: "Earning per share (EPS)",
      isCollapsable: true,
      key: "earningPerShare",
      flagMethod: setFlags,
      flag: "showEarningsPerShare",
    },
    {
      title: "Basic",
      key: "eps",
      flag: "showEarningsPerShare",
      class: "collapse-row",
    },
    {
      title: "Diluted",
      key: "epsDiluted",
      flag: "showEarningsPerShare",
      class: "collapse-row",
    },
  ]
}
