import React from "react"
import { Box } from "@mui/material"
import "react-toastify/dist/ReactToastify.css"
import { Route, Routes } from "react-router-dom"

import Header from "../Header"
import Footer from "../Footer"
import Home from "../Home"
import Dashboard from "../Dashboard"
import useStyles from "./styles"
import ReactGA from "react-ga4"

const Landing = () => {
  const classes = useStyles()
  ReactGA.send({ hitType: "pageview", page: "/landing" })

  return (
    <Box className={classes["home-container"]}>
      <Box className={classes["header-container"]}>
        <Header />
      </Box>
      <Box className={classes["content-container"]} flex={1}>
        <Routes>
          <Route path="/" name="dashboard" element={<Home />} />
          <Route path="/dashboard" name="dashboard" element={<Dashboard />} />
        </Routes>
      </Box>
      <Box className={classes["footer-container"]}>
        <Footer />
      </Box>
    </Box>
  )
}

export default Landing
