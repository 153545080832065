import React, { useState, useEffect, useContext } from "react"
import {
  Backdrop,
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import AsyncSelect from "react-select/async"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Loading from "../../Loading"
import useStyles from "./styles"
import { getPeers } from "../../../services/ticker"
import { searchTicker } from "../../../services/core"
import AppDataContext from "../../../context/data"
import SimpleView from "./components/SimpleView"
import DetailedView from "./components/DetailedView"
import { palette } from "../../../utils/Palette"

const Peers = () => {
  const classes = useStyles()
  const { tickerId } = useParams()
  const [displayType, setDisplayType] = useState("SIMPLE")
  const [isLoading, setIsLoading] = useState(false)
  const [isPeerLoading, setIsPeerLoading] = useState(false)
  const [peersData, setPeersData] = useState(null)
  const [peersOpts, setPeersOpts] = useState([])
  const [selectedOpts, setSelectedOpts] = useState([tickerId])
  const { peers, setPeers, overview } = useContext(AppDataContext)
  const [, setTickers] = useState([])

  useEffect(() => {
    document.title = `${tickerId} - Peers - Lets Value Invest`
    setIsLoading(true)
    setDisplayType("SIMPLE")
    setPeersData(null)
    const getPeersData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getPeers(tickerId)
        setPeersOpts(resp?.data?.peers)
        setPeersData({
          data: [
            {
              ...resp?.data?.keyInsightsData,
              tickerId,
              quoteResponse: { tickerId, ...resp?.data?.quoteResponse[0] },
            },
          ],
          tickerId,
          peersOpts: resp?.data?.peers,
        })
        setPeers({
          data: [
            {
              ...resp?.data?.keyInsightsData,
              tickerId,
              quoteResponse: { tickerId, ...resp?.data?.quoteResponse[0] },
            },
          ],
          tickerId,
          peersOpts: resp?.data?.peers,
        })
        setIsLoading(false)
      } catch (err) {
        setPeersOpts([])
        setPeersData(null)
        setPeers(null)
        setIsLoading(false)
        toast.error("Unable to get peers. Please try after sometime.")
      }
    }
    // check if we have data already in context
    if (peers?.tickerId === tickerId) {
      setPeersData(peers)
      setPeersOpts(peers.peersOpts)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getPeersData()
  }, [JSON.stringify(overview), tickerId])

  const getPeersData = async (opts) => {
    // if (!opts?.at(-1)?.value) return
    try {
      if (opts.length !== peersData?.data?.length) {
        const currentOpts = opts.map((opt) => opt.value)
        const removedItems = selectedOpts.filter(
          (opt) => !currentOpts.includes(opt)
        )
        const newData = peersData.data.filter(
          (item) => !removedItems.includes(item.tickerId)
        )
        setPeersData({
          data: [...newData],
          tickerId,
        })
        return
      } else {
        setSelectedOpts(opts.map((opt) => opt.value))
      }
      const searchTkrId = opts?.at(-1)?.value
      setIsPeerLoading(true)
      toast.dismiss()
      // get data only if context is empty and save into context
      const resp = await getPeers(searchTkrId)
      setPeersData({
        data: [
          ...peersData.data,
          {
            ...resp?.data?.keyInsightsData,
            tickerId: searchTkrId,
            quoteResponse: {
              tickerId: searchTkrId,
              ...resp?.data?.quoteResponse[0],
            },
          },
        ],
        tickerId,
        peersOpts: peers.peersOpts,
      })
      setIsPeerLoading(false)
    } catch (err) {
      setIsPeerLoading(false)
      toast.error("Unable to get data. Please try after sometime.")
    }
  }

  const searchTickers = async (searchTxt, callback) => {
    toast.dismiss()
    try {
      setTickers([])
      const tickersResp = await searchTicker(`query=${searchTxt}&limit=10`)
      const tickersData = tickersResp?.data?.map((ticker) => {
        return {
          value: ticker.symbol,
          label: `${ticker?.symbol} - ${ticker?.name}`,
        }
      })
      setTickers(tickersData || [])
      callback(tickersData)
    } catch (err) {
      toast.error("Unable to search for tickers. Please try after sometime.")
    }
  }

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box>
      <Box className={classes["type-container"]}>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={displayType}
          onChange={(e) => setDisplayType(e.target.value)}
          xs={12}
          md={"flex"}
        >
          <ToggleButton
            value="SIMPLE"
            key="SIMPLE"
            className={classes["toggle-btn"]}
          >
            {"SIMPLE"}
          </ToggleButton>
          ,
          <ToggleButton
            value="DETAILED"
            key="DETAILED"
            className={classes["toggle-btn"]}
          >
            {"DETAILED"}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {peersData?.data && (
        <Box marginTop="2rem">
          <Box marginTop="2rem" maxWidth={"40%"}>
            <Typography className={classes["selection-header"]}>
              <b>Add tickers for analysis...</b>
            </Typography>
            <span className={classes["ticker"]}>
              <AsyncSelect
                cacheOptions
                loadOptions={searchTickers}
                defaultOptions
                onChange={(option) => getPeersData(option)}
                isMulti
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: palette.purple,
                  },
                })}
              />
            </span>
          </Box>
          <Box marginTop="0.8rem">
            <span>
              <b>Peers: </b>
            </span>
            {peersOpts?.join(", ")}
          </Box>
          {displayType === "SIMPLE" ? (
            <SimpleView data={peersData?.data} overview={overview} />
          ) : (
            <DetailedView data={peersData?.data} overview={overview} />
          )}
        </Box>
      )}
      <Backdrop open={isPeerLoading}>
        <CircularProgress />
      </Backdrop>
    </Box>
  )
}

export default Peers
