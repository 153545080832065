import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material"
import { Circle, North } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import clsx from "clsx"
// import { Line } from "react-chartjs-2"
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js"

// import { palette } from "../../../../utils/Palette"
import useStyles from "../styles"
import { orderBy } from "lodash"

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// )

const PriceTargets = ({ priceTargets, historicalPrices }) => {
  const classes = useStyles()
  const { tickerId } = useParams()
  const currentPrice = historicalPrices?.[0]?.close || 0
  const { targetHigh, targetLow, targetConsensus } = priceTargets || {}
  const prices = orderBy(
    [
      {
        price: currentPrice,
        type: "Current",
      },
      { price: targetHigh, type: "High" },
      { price: targetLow, type: "Low" },
      { price: targetConsensus, type: "Average" },
    ],
    ["price"],
    ["asc"]
  )
  const changeFromAvg = (
    ((targetConsensus - currentPrice) / targetConsensus) *
    100
  ).toFixed(2)
  // const [chartData, setChartData] = useState(null)

  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   elements: {
  //     point: {
  //       radius: 0,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false,
  //         drawBorder: false,
  //       },
  //       ticks: {
  //         autoSkip: true,
  //         maxTicksLimit: 8,
  //         maxRotation: 0,
  //         minRotation: 0,
  //         font: {
  //           family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
  //         },
  //       },
  //     },
  //     y: {
  //       grid: {
  //         display: false,
  //         drawBorder: false,
  //       },
  //       ticks: {
  //         maxTicksLimit: 8,
  //         autoSkip: true,
  //         font: {
  //           family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
  //         },
  //         // Include a dollar sign in the ticks
  //         callback: function (value) {
  //           return Intl.NumberFormat("hi", {
  //             style: "currency",
  //             currency: "USD",
  //             minimumFractionDigits: 0,
  //           })
  //             .format(value)
  //             .replace(/^(\D+)/, "$1 ")
  //         },
  //         precision: 0,
  //       },
  //     },
  //   },
  //   interaction: {
  //     mode: "x",
  //   },
  //   plugins: {
  //     tooltip: {
  //       displayColors: false,
  //       callbacks: {
  //         title: function (item) {
  //           const titleValue = item[0].formattedValue
  //           return "USD $" + titleValue
  //         },
  //         label: function (item) {
  //           return item.label
  //         },
  //         labelTextColor: function (item) {
  //           return "#757575"
  //         },
  //       },
  //       mode: "index",
  //       intersect: false,
  //       backgroundColor: "white",
  //       borderColor: "#E0E0E0",
  //       borderWidth: 1,
  //       titleColor: "black",
  //       titleFont: {
  //         family: "'Montserrat', 'Arial', sans-serif",
  //       },
  //       bodyFont: {
  //         family: "'Montserrat', 'Arial', sans-serif",
  //       },
  //       yAlign: "bottom",
  //     },
  //     legend: {
  //       display: false,
  //     },
  //     crosshair: {
  //       line: {
  //         color: "#FFFFFF", // crosshair line color
  //         width: 1, // crosshair line width
  //       },
  //       sync: {
  //         enabled: false,
  //       },
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     title: {
  //       display: false,
  //     },
  //   },
  //   layout: {
  //     padding: 10,
  //   },
  //   borderWidth: 1,
  // }

  // useEffect(
  //   () => {
  //     setChartData({
  //       labels: historicalPrices?.map((val) => val.date),
  //       datasets: [
  //         {
  //           label: "Price",
  //           data: historicalPrices?.map((val) => parseInt(val.close)),
  //           borderColor: palette.purple,
  //           backgroundColor: palette.purple,
  //         },
  //       ],
  //     })
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [JSON.stringify(historicalPrices), priceTarget]
  // )

  const getColor = (type) => {
    switch (type) {
      case "High":
        return "success-dark"
      case "Low":
        return "error-dark"
      case "Average":
        return "warning-dark"
      default:
        return "purple"
    }
  }

  const getWidth = (start, current, end) => {
    const first = end - start
    const second = current - start
    return `${(second * 100) / first}%`
  }

  const getPoint = (price) => {
    if (price.type === "Current") {
      return (
        <>
          <North
            className={clsx(
              classes["price-current"],
              classes[getColor(price.type)]
            )}
          />
          <span className={classes[getColor(price.type)]}>
            {price.type}: {price.price}
          </span>
        </>
      )
    }
    return (
      <>
        <Circle
          className={clsx(
            classes["price-circle"],
            classes[getColor(price.type)]
          )}
        />
        <span className={classes[getColor(price.type)]}>
          {price.type}: {price.price}
        </span>
      </>
    )
  }

  return (
    <Box height={"100%"}>
      <TableContainer component={Paper} style={{ height: "100%" }}>
        <Table sx={{ height: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={classes["main-header"]}>Price Targets</div>
                <div className={classes["sub-header"]}>
                  Based on analysts offering 12 month price targets for{" "}
                  {tickerId} in the last 3 months.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {/* <Box width="100%" height="100%">
                  {chartData && <Line options={options} data={chartData} />}
                </Box> */}
                <div
                  style={{ width: "75%", paddingTop: "1rem", margin: "auto" }}
                >
                  <div className={classes["range-line"]}></div>
                  <Box display={"flex"} flexDirection="row">
                    <Box
                      style={{
                        width: getWidth(
                          prices[0].price,
                          prices[1].price,
                          prices[3].price
                        ),
                      }}
                      className={classes["price-box"]}
                    >
                      {getPoint(prices[0])}
                    </Box>
                    <Box
                      style={{
                        width: getWidth(
                          prices[1].price,
                          prices[2].price,
                          prices[3].price
                        ),
                      }}
                      className={classes["price-box"]}
                    >
                      {getPoint(prices[1])}
                    </Box>
                    <Box className={classes["price-box"]}>
                      {getPoint(prices[2])}
                    </Box>
                    <Box
                      style={{ marginLeft: "auto", alignItems: "end" }}
                      className={clsx(
                        classes["price-box"],
                        classes[getColor(prices[0].type)]
                      )}
                    >
                      {getPoint(prices[3])}
                    </Box>
                  </Box>
                </div>
                <div style={{ marginTop: "0.5rem" }}>
                  The average price target is{" "}
                  <strong>${targetConsensus}</strong> with a high estimate of{" "}
                  <strong>${targetHigh}</strong> and a low estimate of{" "}
                  <strong>${targetLow}</strong>. Thus the average price target
                  represents an {changeFromAvg > 0 ? "UPSIDE" : "DOWNSIDE"} of{" "}
                  <strong>{changeFromAvg}%</strong> from the current price of{" "}
                  <strong>${currentPrice}</strong>.
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PriceTargets

PriceTargets.propTypes = {
  priceTarget: PropTypes.object,
  historicalPrices: PropTypes.array.isRequired,
}
