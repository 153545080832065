import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { HelpOutline } from "@mui/icons-material"
import {
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Link,
} from "@mui/material"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { palette } from "./Palette"
import AppDataContext from "../context/data"

const Term = ({ config }) => {
  const { type, key } = config || {}
  const [anchorEl, setAnchorEl] = useState(null)
  const [data, setData] = useState(null)
  const [open, setOpen] = useState(false)

  const { strapiTerms } = useContext(AppDataContext)

  useEffect(() => {
    const term =
      strapiTerms[type]?.filter((term) => term?.key === key)?.[0] || null
    setData(term)
  }, [type, key, strapiTerms])

  if (!data || !config || !config?.type) {
    return <></>
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null)
          setOpen(false)
        }}
      >
        <Box>
          <HelpOutline
            style={{ color: palette["purple-2"] }}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={(event) => {
              event.preventDefault()
              setAnchorEl(event.currentTarget)
              setOpen(true)
            }}
            className={"cursor-pointer"}
            // onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
            // onMouseLeave={() => setAnchorEl(null)}
          />
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={() => {
              setAnchorEl(null)
              setOpen(false)
            }}
            disableRestoreFocus
          >
            <Card onClick={() => setOpen(false)}>
              <CardContent>
                <TableContainer
                  sx={{
                    maxWidth: "30rem",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>
                            <strong>{data?.title}</strong>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <ReactMarkdown
                            children={data?.definition}
                            remarkPlugins={[remarkGfm]}
                          />
                        </TableCell>
                      </TableRow>
                      {data?.formula && (
                        <TableRow>
                          <TableCell>
                            <ReactMarkdown
                              children={data?.formula}
                              remarkPlugins={[remarkGfm]}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {data?.sources && data.sources.length > 0 && (
                        <TableRow>
                          <TableCell>
                            <h3>Sources and further readings:</h3>
                            <ul>
                              {data?.sources?.length > 0
                                ? data.sources.map((source, index) => (
                                    <li key="{source.name}">
                                      <Link
                                        href={source.url}
                                        target="_blank"
                                        underline="always"
                                      >
                                        {source.name}
                                      </Link>
                                    </li>
                                  ))
                                : "Hello"}
                            </ul>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Popover>
        </Box>
      </ClickAwayListener>
    </>
  )
}

export default Term

Term.propTypes = {
  config: PropTypes.object,
}
