import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "home-container": {
    height: "100%",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  "main-container": {
    marginTop: "65px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  "content-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  "header-text": {
    paddingBottom: "20px",
    fontSize: "18px !important",
    textAlign: "center",
  },
  "search-area": {
    width: "25%",
    minWidth: "200px",
  },
  "recent-search-box": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  "recent-search": {
    paddingTop: "12px",
    fontStyle: "italic",
    color: theme.palette["grey-2"],
  },
  "recent-search-txt": {
    paddingTop: "12px",
    paddingLeft: "12px",
    fontStyle: "normal",
    color: theme.palette.purple,
    cursor: "pointer",
  },
}))
