export const getFormatedTerms = (data = []) => {
  const terms = {}
  data?.forEach((term) => {
    term?.tags.forEach((tag) => {
      if (!terms[tag]) terms[tag] = []
      terms[tag].push(term)
    })
  })
  return terms
}
