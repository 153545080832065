import React from "react"
import {
  Box,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Alert,
  Grid,
  Paper,
} from "@mui/material"

import useStyles from "./styles"
import { TextThousandSeparator } from "../../../utils/NumberFormatters"

const DividendSummary = ({ dividendsData, tickerId }) => {
  const classes = useStyles()

  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Alert severity="info" className={classes.alert}>
          <strong>{tickerId}</strong> has a current dividend yield of{" "}
          <strong>{dividendsData?.summary?.dividendYield}%</strong> and a
          forward dividend of $
          <strong>{dividendsData?.summary?.forwardDividend}</strong>. The
          dividend is paid on a{" "}
          <strong>{dividendsData?.summary?.payoutFrequency}</strong> basis and
          the last ex-dividend date was{" "}
          <strong>{dividendsData?.summary?.exDividendDate}</strong>
        </Alert>
      </Grid>
      <Grid item xs={12} sm={12} md={12}></Grid>
      <Grid item xs={12} sm={6} md={3}>
        {dividendsData ? (
          <Box className={classes["big-numbers"]}>
            <TableContainer
              component={Paper}
              className={classes["table-container"]}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <div className={classes["main-header"]}>
                        Dividend Information
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      DIVIDEND YIELD
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.summary?.dividendYield,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      FORWARD DIVIDEND
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(dividendsData?.summary?.forwardDividend)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      EX-DIVIDEND DATE
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {dividendsData?.summary?.exDividendDate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      PAYOUT FREQUENCY
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {dividendsData?.summary?.payoutFrequency}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      PAYOUT RATIO
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.summary?.payoutRatio,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          "Hello"
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {dividendsData ? (
          <Box className={classes["big-numbers"]}>
            <TableContainer
              component={Paper}
              className={classes["table-container"]}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <div className={classes["main-header"]}>
                        Dividend Growth Rates
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      ONE YEAR
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.growthRates?.oneYear,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      THREE YEAR
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.growthRates?.threeYear,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      FIVE YEAR
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.growthRates?.threeYear,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes["row-header"]} align="left">
                      TEN YEAR
                    </TableCell>
                    <TableCell className={classes["row-value"]} align="right">
                      {displayValue(
                        dividendsData?.growthRates?.tenYear,
                        "%",
                        false,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={classes["row-header"]}
                      align="left"
                    ></TableCell>
                    <TableCell
                      className={classes["row-value"]}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          "Hello"
        )}
      </Grid>
    </Grid>
  )
}

export default DividendSummary
