import React from "react"
import { makeStyles, withStyles } from "@mui/styles"

import { LinearProgress, Typography } from "@mui/material"

import { palette } from "../../../../../../utils/Palette"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  rateText: {
    marginTop: "-1.3rem",
    color: palette.white,
    zIndex: 100,
    position: "absolute",
    marginLeft: "5px",
    fontWeight: "600",
  },
})

const Rating = ({ value, rawValue, title }) => {
  const classes = useStyles()

  const getBgColor = () => {
    if (rawValue === 100) return palette["score-5-bg"]
    else return palette.primary
  }

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: "1.4rem !important",
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: `${palette["grey-4"]} !important`,
    },
    bar: {
      borderRadius: 0,
      backgroundColor: `${getBgColor()} !important`,
    },
  }))(LinearProgress)
  return (
    <div className={classes.root}>
      <Typography>{title}</Typography>
      <BorderLinearProgress variant="determinate" value={value} />
      <div className={classes.rateText}>
        {Math.round(rawValue * 100) / 100}
        {"%"}
      </div>
    </div>
  )
}

export default Rating
