import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const ShareStatistics = ({ data }) => {
  const classes = useStyles()

  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Share Statistics</div>
                <div className={classes["sub-header"]}>
                  Share price movements in the recent past
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Previous Close
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "previous_close",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.previousClose)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  Day Range
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "day_range",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.dayLow)}
                {" - "}
                {displayValue(data?.dayHigh)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  52 WEEK PRICE Range
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "52_week_range",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.yearLow)}
                {" - "}
                {displayValue(data?.yearHigh)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  50 DAY MOVING AVERAGE
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "52_day_moving_average",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.priceAvg50)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes["row-header"]} align="left">
                <Box display={"flex"} alignItems="center">
                  200 DAY MOVING AVERAGE
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "200_day_moving_average",
                        type: "Overview",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell className={classes["row-value"]} align="right">
                {displayValue(data?.priceAvg200)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ShareStatistics

ShareStatistics.propTypes = {
  data: PropTypes.object.isRequired,
}
