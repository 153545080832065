import axios from "axios"

const baseURL = `${process.env.REACT_APP_BASE_API}`

export const getOverviewData = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/overview/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getIncomeStatement = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/income-statement/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getBalanceSheet = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/balance-sheet-statement/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getCashFlowStatement = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/cash-flow-statement/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getRatios = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/ratios/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getProsAndCons = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/pros-cons/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getKeyFinancials = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/key-financials/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getKeyInsights = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/key-insights/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getValuationRef = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/valuation/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getProfile = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/profile/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getSECFilings = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/sec-filings/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getOwnershipShareholders = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/ownerships/shareholders/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getOwnershipInsiderTransactions = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/ownerships/insider-transactions/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getDividends = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/dividends/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getAnalystsDataByTickerId = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/analysts/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getPeers = async (tickerId) => {
  return axios.get(`${baseURL}/api/v1/peers/${tickerId}`).catch((err) => {
    throw new Error(err)
  })
}

export const getRevenueSegments = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/revenue-segments/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getEarningCallTranscripts = async (
  tickerId,
  queryParams = null
) => {
  return axios
    .get(
      queryParams
        ? `${baseURL}/api/v1/earning-call-transcripts/${tickerId}?${queryParams}`
        : `${baseURL}/api/v1/earning-call-transcripts/${tickerId}`
    )
    .catch((err) => {
      throw new Error(err)
    })
}

export const getFingraphs = async (tickerId) => {
  return axios
    .get(`${baseURL}/api/v1/financial-graphs/${tickerId}`)
    .catch((err) => {
      throw new Error(err)
    })
}

export const getIndexes = async () => {
  return axios.get(`${baseURL}/api/v1/constituents`).catch((err) => {
    throw new Error(err)
  })
}

export const getLogs = async () => {
  return axios.get(`${baseURL}/api/v1/batch-financials/logs`).catch((err) => {
    throw new Error(err)
  })
}
