import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "home-container": {
    height: "100%",
  },
  "header-container": {
    position: "sticky",
    marginTop: "0px",
    width: "100%",
  },
  "footer-container": {
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
  "tabs-nav": {
    /* marginTop: "100px", */
    /* position: "fixed", */
    marginBottom: "-50px",
    backgroundColor: "white",
    width: "100%",
    zIndex: 1,
  },
}))
