import React, { useEffect, useState } from "react"
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Doughnut } from "react-chartjs-2"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"

ChartJS.register(ArcElement, Title, Tooltip, Legend)
// ChartJS.register(ChartDataLabels)
defaults.font.family = palette.fontFamily

const HoldersChart = ({
  insiderOwnership,
  institutionalOwnership,
  retailOwnership,
  tickerId,
}) => {
  const [chartData, setChartData] = useState(null)

  const options = {
    responsive: true,
    layout: {
      padding: 10,
    },
    labels: [
      "Insider Owndership",
      "Institutional Ownership",
      "Retail Ownership",
    ],
    cutout: "0%",
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        color: "white",
        formatter: (value, context) => {
          return value.toFixed(2) + "%"
        },
      },
    },
  }

  useEffect(
    () => {
      setChartData({
        labels: ["Insiders", "Institutionals", "Retailers"],
        datasets: [
          {
            labels: [
              "Insider Owndership",
              "Institutional Ownership",
              "Retail Ownership",
            ],
            data: [insiderOwnership, institutionalOwnership, retailOwnership],
            backgroundColor: [palette["score-5-bg"], "#DEB887", palette.purple],
          },
        ],
        text: `Ownership structure: ${tickerId}%`,
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [insiderOwnership, institutionalOwnership, retailOwnership, tickerId]
  )

  if (!insiderOwnership) return <></>

  return (
    <Box position={"relative"}>
      {chartData && (
        <Doughnut
          options={options}
          data={chartData}
          plugins={[ChartDataLabels]}
        />
      )}
    </Box>
  )
}

export default HoldersChart

HoldersChart.propTypes = {
  insiderOwnership: PropTypes.any.isRequired,
  retailOwnership: PropTypes.any.isRequired,
  institutionalOwnership: PropTypes.any.isRequired,
  tickerId: PropTypes.string.isRequired,
}
