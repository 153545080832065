import React from "react"
import { makeStyles, withStyles } from "@mui/styles"

import { LinearProgress } from "@mui/material"

import { palette } from "../../../../../utils/Palette"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  rateText: {
    marginTop: "-1.3rem",
    color: palette.white,
    zIndex: 100,
    position: "absolute",
    marginLeft: "20px",
    fontWeight: "600",
  },
})

const Rating = ({ value, rawValue }) => {
  const classes = useStyles()

  const getBgColor = () => {
    if (rawValue >= 4) return palette["score-5-bg"]
    if (rawValue >= 3) return palette["score-4-bg"]
    if (rawValue >= 2) return palette["score-3-bg"]
    if (rawValue >= 1) return palette["score-1-bg"]
    if (rawValue < 1) return palette["score-2-bg"]
  }

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: "1.4rem !important",
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: `${palette["grey-4"]} !important`,
    },
    bar: {
      borderRadius: 0,
      backgroundColor: `${getBgColor()} !important`,
    },
  }))(LinearProgress)
  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={value} />
      <div className={classes.rateText}>{rawValue}</div>
    </div>
  )
}

export default Rating
