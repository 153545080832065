export const getHeaders = (setFlags) => {
  return [
    {
      title: "Price multiples",
      key: "priceMultiples",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showPriceMultiples",
    },
    {
      title: "Price to Sales Ratio",
      key: "priceToSalesRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_sales_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price to Earnings Ratio",
      key: "priceEarningsRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_earnings_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price to Book Ratio",
      key: "priceToBookRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_book_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price to Tangible Book Ratio",
      key: "priceToTangibleBookRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_tangible_book_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price to Operating Cash Flow Ratio",
      key: "priceToOperatingCashFlowsRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_ocf_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price to Free Cash Flow Ratio",
      key: "priceToFreeCashFlowsRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "price_to_fcf_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Price Earnings to Growth Ratio (PEG)",
      key: "priceEarningsToGrowthRatio",
      class: "collapse-row",
      flag: "showPriceMultiples",
      suffix: "x",
      defToolTip: {
        key: "peg_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Management effectiveness",
      key: "managementEffectiveness",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showManagementEffectiveness",
    },
    {
      title: "Return on Assets (ROA)",
      key: "returnOnAssets",
      class: "collapse-row",
      flag: "showManagementEffectiveness",
      suffix: "%",
      defToolTip: {
        key: "return_on_assets",
        type: "Financial Ratios",
      },
    },
    {
      title: "Return on Equity (ROE)",
      key: "returnOnEquity",
      class: "collapse-row",
      flag: "showManagementEffectiveness",
      suffix: "%",
      defToolTip: {
        key: "return_on_equity",
        type: "Financial Ratios",
      },
    },
    {
      title: "Return on Capital Employed (ROCE)",
      key: "returnOnCapitalEmployed",
      class: "collapse-row",
      flag: "showManagementEffectiveness",
      suffix: "%",
      defToolTip: {
        key: "return_on_capital_employed",
        type: "Financial Ratios",
      },
    },
    {
      title: "Margins/Profitability",
      key: "profitability",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showProfitability",
      suffix: "%",
    },
    {
      title: "Gross Profit Margin",
      key: "grossProfitMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "gross_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Operating Profit Margin",
      key: "operatingProfitMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "operating_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Pretax Profit Margin",
      key: "pretaxProfitMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "pretax_profit_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Net Profit Margin",
      key: "netProfitMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "net_profit_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Operating Cash Flow Margin",
      key: "operatingCashFlowMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "ocf_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Free Cash Flow Margin",
      key: "freeCashFlowMargin",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "free_cashflow_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "FCF to Net Income",
      key: "fcfToNetIncome",
      class: "collapse-row",
      flag: "showProfitability",
      suffix: "%",
      defToolTip: {
        key: "fct_to_net_income_margin",
        type: "Financial Ratios",
      },
    },
    {
      title: "Financial Health (Short Term)",
      key: "shortTermFinancialHealth",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showShortTermFinancialHealth",
    },
    {
      title: "Current Ratio",
      key: "currentRatio",
      class: "collapse-row",
      flag: "showShortTermFinancialHealth",
      suffix: "x",
      defToolTip: {
        key: "current_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Quick Ratio",
      key: "quickRatio",
      class: "collapse-row",
      flag: "showShortTermFinancialHealth",
      suffix: "x",
      defToolTip: {
        key: "quick_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Cash Ratio",
      key: "cashRatio",
      class: "collapse-row",
      flag: "showShortTermFinancialHealth",
      suffix: "x",
      defToolTip: {
        key: "cash_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Interest Coverage",
      key: "interestCoverage",
      class: "collapse-row",
      flag: "showShortTermFinancialHealth",
      suffix: "x",
      defToolTip: {
        key: "interest_coverage_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Financial Health (Long Term)",
      key: "longTermFinancialHealth",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showLongTermFinancialHealth",
    },
    {
      title: "Debt to Equity Ratio",
      key: "debtEquityRatio",
      class: "collapse-row",
      flag: "showLongTermFinancialHealth",
      suffix: "%",
      defToolTip: {
        key: "debt_to_equity_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Cash Flow To Debt Ratio",
      key: "cashFlowToDebtRatio",
      class: "collapse-row",
      flag: "showLongTermFinancialHealth",
      suffix: "%",
      defToolTip: {
        key: "cash_flow_to_debt_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Long Term Debt To Capitalization",
      key: "longTermDebtToCapitalization",
      class: "collapse-row",
      flag: "showLongTermFinancialHealth",
      suffix: "%",
      defToolTip: {
        key: "long_term_debt_to_capitalization_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Total Debt To Capitalization",
      key: "totalDebtToCapitalization",
      class: "collapse-row",
      flag: "showLongTermFinancialHealth",
      suffix: "%",
      defToolTip: {
        key: "total_debt_to_capitalization_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Dividend Yield and Coverage",
      isCollapsable: true,
      key: "dividendInfo",
      flagMethod: setFlags,
      flag: "showDividendInfo",
    },
    {
      title: "Dividend Yield",
      key: "dividendYield",
      class: "collapse-row",
      flag: "showDividendInfo",
      suffix: "%",
      defToolTip: {
        key: "dividend_yield",
        type: "Financial Ratios",
      },
    },
    {
      title: "Payout Ratio (Net Income)",
      key: "dividendPayoutRatio",
      class: "collapse-row",
      flag: "showDividendInfo",
      suffix: "%",
      defToolTip: {
        key: "dividend_payout_ratio",
        type: "Dividend Info",
      },
    },
    {
      title: "Payout Ratio (Free Cash Flow)",
      key: "fcfPayoutRatio",
      class: "collapse-row",
      flag: "showDividendInfo",
      suffix: "%",
      defToolTip: {
        key: "dividend_fcf_payout_ratio",
        type: "Dividend Info",
      },
    },
    {
      title: "Cycles and Turnovers",
      key: "cyclesAndTurnovers",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showCyclesAndTurnovers",
    },
    {
      title: "Days Of Sales Outstanding",
      key: "daysOfSalesOutstanding",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "days_of_sales_outstanding",
        type: "Financial Ratios",
      },
    },
    {
      title: "Days Of Inventory Outstanding",
      key: "daysOfInventoryOutstanding",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "days_of_inventory_outstanding",
        type: "Financial Ratios",
      },
    },
    {
      title: "Operating Cycle",
      key: "operatingCycle",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "operating_cycle",
        type: "Financial Ratios",
      },
    },
    {
      title: "Days Of Payables Outstanding",
      key: "daysOfPayablesOutstanding",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "days_of_payables_outstanding",
        type: "Financial Ratios",
      },
    },
    {
      title: "Cash Conversion Cycle",
      key: "cashConversionCycle",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "cash_conversion_cycle",
        type: "Financial Ratios",
      },
    },
    {
      title: "Receivables Turnover",
      key: "receivablesTurnover",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "receivables_turnover",
        type: "Financial Ratios",
      },
    },
    {
      title: "Payables Turnover",
      key: "payablesTurnover",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "payables_turnover",
        type: "Financial Ratios",
      },
    },
    {
      title: "Inventory Turnover",
      key: "inventoryTurnover",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "inventory_turnover",
        type: "Financial Ratios",
      },
    },
    {
      title: "Fixed Asset Turnover",
      key: "fixedAssetTurnover",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "fixed_asset_turnover",
        type: "Financial Ratios",
      },
    },
    {
      title: "Asset Turnover",
      key: "assetTurnover",
      class: "collapse-row",
      flag: "showCyclesAndTurnovers",
      suffix: "x",
      defToolTip: {
        key: "asset_turnover",
        type: "Financial Ratios",
      },
    },
  ]
}
