export const getHeaders = (setFlags) => {
  return [
    {
      title: "Assets",
      key: "assets",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showAssets",
      dependentFlags: [
        "showCurrentAssets",
        "showCurrentAssetsCash",
        "showNonCurrentAssets",
        "showNonCurrentAssetsProperty",
      ],
      defToolTip: {
        key: "assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Current Assets",
      key: "curAssets",
      isCollapsable: true,
      class: "collapse-row-1",
      flagMethod: setFlags,
      toggleFlag: "showCurrentAssets",
      dependentFlags: ["showCurrentAssetsCash"],
      displayFlag: "showAssets",
    },
    {
      title: "Cash",
      key: "cash",
      class: "collapse-row-2",
      flagMethod: setFlags,
      toggleFlag: "showCurrentAssetsCash",
      displayFlag: "showCurrentAssets",
      isCollapsable: true,
      defToolTip: {
        key: "balance_sheet_cash",
        type: "Balance sheet",
      },
    },
    {
      title: "Cash & cash equivalents",
      class: "collapse-row-4",
      key: "cashEquivalents",
      displayFlag: "showCurrentAssetsCash",
      defToolTip: {
        key: "cash_and_cash_equivalents",
        type: "Balance sheet",
      },
    },
    {
      title: "Short-term investments",
      class: "collapse-row-4",
      key: "shortInvestments",
      displayFlag: "showCurrentAssetsCash",
      defToolTip: {
        key: "short_term_investments",
        type: "Balance sheet",
      },
    },
    {
      title: "Total Cash",
      class: "total-row",
      key: "totalCash",
      displayFlag: "showCurrentAssetsCash",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_cash",
        type: "Balance sheet",
      },
    },
    {
      title: "Receivables",
      key: "netReceivables",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      defToolTip: {
        key: "net_receivables",
        type: "Balance sheet",
      },
    },
    {
      title: "Inventories",
      key: "inventory",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      defToolTip: {
        key: "inventory",
        type: "Balance sheet",
      },
    },
    {
      title: "Other current assets",
      key: "otherCurrentAssets",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      defToolTip: {
        key: "prepaid_expenses_other_current_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Total current assets",
      key: "totalCurrentAssets",
      class: "total-row-2",
      dataClass: "bold-text",
      displayFlag: "showCurrentAssets",
      defToolTip: {
        key: "total_current_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Non-current Assets",
      key: "nonCurrentAssets",
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentAssets",
      dependentFlags: ["showNonCurrentAssetsProperty"],
      displayFlag: "showAssets",
      class: "collapse-row-1",
      isCollapsable: true,
      defToolTip: {
        key: "non_current_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Property, plant & Equipments",
      key: "propertyPlantEquipment",
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentAssetsProperty",
      displayFlag: "showNonCurrentAssets",
      isCollapsable: true,
      class: "collapse-row-2",
      defToolTip: {
        key: "property_plant_equipment",
        type: "Balance sheet",
      },
    },
    {
      title: "Gross Property, plant & Equipments",
      key: "propertyPlantEquipmentNet",
      displayFlag: "showNonCurrentAssetsProperty",
      class: "collapse-row-4",
      defToolTip: {
        key: "gross_property_plant_equipment",
        type: "Balance sheet",
      },
    },
    // {
    //   title: "Accumulated Depreciation",
    //   key: "totalOtherIncomeExpensesNet",
    //   class: "bold-text",
    //   dataClass: "bold-text",
    // },
    // {
    //   title: "Net property, plant & Equipments",
    //   key: "incomeBeforeTax",
    // },
    {
      title: "Long term investments",
      key: "longTermInvestments",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      defToolTip: {
        key: "long_term_investments",
        type: "Balance sheet",
      },
    },
    {
      title: "Goodwill",
      key: "goodwill",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      defToolTip: {
        key: "goodwill",
        type: "Balance sheet",
      },
    },
    {
      title: "Intangible Assets",
      key: "intangibleAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      defToolTip: {
        key: "intangible_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Deferred income taxes",
      key: "taxAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      defToolTip: {
        key: "deferred_income_taxes",
        type: "Balance sheet",
      },
    },
    {
      title: "Other long-term assets",
      key: "otherNonCurrentAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      defToolTip: {
        key: "other_long_term_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Total non-current assets",
      key: "totalNonCurrentAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_non_current_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Total assets",
      key: "totalAssets",
      displayFlag: "showAssets",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_assets",
        type: "Balance sheet",
      },
    },
    {
      title: "Liabilities and stockholder's Equity",
      key: "lsEquity",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showLiabilitiesAndSHEquity",
      dependentFlags: [
        "showLiabilities",
        "showStockHldsEquity",
        "showCurrentLiabilities",
        "showNonCurrentLiabilities",
      ],
      defToolTip: {
        key: "liabilities_and_stockholder's_equity",
        type: "Balance sheet",
      },
    },
    {
      title: "Liabilities",
      key: "liabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showLiabilities",
      displayFlag: "showLiabilitiesAndSHEquity",
      dependentFlags: ["showCurrentLiabilities", "showNonCurrentLiabilities"],
      class: "collapse-row-1",
      defToolTip: {
        key: "liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Current Liabilities",
      key: "curLiabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showCurrentLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2",
      defToolTip: {
        key: "current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Short-term debt",
      key: "shortTermDebt",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "short_term_debt",
        type: "Balance sheet",
      },
    },
    {
      title: "Deferred Revenues",
      key: "deferredRevenue",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "deferred_revenues",
        type: "Balance sheet",
      },
    },
    {
      title: "Account Payables",
      key: "accountPayables",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "account_payables",
        type: "Balance sheet",
      },
    },
    {
      title: "Tax Payables",
      key: "taxPayables",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "tax_payables",
        type: "Balance sheet",
      },
    },
    {
      title: "Other current liabilities",
      key: "otherCurrentLiabilities",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "other_current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Total current liabilities",
      key: "totalCurrentLiabilities",
      displayFlag: "showCurrentLiabilities",
      class: "total-row",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Non-current liabilities",
      key: "nonCurrentLiabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2",
      defToolTip: {
        key: "non_current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Long term debt",
      key: "longTermDebt",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "long_term_debt",
        type: "Balance sheet",
      },
    },
    {
      title: "Deferred taxes liabilities",
      key: "deferredTaxLiabilitiesNonCurrent",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "deferred_taxes_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Deferred Revenues",
      key: "deferredRevenueNonCurrent",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "deferred_revenues_long_term",
        type: "Balance sheet",
      },
    },
    {
      title: "Capital Lease Obligations",
      key: "capitalLeaseObligations",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "capital_lease_obligations",
        type: "Balance sheet",
      },
    },
    {
      title: "Minority Interest",
      key: "minorityInterest",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "minority_interest",
        type: "Balance sheet",
      },
    },
    {
      title: "Other non-current liabilities",
      key: "otherNonCurrentLiabilities",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      defToolTip: {
        key: "other_non_current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Total non-current liabilities",
      key: "totalNonCurrentLiabilities",
      displayFlag: "showNonCurrentLiabilities",
      class: "total-row",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_non_current_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Other Liabilities",
      key: "otherLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-6",
    },
    {
      title: "Total Liabilities",
      key: "totalLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2-bold",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_liabilities",
        type: "Balance sheet",
      },
    },
    {
      title: "Stockholder's equity",
      key: "stockHolderEquity",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showStockHldsEquity",
      displayFlag: "showLiabilitiesAndSHEquity",
      class: "collapse-row-1",
      defToolTip: {
        key: "stockholders_equity",
        type: "Balance sheet",
      },
    },
    {
      title: "Preferred stock",
      key: "preferredStock",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      defToolTip: {
        key: "preferred_stock",
        type: "Balance sheet",
      },
    },
    {
      title: "Common stock",
      key: "commonStock",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      defToolTip: {
        key: "common_stock",
        type: "Balance sheet",
      },
    },
    {
      title: "Retained Earnings",
      key: "retainedEarnings",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      defToolTip: {
        key: "retained_earnings",
        type: "Balance sheet",
      },
    },
    {
      title: "Comprehensive income",
      key: "accumulatedOtherComprehensiveIncomeLoss",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      defToolTip: {
        key: "comprehensive_income",
        type: "Balance sheet",
      },
    },
    {
      title: "Total stockholder's equity",
      key: "totalStockholdersEquity",
      displayFlag: "showStockHldsEquity",
      class: "total-row-2",
      dataClass: "bold-text",
      defToolTip: {
        key: "total_stockholders_equity",
        type: "Balance sheet",
      },
    },
    {
      title: "Total liabilities and shareholder's equity",
      key: "totalLiabilitiesAndStockholdersEquity",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      displayFlag: "showLiabilitiesAndSHEquity",
      defToolTip: {
        key: "total_liabilities_and_shareholders_equity",
        type: "Balance sheet",
      },
    },
  ]
}

export const getPercentageHeaders = (setFlags) => {
  return [
    {
      title: "Assets",
      key: "assets",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showAssets",
      dependentFlags: [
        "showCurrentAssets",
        "showCurrentAssetsCash",
        "showNonCurrentAssets",
        "showNonCurrentAssetsProperty",
      ],
      suffix: "%",
    },
    {
      title: "Current Assets",
      key: "curAssets",
      isCollapsable: true,
      class: "collapse-row-1",
      flagMethod: setFlags,
      toggleFlag: "showCurrentAssets",
      dependentFlags: ["showCurrentAssetsCash"],
      displayFlag: "showAssets",
      suffix: "%",
    },
    {
      title: "Cash",
      key: "cash",
      class: "collapse-row-2",
      flagMethod: setFlags,
      toggleFlag: "showCurrentAssetsCash",
      displayFlag: "showCurrentAssets",
      isCollapsable: true,
      suffix: "%",
    },
    {
      title: "Cash & cash equivalents",
      class: "collapse-row-4",
      key: "cashEquivalents",
      displayFlag: "showCurrentAssetsCash",
      suffix: "%",
    },
    {
      title: "Short-term investments",
      class: "collapse-row-4",
      key: "shortInvestments",
      displayFlag: "showCurrentAssetsCash",
      suffix: "%",
    },
    {
      title: "Total Cash",
      class: "total-row",
      key: "totalCash",
      displayFlag: "showCurrentAssetsCash",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Receivables",
      key: "netReceivables",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      suffix: "%",
    },
    {
      title: "Inventories",
      key: "inventory",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      suffix: "%",
    },
    {
      title: "Other current assets",
      key: "otherCurrentAssets",
      class: "collapse-row-3",
      displayFlag: "showCurrentAssets",
      suffix: "%",
    },
    {
      title: "Total current assets",
      key: "totalCurrentAssets",
      class: "total-row-2",
      dataClass: "bold-text",
      displayFlag: "showCurrentAssets",
      suffix: "%",
    },
    {
      title: "Non-current Assets",
      key: "nonCurrentAssets",
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentAssets",
      dependentFlags: ["showNonCurrentAssetsProperty"],
      displayFlag: "showAssets",
      class: "collapse-row-1",
      isCollapsable: true,
      suffix: "%",
    },
    {
      title: "Property, plant & Equipments",
      key: "propertyPlantEquipment",
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentAssetsProperty",
      displayFlag: "showNonCurrentAssets",
      isCollapsable: true,
      class: "collapse-row-2",
      suffix: "%",
    },
    {
      title: "Gross Property, plant & Equipments",
      key: "propertyPlantEquipmentNet",
      displayFlag: "showNonCurrentAssetsProperty",
      class: "collapse-row-4",
      suffix: "%",
    },
    // {
    //   title: "Accumulated Depreciation",
    //   key: "totalOtherIncomeExpensesNet",
    //   class: "bold-text",
    //   dataClass: "bold-text",
    // },
    // {
    //   title: "Net property, plant & Equipments",
    //   key: "incomeBeforeTax",
    // },
    {
      title: "Long term investments",
      key: "longTermInvestments",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Goodwill",
      key: "goodwill",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Intangible Assets",
      key: "intangibleAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Deferred income taxes",
      key: "taxAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Other long-term assets",
      key: "otherNonCurrentAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Total non-current assets",
      key: "totalNonCurrentAssets",
      displayFlag: "showNonCurrentAssets",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Total assets",
      key: "totalAssets",
      displayFlag: "showAssets",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Liabilities and stockholder's Equity",
      key: "lsEquity",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showLiabilitiesAndSHEquity",
      dependentFlags: [
        "showLiabilities",
        "showStockHldsEquity",
        "showCurrentLiabilities",
        "showNonCurrentLiabilities",
      ],
      suffix: "%",
    },
    {
      title: "Liabilities",
      key: "liabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showLiabilities",
      displayFlag: "showLiabilitiesAndSHEquity",
      dependentFlags: ["showCurrentLiabilities", "showNonCurrentLiabilities"],
      class: "collapse-row-1",
      suffix: "%",
    },
    {
      title: "Current Liabilities",
      key: "curLiabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showCurrentLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2",
      suffix: "%",
    },
    {
      title: "Short-term debt",
      key: "shortTermDebt",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Deferred Revenues",
      key: "deferredRevenue",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Account Payables",
      key: "accountPayables",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
    },
    {
      title: "Tax Payables",
      key: "taxPayables",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
    },
    {
      title: "Other current liabilities",
      key: "otherCurrentLiabilities",
      displayFlag: "showCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Total current liabilities",
      key: "totalCurrentLiabilities",
      displayFlag: "showCurrentLiabilities",
      class: "total-row",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Non-current liabilities",
      key: "nonCurrentLiabilities",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showNonCurrentLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2",
      suffix: "%",
    },
    {
      title: "Long term debt",
      key: "longTermDebt",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Deferred taxes liabilities",
      key: "deferredTaxLiabilitiesNonCurrent",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Deferred Revenues",
      key: "deferredRevenueNonCurrent",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Capital Lease Obligations",
      key: "capitalLeaseObligations",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
    },
    {
      title: "Minority Interest",
      key: "minorityInterest",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
    },
    {
      title: "Other non-current liabilities",
      key: "otherNonCurrentLiabilities",
      displayFlag: "showNonCurrentLiabilities",
      class: "collapse-row-4",
      suffix: "%",
    },
    {
      title: "Total non-current liabilities",
      key: "totalNonCurrentLiabilities",
      displayFlag: "showNonCurrentLiabilities",
      class: "total-row",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Other Liabilities",
      key: "otherLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-6",
    },
    {
      title: "Total Liabilities",
      key: "totalLiabilities",
      displayFlag: "showLiabilities",
      class: "collapse-row-2-bold",
      suffix: "%",
      dataClass: "bold-text",
    },
    {
      title: "Stockholder's equity",
      key: "stockHolderEquity",
      isCollapsable: true,
      flagMethod: setFlags,
      toggleFlag: "showStockHldsEquity",
      displayFlag: "showLiabilitiesAndSHEquity",
      class: "collapse-row-1",
      suffix: "%",
    },
    {
      title: "Preferred stock",
      key: "preferredStock",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
    },
    {
      title: "Common stock",
      key: "commonStock",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Retained Earnings",
      key: "retainedEarnings",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Comprehensive income",
      key: "accumulatedOtherComprehensiveIncomeLoss",
      displayFlag: "showStockHldsEquity",
      class: "collapse-row-3",
      suffix: "%",
    },
    {
      title: "Total stockholder's equity",
      key: "totalStockholdersEquity",
      displayFlag: "showStockHldsEquity",
      class: "total-row-2",
      dataClass: "bold-text",
      suffix: "%",
    },
    {
      title: "Total liabilities and shareholder's equity",
      key: "totalLiabilitiesAndStockholdersEquity",
      class: "collapse-row-1-bold",
      dataClass: "bold-text",
      displayFlag: "showLiabilitiesAndSHEquity",
      suffix: "%",
    },
  ]
}
