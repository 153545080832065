import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  header: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    padding: "12px 0 !important",
  },
  "content-txt": {
    color: theme.palette["grey-2"],
    textAlign: "justify",
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
    fontSize: "14px",
  },
  "load-btn": {
    backgroundColor: `${theme.palette.purple} !important`,
    color: `${theme.palette.white} !important`,
    marginTop: "1.5rem !important",
  },
  "row-header": {
    textTransform: "uppercase",
    color: `${theme.palette["grey-2"]} !important`,
  },
  "row-value": {},
  "table-container": {
    border: `1px solid ${theme.palette["grey-3"]}`,
    boxShadow: "none",
  },
  "main-header": {
    fontSize: "18px",
  },
  "main-header-2": {
    fontWeight: "bold",
    textAlign: "left",
    flexBasis: "25%",
    flexGrow: 0,
    marginTop: "20px",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  alert: {
    "& .MuiAlert-icon": {
      alignItems: "center",
    },
  },
}))
