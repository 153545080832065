import { round } from "lodash"
import {
  Box,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { InputThousandSeparator } from "../../../../utils/NumberFormatters"
import DcfFreeCashFlow from "./DcfFreeCashFlow"
import DcfSales from "./DcfSales"
import clsx from "clsx"
import PerShareProjection from "./PerShareProjection"
import ValueProjectionExitMultiple from "./ValueProjectionExitMultiple"

const Calculator = ({
  valuationRefData,
  displayCurrency,
  showCurrencyConverter,
  setShowCurrencyConverter,
  currencyOpt,
  setCurrencyOpt,
  tickerId,
}) => {
  const classes = useStyles()
  const [calcType, setCalcType] = useState("DCF_FCF")
  const [noOfYears, setNoOfYears] = useState(5)
  const [calcFormVals, setCalcFormVals] = useState({})
  const [discountRate, setDiscountRate] = useState(15)

  const calcTypes = [
    {
      value: "DCF using sales growth and FCF margin",
      key: "DCF_SALES",
    },
    {
      value: "DCF using free cash flow growth",
      key: "DCF_FCF",
    },
    {
      value: "Per share projection and exit multiple",
      key: "PER_SHARE",
    },
    {
      value: "Value projection and exit multiple",
      key: "VALUE_PROJECTION",
    },
  ]

  const getCalcFormVals = (type) => {
    setCalcType(type)
    switch (type) {
      case "DCF_FCF":
        return setCalcFormVals({
          bull: {
            fcfVal: valuationRefData?.freeCashFlow?.ttm / 1000000,
            growthRate1: 15,
            growthRate2: 12,
            exitFcfMultiple: 18,
          },
          normal: {
            fcfVal: valuationRefData?.freeCashFlow?.ttm / 1000000,
            growthRate1: 12,
            growthRate2: 12,
            exitFcfMultiple: 15,
          },
          bear: {
            fcfVal: valuationRefData?.freeCashFlow?.ttm / 1000000,
            growthRate1: 10,
            growthRate2: 5,
            exitFcfMultiple: 14,
          },
        })
      case "DCF_SALES":
        return setCalcFormVals({
          bull: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 20,
            growthRate2: 15,
            exitFcfMultiple: 18,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
          normal: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 15,
            growthRate2: 12,
            exitFcfMultiple: 15,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
          bear: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 12,
            growthRate2: 10,
            exitFcfMultiple: 12,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
        })
      case "PER_SHARE":
        return setCalcFormVals({
          bull: {
            eps: round(valuationRefData?.eps?.ttm, 2),
            exitPEMultiple: 18,
            bvps: round(valuationRefData?.bvps?.ttm, 2),
            exitPBMultiple: 6,
            revenuePerShare: round(valuationRefData?.revenuePerShare?.ttm, 2),
            exitPSMultiple: 8,
            freeCashFlowPerShare: round(
              valuationRefData?.freeCashFlowPerShare?.ttm,
              2
            ),
            exitPFCFMultiple: 18,
            growthRate1: 12,
            growthRate2: 9,
            paramType: "eps",
          },
          normal: {
            eps: round(valuationRefData?.eps?.ttm, 2),
            exitPEMultiple: 15,
            bvps: round(valuationRefData?.bvps?.ttm, 2),
            exitPBMultiple: 5,
            revenuePerShare: round(valuationRefData?.revenuePerShare?.ttm, 2),
            exitPSMultiple: 6,
            freeCashFlowPerShare: round(
              valuationRefData?.freeCashFlowPerShare?.ttm,
              2
            ),
            exitPFCFMultiple: 16,
            growthRate1: 10,
            growthRate2: 7,
            paramType: "eps",
          },
          bear: {
            eps: round(valuationRefData?.eps?.ttm, 2),
            exitPEMultiple: 12,
            bvps: round(valuationRefData?.bvps?.ttm, 2),
            exitPBMultiple: 4,
            revenuePerShare: round(valuationRefData?.revenuePerShare?.ttm, 2),
            exitPSMultiple: 4,
            freeCashFlowPerShare: round(
              valuationRefData?.freeCashFlowPerShare?.ttm,
              2
            ),
            exitPFCFMultiple: 14,
            growthRate1: 7,
            growthRate2: 5,
            paramType: "eps",
          },
        })
      case "VALUE_PROJECTION":
        return setCalcFormVals({
          bull: {
            netIncome: valuationRefData?.netIncome?.ttm / 1000000,
            exitPEMultiple: 18,
            operatingIncome: valuationRefData?.operatingIncome?.ttm / 1000000,
            exitPOIMultiple: 15,
            operatingCashFlow:
              valuationRefData?.operatingCashFlow?.ttm / 1000000,
            exitPOCFMultiple: 15,
            freeCashFlow: valuationRefData?.freeCashFlow?.ttm / 1000000,
            exitPFCFMultiple: 18,
            growthRate1: 12,
            growthRate2: 9,
            paramType: "netIncome",
            sharesChange: -2,
          },
          normal: {
            netIncome: valuationRefData?.netIncome?.ttm / 1000000,
            exitPEMultiple: 15,
            operatingIncome: valuationRefData?.operatingIncome?.ttm / 1000000,
            exitPOIMultiple: 12,
            operatingCashFlow:
              valuationRefData?.operatingCashFlow?.ttm / 1000000,
            exitPOCFMultiple: 12,
            freeCashFlow: valuationRefData?.freeCashFlow?.ttm / 1000000,
            exitPFCFMultiple: 16,
            growthRate1: 10,
            growthRate2: 7,
            paramType: "netIncome",
            sharesChange: 0,
          },
          bear: {
            netIncome: valuationRefData?.netIncome?.ttm / 1000000,
            exitPEMultiple: 12,
            operatingIncome: valuationRefData?.operatingIncome?.ttm / 1000000,
            exitPOIMultiple: 10,
            operatingCashFlow:
              valuationRefData?.operatingCashFlow?.ttm / 1000000,
            exitPOCFMultiple: 10,
            freeCashFlow: valuationRefData?.freeCashFlow?.ttm / 1000000,
            exitPFCFMultiple: 14,
            growthRate1: 7,
            growthRate2: 5,
            paramType: "netIncome",
            sharesChange: 2,
          },
        })
      default:
        return setCalcFormVals({
          bull: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 20,
            growthRate2: 15,
            exitFcfMultiple: 18,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
          normal: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 15,
            growthRate2: 12,
            exitFcfMultiple: 15,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
          bear: {
            sales: valuationRefData?.revenue?.ttm / 1000000,
            growthRate1: 12,
            growthRate2: 10,
            exitFcfMultiple: 12,
            fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
          },
        })
    }
  }

  useEffect(() => {
    setCalcType("DCF_SALES")
    setCalcFormVals({
      bull: {
        sales: valuationRefData?.revenue?.ttm / 1000000,
        growthRate1: 20,
        growthRate2: 15,
        exitFcfMultiple: 18,
        fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
      },
      normal: {
        sales: valuationRefData?.revenue?.ttm / 1000000,
        growthRate1: 15,
        growthRate2: 12,
        exitFcfMultiple: 15,
        fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
      },
      bear: {
        sales: valuationRefData?.revenue?.ttm / 1000000,
        growthRate1: 12,
        growthRate2: 10,
        exitFcfMultiple: 12,
        fcfMargin: valuationRefData?.freeCashFlowMargin?.ttm,
      },
    })
  }, [JSON.stringify(valuationRefData)])

  const renderCalculator = (type) => {
    switch (type) {
      case "DCF_FCF":
        return (
          <DcfFreeCashFlow
            calcFormVals={calcFormVals}
            setCalcFormVals={setCalcFormVals}
            valuationRefData={valuationRefData}
            noOfYears={noOfYears}
            discountRate={discountRate}
            tickerId={tickerId}
          />
        )
      case "DCF_SALES":
        return (
          <DcfSales
            calcFormVals={calcFormVals}
            setCalcFormVals={setCalcFormVals}
            valuationRefData={valuationRefData}
            noOfYears={noOfYears}
            discountRate={discountRate}
            tickerId={tickerId}
          />
        )
      case "PER_SHARE":
        return (
          <PerShareProjection
            calcFormVals={calcFormVals}
            setCalcFormVals={setCalcFormVals}
            valuationRefData={valuationRefData}
            noOfYears={noOfYears}
            discountRate={discountRate}
            tickerId={tickerId}
          />
        )
      case "VALUE_PROJECTION":
        return (
          <ValueProjectionExitMultiple
            calcFormVals={calcFormVals}
            setCalcFormVals={setCalcFormVals}
            valuationRefData={valuationRefData}
            noOfYears={noOfYears}
            discountRate={discountRate}
            tickerId={tickerId}
          />
        )
      default:
    }
    getCalcFormVals()
  }

  return (
    <Box display={"flex"} flexDirection="column">
      <Box className={clsx(classes["calc-header"], "mb8")}>
        <Box>
          <Typography textAlign="center" fontSize={20}>
            Intrinsic Value Estimator
          </Typography>
          <Typography variant="body1" gutterBottom>
            Remember, <strong>Garbage IN = Garbage OUT</strong>. Your intrinsic
            value number is only as good as your assumptions about the inputs,
            such as growth rate, future multiple, etc., so be as conservative as
            possible in your assumptions.{" "}
            <strong>
              DO NOT make a buy or sell decision based on an estimated value
              alone.
            </strong>
          </Typography>
          {/* <ul className={classes.notes}>
            <li>Past performances do not guarantee future performance</li>
            <li>
              Remember, Garbage IN = Garbage OUT. Your intrinsic value numbers
              are as good as your growth rate assumptions.
            </li>
            <li>Be as conservative as possible in your assumptions.</li>
          </ul> */}
        </Box>
        {showCurrencyConverter && (
          <Box padding={"0 12px 0 0"}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={currencyOpt}
              onChange={(e) => setCurrencyOpt(e.target.value)}
              xs={12}
              md={"flex"}
            >
              <ToggleButton
                value={displayCurrency}
                key={displayCurrency}
                className={classes["toggle-btn"]}
              >
                {displayCurrency}
              </ToggleButton>
              <ToggleButton
                value="USD"
                key="USD"
                className={classes["toggle-btn"]}
              >
                {"USD"}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes["calc-attr-2"]}>
          <span>Method:</span>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={calcType}
              onChange={(event) => getCalcFormVals(event.target.value)}
            >
              {calcTypes.map((type) => (
                <MenuItem value={type.key} key={type.key}>
                  {type.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes["calc-attr-2"]}>
          <span>Discount Rate:</span>
          <Input
            value={discountRate}
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              suffix: "%",
              allowNegative: false,
              onChange: (event) => {
                setDiscountRate(Number(event.target.value))
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes["calc-attr-2"]}>
          <span>No of Years:</span>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={noOfYears}
              onChange={(event) => setNoOfYears(Number(event.target.value))}
              style={{ textAlign: "center" }}
            >
              <MenuItem value={5} key={5} className={"justify-content-center"}>
                5
              </MenuItem>
              <MenuItem value={7} key={7} className={"justify-content-center"}>
                7
              </MenuItem>
              <MenuItem
                value={10}
                key={10}
                className={"justify-content-center"}
              >
                10
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} paddingTop="1rem">
          {valuationRefData && renderCalculator(calcType)}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calculator

Calculator.propTypes = {
  valuationRefData: PropTypes.object.isRequired,
  displayCurrency: PropTypes.string.isRequired,
  showCurrencyConverter: PropTypes.bool.isRequired,
  setShowCurrencyConverter: PropTypes.func.isRequired,
  currencyOpt: PropTypes.string.isRequired,
  setCurrencyOpt: PropTypes.func.isRequired,
  tickerId: PropTypes.string.isRequired,
}
