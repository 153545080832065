import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { palette } from "../../../../../utils/Palette"
import { forEach } from "lodash"
import { defaults } from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
defaults.font.family = palette.fontFamily

const BarChart = ({
  dataSet,
  labels,
  config,
  displayDataType,
  displayDataConversionType,
  chartTitle,
}) => {
  const logo = new Image()
  logo.src = "https://letsvalueinvest.com/favicon.ico"
  const logoImage = {
    id: "logoImage",
    beforeDraw(chart, args, options) {
      /* const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart */
      const { ctx } = chart
      ctx.save()
      if (logo.complete) {
        ctx.drawImage(logo, 60, 10, 25, 25)
      } else {
        logo.onload = () => chart.draw()
      }
      ctx.restore()
    },
  }

  const datasets = []
  forEach(config, function (value, key) {
    let positiveData = []
    if (!value.displayNegative) {
      forEach(dataSet[value.key], function (val) {
        positiveData.push(Math.abs(val))
      })
      // convert negative into positive and display it.
      datasets.push({
        label: value.title,
        data: positiveData,
        borderColor: value.borderColor,
        backgroundColor: value.backgroundColor,
        stack: value.stack,
      })
    } else {
      //display as is
      datasets.push({
        label: value.title,
        data: dataSet[value.key],
        borderColor: value.borderColor,
        backgroundColor: value.backgroundColor,
        stack: value.stack,
      })
    }
  })

  const data = {
    labels: labels?.map((label) => `${label}`.split("-")[0]),
    datasets: datasets,
  }
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 20,
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
    },
    plugins: {
      /* tooltip: {
        displayColors: false,
        callbacks: {
          title: function (item) {
            return `${item[0].dataset.label}`
          },
          beforelabel: function (item) {
            return `${item[0].label}`
          },
          label: function (item) {
            const newLineArray = []
            newLineArray.push(item.label)
            if (
              config.suffix &&
              (config.suffix === "%" || config.suffix === "x")
            ) {
              newLineArray.push(round(item.formattedValue, 2) + config.suffix)
            } else if (config.isNumber) {
              newLineArray.push(item.formattedValue)
            } else {
              newLineArray.push("$" + item.formattedValue)
            }
            return newLineArray
          },
          labelTextColor: function (item) {
            return "#757575"
          },
        },
        mode: "index",
        intersect: false,
        backgroundColor: "white",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        titleColor: "black",
        titleFont: {
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
        bodyFont: {
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
        yAlign: "bottom",
      }, */
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 16,
          weight: "bold",
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
      },
      /* annotation: {
        annotations: {
          annotation,
        },
      }, */
    },
  }
  return <Bar options={options} data={data} plugins={[logoImage]} />
}

export default BarChart
