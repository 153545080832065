import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const ReferenceTable = ({ valuationRefData }) => {
  const classes = useStyles()

  // To display formatted value
  const displayPerValue = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  // To display formatted value
  const display$Value = (
    value,
    ext = "%",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  const rows = [
    {
      title: "Revenue",
      key: "revenue",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "revenue",
        type: "Overview",
      },
    },
    {
      title: "Operating Income/Profit",
      key: "operatingIncome",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "operating_income",
        type: "Overview",
      },
    },
    {
      title: "Net Income/Profit",
      key: "netIncome",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "net_income",
        type: "Overview",
      },
    },
    {
      title: "Operating cash flow",
      key: "operatingCashFlow",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "operating_cash_flow",
        type: "Overview",
      },
    },
    {
      title: "Free cash flow",
      key: "freeCashFlow",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "free_cash_flow",
        type: "Overview",
      },
    },
    {
      title: "Revenue/Sales per share",
      key: "revenuePerShare",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "revenue_per_share",
        type: "Overview",
      },
    },
    {
      title: "Earning per share",
      key: "eps",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "earning_per_share",
        type: "Overview",
      },
    },
    {
      title: "Book value per share",
      key: "bvps",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "book_value_per_share",
        type: "Overview",
      },
    },
    {
      title: "Free cash flow per share",
      key: "freeCashFlowPerShare",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "free_cashflow_per_share",
        type: "Overview",
      },
    },
    {
      title: "Net income margin",
      key: "netProfitMargin",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "net_profit_margin",
        type: "Overview",
      },
    },
    {
      title: "Operating cash flow margin",
      key: "operatingCashFlowMargin",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "operating_cashflow_margin",
        type: "Overview",
      },
    },
    {
      title: "Free cash flow margin",
      key: "freeCashFlowMargin",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "free_cashflow_margin",
        type: "Overview",
      },
    },
    {
      title: "Share buyback CAGR",
      key: "shareBuyBack",
      displayMethod: displayPerValue,
      defToolTip: {
        key: "share_buybacks",
        type: "Overview",
      },
    },
    {
      title: "P/S Ratio",
      key: "priceToSalesRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_sales_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "P/OI Ratio",
      key: "priceOperatingIncomeRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_operating_income_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "P/E Ratio",
      key: "priceEarningsRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_earnings_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "P/B Ratio",
      key: "priceToBookRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_book_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "P/OCF Ratio",
      key: "priceToOperatingCashFlowsRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_operating_cashflow_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "P/FCF Ratio",
      key: "priceToFreeCashFlowsRatio",
      displayMethod: null,
      defToolTip: {
        key: "price_to_fcf_ratio",
        type: "Financial Ratios",
      },
    },
  ]

  const getTTMVal = (index, value) => {
    if ([0, 1, 2, 3, 4].includes(index))
      return display$Value(value / 1000000, "$", true, false) // $ value
    if ([5, 6, 7, 8].includes(index))
      return display$Value(value, "$", true, false) // per share
    if ([9, 10, 11, 12].includes(index)) return displayPerValue(value) // % value
    if ([13, 14, 15, 16, 17, 18].includes(index))
      return displayPerValue(value, "") // ratio value
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes["main-header"]}>
                  Reference data for calculation
                </div>
                <div className={classes["sub-header"]}>
                  All TTM figures are on Millions except for the ratios
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Metrics</TableCell>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {row?.displayMethod
                    ? row?.displayMethod(valuationRefData[row.key]?.tenYear)
                    : displayPerValue(valuationRefData[row.key]?.tenYear, "")}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {row?.displayMethod
                    ? row?.displayMethod(valuationRefData[row.key]?.fiveYear)
                    : displayPerValue(valuationRefData[row.key]?.fiveYear, "")}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {row?.displayMethod
                    ? row?.displayMethod(valuationRefData[row.key]?.threeYear)
                    : displayPerValue(valuationRefData[row.key]?.threeYear, "")}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {getTTMVal(index, valuationRefData[row.key]?.ttm)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Box display={"flex"} alignItems="center">
                  Net Debt
                  <span className="ml1 mt1">
                    <Term
                      config={{
                        key: "net_debt",
                        type: "Valuation",
                      }}
                    />
                  </span>
                </Box>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center">
                {display$Value(
                  valuationRefData?.debtScenario?.netDebt / 1000000,
                  "$",
                  true,
                  false
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ReferenceTable

ReferenceTable.propTypes = {
  valuationRefData: PropTypes.object.isRequired,
}
