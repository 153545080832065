import { BrowserRouter, Route, Routes } from "react-router-dom"
import React, { useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Landing from "./components/Landing"
import Loading from "./components/Loading"
import Dashboard from "./components/Dashboard"
import ProtectedRoute from "./components/ProtectedRoute"
import AppDataContext from "./context/data"
import AuthDataContext from "./context/auth"
import { palette } from "./utils/Palette"
import ReactGA from "react-ga4"

function App() {
  ReactGA.initialize("G-B86FY908FQ")
  ReactGA.send("pageview")
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [recentSearches, setRecentSearches] = useState([])
  const [overview, setOverview] = useState({})
  const [incomeStatment, setIncomeStatment] = useState({})
  const [balanceSheet, setBalanceSheet] = useState({})
  const [cashFlowStatment, setCashFlowStatment] = useState({})
  const [ratios, setRatios] = useState({})
  const [keyFinancials, setKeyFinancials] = useState({})
  const [prosAndCons, setProsAndCons] = useState({})
  const [fingraphs, setFingraphs] = useState({})
  const [keyInsights, setKeyInsights] = useState({})
  const [valuationRef, setValuationRef] = useState({})
  const [checkList, setCheckList] = useState({})
  const [profile, setProfile] = useState({})
  const [secFilings, setSecFilings] = useState({})
  const [shareholders, setShareholders] = useState({})
  const [insiderTransactions, setInsiderTransactions] = useState({})
  const [dividends, setDividends] = useState({})
  const [analysts, setAnalysts] = useState({})
  const [strapiTerms, setStrapiTerms] = useState({})
  const [quotes, setQuotes] = useState({})
  const [peers, setPeers] = useState({})
  const [revenueSegments, setRevenueSegments] = useState({})
  const [earningsCallTranscripts, setEarningsCallTranscripts] = useState({})
  const [indexData, setIndexData] = useState({})
  return (
    <>
      <AuthDataContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <AppDataContext.Provider
          value={{
            recentSearches,
            setRecentSearches,
            overview,
            setOverview,
            incomeStatment,
            setIncomeStatment,
            balanceSheet,
            setBalanceSheet,
            cashFlowStatment,
            setCashFlowStatment,
            ratios,
            setRatios,
            keyFinancials,
            setKeyFinancials,
            prosAndCons,
            setProsAndCons,
            fingraphs,
            setFingraphs,
            keyInsights,
            setKeyInsights,
            valuationRef,
            setValuationRef,
            checkList,
            setCheckList,
            profile,
            setProfile,
            secFilings,
            setSecFilings,
            shareholders,
            setShareholders,
            insiderTransactions,
            setInsiderTransactions,
            dividends,
            setDividends,
            analysts,
            setAnalysts,
            strapiTerms,
            setStrapiTerms,
            quotes,
            setQuotes,
            peers,
            setPeers,
            revenueSegments,
            setRevenueSegments,
            earningsCallTranscripts,
            setEarningsCallTranscripts,
            indexData,
            setIndexData,
          }}
        >
          <BrowserRouter>
            <React.Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" name="Landing" element={<Landing />} />
                <Route
                  path="/dashboard/*"
                  name="Dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </AppDataContext.Provider>
      </AuthDataContext.Provider>
      <ToastContainer
        toastStyle={{
          backgroundColor: palette["grey-1"],
          color: palette["white"],
        }}
      />
    </>
  )
}

export default App
