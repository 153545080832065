import React, { useEffect, useState } from "react"
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Doughnut } from "react-chartjs-2"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"
import useStyles from "./styles"

import { palette } from "../../utils/Palette"

ChartJS.register(ArcElement, Title, Tooltip, Legend)
// ChartJS.register(ChartDataLabels)
defaults.font.family = palette.fontFamily

const SectorWeightage = (data) => {
  const classes = useStyles()
  const [chartData, setChartData] = useState(null)
  const sectorColors = {
    Technology: "#8A2BE2",
    "Information Technology": "#8A2BE2",
    Retails: "#A52A2A",
    Energy: "#8B0000",
    Industrials: "#9ACD32",
    "Financial Services": "#2F4F4F",
    Financials: "#2F4F4F",
    "Communication Services": "#A9A9A9",
    "Consumer Cyclical": "#FF8C00",
    "Consumer Defensive": "#32CD32",
    "Consumer Staples": "#32CD32",
    "Consumer Discretionary": "#000000",
    Healthcare: "#F0E68C",
    "Health Care": "#F0E68C",
    "Basic Materials": "#FFFFE0",
    Utilities: "#778899",
    "Real Estate": "#808000",
    Materials: "#FF6347",
  }

  const generateRandomColor = () => {
    const randomHex = Math.floor(Math.random() * 16777215).toString(16)
    return "#" + randomHex.padStart(6, "0")
  }

  // Extract labels and values using Object.entries()
  let labels = []
  let values = []
  if (data.sectorWeightages) {
    labels = Object.keys(data?.sectorWeightages)
    values = Object.values(data?.sectorWeightages)
  }

  const options = {
    responsive: true,
    layout: {
      padding: 10,
    },
    labels: labels,
    cutout: "25%",
    maintainAspectRatio: true,
    width: 400, // Adjust this for desired width
    height: 400, // Adjust this for desired height
    plugins: {
      title: {
        display: true,
        text: "Sectorwise Weightage",
        position: "bottom",
        padding: 10,
        color: "black",
        font: "'Montserrat', 'Arial', sans-serif",
      },
      legend: {
        display: false, // Hide legend if you don't want it
      },
      // Change options for ALL labels of THIS CHART
      datalabels: {
        color: "white",
        formatter: (value, context) => {
          return value.toFixed(2) + "%"
        },
      },
    },
  }

  useEffect(
    () => {
      setChartData({
        labels: labels,
        datasets: [
          {
            labels: labels,
            data: values,
            backgroundColor: labels.map(
              (sector) => sectorColors[sector] || generateRandomColor()
            ),
          },
        ],
        label: `Sector Weightages`,
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  if (!labels) return <></>

  return (
    <Box className={classes["donut-chart"]} position={"relative"}>
      {chartData && (
        <Doughnut
          options={options}
          data={chartData}
          plugins={[ChartDataLabels]}
        />
      )}
    </Box>
  )
}

export default SectorWeightage

SectorWeightage.propTypes = {
  sectorWeightages: PropTypes.any.isRequired,
}
