import { round } from "lodash"
import { format } from "date-fns"

export const getUsdValues = (keyFinancialsData) => {
  // default data
  const defaultData = {
    marketCap: Array.apply(null, Array(10)),
    enterpriseValue: Array.apply(null, Array(10)),
    revenue: Array.apply(null, Array(10)),
    grossProfitMargin: Array.apply(null, Array(10)),
    researchAndDevelopmentExpenses: Array.apply(null, Array(10)),
    researchAndDevelopmentAsPercentOfGrossProfit: Array.apply(null, Array(10)),
    operatingIncome: Array.apply(null, Array(10)),
    operatingProfitMargin: Array.apply(null, Array(10)),
    incomeBeforeTax: Array.apply(null, Array(10)),
    taxRate: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    netProfitMargin: Array.apply(null, Array(10)),
    weightedAverageShsOutDil: Array.apply(null, Array(10)),
    epsDiluted: Array.apply(null, Array(10)),
    workingCapital: Array.apply(null, Array(10)),
    bookValuePerShare: Array.apply(null, Array(10)),
    operatingCashFlow: Array.apply(null, Array(10)),
    operatingCashFlowMargin: Array.apply(null, Array(10)),
    operatingCashFlowPerShare: Array.apply(null, Array(10)),
    capitalExpenditure: Array.apply(null, Array(10)),
    freeCashFlow: Array.apply(null, Array(10)),
    freeCashFlowMargin: Array.apply(null, Array(10)),
    freeCashFlowPerShare: Array.apply(null, Array(10)),
    dividendPerShare: Array.apply(null, Array(10)),
    payoutRatio: Array.apply(null, Array(10)),
    financialHealth: Array.apply(null, Array(10)),
    currentRatio: Array.apply(null, Array(10)),
    quickRatio: Array.apply(null, Array(10)),
    debtEquityRatio: Array.apply(null, Array(10)),
    interestCoverage: Array.apply(null, Array(10)),
    managementEffectiveness: Array.apply(null, Array(10)),
    returnOnAssets: Array.apply(null, Array(10)),
    returnOnEquity: Array.apply(null, Array(10)),
    returnOnCapitalEmployed: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  keyFinancialsData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    const maxIndex =
      keyFinancialsData.length > 10 ? keyFinancialsData.length - 1 : 9
    // data in currency
    defaultData.marketCap[maxIndex - index] = data?.marketCap / 1000000
    defaultData.enterpriseValue[maxIndex - index] =
      data?.enterpriseValue / 1000000
    defaultData.revenue[maxIndex - index] =
      data.incomeStatement?.revenue / 1000000
    defaultData.grossProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.grossProfitMargin * 100,
      2
    )
    defaultData.researchAndDevelopmentExpenses[maxIndex - index] =
      data.incomeStatement?.researchAndDevelopmentExpenses / 1000000
    defaultData.researchAndDevelopmentAsPercentOfGrossProfit[maxIndex - index] =
      round(
        data.incomeStatement?.researchAndDevelopmentAsPercentOfGrossProfit *
          100,
        2
      )
    defaultData.operatingIncome[maxIndex - index] =
      data.incomeStatement?.operatingIncome / 1000000
    defaultData.operatingProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.operatingProfitMargin * 100,
      2
    )
    defaultData.incomeBeforeTax[maxIndex - index] =
      data.incomeStatement?.incomeBeforeTax / 1000000
    defaultData.taxRate[maxIndex - index] = round(
      data.incomeStatement?.taxRate * 100,
      2
    )
    defaultData.netIncome[maxIndex - index] =
      data.incomeStatement?.netIncome / 1000000
    defaultData.netProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.netProfitMargin * 100,
      2
    )
    defaultData.weightedAverageShsOutDil[maxIndex - index] =
      data.incomeStatement?.weightedAverageShsOutDil / 1000000
    defaultData.epsDiluted[maxIndex - index] = data.incomeStatement?.epsdiluted

    defaultData.workingCapital[maxIndex - index] =
      data.balanceSheet?.workingCapital / 1000000

    defaultData.bookValuePerShare[maxIndex - index] =
      data.balanceSheet?.bookValuePerShare

    defaultData.operatingCashFlow[maxIndex - index] =
      data.cashFlowStatement?.operatingCashFlow / 1000000

    defaultData.operatingCashFlowMargin[maxIndex - index] = round(
      data.cashFlowStatement?.operatingCashFlowMargin * 100,
      2
    )

    defaultData.operatingCashFlowPerShare[maxIndex - index] =
      data.cashFlowStatement?.operatingCashFlowPerShare

    defaultData.capitalExpenditure[maxIndex - index] =
      data.cashFlowStatement?.capitalExpenditure / 1000000

    defaultData.freeCashFlow[maxIndex - index] =
      data.cashFlowStatement?.freeCashFlow / 1000000

    defaultData.freeCashFlowMargin[maxIndex - index] = round(
      data.cashFlowStatement?.freeCashFlowMargin * 100,
      2
    )

    defaultData.freeCashFlowPerShare[maxIndex - index] =
      data.cashFlowStatement?.freeCashFlowPerShare

    defaultData.dividendPerShare[maxIndex - index] =
      data.dividendInfo?.dividendPerShare

    defaultData.payoutRatio[maxIndex - index] = round(
      data.dividendInfo?.payoutRatio * 100,
      2
    )

    defaultData.financialHealth[maxIndex - index] = 0
    defaultData.currentRatio[maxIndex - index] =
      data.financialHealth?.currentRatio
    defaultData.quickRatio[maxIndex - index] = data.financialHealth?.quickRatio
    defaultData.debtEquityRatio[maxIndex - index] =
      data.financialHealth?.debtEquityRatio
    defaultData.interestCoverage[maxIndex - index] =
      data.financialHealth?.interestCoverage

    defaultData.managementEffectiveness[maxIndex - index] = 0
    defaultData.returnOnAssets[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnAssets * 100,
      2
    )
    defaultData.returnOnEquity[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnEquity * 100,
      2
    )
    defaultData.returnOnCapitalEmployed[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnCapitalEmployed * 100,
      2
    )
  })
  return { tempHeaders, defaultData }
}
export const getConversionValues = (keyFinancialsData, price) => {
  // default data
  const defaultData = {
    marketCap: Array.apply(null, Array(10)),
    enterpriseValue: Array.apply(null, Array(10)),
    revenue: Array.apply(null, Array(10)),
    grossProfitMargin: Array.apply(null, Array(10)),
    researchAndDevelopmentExpenses: Array.apply(null, Array(10)),
    researchAndDevelopmentAsPercentOfGrossProfit: Array.apply(null, Array(10)),
    operatingIncome: Array.apply(null, Array(10)),
    operatingProfitMargin: Array.apply(null, Array(10)),
    incomeBeforeTax: Array.apply(null, Array(10)),
    taxRate: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    netProfitMargin: Array.apply(null, Array(10)),
    weightedAverageShsOutDil: Array.apply(null, Array(10)),
    epsDiluted: Array.apply(null, Array(10)),
    workingCapital: Array.apply(null, Array(10)),
    bookValuePerShare: Array.apply(null, Array(10)),
    operatingCashFlow: Array.apply(null, Array(10)),
    operatingCashFlowMargin: Array.apply(null, Array(10)),
    operatingCashFlowPerShare: Array.apply(null, Array(10)),
    capitalExpenditure: Array.apply(null, Array(10)),
    freeCashFlow: Array.apply(null, Array(10)),
    freeCashFlowMargin: Array.apply(null, Array(10)),
    freeCashFlowPerShare: Array.apply(null, Array(10)),
    dividendPerShare: Array.apply(null, Array(10)),
    payoutRatio: Array.apply(null, Array(10)),
    financialHealth: Array.apply(null, Array(10)),
    currentRatio: Array.apply(null, Array(10)),
    quickRatio: Array.apply(null, Array(10)),
    debtEquityRatio: Array.apply(null, Array(10)),
    interestCoverage: Array.apply(null, Array(10)),
    managementEffectiveness: Array.apply(null, Array(10)),
    returnOnAssets: Array.apply(null, Array(10)),
    returnOnEquity: Array.apply(null, Array(10)),
    returnOnCapitalEmployed: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  keyFinancialsData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    const maxIndex = keyFinancialsData.length - 1

    // data in currency
    defaultData.marketCap[maxIndex - index] = data?.marketCap / 1000000
    defaultData.enterpriseValue[maxIndex - index] =
      (data?.enterpriseValue / 1000000) * price
    defaultData.revenue[maxIndex - index] =
      (data.incomeStatement?.revenue / 1000000) * price
    defaultData.grossProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.grossProfitMargin * 100,
      2
    )
    defaultData.researchAndDevelopmentExpenses[maxIndex - index] =
      (data.incomeStatement?.researchAndDevelopmentExpenses / 1000000) * price
    defaultData.researchAndDevelopmentAsPercentOfGrossProfit[maxIndex - index] =
      round(
        data.incomeStatement?.researchAndDevelopmentAsPercentOfGrossProfit *
          100,
        2
      )
    defaultData.operatingIncome[maxIndex - index] =
      (data.incomeStatement?.operatingIncome / 1000000) * price
    defaultData.operatingProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.operatingProfitMargin * 100,
      2
    )
    defaultData.incomeBeforeTax[maxIndex - index] =
      (data.incomeStatement?.incomeBeforeTax / 1000000) * price
    defaultData.taxRate[maxIndex - index] = round(
      data.incomeStatement?.taxRate * 100,
      2
    )
    defaultData.netIncome[maxIndex - index] =
      (data.incomeStatement?.netIncome / 1000000) * price
    defaultData.netProfitMargin[maxIndex - index] = round(
      data.incomeStatement?.netProfitMargin * 100,
      2
    )
    defaultData.weightedAverageShsOutDil[maxIndex - index] =
      data.incomeStatement?.weightedAverageShsOutDil / 1000000
    defaultData.epsDiluted[maxIndex - index] =
      data.incomeStatement?.epsdiluted * price

    defaultData.workingCapital[maxIndex - index] =
      (data.balanceSheet?.workingCapital / 1000000) * price

    defaultData.bookValuePerShare[maxIndex - index] =
      data.balanceSheet?.bookValuePerShare * price

    defaultData.operatingCashFlow[maxIndex - index] =
      (data.cashFlowStatement?.operatingCashFlow / 1000000) * price

    defaultData.operatingCashFlowMargin[maxIndex - index] = round(
      data.cashFlowStatement?.operatingCashFlowMargin * 100,
      2
    )

    defaultData.operatingCashFlowPerShare[maxIndex - index] =
      data.cashFlowStatement?.operatingCashFlowPerShare * price

    defaultData.capitalExpenditure[maxIndex - index] =
      (data.cashFlowStatement?.capitalExpenditure / 1000000) * price

    defaultData.freeCashFlow[maxIndex - index] =
      (data.cashFlowStatement?.freeCashFlow / 1000000) * price

    defaultData.freeCashFlowMargin[maxIndex - index] = round(
      data.cashFlowStatement?.freeCashFlowMargin * 100,
      2
    )

    defaultData.freeCashFlowPerShare[maxIndex - index] =
      data.cashFlowStatement?.freeCashFlowPerShare * price

    defaultData.dividendPerShare[maxIndex - index] =
      data.dividendInfo?.dividendPerShare * price

    defaultData.payoutRatio[maxIndex - index] = round(
      data.dividendInfo?.payoutRatio * 100,
      2
    )

    defaultData.financialHealth[maxIndex - index] = 0
    defaultData.currentRatio[maxIndex - index] =
      data.financialHealth?.currentRatio
    defaultData.quickRatio[maxIndex - index] = data.financialHealth?.quickRatio
    defaultData.debtEquityRatio[maxIndex - index] =
      data.financialHealth?.debtEquityRatio
    defaultData.interestCoverage[maxIndex - index] =
      data.financialHealth?.interestCoverage

    defaultData.managementEffectiveness[maxIndex - index] = 0
    defaultData.returnOnAssets[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnAssets * 100,
      2
    )
    defaultData.returnOnEquity[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnEquity * 100,
      2
    )
    defaultData.returnOnCapitalEmployed[maxIndex - index] = round(
      data.managementEffectiveness?.returnOnCapitalEmployed * 100,
      2
    )
  })
  return { tempHeaders, defaultData }
}
