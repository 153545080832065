import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  // Common styles
  "calculator-container": {
    padding: "2rem 0rem",
    width: "100%",
  },
  container: {
    alignItems: "start",
    padding: "2rem 1rem",
  },
  "calculator-header-text": {
    margin: "20px 0 !important",
    textAlign: "center",
  },
  labels: {
    color: theme.palette["grey-2"],
    marginBottom: "12px !important",
  },

  // CAGR
  "cagr-val": {
    marginTop: "20px !important",
  },
  "donut-chart": {
    display: "flex",
    justifyContent: "center",
    width: "20rem",
    height: "20rem",
  },

  // Compound
  "compound-header": {
    marginBottom: "10px !important",
  },
  "author-name": {
    color: theme.palette["grey-2"],
  },
  "contribution-type": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  "compound-calc-text": {
    marginTop: "16px !important",
    textAlign: "justify",
  },
  "compound-val-table": {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px !important",
  },
  "custom-link": {
    margin: 0,
    color: "#3F51B5",
    textDecoration: "underline",
    WebkitTextDecoration: "underline",
    cursor: "pointer",
    textDecorationColor: "rgba(63, 81, 181, 0.4)",
  },
  priceChange: {
    fontSize: "1rem !important",
    paddingLeft: 8,
    whiteSpace: "nowrap",
  },
  priceChangePer: {
    fontSize: "1rem !important",
    paddingLeft: 4,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginLeft: "5px !important",
  },
  LVIDataTable: {
    "& .p-component": {
      fontFamily: "Montserrat !important",
    },
    alignItems: "start",
    padding: "1rem 1rem",
  },
  momentAgo: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 5,
    fontStyle: "italic",
    justifyContent: "left",
  },
  "score-5": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-5-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-4": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-4-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-3": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-3-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-2": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-1-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-1": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-2-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-5": {
    fontWeight: "600",
    fontSize: "16px",
    backgroundColor: theme.palette["tot-score-5-bg"],
    color: theme.palette["score-5-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-4": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-4-bg"],
    color: theme.palette["score-4-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-3": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-3-bg"],
    color: theme.palette["score-3-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-2": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-2-bg"],
    color: theme.palette["score-2-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "total-score-1": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-1-bg"],
    color: theme.palette["score-1-bg"],
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "score-bg": {
    width: "200px",
    height: "30px",
    backgroundColor: theme.palette["grey-4"],
    display: "inline-block",
    textAlign: "center",
    borderRadius: "8px",
    marginLeft: "1rem",
  },
  "tot-score": {
    height: "30px",
    backgroundColor: theme.palette.purple,
    backgroundImage:
      "linear-gradient(to right, #F56666, #F44336, #A9A9A9, #8BC34A, #3D9141)",
    display: "block",
    borderRadius: "8px",
    color: theme.palette.white,
    fontWeight: "bold",
  },
  "score-txt": {
    position: "relative",
    top: "5px",
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
    fontSize: "14px",
  },
  "wide-moat": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-5-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "narrow-moat": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-3-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  "none-moat": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-1-bg"],
    color: theme.palette.white,
    padding: "2px 14px",
    borderRadius: "10px",
  },
  alert: {
    "& .MuiAlert-icon": {
      alignItems: "center",
    },
  },
  "index-opts": {
    marginTop: "20px",
  },
  "opt-label": {
    fontSize: "14px !important",
    minWidth: "9rem",
    marginRight: "1rem !important",
    marginTop: "4px !important",
    backgroundColor: `${theme.palette.white} !important`,
    border: `1px solid ${theme.palette["grey-3"]} !important`,
  },
  "active-opt": {
    backgroundColor: `${theme.palette["primary-bg"]} !important`,
    color: `${theme.palette.purple} !important`,
  },
}))
