import React, { useEffect, useState, useContext } from "react"
import { Box, Chip } from "@mui/material"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import Shareholders from "./Shareholders"
import InsiderTransactions from "./InsiderTransactions"
import useStyles from "./styles"
import {
  getOwnershipShareholders,
  getOwnershipInsiderTransactions,
} from "../../../services/ticker"
import AppDataContext from "../../../context/data"
import Loading from "../../Loading"

const tabs = ["shareholders", "insider-transactions"]

const serviceCallMapper = {
  1: getOwnershipShareholders,
  2: getOwnershipInsiderTransactions,
}

const Ownerships = () => {
  const [ownershipData, setOwnershipData] = useState({})
  const [ownershipOpt, setOwnershipOpt] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const classes = useStyles()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    shareholders,
    setShareholders,
    insiderTransactions,
    setInsiderTransactions,
  } = useContext(AppDataContext)

  const setContext = (contextId, data) => {
    switch (contextId) {
      case 1:
        setShareholders({ data, tickerId })
        break
      case 2:
        setInsiderTransactions({ data, tickerId })
        break
      default:
        setShareholders({ data, tickerId })
    }
  }

  const getDataFromContext = (contextId) => {
    switch (contextId) {
      case 1:
        return shareholders
      case 2:
        return insiderTransactions
      default:
        return shareholders
    }
  }

  const updateFinancialData = (opt) => {
    navigate(`/dashboard/ticker/${tickerId}/ownerships/${opt}`)
  }

  /* const getQueryParams = () => {
    switch (ownershipOpt) {
      case 1:
        return `symbol=${tickerId}&date=2022-03-31&page=0`
        break
      case 1:
        return `symbol=${tickerId}&page=0`
        break
      default:
        return `symbol=${tickerId}&date=2022-03-31&page=0`
        break
    }
  } */

  useEffect(() => {
    setOwnershipOpt(1)
    const tabIndex = tabs.indexOf(pathname.split("/").at(-1))
    if (tabIndex > -1) setOwnershipOpt(tabIndex + 1)
    if (serviceCallMapper[tabIndex + 1]) {
      setIsLoading(true)
      const getOwnershipData = async () => {
        toast.dismiss()
        try {
          // get data only if context is empty and save into context
          const resp = await serviceCallMapper[tabIndex + 1](tickerId)
          setOwnershipData({ ...resp.data, tickerId })
          setContext(tabIndex + 1, { ...resp.data, tickerId })
          setIsLoading(false)
        } catch (err) {
          setOwnershipData({})
          setContext(tabIndex + 1, {})
          setIsLoading(false)
          toast.error(
            "Unable to get ownerships data. Please try after sometime."
          )
        }
      }
      // check if we have data already in context
      const dataFromContext = getDataFromContext(tabIndex + 1)
      if (dataFromContext?.tickerId === tickerId) {
        setOwnershipData(dataFromContext?.data)
        setIsLoading(false)
        return
      }
      getOwnershipData()
    }
  }, [pathname, tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box>
      <Box className={classes["financials-opts"]}>
        <Chip
          className={`${classes["opt-label"]} ${
            ownershipOpt === 1 && classes["active-opt"]
          }`}
          label="Shareholders"
          onClick={() => updateFinancialData("shareholders")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            ownershipOpt === 2 && classes["active-opt"]
          }`}
          label="Insider Transactions"
          onClick={() => updateFinancialData("insider-transactions")}
        />
      </Box>
      <Box>
        {ownershipData ? (
          <>
            {ownershipOpt === 1 && <Shareholders data={ownershipData} />}
            {ownershipOpt === 2 && <InsiderTransactions data={ownershipData} />}
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  )
}

export default Ownerships
