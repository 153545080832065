import React, { useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import { Bar } from "react-chartjs-2"
import { palette } from "../../../../../utils/Palette"
import { differenceInDays } from "date-fns"
import { round, mean, compact, isNil } from "lodash"
import { defaults } from "chart.js"
import { getCAGR } from "../../../../../utils/financeFns"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
defaults.font.family = palette.fontFamily

const BarChart = ({ dataSet, labels, config }) => {
  const compactDataSet = compact(dataSet)
  const priceChangeCagr = getCAGR(
    compactDataSet[0],
    compactDataSet.at(-1),
    differenceInDays(
      new Date(labels.at(-1)),
      new Date(labels[dataSet.length - compactDataSet.length])
    ) / 365
  )
  let twentyYearCagr,
    tenYearCagr,
    fiveYearCagr = 0
  let cagrSummary = []
  let dataPoints20Yrs,
    dataPoints10Yrs,
    dataPoints5Yrs = 0
  switch (config.displayDataType) {
    case "Annual":
      dataPoints20Yrs = 20
      dataPoints10Yrs = 10
      dataPoints5Yrs = 5
      break
    case "Quarterly":
      dataPoints20Yrs = 80
      dataPoints10Yrs = 40
      dataPoints5Yrs = 20
      break
    case "TTM":
      dataPoints20Yrs = 80
      dataPoints10Yrs = 40
      dataPoints5Yrs = 20
      break
    default:
      dataPoints20Yrs = 20
      dataPoints10Yrs = 10
      dataPoints5Yrs = 5
      break
  }
  if (compactDataSet.length > dataPoints20Yrs - 1) {
    twentyYearCagr = getCAGR(
      compactDataSet[0],
      compactDataSet.at(dataPoints20Yrs - 1),
      differenceInDays(
        new Date(labels.at(dataPoints20Yrs - 1)),
        new Date(labels[0])
      ) / 365
    )
    if (!isNil(twentyYearCagr)) {
      cagrSummary.push("20 Yr CAGR: " + twentyYearCagr.toFixed(2) + " %")
    }
  }
  if (compactDataSet.length > dataPoints10Yrs) {
    tenYearCagr = getCAGR(
      compactDataSet.at(-dataPoints10Yrs),
      compactDataSet.at(-1),
      differenceInDays(
        new Date(labels.at(-1)),
        new Date(labels.at(-dataPoints10Yrs))
      ) / 365
    )
    if (!isNil(tenYearCagr)) {
      cagrSummary.push("10 Yr CAGR: " + tenYearCagr.toFixed(2) + " %")
    }
  }
  if (compactDataSet.length > dataPoints5Yrs) {
    fiveYearCagr = getCAGR(
      compactDataSet.at(-dataPoints5Yrs),
      compactDataSet.at(-1),
      differenceInDays(
        new Date(labels.at(-1)),
        new Date(labels.at(-dataPoints5Yrs))
      ) / 365
    )
    if (!isNil(fiveYearCagr)) {
      cagrSummary.push("5 Yr CAGR: " + fiveYearCagr.toFixed(2) + " %")
    }
  }
  const averageDataSet = round(mean(compact(dataSet)), 2)
  let title = ""
  let annotation = {}
  if (config.suffix && (config.suffix === "%" || config.suffix === "x")) {
    if (config.displayDataConversionType === "raw") {
      title =
        config.displayDataType + " " + config.title + " for " + config.tickerId
    } else {
      title =
        config.displayDataType +
        " " +
        config.title +
        " trend for " +
        config.tickerId +
        " ( Average = " +
        averageDataSet +
        "" +
        config.suffix +
        " )"
    }
    ChartJS.register(annotationPlugin)
    annotation = {
      type: "line",
      borderColor: "black",
      borderDash: [6, 6],
      borderDashOffset: 0,
      borderWidth: 2,
      label: {
        enabled: true,
        content: () =>
          "Average: " + mean(compact(dataSet)).toFixed(2) + config.suffix,
        position: "end",
      },
      scaleID: "y",
      value: () => mean(compact(dataSet)),
    }
  } else {
    if (isNil(priceChangeCagr)) {
      title =
        config.displayDataType +
        " " +
        config.title +
        " trend for " +
        config.tickerId
    } else {
      title =
        config.displayDataType +
        " " +
        config.title +
        " trend for " +
        config.tickerId +
        " ( CAGR = " +
        round(priceChangeCagr, 2) +
        "% )"
    }

    ChartJS.register(annotationPlugin)
    if (cagrSummary.length > 0) {
      annotation = {
        type: "label",
        padding: 10,
        backgroundColor: "#CFF4A5",
        yAdjust: -100,
        content: cagrSummary.length > 0 ? cagrSummary : [],
        font: {
          size: 12,
        },
      }
    } else {
      annotation = null
    }
    // ChartJS.unregister(annotationPlugin)
  }

  const [logo] = useState("Lets Value Invest")

  const data = {
    labels: labels?.map((label) => `${label}`.split("-")[0]),
    datasets: [
      {
        label: config.title,
        data: dataSet,
        borderColor: palette.purple,
        backgroundColor: palette.purple,
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 20,
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (item) {
            return `${item[0].dataset.label}`
          },
          beforelabel: function (item) {
            return `${item[0].label}`
          },
          label: function (item) {
            const newLineArray = []
            newLineArray.push(item.label)
            if (
              config.suffix &&
              (config.suffix === "%" || config.suffix === "x")
            ) {
              newLineArray.push(round(item.formattedValue, 2) + config.suffix)
            } else if (config.isNumber) {
              newLineArray.push(item.formattedValue)
            } else {
              newLineArray.push("$" + item.formattedValue)
            }
            return newLineArray
          },
          labelTextColor: function (item) {
            return "#757575"
          },
        },
        mode: "index",
        intersect: false,
        backgroundColor: "white",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        titleColor: "black",
        titleFont: {
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
        bodyFont: {
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
        yAlign: "bottom",
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
          weight: "bold",
          family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
        },
      },
      annotation: {
        annotations: {
          annotation,
        },
      },
    },
  }

  return (
    <>
      <Bar options={options} data={data} />
      <div style={{ position: "absolute", top: 0, left: 20 }}>
        <h1>{logo}</h1>
      </div>
    </>
  )
}

export default BarChart
