import React from "react"
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material"
import PropTypes from "prop-types"
import { ContentCopy, CompareArrows } from "@mui/icons-material"
import { toast } from "react-toastify"
import clsx from "clsx"

import useStyles from "../styles"

const Header = ({ displayDataType, setDisplayDataType, reverseData }) => {
  const classes = useStyles()

  const copyTable = () => {
    const elTable = document.querySelector("table")

    let range, sel

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange()
      sel = window.getSelection()
      // unselect any element in the page
      sel.removeAllRanges()

      try {
        range.selectNodeContents(elTable)
        sel.addRange(range)
      } catch (e) {
        range.selectNode(elTable)
        sel.addRange(range)
      }

      document.execCommand("copy")
    }
    toast.info("Copied the financial ratios.")
  }

  return (
    <>
      <Box className={classes["header-container"]}>
        <Typography
          className={classes["info-text"]}
          paddingBottom="12px"
          xs={12}
          md={"flex"}
        >
          All ratios are in % terms except for multiples.
        </Typography>
        <Box className={classes["right-content"]}>
          <Tooltip title="Switch order of columns" arrow placement="top">
            <CompareArrows
              onClick={reverseData}
              className={clsx(classes["reverse-btn"], "mr3")}
            />
          </Tooltip>
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={displayDataType}
            onChange={(e) => setDisplayDataType(e.target.value)}
            xs={12}
            md={"flex"}
          >
            <Tooltip title="Switch to annual" arrow placement="top">
              <ToggleButton
                value="annualData"
                key="annualData"
                className={classes["toggle-btn"]}
              >
                {"Annual"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to quarterly" arrow placement="top">
              <ToggleButton
                value="quarterlyData"
                key="quarterlyData"
                className={classes["toggle-btn"]}
              >
                {"Quarterly"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to trailing 12 months" arrow placement="top">
              <ToggleButton
                value="ttmData"
                key="ttmData"
                className={classes["toggle-btn"]}
              >
                {"TTM"}
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Tooltip title="Copy financial ratios" arrow placement="top">
            <ContentCopy onClick={copyTable} className={classes["copy-btn"]} />
          </Tooltip>
        </Box>
      </Box>
    </>
  )
}

export default Header

Header.propTypes = {
  displayDataType: PropTypes.string.isRequired,
  setDisplayDataType: PropTypes.func.isRequired,
}
