import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material"

import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import useStyles from "../styles"

const RevenueEstimates = ({ analystEstimates }) => {
  const classes = useStyles()

  const getPercentChange = (currentVal, prevVal) => {
    const change = (Number(currentVal) / Number(prevVal) - 1) * 100
    if (currentVal < 0 && prevVal < 0) return change * -1
    if (currentVal > 0 && prevVal < 0) return change * -1
    return change
  }

  const getColorClass = (value, compareValue) => {
    return value < compareValue ? "error-txt" : "success-txt"
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes["main-header"]}>Revenue Estimates</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"></TableCell>
              {analystEstimates?.map((row, index) => (
                <TableCell key={`${row.date}-${index}`} align="center">
                  {row.date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Low</TableCell>
              {analystEstimates?.map((row, index) => (
                <TableCell
                  key={`${row.estimatedRevenueLow}-${index}`}
                  align="center"
                >
                  <TextThousandSeparator
                    value={row.estimatedRevenueLow / 1000000}
                    prefix={"$"}
                    suffix={""}
                    thousandSeparator={true}
                  />
                  <div
                    className={getColorClass(
                      row.estimatedRevenueLow,
                      analystEstimates[index - 1]?.estimatedRevenueLow
                    )}
                  >
                    {index !== 0 && (
                      <TextThousandSeparator
                        value={getPercentChange(
                          row.estimatedRevenueLow,
                          analystEstimates[index - 1]?.estimatedRevenueLow
                        )}
                        prefix={""}
                        suffix={"%"}
                        thousandSeparator={false}
                        fixedDecimalScale={true}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Average</TableCell>
              {analystEstimates?.map((row, index) => (
                <TableCell
                  key={`${row.estimatedRevenueAvg}-${index}`}
                  align="center"
                >
                  <TextThousandSeparator
                    value={row.estimatedRevenueAvg / 1000000}
                    prefix={"$"}
                    suffix={""}
                    thousandSeparator={true}
                  />
                  <div
                    className={getColorClass(
                      row.estimatedRevenueAvg,
                      analystEstimates[index - 1]?.estimatedRevenueAvg
                    )}
                  >
                    {index !== 0 && (
                      <TextThousandSeparator
                        value={getPercentChange(
                          row.estimatedRevenueAvg,
                          analystEstimates[index - 1]?.estimatedRevenueAvg
                        )}
                        prefix={""}
                        suffix={"%"}
                        thousandSeparator={false}
                        fixedDecimalScale={true}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>High</TableCell>
              {analystEstimates?.map((row, index) => (
                <TableCell
                  key={`${row.estimatedRevenueHigh}-${index}`}
                  align="center"
                >
                  <TextThousandSeparator
                    value={row.estimatedRevenueHigh / 1000000}
                    prefix={"$"}
                    suffix={""}
                    thousandSeparator={true}
                  />
                  <div
                    className={getColorClass(
                      row.estimatedRevenueHigh,
                      analystEstimates[index - 1]?.estimatedRevenueHigh
                    )}
                  >
                    {index !== 0 && (
                      <TextThousandSeparator
                        value={getPercentChange(
                          row.estimatedRevenueHigh,
                          analystEstimates[index - 1]?.estimatedRevenueHigh
                        )}
                        prefix={""}
                        suffix={"%"}
                        thousandSeparator={false}
                        fixedDecimalScale={true}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell># of analysts</TableCell>
              {analystEstimates?.map((row, index) => (
                <TableCell
                  key={`${row.numberAnalystEstimatedRevenue}-${index}`}
                  align="center"
                >
                  <TextThousandSeparator
                    value={row.numberAnalystEstimatedRevenue}
                    prefix={""}
                    suffix={""}
                    thousandSeparator={true}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default RevenueEstimates

RevenueEstimates.propTypes = {
  analystEstimates: PropTypes.array.isRequired,
}
