import { Box, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

import useStyles from "./styles"
import TickersSearch from "./components/Tickers"
import CriteriaSearch from "./components/Criterias"
import Chart from "./components/Chart"

const Visualizer = () => {
  const classes = useStyles()
  const [, setTickers] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [criterias, setCriterias] = useState([])
  const [visualizationData, setVisualizationData] = useState([])
  const [selectedTickers, setSelectedTickers] = useState([])
  const [selectedCriterias, setSelectedCriterias] = useState([])

  useEffect(() => {
    document.title = `Visualizer - Lets Value Invest`
  }, [])

  return (
    <Box className={classes["main-container"]}>
      <Typography className={classes["header-text"]}>
        Use this tool to visualize the historical performance of one or more
        stocks relative to one or more selected criteria.
      </Typography>
      <Grid container className={classes["selection-container"]}>
        <Grid item xs={12} sm={4} md={3}>
          <TickersSearch
            setTickers={setTickers}
            setVisualizationData={setVisualizationData}
            setCriterias={setCriterias}
            setSelectedTickers={setSelectedTickers}
            setisLoading={setisLoading}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={5}></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CriteriaSearch
            criterias={criterias}
            setSelectedCriterias={setSelectedCriterias}
          />
        </Grid>
        <Grid item xs={12}>
          <Chart
            rawData={visualizationData}
            selectedCriterias={selectedCriterias}
            selectedTickers={selectedTickers}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Visualizer
