import React, { useContext, useEffect, useState } from "react"
import { Box, Tabs, Tab, useMediaQuery } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Overview from "./Overview"
import Financials from "./Financials"
import FinGraphs from "./FinGraphs"
import AppDataContext from "../../context/data"
import Loading from "../Loading"
import TickerHeader from "./TickerHeader"
import KeyInsights from "./KeyInsights"
import Valuation from "./Valuation"
import Peers from "./Peers"
import Profile from "./Profile"
import Ownerships from "./Ownerships"
import Dividends from "./Dividends"
import SecFilings from "./SecFilings"
import Analysts from "./Analysts"
import Transcripts from "./Transcripts"
import { getOverviewData } from "../../services/ticker"
import { getTickerHistoricalPrice } from "../../services/core"
import useStyles from "./styles"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  )
}

const tabs = [
  "overview",
  "financials",
  "financial-graphs",
  "key-insights",
  "valuation",
  "peers",
  "analysts",
  "ownerships",
  "sec-filings",
  "dividends",
  "transcripts",
  "profile",
]

const Ticker = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [overviewData, setOverviewData] = useState({})
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { tickerId } = useParams()
  const { overview, setOverview } = useContext(AppDataContext)
  const classes = useStyles()

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"))

  useEffect(() => {
    if (tabs.indexOf(pathname.split("/").at(-1)) > -1)
      setTabIndex(tabs.indexOf(pathname.split("/").at(-1)))
    else if (tabs.indexOf(pathname.split("/").at(-2)) > -1)
      setTabIndex(tabs.indexOf(pathname.split("/").at(-2)))
    else setTabIndex(0)
  }, [pathname])

  useEffect(() => {
    setIsLoading(true)
    const getData = async () => {
      toast.dismiss()
      try {
        const resp = await getOverviewData(tickerId)
        const resp2 = await getTickerHistoricalPrice(tickerId)
        setOverviewData({
          ...resp?.data,
          historicalPriceFull: resp2?.data?.historicalPriceFull,
          historicalPrice5Min: resp2?.data?.historicalPrice5Min,
        })
        setOverview({
          ...resp?.data,
          historicalPriceFull: resp2?.data?.historicalPriceFull,
          historicalPrice5Min: resp2?.data?.historicalPrice5Min,
        })
        setIsLoading(false)
      } catch (err) {
        setOverviewData({})
        setOverview({})
        setIsLoading(false)
        toast.error("Unable to get Overview data. Please try after sometime.")
      }
    }
    if (overview?.tickerId === tickerId) {
      setOverviewData(overview)
      setIsLoading(false)
      return
    }
    getData()
  }, [tickerId])

  const handleChange = (event, newValue) => {
    if (tabs[newValue] === "financials")
      navigate(
        `/dashboard/ticker/${tickerId}/${tabs[newValue]}/income-statement`
      )
    else if (tabs[newValue] === "ownerships")
      navigate(`/dashboard/ticker/${tickerId}/${tabs[newValue]}/shareholders`)
    else navigate(`/dashboard/ticker/${tickerId}/${tabs[newValue]}`)
  }

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <TickerHeader data={overviewData} />
      </Box>
      <Box
        sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}
        className={classes["tabs-nav"]}
      >
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant={largeScreen ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
        >
          <Tab label="Overview" />
          <Tab label="Financials" />
          <Tab label="FinGraphs" />
          <Tab label="Key Insights" />
          <Tab label="Valuation" />
          <Tab label="Peers" />
          <Tab label="Analysts" />
          <Tab label="Ownerships" />
          <Tab label="SEC Filings" />
          <Tab label="Dividends" />
          <Tab label="Transcripts" />
          <Tab label="Profile" />
        </Tabs>
      </Box>
      <Box marginTop="35px">
        <TabPanel value={tabIndex} index={0}>
          <Overview />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Financials />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <FinGraphs />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <KeyInsights />
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <Valuation />
        </TabPanel>
        <TabPanel value={tabIndex} index={5}>
          <Peers />
        </TabPanel>
        <TabPanel value={tabIndex} index={6}>
          <Analysts />
        </TabPanel>
        <TabPanel value={tabIndex} index={7}>
          <Ownerships />
        </TabPanel>
        <TabPanel value={tabIndex} index={8}>
          <SecFilings />
        </TabPanel>
        <TabPanel value={tabIndex} index={9}>
          <Dividends />
        </TabPanel>
        <TabPanel value={tabIndex} index={10}>
          <Transcripts />
        </TabPanel>
        <TabPanel value={tabIndex} index={11}>
          <Profile />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default Ticker
