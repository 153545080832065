export const getRatingTemplate = (score, classes) => {
  if (score > 4)
    return (
      <span className={classes["score-5"]}>
        {window.innerWidth < 500 ? "SUPER" : "VERY GOOD"}
      </span>
    )
  if (score > 3) return <span className={classes["score-4"]}>GOOD</span>
  if (score > 2) return <span className={classes["score-3"]}>AVERAGE</span>
  if (score > 1) return <span className={classes["score-2"]}>POOR</span>
  if (score > 0) return <span className={classes["score-1"]}>BAD</span>
  if (score === 0) return <span className={classes["score-1"]}>N/A</span>
}

export const getMoatFromScore = (score, classes) => {
  if (score >= 3.75) return <span className={classes["wide-moat"]}>WIDE</span>
  if (score >= 2.5)
    return <span className={classes["narrow-moat"]}>NARROW</span>
  return <span className={classes["none-moat"]}>NONE</span>
}

export const getScoreTemplate = (score, classes) => {
  if (score > 4)
    return <span className={classes["total-score-5"]}>{score}/5</span>
  if (score > 3)
    return <span className={classes["total-score-4"]}>{score}/5</span>
  if (score > 2)
    return <span className={classes["total-score-3"]}>{score}/5</span>
  if (score > 1)
    return <span className={classes["total-score-2"]}>{score}/5</span>
  if (score >= 0)
    return <span className={classes["total-score-1"]}>{score}/5</span>
}

export const isBankInsuranceOrREIT = (sector, industry) => {
  if (sector === "Financial Services") {
    if (industry.indexOf("Banks") >= 0) {
      return true
    }
    if (industry.indexOf("REIT") >= 0) {
      return true
    }
    if (industry.indexOf("Insurance") >= 0) {
      return true
    }
  }
  return false
}
