import { makeStyles } from "@mui/styles"

import { palette } from "../../utils/Palette"

export default makeStyles((theme) => ({
  "guide-box": {
    width: "100%",
  },
  header: {
    fontSize: "2rem !important",
    fontWeight: "600 !important",
    paddingBottom: "1rem !important",
  },
  divider: {
    width: "10%",
    backgroundColor: palette["grey-3"],
    borderRadius: "2rem",
  },
  date: {
    paddingTop: "0px !important",
    paddingBottom: "0 !important",
    color: palette["grey-2"],
  },
}))
