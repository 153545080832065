import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"
import Term from "../../../../utils/Term"

import useStyles from "../styles"

const FinancialHealth = ({ data }) => {
  const classes = useStyles()
  const [rows] = useState([
    {
      title: "Current Ratio",
      key: "currentRatioTtm",
      defToolTip: {
        key: "current_ratio",
        type: "Overview",
      },
    },
    {
      title: "Quick Ratio",
      key: "quickRatioTtm",
      defToolTip: {
        key: "quick_ratio",
        type: "Overview",
      },
    },
    {
      title: "Debt to Equity Ratio",
      key: "debtEquityRatioTtm",
      defToolTip: {
        key: "debt_to_equity_ratio",
        type: "Overview",
      },
    },
    {
      title: "Debt to Operating Fcf Ratio",
      key: "debtToCashflowRatioTtm",
      defToolTip: {
        key: "debt_to_ocf_ratio",
        type: "Overview",
      },
    },
    {
      title: "Interest Coverage Ratio",
      key: "interestCoverageTtm",
      defToolTip: {
        key: "interest_coverage_ratio",
        type: "Overview",
      },
    },
  ])

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Financial Health</div>
                <div className={classes["sub-header"]}>
                  Based on most recent/trailing 12 month values
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="right">
                  {data[row.key]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default FinancialHealth

FinancialHealth.propTypes = {
  data: PropTypes.object.isRequired,
}
