import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "home-container": {
    height: "100%",
  },
  "header-container": {
    position: "sticky",
    marginTop: "0px",
    width: "100%",
  },
  "footer-container": {
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
  "ads-container": {
    display: "flex",
    height: "100%",
    backgroundColor: theme.palette["grey-4"],
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  },
  "big-numbers": {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
  },
  "table-container": {
    border: `1px solid ${theme.palette["grey-3"]}`,
    boxShadow: "none",
  },
  "main-header": {
    fontSize: "18px",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  "row-header": {
    textTransform: "uppercase",
    color: `${theme.palette["grey-2"]} !important`,
  },
  "row-value": {},
  "btn-container": {
    width: "100%",
    marginBottom: "8px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  "chart-component": {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-10px",
  },
  "range-tab": {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "12px",
    paddingBottom: "12px",
    textDecoration: "none !important",
    color: `${theme.palette.black} !important`,
    cursor: "pointer",
  },
  active: {
    cursor: "pointer",
    width: "45px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "4px",
    backgroundColor: theme.palette.purple,
  },
  inactive: {
    cursor: "pointer",
    width: "45px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    height: "4px",
    backgroundColor: theme.palette.white,
  },
  "chart-title-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "12px",
  },
  "chart-title": {
    fontSize: "18px",
    fontWeight: "500 !important",
  },
  "row-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  name: {
    fontSize: "20px !important",
  },
  symbol: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
  },
  exchange: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
    paddingRight: 8,
  },
  price: {
    fontSize: "24px !important",
  },
  priceChange: {
    fontSize: "16px !important",
    paddingLeft: 8,
    fontWeight: "500 !important",
  },
  priceChangePer: {
    fontSize: "16px !important",
    paddingLeft: 8,
    fontWeight: "500 !important",
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
  },
  title: {
    color: `${theme.palette["news"]} !important`,
    display: "block",
    textDecoration: "none !important",
    paddingLeft: 8,
    whiteSpace: "pre-line !important",
    justifyContent: "left",
  },
  site: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
    fontWeight: "500",
    justifyContent: "left",
  },
  momentAgo: {
    fontSize: "12px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 5,
    fontStyle: "italic",
    justifyContent: "left",
  },
}))
