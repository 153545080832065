import React from "react"
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
} from "@mui/material"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
const TransactionsSummary = ({ data }) => {
  const classes = useStyles()
  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography className={classes["main-header"]}>
            Number of Insider Trades:
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>INSIDER TRADE</TableCell>
                  <TableCell align="right">3 MONTHS</TableCell>
                  <TableCell align="right">12 MONTHS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Number of Open Market Buys
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last3months.buys}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last12months.buys}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Number of Sells
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last3months.sells}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last12months.sells}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Total Insider Trades
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last3months.total}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.tradesCount.last12months.total}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography className={classes["main-header"]}>
            Number of Insider Shares Traded:
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>INSIDER TRADE</TableCell>
                  <TableCell align="right">3 MONTHS</TableCell>
                  <TableCell align="right">12 MONTHS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Number of Shares Bought
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last3months.buys}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last12months.buys}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Number of Shares Sold
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last3months.sells}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last12months.sells}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Total Shares Trades
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={
                          data.sharesCount.last3months.buys +
                          data.sharesCount.last3months.sells
                        }
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={
                          data.sharesCount.last12months.buys +
                          data.sharesCount.last12months.sells
                        }
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes["content-txt"]}>
                      Net Activity
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last3months.netActivity}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes["content-txt-2"]}>
                      <TextThousandSeparator
                        value={data.sharesCount.last12months.netActivity}
                        prefix={""}
                        suffix={""}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TransactionsSummary

TransactionsSummary.propTypes = {
  data: PropTypes.object.isRequired,
}
