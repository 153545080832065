import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "search-box": {
    width: "100%",
  },
  loader: {
    marginRight: "28px",
  },
}))
