import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { cloneDeep, reverse } from "lodash"

import { generatePrevYears } from "../../../../utils/financeFns"
import Header from "./components/Header"
import Row from "./components/Row"
import useStyles from "./styles"
import { getHeaders } from "./config/headers"
import { getRatioValues } from "./services"
import ReactGA from "react-ga4"

const Ratios = ({ ratios }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/financials/ratios",
  })
  const { tickerId } = useParams()
  const [displayData, setDisplayData] = useState({})
  const [displayDataType, setDisplayDataType] = useState("annualData")
  const [displayDataConversionType, setDisplayDataConversionType] =
    useState("raw")
  const [headers, setHeaders] = useState([])
  const [rowHeaders, setRowHeaders] = useState([])
  const [masterDisplayData, setMasterDisplayData] = useState({})
  const [masterHeaders, setMasterHeaders] = useState([])
  const [flags, setFlags] = useState({
    showPriceMultiples: true,
    showManagementEffectiveness: true,
    showProfitability: true,
    showShortTermFinancialHealth: true,
    showLongTermFinancialHealth: true,
    showDividendInfo: true,
    showCyclesAndTurnovers: true,
  })
  const classes = useStyles()

  const displayDataTypeMapper = {
    annualData: "Annual",
    quarterlyData: "Quarterly",
    ttmData: "TTM",
  }

  useEffect(() => {
    document.title = `${tickerId} - Financials - Ratios - Lets Value Invest`
    let ratiosData = ratios[displayDataType]
    if (!ratios[displayDataType]) return
    let { tempHeaders, defaultData } = getRatioValues(ratiosData || [])
    // generate extra headers if there are less than 10 data obj
    if (
      tempHeaders.length < 10 &&
      defaultData?.cashRatio.length &&
      tempHeaders[0]
    ) {
      tempHeaders = [
        ...generatePrevYears(
          10 - tempHeaders.length,
          new Date(tempHeaders[0]).getFullYear() - 1
        ).reverse(),
        ...tempHeaders,
      ]
    }
    setHeaders(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    setDisplayData(defaultData)
    setMasterDisplayData(cloneDeep(defaultData))
    setMasterHeaders(
      cloneDeep(tempHeaders.length ? tempHeaders : Array.apply("NA", Array(10)))
    )
    setRowHeaders(getHeaders(setFlags))
  }, [displayDataType, ratios])

  const reverseData = () => {
    setHeaders([...reverse(headers)])
    let tempData = displayData
    for (const key in displayData) {
      tempData = { ...tempData, [key]: reverse(displayData[key]) }
    }
    setDisplayData(tempData)
  }

  return (
    <Box>
      <Header
        displayDataType={displayDataType}
        setDisplayDataType={setDisplayDataType}
        displayDataConversionType={displayDataConversionType}
        setDisplayDataConversionType={setDisplayDataConversionType}
        reverseData={reverseData}
      />
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 300 }} className={classes.sticky}>
                Years
              </TableCell>
              {headers.map((year, index) => (
                <TableCell key={year} align="right">
                  {year}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowHeaders.map((header) => {
              if (header.flag && !header.flagMethod)
                return (
                  flags[header.flag] && (
                    <Row
                      key={header.key}
                      rowData={displayData[header?.key]}
                      config={header}
                      flags={flags}
                      headers={headers}
                      masterRowData={masterDisplayData[header?.key]}
                      masterHeaders={masterHeaders}
                      displayDataType={displayDataTypeMapper[displayDataType]}
                    />
                  )
                )
              return (
                <Row
                  key={header.key}
                  rowData={displayData[header?.key]}
                  config={header}
                  flags={flags}
                  headers={headers}
                  masterRowData={masterDisplayData[header?.key]}
                  masterHeaders={masterHeaders}
                  displayDataType={displayDataTypeMapper[displayDataType]}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Ratios

Ratios.propTypes = {
  ratios: PropTypes.object.isRequired,
}
