export const getHeaders = () => {
  return [
    {
      title: "",
      key: "tickerId",
      isTickerHeader: true,
    },
    {
      title: "Growth Rates",
      key: "growthRates",
      metrics: [
        {
          title: "Revenue",
          key: "revenue",
          ext: "%",
          defToolTip: {
            key: "revenue",
            type: "Overview",
          },
        },
        {
          title: "Operating Income",
          key: "operatingIncome",
          ext: "%",
          defToolTip: {
            key: "operating_income",
            type: "Overview",
          },
        },
        {
          title: "Net Income",
          key: "netIncome",
          ext: "%",
          defToolTip: {
            key: "net_income",
            type: "Overview",
          },
        },
        {
          title: "Earning per Share",
          key: "eps",
          ext: "%",
          defToolTip: {
            key: "earning_per_share",
            type: "Overview",
          },
        },
        {
          title: "Free Cash Flow",
          key: "freeCashFlow",
          ext: "%",
          defToolTip: {
            key: "free_cash_flow",
            type: "Overview",
          },
        },
      ],
    },
    {
      title: "Financial Health",
      key: "financialHealth",
      metrics: [
        {
          title: "Current ratio",
          key: "currentRatio",
          ext: "",
          defToolTip: {
            key: "current_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "Debt to equity ratio",
          key: "debtEquityRatio",
          ext: "",
          defToolTip: {
            key: "debt_to_equity_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "Debt to Op cash flow",
          key: "debtToCashflowRatio",
          ext: "x",
          defToolTip: {
            key: "debt_to_ocf_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "Intangibles as % to Assets",
          key: "goodwillAndIntangibleAssetsToTotalAssets",
          ext: "%",
          defToolTip: {
            key: "intangibles_as_percentage_of_total_assets_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "Interest coverage",
          key: "interestCoverage",
          ext: "x",
          defToolTip: {
            key: "interest_coverage_ratio",
            type: "Key Insights",
          },
        },
      ],
    },
    {
      title: "Managment Effectiveness",
      key: "managementEffectiveness",
      metrics: [
        {
          title: "Return on assets (ROA)",
          key: "returnOnAssets",
          ext: "%",
          defToolTip: {
            key: "return_on_assets",
            type: "Key Insights",
          },
        },
        {
          title: "Return on equity (ROE)",
          key: "returnOnEquity",
          ext: "%",
          defToolTip: {
            key: "return_on_equity",
            type: "Key Insights",
          },
        },
        {
          title: "Return on capital (ROCE)",
          key: "returnOnCapitalEmployed",
          ext: "%",
          defToolTip: {
            key: "return_on_capital_employed",
            type: "Key Insights",
          },
        },
        {
          title: "Return on inremental capital employed (ROICE)",
          key: "returnOnIncrementalCapitalEmployed",
          ext: "%",
          defToolTip: {
            key: "return_on_incremental_capital_employed",
            type: "Key Insights",
          },
        },
        {
          title: "Return on retained earnings",
          key: "returnOnRetainedEarnings",
          ext: "%",
          defToolTip: {
            key: "return_on_retained_earnings",
            type: "Key Insights",
          },
        },
        {
          title: "Shares Repurchase",
          key: "shareBuyBack",
          ext: "%",
          defToolTip: {
            key: "share_buybacks",
            type: "Key Insights",
          },
        },
      ],
    },
    {
      title: "Margins/Profitability",
      key: "profitability",
      metrics: [
        {
          title: "Gross margin",
          key: "grossProfitMargin",
          ext: "%",
          defToolTip: {
            key: "gross_margin",
            type: "Key Insights",
          },
        },
        {
          title: "Operating margin",
          key: "operatingProfitMargin",
          ext: "%",
          defToolTip: {
            key: "operating_margin",
            type: "Key Insights",
          },
        },
        {
          title: "Net profit margin",
          key: "netProfitMargin",
          ext: "%",
          defToolTip: {
            key: "net_profit_margin",
            type: "Key Insights",
          },
        },
        {
          title: "Free Cashflow margin",
          key: "freeCashFlowMargin",
          ext: "%",
          defToolTip: {
            key: "free_cashflow_margin",
            type: "Key Insights",
          },
        },
        {
          title: "FCF to Net Income ratio",
          key: "freeCashFlowToNetIncome",
          ext: "%",
          defToolTip: {
            key: "fct_to_net_income_margin",
            type: "Key Insights",
          },
        },
      ],
    },
    {
      title: "Relative Valuations",
      key: "valuationMultiples",
      metrics: [
        {
          title: "P/S ratio",
          key: "priceToSalesRatio",
          ext: "x",
          defToolTip: {
            key: "price_to_sales_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "P/E ratio",
          key: "priceEarningsRatio",
          ext: "x",
          defToolTip: {
            key: "price_to_earnings_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "P/B ratio",
          key: "priceToBookRatio",
          ext: "x",
          defToolTip: {
            key: "price_to_book_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "P/OCF ratio",
          key: "priceToOperatingCashFlowsRatio",
          ext: "x",
          defToolTip: {
            key: "price_to_ocf_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "P/FCF ratio",
          key: "priceToFreeCashFlowsRatio",
          ext: "x",
          defToolTip: {
            key: "price_to_fcf_ratio",
            type: "Key Insights",
          },
        },
      ],
    },
    {
      title: "Moat",
      key: "moat",
      metrics: [
        {
          title: "Net profit margin",
          key: "netProfitMargin",
          ext: "%",
          defToolTip: {
            key: "net_profit_margin",
            type: "Key Insights",
          },
        },
        {
          title: "Free Cash flow margin",
          key: "freeCashFlowMargin",
          ext: "%",
          defToolTip: {
            key: "free_cashflow_margin",
            type: "Key Insights",
          },
        },
        {
          title: "Asset Turnover",
          key: "assetTurnover",
          ext: "x",
          defToolTip: {
            key: "asset_turnover",
            type: "Key Insights",
          },
        },
        {
          title: "Return on assets (ROA)",
          key: "returnOnAssets",
          ext: "%",
          defToolTip: {
            key: "return_on_assets",
            type: "Key Insights",
          },
        },
        {
          title: "Return on equity (ROE)",
          key: "returnOnEquity",
          ext: "%",
          defToolTip: {
            key: "return_on_equity",
            type: "Key Insights",
          },
        },
        {
          title: "Return on increment capital employed",
          key: "returnOnIncrementalCapitalEmployed",
          ext: "%",
          defToolTip: {
            key: "return_on_incremental_capital_employed",
            type: "Key Insights",
          },
        },
      ],
    },
    {
      title: "Dividend Info",
      key: "dividend",
      metrics: [
        {
          title: "CAGR",
          key: "growth",
          ext: "%",
          defToolTip: {
            key: "dividend_cagr",
            type: "Key Insights",
          },
        },
        {
          title: "Payout ratio",
          key: "payoutRatio",
          ext: "%",
          defToolTip: {
            key: "dividend_payout_ratio",
            type: "Key Insights",
          },
        },
        {
          title: "FCF Payout ratio",
          key: "dividendToFreeCashFlow",
          ext: "%",
          defToolTip: {
            key: "dividend_fcf_payout_ratio",
            type: "Key Insights",
          },
        },
      ],
    },
  ]
}
