export const getHeaders = (setFlags) => {
  return [
    {
      title: "Market Cap",
      key: "marketCap",
      defToolTip: {
        key: "market_cap",
        type: "Overview",
      },
    },
    {
      title: "Enterprise Value",
      key: "enterpriseValue",
      defToolTip: {
        key: "enterprise_value",
        type: "Overview",
      },
    },
    {
      title: "Revenue",
      key: "revenue",
      defToolTip: {
        key: "revenue",
        type: "Overview",
      },
    },
    {
      title: "Gross Profit Margin %",
      key: "grossProfitMargin",
      suffix: "%",
      defToolTip: {
        key: "gross_margin",
        type: "Overview",
      },
    },
    {
      title: "Research & Development",
      key: "researchAndDevelopmentExpenses",
      isNegValue: true,
      defToolTip: {
        key: "research_and_development",
        type: "Income statement",
      },
    },
    {
      title: "Research & Development Expense as % of Gross Profit",
      key: "researchAndDevelopmentAsPercentOfGrossProfit",
      suffix: "%",
      defToolTip: {
        key: "research_and_development_as_percent_of_gross_profit",
        type: "Income statement",
      },
    },
    {
      title: "Operating Income",
      key: "operatingIncome",
      defToolTip: {
        key: "operating_income",
        type: "Income statement",
      },
    },
    {
      title: "Operating Profit Margin %",
      key: "operatingProfitMargin",
      suffix: "%",
      defToolTip: {
        key: "operating_margin",
        type: "Income statement",
      },
    },
    {
      title: "Income Before Tax",
      key: "incomeBeforeTax",
      defToolTip: {
        key: "income_before_tax_or_ebt",
        type: "Income statement",
      },
    },
    {
      title: "Tax Rate %",
      key: "taxRate",
      suffix: "%",
      defToolTip: {
        key: "tax_rate",
        type: "Income statement",
      },
    },
    {
      title: "Net Income",
      key: "netIncome",
      defToolTip: {
        key: "net_income",
        type: "Income statement",
      },
    },
    {
      title: "Net Profit Margin %",
      key: "netProfitMargin",
      suffix: "%",
      defToolTip: {
        key: "net_profit_margin",
        type: "Income statement",
      },
    },
    {
      title: "Shares Outstanding (Diluted)",
      key: "weightedAverageShsOutDil",
      isNumber: true,
      defToolTip: {
        key: "shares_outstanding_diluted",
        type: "Income statement",
      },
    },
    {
      title: "Earning per share - Diluted",
      key: "epsDiluted",
      defToolTip: {
        key: "eps_diluted",
        type: "Income statement",
      },
    },
    {
      title: "Working Capital",
      key: "workingCapital",
      defToolTip: {
        key: "working_capital",
        type: "Summary",
      },
    },
    {
      title: "Book Value Per Share",
      key: "bookValuePerShare",
      defToolTip: {
        key: "book_value_per_share",
        type: "Overview",
      },
    },
    {
      title: "Operating Cash Flow",
      key: "operatingCashFlow",
      defToolTip: {
        key: "operating_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Operating Cash Flow Margin %",
      key: "operatingCashFlowMargin",
      suffix: "%",
      defToolTip: {
        key: "ocf_margin",
        type: "Cash flow statement",
      },
    },
    {
      title: "Operating Cash Flow Per Share",
      key: "operatingCashFlowPerShare",
      defToolTip: {
        key: "ocf_per_share",
        type: "Cash flow statement",
      },
    },
    {
      title: "Capital Expenditure",
      key: "capitalExpenditure",
      isNegValue: true,
      defToolTip: {
        key: "capital_expenditures",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free Cash Flow",
      key: "freeCashFlow",
      defToolTip: {
        key: "free_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free Cash Flow Margin %",
      key: "freeCashFlowMargin",
      suffix: "%",
      defToolTip: {
        key: "free_cashflow_margin",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free Cash Flow Per Share",
      key: "freeCashFlowPerShare",
      defToolTip: {
        key: "free_cashflow_per_share",
        type: "Cash flow statement",
      },
    },
    {
      title: "Dividend Per Share",
      key: "dividendPerShare",
      defToolTip: {
        key: "dividend_per_share",
        type: "Overview",
      },
    },
    {
      title: "Payout Ratio %",
      key: "payoutRatio",
      suffix: "%",
      defToolTip: {
        key: "dividend_payout_ratio",
        type: "Dividend Info",
      },
    },
    {
      title: "Financial Health",
      key: "financialHealth",
      class: "bold-text",
      dataClass: "bold-text",
    },
    {
      title: "Current Ratio",
      key: "currentRatio",
      suffix: "x",
      defToolTip: {
        key: "current_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Quick Ratio",
      key: "quickRatio",
      suffix: "x",
      defToolTip: {
        key: "quick_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Debt to Equity Ratio",
      key: "debtEquityRatio",
      suffix: "x",
      defToolTip: {
        key: "debt_to_equity_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Interest Coverage",
      key: "interestCoverage",
      suffix: "x",
      defToolTip: {
        key: "interest_coverage_ratio",
        type: "Financial Ratios",
      },
    },
    {
      title: "Management Effectiveness",
      key: "managementEffectiveness",
      class: "bold-text",
      dataClass: "bold-text",
    },
    {
      title: "Return on Assets",
      key: "returnOnAssets",
      suffix: "%",
      defToolTip: {
        key: "return_on_assets",
        type: "Financial Ratios",
      },
    },
    {
      title: "Return on Equity",
      key: "returnOnEquity",
      suffix: "%",
      defToolTip: {
        key: "return_on_equity",
        type: "Financial Ratios",
      },
    },
    {
      title: "Return on Capital Employed",
      key: "returnOnCapitalEmployed",
      suffix: "%",
      defToolTip: {
        key: "return_on_capital_employed",
        type: "Financial Ratios",
      },
    },
  ]
}
