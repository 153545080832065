import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"
import { yaxisLabel } from "../../../../utils/charts"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
defaults.font.family = palette.fontFamily

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          return yaxisLabel(value)
        },
      },
    },
  },
  interaction: {
    mode: "x",
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (item) {
          return `${item.dataset.label}: $${item.formattedValue}`
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        padding: 10, //default is 10
      },
    },
    title: {
      display: true,
      text: "Total Savings",
    },
  },
  layout: {
    padding: 10,
  },
}

const Linechart = ({ values, calculatorParams }) => {
  const [chartData, setChartData] = useState(null)

  useEffect(
    () => {
      setChartData({
        labels: values.map((val) => val.year),
        datasets: [
          {
            label: "Future Value",
            data: values.map((val) => parseInt(val.futureVal)),
            borderColor: palette.purple,
            backgroundColor: palette.purple,
          },
          {
            label: `Variance Below (${
              parseInt(calculatorParams.rateOfReturn) -
              parseInt(calculatorParams.rateVar)
            }%)`,
            data: values.map((val) => parseInt(val.leftVar)),
            borderColor: palette["error-dark"],
            backgroundColor: palette["error-dark"],
          },
          {
            label: `Variance Above (${
              parseInt(calculatorParams.rateOfReturn) +
              parseInt(calculatorParams.rateVar)
            }%)`,
            data: values.map((val) => parseInt(val.rightVar)),
            borderColor: palette["success-dark"],
            backgroundColor: palette["success-dark"],
          },
        ],
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(values), calculatorParams, values]
  )

  return (
    <Box width="100%" height="100%" minHeight="300px">
      {chartData && <Line options={options} data={chartData} />}
    </Box>
  )
}

export default Linechart

Linechart.propTypes = {
  values: PropTypes.array.isRequired,
  calculatorParams: PropTypes.object.isRequired,
}
