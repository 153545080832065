import * as React from "react"
import { styled } from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Link from "@mui/material/Link"
import { useNavigate } from "react-router-dom"
import useStyles from "./styles"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

export default function CustomizedAccordions() {
  const classes = useStyles()
  const navigate = useNavigate()

  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            <strong>Is it really free?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              Yes, our services are currently free to use. We are not asking for
              any payment information at this time. The reason for this is that
              we are still in the early stages of development and we are looking
              for feedback from our users. We want to make sure that our
              services are meeting the needs of our users before we start
              charging for them.
            </p>
            <p>
              We do plan to start charging for our services in the future.
              However, we want to make sure that our prices are affordable and
              that our users are getting a good value for their money. We will
              also offer a free trial period so that users can try out our
              services before they commit to paying for them.
            </p>
            <p>
              We appreciate your feedback and support as we continue to develop
              our services. Please{" "}
              <Link href="mailto:support@letsvalueinvest.com?subject=Feedback/Request">
                reach out
              </Link>{" "}
              to us and help us improve the product.
            </p>
            <p>
              Please see section <u>"Free Trial"</u> under our{" "}
              <span
                onClick={() => navigate("/dashboard/guides/terms-of-service")}
                className={classes["custom-link"]}
              >
                Terms of Service
              </span>{" "}
              for more details.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            <strong>
              What information do you collect from me and how do you use it?
            </strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              You can use our platform with your existing Google account. You do
              not need to create a new username and password. When you
              authenticate with your Google account, we only store your first
              name, last name, and email address in our database. This allows us
              to contact you about new features, upgrades, and to ask for your
              feedback. We do not store any other data on our servers or
              database. We will not sell your data to any third-party services.
            </p>
            <p>
              For more details, please also see our{" "}
              <span
                onClick={() => navigate("/dashboard/guides/privacy-policy")}
                className={classes["custom-link"]}
              >
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                onClick={() => navigate("/dashboard/guides/terms-of-service")}
                className={classes["custom-link"]}
              >
                Terms of Service
              </span>
              {" ."}{" "}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            <strong>How accurate is the data presented</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              We take great care to ensure the accuracy of the data presented on
              our website. We are relying on a third party service{" "}
              <Link target="_blank" href="https://financialmodelingprep.com/">
                Financial Modeling Prep
              </Link>{" "}
              for all data related to the stocks. We get the data from them via
              API calls and as needed, apply business logics and present it in
              your browser.{" "}
            </p>
            <p>
              We understand that the accuracy of the data is important to our
              users, and we are committed to providing the most accurate
              information possible. If you have any questions about the accuracy
              of the data, please contact us at{" "}
              <Link
                target="_blank"
                href="mailto:support@letsvalueinvest.com?subject=I found a Data Problem"
              >
                support@letsvalueinvest.com
              </Link>{" "}
            </p>
            <p>
              For more details, please also see our{" "}
              <span
                onClick={() => navigate("/dashboard/guides/disclaimer")}
                className={classes["custom-link"]}
              >
                Disclaimer
              </span>{" "}
              and refer to Financial Modeling Prep's{" "}
              <Link
                target="_blank"
                href="https://site.financialmodelingprep.com/terms-of-service"
              >
                Terms of Service
              </Link>
              .
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            <strong>Why should I use Lets Value Invest?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"}>
            We like that you ask this. You should use Let Us Value Invest if:
            <List>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="You are a value oriented investor and are eagar to improve your value orientation" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="You want to do the hard work before you lend your money to a business" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="You understand the power of compounding and always think about downside protection before thinking about any upside" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="You like to support your business story with numbers, intrinsic value and margin of safety" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="You are an independent decision maker" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="Your voice matters: We actively listen to user feedback and implement new features based on your needs." />
              </ListItem>
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
