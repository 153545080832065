import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const PerShareNumbers = ({ data }) => {
  const classes = useStyles()
  const [rows] = useState([
    {
      title: "Revenue Per Share",
      key: "salesPerShare",
      defToolTip: {
        key: "revenue_per_share",
        type: "Overview",
      },
    },
    {
      title: "Earning per share (EPS)",
      key: "eps",
      defToolTip: {
        key: "earning_per_share",
        type: "Overview",
      },
    },
    {
      title: "Free Cash Flow per share",
      key: "freeCashFlowPerShare",
      defToolTip: {
        key: "free_cashflow_per_share",
        type: "Overview",
      },
    },
    {
      title: "Book Value per share",
      key: "bookValuePerShare",
      defToolTip: {
        key: "book_value_per_share",
        type: "Overview",
      },
    },
    {
      title: "Dividend Per share",
      key: "forwardDividendPerShare",
      defToolTip: {
        key: "dividend_per_share",
        type: "Overview",
      },
    },
  ])

  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Per Share Numbers</div>
                <div className={classes["sub-header"]}>
                  Based on TTM values and shares outstanding
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="right">
                  {displayValue(data[row.key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PerShareNumbers

PerShareNumbers.propTypes = {
  data: PropTypes.object.isRequired,
}
