import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "financials-opts": {
    paddingBottom: "24px",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  "opt-label": {
    fontSize: "14px !important",
    minWidth: "9rem",
    marginRight: "1rem !important",
    marginTop: "4px !important",
    backgroundColor: `${theme.palette.white} !important`,
    border: `1px solid ${theme.palette["grey-3"]} !important`,
  },
  "active-opt": {
    backgroundColor: `${theme.palette["primary-bg"]} !important`,
    color: `${theme.palette.purple} !important`,
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
  },
  "super-text": {
    color: `green !important`,
    paddingLeft: "5px",
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: "10px",
    paddingBottom: "5px",
  },
}))
