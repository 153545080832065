import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"

import InstitutionalHolders from "./InstitutionalHolders"
import MutualFundHolders from "./MutualFundHolders"
import HoldersBreakdown from "./HoldersBreakdown"
import ReactGA from "react-ga4"

const Shareholders = ({ data }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/ownerships/shareholders",
  })
  const { tickerId } = useParams()

  useEffect(() => {
    document.title = `${tickerId} - Ownerships - Shareholders - Lets Value Invest`
  })

  if (!data.majorHoldersBreakdown) return <></>

  return (
    <Box>
      <HoldersBreakdown data={data.majorHoldersBreakdown} />
      <InstitutionalHolders data={data.institutionalShareholders} />
      <MutualFundHolders data={data.mutualFundsOwnership} />
    </Box>
  )
}

export default Shareholders

Shareholders.propTypes = {
  data: PropTypes.object.isRequired,
}
