import { Box, Input } from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { sum } from "lodash"

import useStyles from "../../styles"
import { InputThousandSeparator } from "../../../../../utils/NumberFormatters"
import {
  getProjectionValue,
  getPresentValue,
} from "../../../../../utils/Valuation"
import Results from "../Results"
import ExpectedReturns from "../ExpectedReturns"
import ReactGA from "react-ga4"

const DcfFreeCashFlow = ({
  calcFormVals,
  setCalcFormVals,
  noOfYears,
  discountRate,
  valuationRefData,
  tickerId,
}) => {
  const classes = useStyles()
  const [calcResult, setCalcResult] = useState(null)
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/valuation/dcf-free-cashflow",
  })

  useEffect(() => {
    document.title = `${tickerId} - DCF Free Cash Flow - Valuation - Lets Value Invest`
    const bull = {
      years: [],
      growthRates: [],
      fcfProjections: [],
      discountRates: [],
      presentValues: [],
      otherData: {},
      expectedReturns: [],
    }
    const normal = {
      years: [],
      growthRates: [],
      fcfProjections: [],
      discountRates: [],
      presentValues: [],
      otherData: {},
      expectedReturns: [],
    }
    const bear = {
      years: [],
      growthRates: [],
      fcfProjections: [],
      discountRates: [],
      presentValues: [],
      otherData: {},
      expectedReturns: [],
    }

    const calculateData = (type, typeStr) => {
      for (const year of Array(noOfYears).keys()) {
        type.years.push(year + 1)
        type.discountRates.push(discountRate)
        switch (noOfYears) {
          case 10:
            if (year < 5)
              type.growthRates.push(calcFormVals?.[typeStr]?.growthRate1)
            else type.growthRates.push(calcFormVals?.[typeStr]?.growthRate2)
            break
          case 5:
            type.growthRates.push(calcFormVals?.[typeStr]?.growthRate1)
            break
          case 7:
            if (year < 5)
              type.growthRates.push(calcFormVals?.[typeStr]?.growthRate1)
            else type.growthRates.push(calcFormVals?.[typeStr]?.growthRate2)
            break
          default:
            if (year < 5)
              type.growthRates.push(calcFormVals?.[typeStr]?.growthRate1)
            else type.growthRates.push(calcFormVals?.[typeStr]?.growthRate2)
        }
        type.fcfProjections.push(
          getProjectionValue(
            year === 0
              ? calcFormVals?.[typeStr]?.fcfVal
              : type.fcfProjections[year - 1],
            type.growthRates[year]
          )
        )
        // Other data
        type.presentValues.push(
          getPresentValue(
            type.fcfProjections[year],
            type.discountRates[year],
            year + 1
          )
        )
      }
      // Terminal Values
      type.years.push(noOfYears + 1)
      type.growthRates.push(calcFormVals?.[typeStr]?.exitFcfMultiple)
      type.fcfProjections.push(
        calcFormVals?.[typeStr]?.exitFcfMultiple * type?.fcfProjections.at(-1)
      )
      type.discountRates.push(discountRate)
      type.presentValues.push(
        getPresentValue(
          type.fcfProjections.at(-1),
          type.discountRates.at(-1),
          noOfYears
        )
      )
      type.otherData["totalPresentValue"] = sum(type.presentValues)
      type.otherData["netDebt"] =
        valuationRefData?.debtScenario?.netDebt / 1000000
      type.otherData["nettotalPresentValue"] =
        type.otherData.totalPresentValue - type.otherData.netDebt
      type.otherData["totalShares"] =
        valuationRefData?.shareBuyBack?.outstandingSharesDil / 1000000

      type.otherData["intrinsicValue"] =
        type.otherData.nettotalPresentValue / type.otherData.totalShares

      type.otherData["sharePrice"] =
        type.otherData.intrinsicValue *
        Math.pow(1 + discountRate / 100, noOfYears)
      type.otherData["expCagr"] =
        (Math.pow(
          type.otherData.sharePrice / valuationRefData.currentPrice,
          1 / noOfYears
        ) -
          1) *
        100
      type.otherData["marginOfSafety"] =
        ((type.otherData.intrinsicValue - valuationRefData.currentPrice) /
          type.otherData.intrinsicValue) *
        100

      type.otherData["futureValue"] =
        10000 * Math.pow(1 + type.otherData.expCagr / 100, noOfYears)

      // sensitivity analysis
      // expected return at when price is down by 50%
      let sharePrice = 0.5 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is down by 25%
      sharePrice = 0.75 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is down by 10%
      sharePrice = 0.9 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is down by 5%
      sharePrice = 0.95 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is up by 5%
      sharePrice = 1.05 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is up by 10%
      sharePrice = 1.1 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // expected return at when price is up by 25%
      sharePrice = 1.25 * valuationRefData.currentPrice
      type.expectedReturns.push({
        sharePrice: Math.round(sharePrice),
        cagr:
          (Math.pow(type.otherData.sharePrice / sharePrice, 1 / noOfYears) -
            1) *
          100,
      })
      // console.log(type)
    }
    calculateData(bull, "bull")
    calculateData(normal, "normal")
    calculateData(bear, "bear")

    setCalcResult({
      bull,
      normal,
      bear,
    })
  }, [JSON.stringify(calcFormVals), discountRate, noOfYears])

  return (
    <Box display={"flex"} flexDirection="column" overflow={"scroll"}>
      <Box overflow={"scroll"} minWidth="50rem">
        <Box display={"flex"} width="100%" flexDirection={"row"}>
          <Box className={classes["attr-name"]}></Box>
          <Box className={classes["case-header-bull"]}>
            <span>Bull Case</span>
          </Box>
          <Box className={classes["case-header-base"]}>
            <span>Base Case</span>
          </Box>
          <Box className={classes["case-header"]}>
            <span>Bear Case</span>
          </Box>
        </Box>
        {/* Starting FCF */}
        <Box display={"flex"} width="100%" flexDirection={"row"}>
          <Box className={classes["attr-name"]}>
            Starting Free Cash Flow (Mil)
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bull?.fcfVal}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "$",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bull: {
                      ...calcFormVals?.bull,
                      fcfVal: Number(event.target.value),
                    },
                    normal: calcFormVals?.normal,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.normal?.fcfVal}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "$",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    normal: {
                      ...calcFormVals?.normal,
                      fcfVal: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bear?.fcfVal}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "$",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bear: {
                      ...calcFormVals?.bear,
                      fcfVal: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    normal: calcFormVals?.normal,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
        </Box>
        {/* Growth Rate 1-5 */}
        <Box display={"flex"} width="100%" flexDirection={"row"}>
          <Box className={classes["attr-name"]}>Growth Rate (1-5 Yrs)</Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bull?.growthRate1}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                suffix: "%",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bull: {
                      ...calcFormVals?.bull,
                      growthRate1: Number(event.target.value),
                    },
                    normal: calcFormVals?.normal,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.normal?.growthRate1}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                suffix: "%",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    normal: {
                      ...calcFormVals?.normal,
                      growthRate1: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bear?.growthRate1}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                suffix: "%",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bear: {
                      ...calcFormVals?.bear,
                      growthRate1: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    normal: calcFormVals?.normal,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
        </Box>
        {/* Growth Rate 6-10 */}
        <Box display={"flex"} width="100%" flexDirection={"row"}>
          {noOfYears > 5 && (
            <>
              <Box className={classes["attr-name"]}>
                Growth Rate (6-{noOfYears} Yrs)
              </Box>
              <Box className={classes["case-value"]}>
                <Input
                  value={calcFormVals?.bull?.growthRate2}
                  inputComponent={InputThousandSeparator}
                  inputProps={{
                    style: { textAlign: "center" },
                    suffix: "%",
                    allowNegative: false,
                    onChange: (event) => {
                      setCalcFormVals({
                        bull: {
                          ...calcFormVals?.bull,
                          growthRate2: Number(event.target.value),
                        },
                        normal: calcFormVals?.normal,
                        bear: calcFormVals?.bear,
                      })
                    },
                    thousandSeparator: true,
                  }}
                />
              </Box>
              <Box className={classes["case-value"]}>
                <Input
                  value={calcFormVals?.normal?.growthRate2}
                  inputComponent={InputThousandSeparator}
                  inputProps={{
                    style: { textAlign: "center" },
                    suffix: "%",
                    allowNegative: false,
                    onChange: (event) => {
                      setCalcFormVals({
                        normal: {
                          ...calcFormVals?.normal,
                          growthRate2: Number(event.target.value),
                        },
                        bull: calcFormVals?.bull,
                        bear: calcFormVals?.bear,
                      })
                    },
                    thousandSeparator: true,
                  }}
                />
              </Box>
              <Box className={classes["case-value"]}>
                <Input
                  value={calcFormVals?.bear?.growthRate2}
                  inputComponent={InputThousandSeparator}
                  inputProps={{
                    style: { textAlign: "center" },
                    suffix: "%",
                    allowNegative: false,
                    onChange: (event) => {
                      setCalcFormVals({
                        bear: {
                          ...calcFormVals?.bear,
                          growthRate2: Number(event.target.value),
                        },
                        bull: calcFormVals?.bull,
                        normal: calcFormVals?.normal,
                      })
                    },
                    thousandSeparator: true,
                  }}
                />
              </Box>
            </>
          )}
        </Box>
        {/* Exit Free Cash Flow Multiple */}
        <Box display={"flex"} width="100%" flexDirection={"row"}>
          <Box className={classes["attr-name"]}>
            Exit Free Cash Flow Multiple
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bull?.exitFcfMultiple}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bull: {
                      ...calcFormVals?.bull,
                      exitFcfMultiple: Number(event.target.value),
                    },
                    normal: calcFormVals?.normal,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.normal?.exitFcfMultiple}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    normal: {
                      ...calcFormVals?.normal,
                      exitFcfMultiple: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    bear: calcFormVals?.bear,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
          <Box className={classes["case-value"]}>
            <Input
              value={calcFormVals?.bear?.exitFcfMultiple}
              inputComponent={InputThousandSeparator}
              inputProps={{
                style: { textAlign: "center" },
                prefix: "",
                allowNegative: false,
                onChange: (event) => {
                  setCalcFormVals({
                    bear: {
                      ...calcFormVals?.bear,
                      exitFcfMultiple: Number(event.target.value),
                    },
                    bull: calcFormVals?.bull,
                    normal: calcFormVals?.normal,
                  })
                },
                thousandSeparator: true,
              }}
            />
          </Box>
        </Box>
        <Results calcResult={calcResult} discountRate={discountRate}></Results>
        <ExpectedReturns
          calcResult={calcResult}
          discountRate={discountRate}
          currentPrice={valuationRefData.currentPrice}
          tickerId={tickerId}
        ></ExpectedReturns>
      </Box>
    </Box>
  )
}

export default DcfFreeCashFlow

DcfFreeCashFlow.propTypes = {
  calcFormVals: PropTypes.object.isRequired,
  setCalcFormVals: PropTypes.func.isRequired,
  valuationRefData: PropTypes.object.isRequired,
  noOfYears: PropTypes.number.isRequired,
  discountRate: PropTypes.number.isRequired,
  tickerId: PropTypes.string.isRequired,
}
