import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "main-header": {
    fontSize: "18px",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
    flexBasis: "25%",
    flexGrow: 0,
  },
  "row-header": {
    textTransform: "uppercase",
    color: `${theme.palette["grey-2"]} !important`,
  },
  "calc-header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  "calc-attr": {
    display: "flex",
    alignItems: "center",
    "& span": {
      color: `${theme.palette["grey-2"]} !important`,
    },
    flexBasis: "25%",
    flexGrow: 0,
  },
  "calc-attr-2": {
    display: "flex",
    alignItems: "center",
    flexBasis: "33%",
    flexGrow: 0,
  },
  "case-header": {
    fontWeight: "bold",
    textAlign: "center",
    flexBasis: "25%",
    flexGrow: 0,
    color: "red",
  },
  "case-header-bull": {
    fontWeight: "bold",
    textAlign: "center",
    flexBasis: "25%",
    flexGrow: 0,
    color: "green",
  },
  "case-header-base": {
    fontWeight: "bold",
    textAlign: "center",
    flexBasis: "25%",
    flexGrow: 0,
    color: "orange",
  },
  "case-value": {
    textAlign: "center",
    flexBasis: "25%",
    flexGrow: 0,
  },
  "res-value": {
    textAlign: "center",
    marginBottom: "8px !important",
    flexBasis: "25%",
    flexGrow: 0,
  },
  "attr-name": {
    textAlign: "end",
    color: `${theme.palette["grey-2"]} !important`,
    flexBasis: "25%",
    flexGrow: 0,
  },
  divider: {
    margin: "2rem 0 1rem 4rem !important",
  },
}))
