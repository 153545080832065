import React from "react"

import { Helmet } from "react-helmet"

const MetaTags = (tags) => {
  return (
    <Helmet>
      {/* HTML Meta Tags */}
      <title>{tags.title}</title>
      <meta name="description" content={tags.description} />
      <meta
        name="keywords"
        content="Power of Compounding, Compound interest calculator, Eighth (8th) wonder of the world, Lets Value Invest, letsvalueinvest.com, Value Investing platform, Learn value investing, Become a better value investor, Charlie Munger, Warren Buffett, All intelligent investing is value investing, Intrinsic Value Calculator, Calculate Intrinsic value, Stocks Visualizer, 20 Years detailed financials, 3 valuation scenarios, Multiple valuation methods, stock analysis, free stock valuation"
      />
      {/* Facebook Meta Tags */}
      <meta property="og:url" content={tags.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={tags.title} />
      <meta property="og:description" content={tags.description} />
      <meta property="og:image" content={tags.imageUrl} />
      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:domain" content="letsvalueinvest.com" />
      <meta property="twitter:url" content={tags.url} />
      <meta name="twitter:title" content={tags.title} />
      <meta name="twitter:description" content={tags.description} />
      <meta name="twitter:image" content={tags.imageUrl} />
      {/* Meta Tags Generated via https://www.opengraph.xyz */}
    </Helmet>
  )
}

export default MetaTags
