export const getHeaders = (setFlags) => {
  return [
    {
      title: "Cash flows from operating activities",
      key: "cfOperatingActivities",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showOperatingActivities",
      defToolTip: {
        key: "cash_flows_from_operating_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Net Income",
      key: "netIncome",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "net_income_op_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Depreciation & Amortization",
      key: "depreciationAndAmortization",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_depreciation_amortization",
        type: "Cash flow statement",
      },
    },
    // {
    //   title: "Investment losses (gains)",
    //   key: "depreciationAndAmortization",
    //   class: "collapse-row",
    //   flag: "showOperatingActivities",
    // },
    {
      title: "Deferred income taxes",
      key: "deferredIncomeTax",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_deferred_income_taxes",
        type: "Cash flow statement",
      },
    },
    {
      title: "Stock based compensation",
      key: "stockBasedCompensation",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_sbc",
        type: "Cash flow statement",
      },
    },
    {
      title: "Change in working capital",
      key: "changeInWorkingCapital",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_change_in_working_capital",
        type: "Cash flow statement",
      },
    },
    {
      title: "Accounts receivable",
      key: "accountsReceivables",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_accounts_receivable",
        type: "Cash flow statement",
      },
    },
    {
      title: "Inventory",
      key: "inventory",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_inventory",
        type: "Cash flow statement",
      },
    },
    // {
    //   title: "Prepaid expenses",
    //   key: "inventory",
    //   class: "collapse-row",
    //   suffix: "%",
    // },
    {
      title: "Accounts payable",
      key: "accountsPayables",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_accounts_payable",
        type: "Cash flow statement",
      },
    },
    {
      title: "Other working capital",
      key: "otherWorkingCapital",
      class: "collapse-row",
      flag: "showOperatingActivities",
    },
    {
      title: "Other non-cash items",
      key: "otherNonCashItems",
      class: "collapse-row",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "op_cash_flow_other_non_cash_items",
        type: "Cash flow statement",
      },
    },
    {
      title: "Net cash provided by operating activities",
      key: "netCashProvidedByOperatingActivities",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showOperatingActivities",
      defToolTip: {
        key: "net_cash_provided_by_operating_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Cash flows from investing activities",
      key: "cashFlowFromInvestingActivities",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cash_flows_from_investing_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Investment in property, plant & equipment",
      key: "investmentsInPropertyPlantAndEquipment",
      class: "collapse-row",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cashflows_from_investing_activities_ppe",
        type: "Cash flow statement",
      },
    },
    {
      title: "Acquisitions, net",
      key: "acquisitionsNet",
      class: "collapse-row",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cashflows_from_investing_activities_acquisitions_net",
        type: "Cash flow statement",
      },
    },
    {
      title: "Purchases of investments",
      key: "purchasesOfInvestments",
      class: "collapse-row",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cashflows_from_investing_purchases_of_investments",
        type: "Cash flow statement",
      },
    },
    {
      title: "Sales/maturities of investments",
      key: "salesMaturitiesOfInvestments",
      class: "collapse-row",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cashflows_from_investing_sales_maturities_investments",
        type: "Cash flow statement",
      },
    },
    {
      title: "Other investing activities",
      key: "otherInvestingActivites",
      class: "collapse-row",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "cashflows_from_investing_other_investing_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Net cash used for investing activities",
      key: "netCashUsedForInvestingActivites",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showInvestingActivities",
      defToolTip: {
        key: "net_cash_used_for_investing_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Cash flows from financing activities",
      isCollapsable: true,
      key: "netCashFlowsFromFinancingActivities",
      flagMethod: setFlags,
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cash_flows_from_financing_activities",
        type: "Cash flow statement",
      },
    },
    // {
    //   title: "Debt issued",
    //   key: "debtRepayment",
    //   class: "collapse-row",
    //   flag: "showFinancingActivities",
    // },
    {
      title: "Debt repayment",
      key: "debtRepayment",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cashflows_from_financing_activities_debt_repayment",
        type: "Cash flow statement",
      },
    },
    {
      title: "Common stock repurchased",
      key: "commonStockRepurchased",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cashflows_from_financing_activities_common_stock_repurchased",
        type: "Cash flow statement",
      },
    },
    {
      title: "Dividend Paid",
      key: "dividendsPaid",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cashflows_from_financing_activities_dividend_paid",
        type: "Cash flow statement",
      },
    },
    {
      title: "Other financing activities",
      key: "otherFinancingActivites",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cashflows_from_financing_activities_other_financing_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Net cash provided by (used for) financing activities",
      key: "netCashUsedProvidedByFinancingActivities",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "net_cash_provided_by_financing_activities",
        type: "Cash flow statement",
      },
    },
    {
      title: "Net change in cash",
      key: "netChangeInCash",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "net_change_in_cash",
        type: "Cash flow statement",
      },
    },
    {
      title: "Cash at the beginning of period",
      key: "cashAtBeginningOfPeriod",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cash_at_the_beginning_of_period",
        type: "Cash flow statement",
      },
    },
    {
      title: "Cash at the end of period",
      key: "cashAtEndOfPeriod",
      class: "collapse-row",
      flag: "showFinancingActivities",
      defToolTip: {
        key: "cash_at_the_end_of_period",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free Cash Flow",
      key: "freeCashFlowHeader",
      isCollapsable: true,
      flag: "showCashFlow",
      flagMethod: setFlags,
      defToolTip: {
        key: "free_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Operating cash flow",
      key: "operatingCashFlow",
      class: "collapse-row",
      flag: "showCashFlow",
      defToolTip: {
        key: "operating_cash_flow",
        type: "Cash flow statement",
      },
    },
    {
      title: "Capital expenditure",
      key: "capitalExpenditure",
      class: "collapse-row",
      flag: "showCashFlow",
      defToolTip: {
        key: "capital_expenditures",
        type: "Cash flow statement",
      },
    },
    {
      title: "Free cash flow",
      key: "freeCashFlow",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showCashFlow",
      defToolTip: {
        key: "fcf_final",
        type: "Cash flow statement",
      },
    },
  ]
}

export const getPercentageHeaders = (setFlags) => {
  return [
    {
      title: "Cash flows from operating activities",
      key: "cfOperatingActivities",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showOperatingActivities",
    },
    {
      title: "Net Income",
      key: "netIncome",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Depreciation & Amortization",
      key: "depreciationAndAmortization",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    // {
    //   title: "Investment losses (gains)",
    //   key: "depreciationAndAmortization",
    //   class: "collapse-row",
    //   flag: "showOperatingActivities",
    //   suffix: "%",
    // },
    {
      title: "Deferred income taxes",
      key: "deferredIncomeTax",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Stock based compensation",
      key: "stockBasedCompensation",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Change in working capital",
      key: "changeInWorkingCapital",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Accounts receivable",
      key: "accountsReceivables",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Inventory",
      key: "inventory",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    // {
    //   title: "Prepaid expenses",
    //   key: "inventory",
    //   class: "collapse-row",
    //   suffix: "%",
    // },
    {
      title: "Accounts payable",
      key: "accountsPayables",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Other working capital",
      key: "otherWorkingCapital",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Other non-cash items",
      key: "otherNonCashItems",
      class: "collapse-row",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Net cash provided by operating activities",
      key: "netCashProvidedByOperatingActivities",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showOperatingActivities",
      suffix: "%",
    },
    {
      title: "Cash flows from investing activities",
      key: "cashFlowFromInvestingActivities",
      isCollapsable: true,
      flagMethod: setFlags,
      flag: "showInvestingActivities",
    },
    {
      title: "Investment in property, plant & equipment",
      key: "investmentsInPropertyPlantAndEquipment",
      class: "collapse-row",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Acquisitions, net",
      key: "acquisitionsNet",
      class: "collapse-row",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Purchases of investments",
      key: "purchasesOfInvestments",
      class: "collapse-row",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Sales/maturities of investments",
      key: "salesMaturitiesOfInvestments",
      class: "collapse-row",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Other investing activities",
      key: "otherInvestingActivites",
      class: "collapse-row",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Net cash used for investing activities",
      key: "netCashUsedForInvestingActivites",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showInvestingActivities",
      suffix: "%",
    },
    {
      title: "Cash flows from financing activities",
      isCollapsable: true,
      key: "netCashFlowsFromFinancingActivities",
      flagMethod: setFlags,
      flag: "showFinancingActivities",
    },
    // {
    //   title: "Debt issued",
    //   key: "debtRepayment",
    //   class: "collapse-row",
    //   flag: "showFinancingActivities",
    // },
    {
      title: "Debt repayment",
      key: "debtRepayment",
      class: "collapse-row",
      flag: "showFinancingActivities",
      suffix: "%",
    },
    {
      title: "Common stock repurchased",
      key: "commonStockRepurchased",
      class: "collapse-row",
      flag: "showFinancingActivities",
      suffix: "%",
    },
    {
      title: "Dividend Paid",
      key: "dividendsPaid",
      class: "collapse-row",
      flag: "showFinancingActivities",
      suffix: "%",
    },
    {
      title: "Other financing activities",
      key: "otherFinancingActivites",
      class: "collapse-row",
      flag: "showFinancingActivities",
      suffix: "%",
    },
    {
      title: "Net cash provided by (used for) financing activities",
      key: "netCashUsedProvidedByFinancingActivities",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showFinancingActivities",
      suffix: "%",
    },
    {
      title: "Net change in cash",
      key: "netChangeInCash",
      class: "collapse-row",
      flag: "showFinancingActivities",
    },
    {
      title: "Cash at the beginning of period",
      key: "cashAtBeginningOfPeriod",
      class: "collapse-row",
      flag: "showFinancingActivities",
    },
    {
      title: "Cash at the end of period",
      key: "cashAtEndOfPeriod",
      class: "collapse-row",
      flag: "showFinancingActivities",
    },
    {
      title: "Free Cash Flow",
      key: "freeCashFlowHeader",
      isCollapsable: true,
      flag: "showCashFlow",
      flagMethod: setFlags,
    },
    {
      title: "Operating cash flow",
      key: "operatingCashFlow",
      class: "collapse-row",
      flag: "showCashFlow",
      suffix: "%",
    },
    {
      title: "Capital expenditure",
      key: "capitalExpenditure",
      class: "collapse-row",
      flag: "showCashFlow",
      suffix: "%",
    },
    {
      title: "Free cash flow",
      key: "freeCashFlow",
      class: "total-row-1",
      dataClass: "bold-text",
      flag: "showCashFlow",
      suffix: "%",
    },
  ]
}
