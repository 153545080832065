import React, { useContext, useEffect, useState } from "react"
import {
  Typography,
  Button,
  MenuItem,
  Menu,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Tooltip,
  Avatar,
  useMediaQuery,
} from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import { GoogleLogout } from "react-google-login"

import Search from "../Search"
import useStyles from "./styles"
import LoginForm from "../Login"
import AuthDataContext from "../../context/auth"

const Home = () => {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"))

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElIndex, setAnchorElIndex] = useState(null)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const { setLoggedInUser } = useContext(AuthDataContext)
  const currentUser = window.sessionStorage.getItem("currentUser")

  useEffect(
    () => {
      if (JSON.parse(currentUser)?.email) {
        setIsLoggedIn(true)
        setUser(JSON.parse(currentUser))
      }
      setLoggedInUser(JSON.parse(currentUser))
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.parse(currentUser)?.email]
  )

  const classes = useStyles()
  const navigate = useNavigate()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const logout = () => {
    setLoggedInUser(null)
    window.sessionStorage.setItem("currentUser", null)
    window.sessionStorage.setItem("tokenId", null)
    setIsLoggedIn(false)
    navigate("/")
  }

  const getCalculatorsMenu = () => {
    return (
      <Box>
        <Button
          aria-controls={"calculators-menu"}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
          }}
          className={classes["calculators-tab"]}
        >
          Calculators
          <KeyboardArrowDown />
        </Button>
        <Menu
          id="calculators-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              navigate("/dashboard/calculators/compound-interest")
            }}
          >
            Compound Interest
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              navigate("/dashboard/calculators/cagr")
            }}
          >
            CAGR
          </MenuItem>
        </Menu>
      </Box>
    )
  }

  const avatarMenu = () => {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={(e) => setAnchorElUser(e.target)} sx={{ p: 0 }}>
            <Avatar alt={user?.name?.toUpperCase()} src={user?.imageUrl} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          <MenuItem onClick={() => setAnchorElUser(null)}>
            <GoogleLogout
              clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
              buttonText="Logout"
              onLogoutSuccess={logout}
              render={(renderProps) => (
                <Typography
                  className={classes.googleButton}
                  textAlign="center"
                  onClick={() => {
                    setLoggedInUser(null)
                    setUser(null)
                    window.sessionStorage.setItem("currentUser", null)
                    setIsLoggedIn(false)
                    navigate("/")
                  }}
                  variant="contained"
                >
                  Logout
                </Typography>
              )}
            />
          </MenuItem>
        </Menu>
      </Box>
    )
  }

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters className={classes["main-tabs"]}>
        {/* Small screens */}
        <Typography
          sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          onClick={() => navigate("/")}
          className={classes["app-header"]}
        >
          Lets Value Invest
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {isLoggedIn && (
              <MenuItem>
                <Button
                  onClick={() => navigate("/dashboard")}
                  className={classes["home-tab"]}
                >
                  Home
                </Button>
              </MenuItem>
            )}
            {isLoggedIn && (
              <>
                <MenuItem>
                  <Button
                    onClick={() => navigate("/dashboard/visualizer")}
                    className={classes["home-tab"]}
                  >
                    Visualizer
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    onClick={() => navigate("/dashboard/screener")}
                    className={classes["home-tab"]}
                  >
                    Screener{" "}
                    <sup className={`${classes["super-text"]}`}>NEW</sup>
                  </Button>
                </MenuItem>
              </>
            )}
            <MenuItem>{getCalculatorsMenu()}</MenuItem>
            <MenuItem>
              <Button
                onClick={() => navigate("/dashboard/indexes/sp500")}
                className={classes["home-tab"]}
              >
                Indexes <sup className={`${classes["super-text"]}`}>NEW</sup>
              </Button>
            </MenuItem>
            {/* <MenuItem>
              <Button className={classes["home-tab"]}>Super Investors</Button>
            </MenuItem>
            <MenuItem>
              <Button className={classes["home-tab"]}>Blogs</Button>
            </MenuItem> */}
            <MenuItem>
              <Button
                onClick={() => navigate("/dashboard/reading")}
                className={classes["home-tab"]}
              >
                Suggested Reading{" "}
                <sup className={`${classes["super-text"]}`}>NEW</sup>
              </Button>
            </MenuItem>
            {isLoggedIn ? (
              <MenuItem>
                <Search />
              </MenuItem>
            ) : (
              <span>
                <MenuItem>
                  <LoginForm />
                </MenuItem>
                {/* <MenuItem>
                  <Button variant="contained">Try for Free</Button>
                </MenuItem> */}
              </span>
            )}
          </Menu>
        </Box>
        {/* Large screens */}
        <Typography
          noWrap
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          onClick={() => navigate("/")}
          className={classes["app-header"]}
        >
          Lets Value Invest
        </Typography>
        <Box sx={{ flexGrow: 2, display: { xs: "none", md: "flex" } }}>
          {isLoggedIn && (
            <Button
              onClick={() => navigate("/dashboard")}
              className={classes["home-tab"]}
            >
              Home
            </Button>
          )}
          {isLoggedIn && (
            <>
              <Button
                onClick={() => navigate("/dashboard/visualizer")}
                className={classes["home-tab"]}
              >
                Visualizer
              </Button>
              <Button
                onClick={() => navigate("/dashboard/screener")}
                className={classes["home-tab"]}
              >
                Screener <sup className={`${classes["super-text"]}`}>NEW</sup>
              </Button>
            </>
          )}
          {getCalculatorsMenu()}
          <Button
            onClick={() => navigate("/dashboard/indexes/sp500")}
            className={classes["home-tab"]}
          >
            Indexes <sup className={`${classes["super-text"]}`}>NEW</sup>
          </Button>
          {/* <Button className={classes["home-tab"]}>Super Investors</Button>
          <Button className={classes["home-tab"]}>Blogs</Button> */}
          <Button
            onClick={() => navigate("/dashboard/reading")}
            className={classes["home-tab"]}
          >
            Suggested Reading{" "}
            <sup className={`${classes["super-text"]}`}>NEW</sup>
          </Button>
        </Box>
        {isLoggedIn ? (
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              maxWidth: "450px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              marginRight="20px"
              sx={{
                flexGrow: 1,
                width: "300px",
                flexShrink: 0,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Search />
            </Box>
            <Box paddingRight={largeScreen ? "2rem" : "0.5rem"}>
              {avatarMenu()}
            </Box>
          </Box>
        ) : (
          <Box
            paddingRight="32px"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <LoginForm />
            {/* <Button className={classes["try-for-free"]} variant="contained">
              Try for Free
            </Button> */}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Home
