import React from "react"
import BeginnersList from "./components/BeginnersList"
import { Grid, Typography, Box } from "@mui/material"
import useStyles from "./styles"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import MetaTags from "../MetaTags"

const Reading = () => {
  const classes = useStyles()
  // for meta tags
  const title = "Suggested Reading - Lets Value Invest"
  const description =
    "Below, I've curated a carefully sequenced list of books that served as stepping stones on my own path. These titles, if read in this order, will equip you with a solid foundation for understanding the world of investing."
  const imageUrl =
    "https://images.unsplash.com/photo-1597167100957-1dfa110c1c14?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHJlYWRpbmclMjBib29rfGVufDB8fDB8fHww"
  return (
    <Grid container>
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      <div class="p4">
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <MenuBookIcon />
          <Typography variant="h6" className={classes["header-text"]}>
            Ignite Your Investment Journey: Lessons Learned Through Books
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom textAlign="justify">
          My own reading journey began in 2019. As a tech professional with
          absolutely no prior knowledge, I initially found the material
          daunting. While it started slow, each book I devoured unlocked new
          insights and compounded knowledge, transforming my understanding of
          investing, the stock market, psychology, and much more. It's no
          exaggeration to say that reading has become the most valuable tool in
          my value investor toolbox, fueling not only my investment returns but
          also personal growth and wisdom.
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="justify">
          Below, I've curated a carefully sequenced list of books that served as
          stepping stones on my own path. These titles, if read in this order,
          will equip you with a solid foundation for understanding the world of
          investing. By investing in these books (literally!), you'll not only
          be supporting my work but also investing in your own financial future
          and personal development.
        </Typography>
        <Typography variant="subtitle1" gutterBottom textAlign="justify">
          Happy reading! Let's embark on this journey together and unlock the
          power of knowledge.
        </Typography>
        <Typography variant="caption" gutterBottom textAlign="justify">
          <strong>Support & Disclosure: </strong>Your support fuels my passion!
          If you find value in this list, consider purchasing the books through
          the provided Amazon links. You won't pay extra, but I'll earn a small
          commission to keep this project thriving.
        </Typography>
        <BeginnersList />
      </div>
    </Grid>
  )
}

export default Reading
