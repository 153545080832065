import React, { useContext, useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import AppDataContext from "../../../context/data"
import { getDividends } from "../../../services/ticker"
import Loading from "../../Loading"
import DividendHistory from "./DividendHistory"
import DividendSummary from "./DividendSummary"
import useStyles from "./styles"
import ReactGA from "react-ga4"
import { parseISO, subMonths, isBefore } from "date-fns"

const Dividends = () => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/dividends" })
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const [dividendsData, setDividendsData] = useState(null)
  const { dividends, setDividends, overview } = useContext(AppDataContext)

  const getDividendsData = async () => {
    toast.dismiss()
    try {
      // get data only if context is empty and save into context
      const resp = await getDividends(tickerId)
      setDividendsData({ data: resp.data, tickerId })
      setDividends({ data: resp.data, tickerId })
      setIsLoading(false)
    } catch (err) {
      setDividendsData(null)
      setDividends(null)
      setIsLoading(false)
      toast.error(
        "Unable to get historical dividends. Please try after sometime."
      )
    }
  }

  useEffect(() => {
    document.title = `${tickerId} - Dividends - Lets Value Invest`
    setIsLoading(true)
    setDividendsData(null)
    // check if we have data already in context
    if (dividends?.tickerId === tickerId) {
      setDividendsData(dividends)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getDividendsData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  const historicalData = dividendsData?.data?.historical
  let paysDividend = historicalData ? true : false

  if (historicalData && historicalData.length > 0) {
    const paymentDateStr = historicalData[0]?.paymentDate
    const declarationDateStr = historicalData[0]?.date
    const dividendDateStr = paymentDateStr || declarationDateStr

    if (dividendDateStr) {
      // Parse the payment date string into a Date object using parseISO
      const paymentDate = parseISO(paymentDateStr)

      // Calculate the date 6 months ago from today using subMonths
      const twelveMonthsAgo = subMonths(new Date(), 12)

      // Compare paymentDate with sixMonthsAgo using isBefore
      if (isBefore(paymentDate, twelveMonthsAgo)) {
        console.log("Dividend date is older than 12 months")
        paysDividend = false
      } else {
        console.log("Dividend date is within the last 12 months")
        paysDividend = true
      }
    } else {
      console.log("Dividend date information is missing")
      paysDividend = false
    }
  } else {
    console.log("No historical dividend data available")
    paysDividend = false
  }

  return (
    <Box>
      {paysDividend ? (
        <>
          <DividendSummary
            dividendsData={dividendsData?.data}
            tickerId={tickerId}
          />
          <DividendHistory dividendsData={dividendsData?.data?.historical} />
        </>
      ) : (
        <Typography
          className={classes["content-text"]}
          paddingBottom="12px"
          xs={12}
          md={"flex"}
        >
          There is no dividend history available for <b>{tickerId}</b>. This
          usually happens when the company has never paid a dividend in the
          past.
        </Typography>
      )}
    </Box>
  )
}

export default Dividends
