import React from "react"
import PropTypes from "prop-types"
import { format } from "date-fns"
import {
  Box,
  Card,
  CardContent,
  Link,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material"
import LanguageIcon from "@mui/icons-material/Language"

import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import useStyles from "../styles"
import { palette } from "../../../../utils/Palette"

const TickerProfile = ({ profile }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          className={"pb2"}
        >
          <img
            style={{
              maxHeight: 60,
              maxWidth: 80,
            }}
            alt=""
            src={profile?.image}
            className={"pr3"}
          />
          <span className={classes["header"]}>{profile?.companyName}</span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          color={palette.purple}
          className={"pb2"}
        >
          <LanguageIcon className={"pr1"} />
          <Link target="_blank" href={profile?.website}>
            Visit company website
          </Link>
        </Box>
        <TableContainer>
          <Table>
            <TableBody>
              {/* Industry */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Industry
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.industry}</TableCell>
              </TableRow>
              {/* Sector */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Sector
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.sector}</TableCell>
              </TableRow>
              {/* CEO */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    CEO
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.ceo}</TableCell>
              </TableRow>
              {/* Employees */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Employees
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TextThousandSeparator
                    value={profile?.fullTimeEmployees}
                    prefix={""}
                    suffix={""}
                    thousandSeparator={true}
                    fixedDecimalScale={false}
                  />
                </TableCell>
              </TableRow>
              {/* Headquarters */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Headquarters
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {profile?.city}
                  {" ,"}
                  {profile?.state}
                </TableCell>
              </TableRow>
              {/* Exchange */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Exchange
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {profile?.exchangeShortName}
                </TableCell>
              </TableRow>
              {/* Ticker */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Ticker
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.symbol}</TableCell>
              </TableRow>
              {/* Market cap */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Market Cap
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TextThousandSeparator
                    value={Number(profile?.mktCap) / 1000000000}
                    prefix={""}
                    suffix={" B"}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </TableCell>
              </TableRow>
              {/* Shares Outstanding */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Shares Outstanding
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TextThousandSeparator
                    value={
                      Number(profile?.mktCap) /
                      Number(profile?.price) /
                      1000000000
                    }
                    prefix={""}
                    suffix={" B"}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </TableCell>
              </TableRow>
              {/* Reporting Currency */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    Reporting Currency
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.currency}</TableCell>
              </TableRow>
              {/* CIK code */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    CIK code
                  </Typography>
                </TableCell>
                <TableCell align="right">{profile?.cik}</TableCell>
              </TableRow>
              {/* IPO date */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes["content-txt"]}>
                    IPO date
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {format(new Date(profile?.ipoDate), "MM/dd/yyyy")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default TickerProfile

TickerProfile.propTypes = {
  profile: PropTypes.object.isRequired,
}
