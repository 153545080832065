import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const BigNumbers = ({ data }) => {
  const classes = useStyles()
  const [rows] = useState([
    {
      title: "Market Cap",
      key: "marketCap",
      defToolTip: {
        key: "market_cap",
        type: "Overview",
      },
    },
    {
      title: "Enterprise Value",
      key: "enterpriseValue",
      defToolTip: {
        key: "enterprise_value",
        type: "Overview",
      },
    },
    {
      title: "Revenue",
      key: "revenueTtm",
      defToolTip: {
        key: "revenue",
        type: "Overview",
      },
    },
    {
      title: "Net Income",
      key: "netIncomeTtm",
      defToolTip: {
        key: "net_income",
        type: "Overview",
      },
    },
    {
      title: "Operating Cashflow",
      key: "operatingCashFlowTtm",
      defToolTip: {
        key: "operating_cash_flow",
        type: "Overview",
      },
    },
    {
      title: "Free Cashflow",
      key: "freeCashFlowTtm",
      defToolTip: {
        key: "free_cash_flow",
        type: "Overview",
      },
    },
  ])

  // To display formatted value
  const displayValue = (
    value,
    ext = "$",
    thousandSeparator = true,
    isSuffix = false
  ) => {
    if (!value) return "-"
    const absValue = Math.abs(value)
    if (absValue > 999 && absValue <= 1000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000).toFixed(2)
      isSuffix = true
      ext = "K"
    } else if (absValue >= 1000000 && absValue < 1000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000).toFixed(2)
      isSuffix = true
      ext = "M"
    } else if (absValue >= 1000000000 && absValue < 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000).toFixed(2)
      isSuffix = true
      ext = "B"
    } else if (absValue >= 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000000).toFixed(2)
      isSuffix = true
      ext = "T"
    }

    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  return (
    <Box className={classes["big-numbers"]}>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Big Numbers</div>
                <div className={classes["sub-header"]}>
                  Showing TTM/current values
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="right">
                  ${displayValue(data[row.key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default BigNumbers

BigNumbers.propTypes = {
  data: PropTypes.object.isRequired,
}
