import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { getLogs } from "../../services/ticker" // Assuming getLogs fetches logs
import Loading from "../Loading"

import useStyles from "./styles" // Assuming styles for classes
import { toast } from "react-toastify" // Assuming toast for notifications

const LogsViewer = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [logsData, setLogsData] = useState({})
  const [logs, setLogs] = useState([]) // State for displaying filtered logs
  const [selectedLevel, setSelectedLevel] = useState("DEBUG") // Initial level

  const handleChange = (level) => {
    setSelectedLevel(level)
    const filteredLogs = logsData?.logs?.filter((log) => log.level === level)
    setLogs(filteredLogs) // Update logs state with filtered data
  }

  useEffect(() => {
    document.title = `View logs - Lets Value Invest`
    setIsLoading(true)
    const getLogsData = async () => {
      toast.dismiss()
      try {
        let filteredInitialLogs = []
        if (!logsData?._id) {
          const resp = await getLogs()
          setLogsData(resp.data)
          filteredInitialLogs = resp.data.logs?.filter(
            (log) => log.level === selectedLevel
          )
        } else {
          // Set initial logs based on selectedLevel
          filteredInitialLogs = logsData.logs?.filter(
            (log) => log.level === selectedLevel
          )
        }
        setLogs(filteredInitialLogs || []) // Set empty array if filteredInitialLogs is undefined
        setIsLoading(false)
      } catch (err) {
        setLogsData(null)
        setIsLoading(false)
        toast.error("Unable to get logs data. Please try after sometime.")
      }
    }
    getLogsData()
  }, [selectedLevel]) // Re-render on selectedLevel change

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box className={classes["main-container"]}>
      <Typography className={classes["header-text"]}>
        Logs for the last run Batch Job at: {logsData.startTime}
      </Typography>
      <Grid container className={classes["selection-container"]}>
        <Grid item xs={12}>
          <Select
            value={selectedLevel}
            onChange={(e) => handleChange(e.target.value)}
          >
            <MenuItem value="INFO">INFO</MenuItem>
            <MenuItem value="DEBUG">DEBUG</MenuItem>
            <MenuItem value="ERROR">ERROR</MenuItem>
            {/* Add other levels as needed */}
          </Select>
        </Grid>
        <List sx={{ backgroundColor: "black" }}>
          {logs?.map((log) => (
            <ListItem key={log.time}>
              <ListItemText
                className={classes.message}
                primary={
                  <Typography variant="body2">
                    <span className={classes.time}>{log.time}</span>{" "}
                    <span
                      className={
                        log.level === "INFO"
                          ? classes.levelInfo
                          : log.level === "DEBUG"
                          ? classes.levelWarn
                          : classes.levelError
                      }
                    >
                      {log.level}
                    </span>
                    <span className={classes.message}>{log.message}</span>
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Box>
  )
}

export default LogsViewer
