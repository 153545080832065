import { format } from "date-fns"

export const getRatioValues = (financialRatiosData) => {
  // default data
  const defaultData = {
    priceMultiples: Array.apply(null, Array(10)),
    priceToSalesRatio: Array.apply(null, Array(10)),
    priceEarningsRatio: Array.apply(null, Array(10)),
    priceToBookRatio: Array.apply(null, Array(10)),
    priceToTangibleBookRatio: Array.apply(null, Array(10)),
    priceToOperatingCashFlowsRatio: Array.apply(null, Array(10)),
    priceToFreeCashFlowsRatio: Array.apply(null, Array(10)),
    priceEarningsToGrowthRatio: Array.apply(null, Array(10)),
    managementEffectiveness: Array.apply(null, Array(10)),
    returnOnAssets: Array.apply(null, Array(10)),
    returnOnEquity: Array.apply(null, Array(10)),
    returnOnCapitalEmployed: Array.apply(null, Array(10)),
    profitability: Array.apply(null, Array(10)),
    grossProfitMargin: Array.apply(null, Array(10)),
    operatingProfitMargin: Array.apply(null, Array(10)),
    pretaxProfitMargin: Array.apply(null, Array(10)),
    netProfitMargin: Array.apply(null, Array(10)),
    operatingCashFlowMargin: Array.apply(null, Array(10)),
    freeCashFlowMargin: Array.apply(null, Array(10)),
    fcfToNetIncome: Array.apply(null, Array(10)),
    shortTermFinancialHealth: Array.apply(null, Array(10)),
    currentRatio: Array.apply(null, Array(10)),
    quickRatio: Array.apply(null, Array(10)),
    cashRatio: Array.apply(null, Array(10)),
    interestCoverage: Array.apply(null, Array(10)),
    longTermFinancialHealth: Array.apply(null, Array(10)),
    debtEquityRatio: Array.apply(null, Array(10)),
    cashFlowToDebtRatio: Array.apply(null, Array(10)),
    longTermDebtToCapitalization: Array.apply(null, Array(10)),
    totalDebtToCapitalization: Array.apply(null, Array(10)),
    dividendInfo: Array.apply(null, Array(10)),
    dividendYield: Array.apply(null, Array(10)),
    dividendPayoutRatio: Array.apply(null, Array(10)),
    fcfPayoutRatio: Array.apply(null, Array(10)),
    cyclesAndTurnovers: Array.apply(null, Array(10)),
    daysOfSalesOutstanding: Array.apply(null, Array(10)),
    daysOfInventoryOutstanding: Array.apply(null, Array(10)),
    operatingCycle: Array.apply(null, Array(10)),
    daysOfPayablesOutstanding: Array.apply(null, Array(10)),
    cashConversionCycle: Array.apply(null, Array(10)),
    receivablesTurnover: Array.apply(null, Array(10)),
    payablesTurnover: Array.apply(null, Array(10)),
    inventoryTurnover: Array.apply(null, Array(10)),
    fixedAssetTurnover: Array.apply(null, Array(10)),
    assetTurnover: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  financialRatiosData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      financialRatiosData.length > 10 ? financialRatiosData.length - 1 : 9
    defaultData.priceMultiples[maxIndex - index] = 0
    defaultData.priceToSalesRatio[maxIndex - index] = data?.priceToSalesRatio
    defaultData.priceEarningsRatio[maxIndex - index] = data?.priceEarningsRatio
    defaultData.priceToBookRatio[maxIndex - index] = data?.priceToBookRatio
    defaultData.priceToTangibleBookRatio[maxIndex - index] =
      data?.priceToTangibleBookRatio
    defaultData.priceToOperatingCashFlowsRatio[maxIndex - index] =
      data?.priceToOperatingCashFlowsRatio
    defaultData.priceToFreeCashFlowsRatio[maxIndex - index] =
      data?.priceToFreeCashFlowsRatio
    defaultData.priceEarningsToGrowthRatio[maxIndex - index] =
      data?.priceEarningsToGrowthRatio

    defaultData.managementEffectiveness[maxIndex - index] = 0
    defaultData.returnOnAssets[maxIndex - index] = data?.returnOnAssets * 100
    defaultData.returnOnEquity[maxIndex - index] = data?.returnOnEquity * 100
    defaultData.returnOnCapitalEmployed[maxIndex - index] =
      data?.returnOnCapitalEmployed * 100
    defaultData.profitability[maxIndex - index] = 0
    defaultData.grossProfitMargin[maxIndex - index] =
      data?.grossProfitMargin * 100
    defaultData.operatingProfitMargin[maxIndex - index] =
      data?.operatingProfitMargin * 100
    defaultData.pretaxProfitMargin[maxIndex - index] =
      data?.pretaxProfitMargin * 100
    defaultData.netProfitMargin[maxIndex - index] = data?.netProfitMargin * 100
    defaultData.operatingCashFlowMargin[maxIndex - index] =
      data?.operatingCashFlowSalesRatio * 100
    defaultData.freeCashFlowMargin[maxIndex - index] = data
      ? data.operatingCashFlowSalesRatio *
        data.freeCashFlowOperatingCashFlowRatio *
        100
      : ""
    defaultData.fcfToNetIncome[maxIndex - index] = data
      ? ((data.operatingCashFlowSalesRatio *
          data.freeCashFlowOperatingCashFlowRatio) /
          data.netProfitMargin) *
        100
      : ""
    defaultData.shortTermFinancialHealth[maxIndex - index] = 0
    defaultData.currentRatio[maxIndex - index] = data?.currentRatio
    defaultData.quickRatio[maxIndex - index] = data?.quickRatio
    defaultData.cashRatio[maxIndex - index] = data?.cashRatio
    defaultData.interestCoverage[maxIndex - index] = data?.interestCoverage
    defaultData.longTermFinancialHealth[maxIndex - index] = 0
    defaultData.debtEquityRatio[maxIndex - index] = data?.debtEquityRatio * 100
    defaultData.cashFlowToDebtRatio[maxIndex - index] =
      data?.cashFlowToDebtRatio * 100
    defaultData.longTermDebtToCapitalization[maxIndex - index] =
      data?.longTermDebtToCapitalization * 100
    defaultData.totalDebtToCapitalization[maxIndex - index] =
      data?.totalDebtToCapitalization * 100
    defaultData.dividendInfo[maxIndex - index] = 0
    defaultData.dividendYield[maxIndex - index] = data?.dividendYield * 100
    defaultData.dividendPayoutRatio[maxIndex - index] =
      data?.dividendPayoutRatio * 100
    defaultData.fcfPayoutRatio[maxIndex - index] = data
      ? (data.payoutRatio / data.operatingCashFlowSalesRatio) *
        data.freeCashFlowOperatingCashFlowRatio *
        data.grossProfitMargin *
        100
      : ""
    defaultData.cyclesAndTurnovers[maxIndex - index] = 0
    defaultData.daysOfSalesOutstanding[maxIndex - index] =
      data?.daysOfSalesOutstanding
    defaultData.daysOfInventoryOutstanding[maxIndex - index] =
      data?.daysOfInventoryOutstanding
    defaultData.operatingCycle[maxIndex - index] = data?.operatingCycle
    defaultData.daysOfPayablesOutstanding[maxIndex - index] =
      data?.daysOfPayablesOutstanding
    defaultData.cashConversionCycle[maxIndex - index] =
      data?.cashConversionCycle
    defaultData.receivablesTurnover[maxIndex - index] =
      data?.receivablesTurnover
    defaultData.payablesTurnover[maxIndex - index] = data?.payablesTurnover
    defaultData.inventoryTurnover[maxIndex - index] = data?.inventoryTurnover
    defaultData.fixedAssetTurnover[maxIndex - index] = data?.fixedAssetTurnover
    defaultData.assetTurnover[maxIndex - index] = data?.assetTurnover
  })
  return { tempHeaders, defaultData }
}
