import { Box, Typography } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from "react-select/async"
import { toast } from "react-toastify"
import { debounce, sortBy } from "lodash"
import { capitalCase } from "change-case"

import useStyles from "../../styles"
import { searchTicker } from "../../../../services/core"
import { palette } from "../../../../utils/Palette"
import { getVisualizationData } from "../../../../services/core"

const TickersSearch = ({
  setVisualizationData,
  setTickers,
  setCriterias,
  setSelectedTickers,
  setisLoading,
}) => {
  const classes = useStyles()

  const searchTickers = async (searchTxt, callback) => {
    toast.dismiss()
    try {
      setTickers([])
      const tickersResp = await searchTicker(`query=${searchTxt}&limit=10`)
      const tickersData = tickersResp?.data?.map((ticker) => {
        return {
          value: ticker.symbol,
          label: `${ticker?.symbol} - ${ticker?.name}`,
        }
      })
      setTickers(tickersData || [])
      callback(tickersData)
    } catch (err) {
      toast.error("Unable to search for tickers. Please try after sometime.")
    }
  }

  const generateCriterias = (response) => {
    const mainHeaders = Object.keys(response?.data || [])
    const headers = []
    mainHeaders.forEach((header) => {
      if (header !== "tickerId")
        headers.push({
          label: capitalCase(header),
          options: Object.keys(response?.data[header]).map((option) => {
            return {
              label: response?.data[header][option]?.label
                ? response?.data[header][option].label
                : capitalCase(option),
              value: option,
              headerKey: header,
              suffix: response?.data[header][option].suffix,
              prefix: response?.data[header][option].prefix,
            }
          }),
        })
    })
    setCriterias(headers)
  }

  const getStockVisualizationData = (options) => {
    setisLoading(true)
    toast.dismiss()
    setSelectedTickers(sortBy(options, ["value"]))
    const promises = []
    // generate api calls for each ticker
    options.forEach((ticker) => {
      promises.push(getVisualizationData(ticker.value))
    })
    /**
     * TO-DO:
     * 1. call api only if we does not have the data for that ticker in visualizationData
     * 2. Generate criterias only for the first time of ticker search
     */
    const data = []
    Promise.all(promises)
      .then((responses) => {
        generateCriterias(responses[0])
        responses.forEach((response) => {
          data.push(response?.data)
        })
        setVisualizationData(data)
        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(false)
        setVisualizationData([])
        toast.error("Unable to get ticker data. Please try after sometime.")
      })
  }

  const debounceSearchTicker = debounce(getStockVisualizationData, 1000, {
    maxWait: 1000,
  })

  return (
    <Box>
      <Typography className={classes["selection-header"]}>
        Add tickers for analysis...
      </Typography>
      <span className={classes["ticker"]}>
        <AsyncSelect
          cacheOptions
          loadOptions={searchTickers}
          defaultOptions
          onChange={debounceSearchTicker}
          isMulti
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: palette.purple,
            },
          })}
        />
      </span>
    </Box>
  )
}

export default TickersSearch

TickersSearch.propTypes = {
  setVisualizationData: PropTypes.func.isRequired,
  setTickers: PropTypes.func.isRequired,
  setCriterias: PropTypes.func.isRequired,
  setSelectedTickers: PropTypes.func.isRequired,
  setisLoading: PropTypes.func.isRequired,
}
