import { isNil } from "lodash"
const { Finance } = require("financejs")
const finance = new Finance()

export const compoundInterestWithContribution = (
  rateOfReturn,
  contributionTypeVal,
  noOfYears,
  contribution
) => {
  return (
    contribution *
    ((Math.pow(
      1 + rateOfReturn / (100 * contributionTypeVal),
      contributionTypeVal * noOfYears
    ) -
      1) /
      (rateOfReturn / (100 * contributionTypeVal)))
  )
}

export const generatePrevYears = (
  count,
  startYear = new Date().getFullYear()
) => {
  const year = startYear
  return Array.from(new Array(count), (val, index) => year - index)
}

export const getCAGR = (initialValue, finalValue, noOfYears) => {
  if (
    isNil(initialValue) ||
    isNil(finalValue) ||
    (initialValue < 0 && finalValue > 0) ||
    (initialValue > 0 && finalValue < 0) ||
    finalValue < 0
  ) {
    return null
  }
  return finance.CAGR(initialValue, finalValue, noOfYears)
}
