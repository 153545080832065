import React, { useContext, useState, useEffect } from "react"
import { Box, Typography, Link } from "@mui/material"
import { useNavigate, Routes, Route, useLocation } from "react-router-dom"
import decode from "jwt-decode"
import { toast } from "react-toastify"

import Search from "../Search"
import useStyles from "./styles"
import AppDataContext from "../../context/data"
import Header from "../Header"
import Footer from "../Footer"
import Calculators from "../Calculators"
import Guides from "../Guides"
import Ticker from "../Ticker"
import Overview from "../Ticker/Overview"
import Visualizer from "../Visualizer"
import LogsViewer from "../LogsViewer"
import Screener from "../Screener"
import Reading from "../Reading"
import ProtectedRoute from "../ProtectedRoute"
import { getStrapiTerms } from "../../services/core"
import { getFormatedTerms } from "../../utils/strapi"
import { getQuotes } from "../../services/core"
import Constituents from "../Constituents"

const Dashboard = () => {
  const classes = useStyles()

  const [tokenId] = useState(window.sessionStorage.getItem("tokenId"))
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { setStrapiTerms } = useContext(AppDataContext)
  const { setQuotes } = useContext(AppDataContext)

  useEffect(() => {
    const getTerms = async () => {
      try {
        const resp = await getStrapiTerms()
        setStrapiTerms(getFormatedTerms(resp?.data))
      } catch (err) {}
    }
    getTerms()
  }, [setStrapiTerms])

  useEffect(() => {
    // getQuotes
    const loadQuotes = async () => {
      try {
        const resp = await getQuotes()
        setQuotes(resp?.data)
      } catch (err) {}
    }
    loadQuotes()
  }, [setQuotes])

  useEffect(() => {
    try {
      if (
        tokenId &&
        !(
          pathname.includes("compound-interest") ||
          pathname.includes("cagr") ||
          pathname.includes("guides") ||
          pathname.includes("reading") ||
          pathname.includes("indexes")
        )
      ) {
        const decodedToken = decode(tokenId)
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          window.sessionStorage.setItem("currentUser", null)
          window.sessionStorage.setItem("tokenId", null)
          navigate("/")
        }
      }
    } catch (err) {
      navigate("/")
      setTimeout(() => {
        toast.warn("Please login to continue.")
      })
    }
  }, [pathname, tokenId, navigate])

  return (
    <Box className={classes["home-container"]}>
      <Box className={classes["header-container"]}>
        <Header />
      </Box>
      <Box className={classes["main-container"]} flex={1}>
        <Routes>
          <Route path="/" name="Dashboard" element={<DashboardContent />} />
          <Route
            path="/calculators/:type"
            name="Calculators"
            element={<Calculators />}
          />
          <Route
            path="/indexes/:type"
            name="Indexes"
            element={<Constituents />}
          />
          <Route path="/reading" name="Reading" element={<Reading />} />
          <Route path="/guides/:type" name="Guides" element={<Guides />} />
          <Route path="/ticker/:tickerId/*" name="Ticker" element={<Ticker />}>
            <Route
              path="overview"
              name="Overview"
              element={
                <ProtectedRoute>
                  <Overview />
                </ProtectedRoute>
              }
            ></Route>
          </Route>
          <Route
            path="/visualizer"
            name="Visualizer"
            element={
              <ProtectedRoute>
                <Visualizer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/screener"
            name="Screener"
            element={
              <ProtectedRoute>
                <Screener />
              </ProtectedRoute>
            }
          />
          <Route
            path="/logs"
            name="LogsViewer"
            element={
              <ProtectedRoute>
                <LogsViewer />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
      <Box className={classes["footer-container"]}>
        <Footer />
      </Box>
    </Box>
  )
}

export default Dashboard

const DashboardContent = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { recentSearches } = useContext(AppDataContext)

  return (
    <Box className={classes["content-container"]} flex={1}>
      <Typography className={classes["header-text"]}>
        Welcome to <b>Lets Value Invest</b>. Start by searching your favorite
        company using ticker or the company name.
      </Typography>
      <Box className={classes["search-area"]}>
        <Search />
      </Box>
      <Box className={classes["recent-search-box"]}>
        {recentSearches && recentSearches.length > 0 && (
          <Typography className={classes["recent-search"]}>
            Recent Searches:{" "}
          </Typography>
        )}
        {recentSearches &&
          recentSearches
            .slice(-10)
            .reverse()
            .map((text, index) => (
              <Link
                key={`${text}-${index}`}
                className={classes["recent-search-txt"]}
                onClick={() => {
                  navigate(`/dashboard/ticker/${text}/overview`)
                }}
              >
                {text}
              </Link>
            ))}
      </Box>
    </Box>
  )
}
