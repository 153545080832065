import { createTheme } from "@mui/material/styles"
import { palette } from "../utils/Palette"

const theme = createTheme({
  typography: {
    fontFamily: `Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'`,
  },
  palette: {
    ...palette,
  },
  components: {
    // Name of the component ⚛️
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: palette["grey-1"],
          color: palette.white,
          "&:disabled": {
            backgroundColor: palette["grey-1"],
            color: palette.white,
          },
          "&:hover": {
            backgroundColor: palette["grey-1"],
            color: palette.white,
          },
        },
      },
    },
    /* MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // Target the tooltip container itself
          backgroundColor: "white", // Adjust background color
          color: palette.black, // Adjust text color (contrast for readability)
          padding: "8px 12px", // Optional: Adjust padding
          borderRadius: 4, // Optional: Add a subtle border radius
          border: `1px dotted ${palette.black}`,
          // Optional: Adjust styling for disabled states if needed
          // '&.Mui-disabled': {
          //   backgroundColor: palette.grey[200],
          //   color: palette.grey[400],
          // },
        },
        tooltipArrow: {
          // Optionally style the tooltip arrow
          color: palette.black, // Adjust arrow color to match background
        },
      },
    }, */
  },
})

export default theme
