import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "table-container": {
    border: `1px solid ${theme.palette["grey-3"]}`,
    boxShadow: "none",
  },
  "main-header": {
    fontSize: "18px",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  "row-header": {
    textTransform: "uppercase",
    color: `${theme.palette["grey-2"]} !important`,
  },
  "table-cell": {
    borderBottom: "none !important",
    padding: "0.6rem 0.8rem !important",
  },
}))
