import React, { useState, useEffect } from "react"
import {
  Button,
  Box,
  Typography,
  Grid,
  Alert,
  FormControl,
} from "@mui/material"
import Select from "react-select"
import AddIcon from "@mui/icons-material/Add"
import { toast } from "react-toastify"
import Filter from "./filter"
import { findStocks } from "../../services/core"

import useStyles from "./styles"
import FilteredStocks from "./filteredStocks"
import Loading from "../Loading"
import { allClassifications, allSectors } from "./selectOptions.js"

function Screener() {
  const classes = useStyles()
  const [filters, setFilters] = useState([])
  const [filteredStocks, setFilteredStocks] = useState([])
  const [displayResults, setDisplayResults] = useState(false)
  const [filterCriteriaChanged, setFilterCriteriaChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedSectors, setSelectedSectors] = useState([]) // Array to store selected sectors
  const [selectedClassifications, setSelectedClassifications] = useState([]) // Array to store selected sectors

  useEffect(() => {
    document.title = `Screener - Lets Value Invest`
  }, [])

  const handleAddFilter = () => {
    setFilters([
      ...filters,
      {
        metric: {
          label: "Choose a metric...",
          value: "",
          suffix: "",
          type: "",
        },
        operator: { label: "Select operator...", value: "" },
        input: { label: "Select a value...", value: "" },
      },
    ])
    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleFilterChange = (index, field, value) => {
    const updatedFilters = [...filters]
    updatedFilters[index][field] = value
    setFilters(updatedFilters)
    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleDeleteFilter = (index) => {
    const updatedFilters = [...filters]
    updatedFilters.splice(index, 1) // Remove the filter at the specified index
    setFilters(updatedFilters)
    setDisplayResults(false)
    if (filteredStocks?.length > 0) setFilterCriteriaChanged(true)
  }

  const handleFindStocks = async () => {
    // Send filters data to backend API using fetch or axios
    // console.log("Filters submitted:", filters)
    // console.log(JSON.stringify(filters))
    setIsLoading(true)
    try {
      const response = await findStocks(
        JSON.stringify({
          sectors: selectedSectors,
          classifications: selectedClassifications,
          filters: filters,
        })
      )
      setFilteredStocks(response.data)
      setDisplayResults(true)
      setFilterCriteriaChanged(false)
      setIsLoading(false)
    } catch (err) {
      setFilteredStocks([])
      setDisplayResults(false)
      setIsLoading(false)
      toast.error("Unable to run the screener. Please try after sometime.")
    }
  }

  const renderSectorSelection = () => {
    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <Typography className={classes["selection-header"]}>
          Select sectors...
        </Typography>
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isClearable
          isSearchable
          name="allSectors"
          options={allSectors}
          placeholder={"All Sectors"}
          onChange={(options) => {
            setSelectedSectors(options)
            filteredStocks?.length > 0
              ? setFilterCriteriaChanged(true)
              : setFilterCriteriaChanged(false)
            setDisplayResults(false)
          }}
          value={selectedSectors}
        />
      </FormControl>
    )
  }

  const renderClassificationSelection = () => {
    return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
        <Typography className={classes["selection-header"]}>
          Select Market Caps...
        </Typography>
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isClearable
          isSearchable
          name="allClassifications"
          options={allClassifications}
          placeholder={"All Market Caps"}
          onChange={(options) => {
            setSelectedClassifications(options)
            filteredStocks?.length > 0
              ? setFilterCriteriaChanged(true)
              : setFilterCriteriaChanged(false)
            setDisplayResults(false)
          }}
          value={selectedClassifications}
        />
      </FormControl>
    )
  }

  const renderFilters = () => {
    return filters.map((filter, index) => (
      <Filter
        key={index + `-${filter?.metric?.metric}`}
        index={index}
        filter={filter}
        onFilterChange={handleFilterChange}
        onDelete={handleDeleteFilter} // Pass onDelete prop to Filter component
        setSelectedSectors={setSelectedSectors} // Pass the prop here
      />
    ))
  }

  // Check if there are any filters
  let shouldShowFindStocksButton = false
  if (filters.length > 0) {
    filters.forEach((filter) => {
      if (
        !shouldShowFindStocksButton &&
        filter.metric &&
        filter.metric.value !== "" &&
        filter.operator &&
        filter.operator.value !== "" &&
        filter.input &&
        filter.input.value !== ""
      )
        shouldShowFindStocksButton = true
    })
  }

  return (
    <Box className={classes["main-container"]}>
      <Typography variant="h6">Stock Screener</Typography>
      <Typography variant="body">
        Tired of endless stock research? Our powerful Stock Screener helps you
        cut through the noise and
        <strong> discover top investment opportunities </strong>
        based on your specific criteria.
      </Typography>
      <div>
        {renderClassificationSelection()}
        {renderSectorSelection()}
      </div>
      <Grid container className={classes["selection-container"]}>
        <Grid item xs={12} sm={6} md={6}>
          {filters.length === 0 && (
            <Box
              sx={{
                p: { xs: 2, md: 2 },
                m: { xs: 2, md: 2 },
                border: "1px dashed grey",
              }}
            >
              <Typography>
                No filters selected. Please add some filters to get started.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {filters.length > 0 && renderFilters()}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAddFilter}
          sx={{ width: "150px" }}
        >
          Add Filter
        </Button>
        {shouldShowFindStocksButton && ( // Conditionally render Find Stocks button
          <Button
            variant="contained"
            display="row"
            color="primary"
            onClick={handleFindStocks}
            sx={{ width: "150px" }}
          >
            Find Stocks
          </Button>
        )}
      </Box>
      {isLoading && (
        <Box className="loader">
          <Loading />
        </Box>
      )}
      {displayResults && ( // Conditionally render Find Stocks button
        <FilteredStocks filteredStocks={filteredStocks} filters={filters} />
      )}
      {filterCriteriaChanged && ( // Conditionally render Find Stocks button
        <Grid item xs={12} sm={6} md={6}>
          <Alert variant="outlined" severity="warning" sx={{ mt: 5 }}>
            Screening criteria changed !. Please click{" "}
            <strong>FIND STOCKS</strong> to get updated results.
          </Alert>
        </Grid>
      )}
    </Box>
  )
}

export default Screener
