import React, { useEffect, useState } from "react"
import { Box, Typography, Grid } from "@mui/material"
import { Finance } from "financejs"
import { FormatQuote } from "@mui/icons-material"

import useStyles from "../styles"
import Calculator from "./calculator"
import Bar from "./charts/bar"
import Line from "./charts/line"
import CompoundValTable from "./table"
import { compoundInterestWithContribution } from "../../../utils/financeFns"
import MetaTags from "../../MetaTags"

const Compound = () => {
  const finance = new Finance()
  const classes = useStyles()
  const [errMsg, setErrMsg] = useState("")
  const frequency = {
    D: "Daily",
    M: "Monthly",
    Y: "Annually",
  }
  const frequencyVal = {
    D: 365,
    M: 12,
    Y: 1,
  }
  const [data, setData] = useState([])

  // for meta tags
  const title = "Compound Interest Calculator - Lets Value Invest"
  const description =
    "Understanding the power of compounding is essential for successful investing. Try our simple, intuitive calculator to see how small amounts of money can grow over time."
  const imageUrl =
    "https://letsvalueinvest.com/Assets/compound-interest-share.png"

  const [calculatorParams, setCalculatorParams] = useState({
    initialVal: 10000,
    contribution: 100,
    contributionType: "M",
    noOfYears: 10,
    noOfYearsInvested: 10,
    rateOfReturn: 10,
    compoundingFreq: "D",
    rateVar: 2,
    total: 22000,
    futureVal: 0,
    variance: {},
  })

  useEffect(
    () => {
      const {
        initialVal,
        contribution,
        contributionType,
        noOfYears,
        noOfYearsInvested,
        rateOfReturn,
        compoundingFreq,
        rateVar,
      } = calculatorParams

      setErrMsg("")
      if (noOfYearsInvested >= 100 || noOfYears >= 100)
        return setErrMsg("Number of years should be less than 100.")
      if (noOfYearsInvested < noOfYears)
        return setErrMsg(
          "Number of years you want to invest for should be less than total investment years."
        )

      let currentYear = 0
      const calculatedData = []
      while (currentYear <= noOfYearsInvested) {
        const newFutureVal = finance.CI(
          rateOfReturn,
          frequencyVal[compoundingFreq],
          currentYear > noOfYears
            ? calculatedData[noOfYears].futureVal
            : initialVal,
          currentYear > noOfYears ? currentYear - noOfYears : currentYear
        )
        const leftVar = finance.CI(
          rateOfReturn - rateVar,
          frequencyVal[compoundingFreq],
          currentYear > noOfYears
            ? calculatedData[noOfYears].leftVar
            : initialVal,
          currentYear > noOfYears ? currentYear - noOfYears : currentYear
        )
        const rightVar = finance.CI(
          rateOfReturn + rateVar,
          frequencyVal[compoundingFreq],
          currentYear > noOfYears
            ? calculatedData[noOfYears].rightVar
            : initialVal,
          currentYear > noOfYears ? currentYear - noOfYears : currentYear
        )
        const futureValOfContributions = compoundInterestWithContribution(
          rateOfReturn,
          frequencyVal[contributionType],
          currentYear,
          currentYear <= noOfYears ? contribution : 0
        )
        const futureValOfContributionsLeftVar =
          compoundInterestWithContribution(
            rateOfReturn - rateVar,
            frequencyVal[contributionType],
            currentYear,
            currentYear <= noOfYears ? contribution : 0
          )
        const futureValOfContributionsRightVar =
          compoundInterestWithContribution(
            rateOfReturn + rateVar,
            frequencyVal[contributionType],
            currentYear,
            currentYear <= noOfYears ? contribution : 0
          )
        calculatedData.push({
          newFutureVal,
          futureValOfContributions,
          left: leftVar,
          right: rightVar,
          futureValOfContributionsLeftVar,
          futureValOfContributionsRightVar,
          year: parseInt(new Date().getFullYear()) + currentYear,
          futureVal: (newFutureVal + futureValOfContributions).toFixed(2),
          total:
            initialVal +
            (currentYear <= noOfYears ? currentYear : noOfYears) *
              contribution *
              frequencyVal[contributionType],
          leftVar: (leftVar + futureValOfContributionsLeftVar).toFixed(2),
          rightVar: (rightVar + futureValOfContributionsRightVar).toFixed(2),
        })
        currentYear += 1
      }
      setCalculatorParams({
        ...calculatorParams,
        futureVal: parseInt(calculatedData[noOfYearsInvested]?.futureVal),
        total:
          initialVal +
          noOfYears * contribution * frequencyVal[contributionType],
        variance: {
          leftVar: parseInt(calculatedData[noOfYearsInvested]?.leftVar),
          rightVar: parseInt(calculatedData[noOfYearsInvested]?.rightVar),
        },
      })
      setData(calculatedData)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      calculatorParams.initialVal,
      calculatorParams.contribution,
      calculatorParams.contributionType,
      calculatorParams.noOfYears,
      calculatorParams.noOfYearsInvested,
      calculatorParams.rateOfReturn,
      calculatorParams.compoundingFreq,
      calculatorParams.rateVar,
    ]
  )

  return (
    <Box className="p4">
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      <Grid container direction="row">
        {/* quotes 1 */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={classes["compound-header"]}
        >
          <Typography>
            <Box
              sx={{
                p: 2,
                mb: 1,
                maxWidth: "md",
                border: "1px dashed grey",
                backgroundColor: "lightyellow",
              }}
            >
              <FormatQuote />
              Money makes money. And the money that money makes, makes money.
              <FormatQuote />
              <span className={classes["author-name"]}>
                <i> - Benjamin Franklin.</i>
              </span>
            </Box>
          </Typography>
        </Grid>
        {/* quotes 2 */}
        <Grid item md={6}>
          <Typography textAlign="justify">
            Understanding the power of compounding is essential for successful
            investing. Try our simple, intuitive calculator to see how small
            amounts of money can grow over time.
          </Typography>
        </Grid>
        <Grid item md={6}></Grid>
        {/* Calculator */}
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <Calculator
            calculatorParams={calculatorParams}
            setCalculatorParams={setCalculatorParams}
            frequency={frequency}
            errMsg={errMsg}
          />
          <Box className={classes["compound-val-table"]}>
            <CompoundValTable calculatorParams={calculatorParams} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={6}
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={11} lg={9}>
            {data && data.length && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Bar values={data} calculatorParams={calculatorParams} />
                <Line values={data} calculatorParams={calculatorParams} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Compound
