import React from "react"
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import HoldersChart from "./HoldersChart"

const HoldersBreakdown = ({ data }) => {
  const classes = useStyles()

  if (!data) return <></>

  return (
    <Box display={"flex"}>
      <Grid container alignItems="center" rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <span className={classes["main-header"]}>
            Major Holders Breakdown:
          </span>
          <TableContainer>
            <Table>
              {data?.length && (
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {data[0].description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={data[0]?.percentage}
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {data[1].description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={data[1]?.percentage}
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {data[2].description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={data[2]?.percentage}
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        % of Shares Held By Retail Investors
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={
                            100 -
                            (parseFloat(data?.[0].percentage) +
                              parseFloat(data?.[1].percentage))
                          }
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {data[3].description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={data[3]?.percentage}
                          prefix={""}
                          suffix={""}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            width="100%"
            height="100%"
            display={"flex"}
            justifyContent="center"
          >
            <HoldersChart
              insiderOwnership={parseFloat(data?.[0]?.percentage)}
              institutionalOwnership={parseFloat(data?.[1]?.percentage)}
              retailOwnership={
                100 -
                (parseFloat(data?.[0]?.percentage) +
                  parseFloat(data?.[1]?.percentage))
              }
              tickerId={"tickerId"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HoldersBreakdown

HoldersBreakdown.propTypes = {
  data: PropTypes.array.isRequired,
}
