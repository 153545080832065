import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
} from "@mui/material"

import TickerHeader from "../TickerHeader"
import Metrics from "../Metrics"
import { getHeaders } from "../../configs/detailed"
import useStyles from "../../styles"
import clsx from "clsx"
import ReactGA from "react-ga4"
import Term from "../../../../../utils/Term"

const DetailedView = ({ data }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/peers/detailed",
  })
  const rows = getHeaders()
  const classes = useStyles()

  if (!data?.length) return <></>

  return (
    <Grid container justifyContent="start" marginTop={4}>
      <Grid item sm={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow key={row.title}>
                    {rowIndex !== 0 ? (
                      <TableCell className={classes["sticky-cell"]}>
                        <TableContainer
                          component={Paper}
                          className={classes["table-container"]}
                          style={{ minWidth: "300px" }}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className={"h12"}>
                                  <div className={classes["main-header"]}>
                                    {row.title}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center"></TableCell>
                              </TableRow>
                              {row.metrics?.map((row, index) => (
                                <TableRow key={`${row.title}${index}`}>
                                  <TableCell
                                    className={classes["row-header"]}
                                    align="left"
                                  >
                                    <Box display={"flex"} alignItems="center">
                                      {row.title}
                                      <span className="ml1 mt1">
                                        <Term config={row.defToolTip} />
                                      </span>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    ) : (
                      <TableCell className={classes["sticky-cell"]}></TableCell>
                    )}
                    {data.map((record, index) => {
                      if (row.isTickerHeader) {
                        return (
                          <TableCell
                            align={"left"}
                            key={`${row.title}-${record.tickerId}`}
                          >
                            <TickerHeader data={record?.quoteResponse} />
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell
                          align="left"
                          key={`${row.title}-${record.tickerId}`}
                        >
                          <Metrics
                            data={record[row.key] || []}
                            config={row}
                            isFirst={index === 0}
                          />
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default DetailedView

DetailedView.propTypes = {
  data: PropTypes.array.isRequired,
  overview: PropTypes.object.isRequired,
}
