import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "type-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "toggle-btn": {
    textTransform: "capitalize !important",
    fontSize: "16px !important",
    minWidth: "7rem !important",
  },
  title: {
    marginLeft: "6px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold !important",
    fontSize: "20px !important",
    textTransform: "uppercase !important",
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
    fontSize: "14px",
  },
}))
