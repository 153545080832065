import React, { useState, useEffect } from "react"
import { Box, Typography, Grid, Input } from "@mui/material"

import useStyles from "../styles"
import {
  InputThousandSeparator,
  TextThousandSeparator,
} from "../../../utils/NumberFormatters"
import Doughnut from "./doughnut"
import MetaTags from "../../MetaTags"

const CAGR = () => {
  const classes = useStyles()

  const [presentVal, setPresentVal] = useState(1000)
  const [futureVal, setFutureVal] = useState(2000)
  const [years, setYears] = useState(10)
  const [cagr, setCagr] = useState(7.18)

  // for meta tags
  const title = "CAGR Calculator - Lets Value Invest"
  const description =
    "The CAGR calculator can help you make informed investment decisions. Try our CAGR calculator today and see how your investment will grow!"
  const imageUrl = "https://letsvalueinvest.com/Assets/cagr-share.png"

  const displayValue = (value, ext = "%", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  useEffect(() => {
    setCagr(
      ((Math.pow(futureVal / presentVal, 1 / years) - 1) * 100).toFixed(2)
    )
  }, [presentVal, futureVal, years])

  return (
    <Box className="p12">
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      <Grid container direction="row">
        {/* description */}
        <Grid item md={12}>
          <Typography textAlign="justify" sx={{ mb: 1 }}>
            The CAGR calculator can help you make informed investment decisions.
            Try our CAGR calculator today and see how your investment will grow!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography textAlign={"center"}>
            <b>CAGR Calculator</b>
          </Typography>
          {/* Present Value */}
          <Grid container className="mt6">
            <Grid item xs={7}>
              <Typography className={classes.labels}>Present value</Typography>
            </Grid>
            <Grid item xs={5}>
              <Input
                value={presentVal}
                inputComponent={InputThousandSeparator}
                inputProps={{
                  style: { textAlign: "center" },
                  prefix: "$",
                  allowNegative: false,
                  onChange: (event) => {
                    setPresentVal(event.target.value)
                  },
                  thousandSeparator: true,
                }}
                size="small"
              />
            </Grid>
          </Grid>
          {/* Future Value */}
          <Grid container>
            <Grid item xs={7}>
              <Typography className={classes.labels}>Future value</Typography>
            </Grid>
            <Grid item xs={5}>
              <Input
                value={futureVal}
                inputComponent={InputThousandSeparator}
                inputProps={{
                  style: { textAlign: "center" },
                  prefix: "$",
                  allowNegative: false,
                  onChange: (event) => {
                    setFutureVal(event.target.value)
                  },
                  thousandSeparator: true,
                }}
                size="small"
              />
            </Grid>
          </Grid>
          {/* Years */}
          <Grid container className="mb6">
            <Grid item xs={7}>
              <Typography className={classes.labels}>Years</Typography>
            </Grid>
            <Grid item xs={5}>
              <Input
                type="number"
                value={years}
                onChange={(event) => setYears(event.target.value)}
                inputProps={{
                  style: { textAlign: "center" },
                  min: 1,
                }}
                error={years < 1}
                size="small"
              />
              {years < 1 && (
                <Typography className="error-txt">
                  Years can't be less than 1.
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Typography>
              Compound Annual Growth Rate (CAGR):
              <b>
                {"  "}
                {isFinite(cagr) ? cagr : 0}%
              </b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              This also means that if your initial investment of{" "}
              <b>{displayValue(presentVal, "$", false)} </b>
              turns into <b>{displayValue(futureVal, "$", false)}</b> in{" "}
              <b>{displayValue(years, "", false)} </b> years, you achieved a
              compound annual growth rate (CAGR) of{" "}
              <b>{displayValue(cagr, "%", true)} </b>
            </Typography>
            <Box
              width="100%"
              height="100%"
              display={"flex"}
              justifyContent="center"
            >
              <Doughnut
                presentValue={presentVal}
                futureValue={futureVal}
                cagr={cagr}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={8}></Grid>
      </Grid>
    </Box>
  )
}

export default CAGR
