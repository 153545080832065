import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "type-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "toggle-btn": {
    textTransform: "capitalize !important",
    fontSize: "16px !important",
    minWidth: "7rem !important",
  },
  title: {
    marginLeft: "6px",
  },
  header: {
    fontSize: "20px !important",
    marginBottom: "16px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "header-icon": {
    fontSize: "40px !important",
  },
  "header-row": {
    fontWeight: "600",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.purple,
  },
  "data-row": {
    fontSize: "16px",
    color: theme.palette["grey-2"],
    display: "flex",
    alignItems: "center",
  },
  "big-numbers": {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
  },
  "table-container": {
    border: `1px solid ${theme.palette["grey-3"]}`,
    boxShadow: "none",
  },
  "main-header": {
    fontSize: "18px",
  },
  "table-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  "row-header": {
    textTransform: "uppercase",
    color: `${theme.palette["grey-2"]} !important`,
  },
  "score-5": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-5-bg"],
    color: theme.palette.white,
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "score-4": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-4-bg"],
    color: theme.palette.white,
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "score-3": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-3-bg"],
    color: theme.palette.white,
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "score-2": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-1-bg"],
    color: theme.palette.white,
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "score-1": {
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: theme.palette["score-2-bg"],
    color: theme.palette.white,
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "total-score-5": {
    fontWeight: "600",
    fontSize: "16px",
    backgroundColor: theme.palette["tot-score-5-bg"],
    color: theme.palette["score-5-bg"],
    padding: "2px 10px",
    borderRadius: "10px",
  },
  "total-score-4": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-4-bg"],
    color: theme.palette["score-4-bg"],
    padding: "2px 10px",
    margin: "2px",
    borderRadius: "10px",
  },
  "total-score-3": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-3-bg"],
    color: theme.palette["score-3-bg"],
    padding: "2px 10px",
    margin: "2px",
    borderRadius: "10px",
  },
  "total-score-2": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-2-bg"],
    color: theme.palette["score-2-bg"],
    padding: "2px 10px",
    margin: "2px",
    borderRadius: "10px",
  },
  "total-score-1": {
    fontWeight: "600",
    backgroundColor: theme.palette["tot-score-1-bg"],
    color: theme.palette["score-1-bg"],
    padding: "2px 10px",
    margin: "2px",
    borderRadius: "10px",
  },
  "score-bg": {
    width: "200px",
    height: "30px",
    backgroundColor: theme.palette["grey-4"],
    display: "inline-block",
    textAlign: "center",
    borderRadius: "8px",
    marginLeft: "1rem",
  },
  "tot-score": {
    height: "30px",
    backgroundColor: theme.palette.purple,
    backgroundImage:
      "linear-gradient(to right, #F56666, #F44336, #A9A9A9, #8BC34A, #3D9141)",
    display: "block",
    borderRadius: "8px",
    color: theme.palette.white,
    fontWeight: "bold",
  },
  "score-txt": {
    position: "relative",
    top: "5px",
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
    fontSize: "14px",
  },
  "super-text": {
    color: `green !important`,
    paddingLeft: "5px",
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: "10px",
    paddingBottom: "5px",
  },
}))
