import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { min, max, mean } from "lodash"
import { differenceInYears } from "date-fns"

import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import useStyles from "../../styles"

const DataAnanlysis = ({ selectedCriterias, selectedTickers, rawData }) => {
  const [rows, setRows] = useState(null)
  const classes = useStyles()

  const displayValue = (value, ext = "", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  const getData = (ticker, criteria) => {
    const tickerData = rawData.filter(
      (data) =>
        data.tickerId === ticker.value && data.criteriaValue === criteria.value
    )[0]
    if (!tickerData) {
      return
    }
    const filteredDataValues = tickerData.data.filter(
      (object) => object.x !== null && object.y !== null
    )
    const dataValues = filteredDataValues.map((obj) => obj.y)
    const years = filteredDataValues.map((obj) => obj.x)
    const yearsDiff = differenceInYears(
      new Date(years[0]),
      new Date(years.at(-1))
    )
    // percentage values
    if (tickerData.suffix && tickerData.suffix === "%")
      return {
        color: tickerData.borderColor,
        start: displayValue(dataValues[0], tickerData.suffix),
        min: displayValue(min(dataValues), tickerData.suffix),
        max: displayValue(max(dataValues), tickerData.suffix),
        avg: displayValue(mean(dataValues), tickerData.suffix),
        end: displayValue(dataValues.at(-1), tickerData.suffix),
        cagr: displayValue(
          dataValues.at(-1) - dataValues[0],
          tickerData.suffix
        ),
      }
    // dollar values
    if (tickerData.prefix && tickerData.prefix === "$")
      return {
        color: tickerData.borderColor,
        start: displayValue(
          dataValues[0] / 1000000 > 1 ? dataValues[0] / 1000000 : dataValues[0],
          tickerData.prefix,
          false
        ),
        min: displayValue(
          min(dataValues) / 1000000 > 1
            ? min(dataValues) / 1000000
            : min(dataValues),
          tickerData.prefix,
          false
        ),
        max: displayValue(
          max(dataValues) / 1000000 > 1
            ? max(dataValues) / 1000000
            : max(dataValues),
          tickerData.prefix,
          false
        ),
        avg: displayValue(
          mean(dataValues) / 1000000 > 1
            ? mean(dataValues) / 1000000
            : mean(dataValues),
          tickerData.prefix,
          false
        ),
        end: displayValue(
          dataValues.at(-1) / 1000000 > 1
            ? dataValues.at(-1) / 1000000
            : dataValues.at(-1),
          tickerData.prefix,
          false
        ),
        cagr: displayValue(
          (Math.pow(dataValues[0] / dataValues.at(-1), 1 / yearsDiff) - 1) *
            100,
          "%"
        ),
      }
    // times values - multiple expansion
    return {
      color: tickerData.borderColor,
      start: displayValue(dataValues[0], tickerData.suffix),
      min: displayValue(min(dataValues), tickerData.suffix),
      max: displayValue(max(dataValues), tickerData.suffix),
      avg: displayValue(mean(dataValues), tickerData.suffix),
      end: displayValue(dataValues.at(-1), tickerData.suffix),
      cagr: displayValue(
        (Math.pow(dataValues[0] / dataValues.at(-1), 1 / yearsDiff) - 1) * 100,
        "%"
      ),
      multiple: displayValue(dataValues.at(-1) / dataValues[0], "x"),
    }
  }

  useEffect(() => {
    setRows(null)
    if (selectedCriterias.length && selectedTickers.length && rawData.length) {
      const tableData = []
      selectedTickers.forEach((ticker) => {
        selectedCriterias.forEach((criteria) => {
          tableData.push({
            tickerLabel: `${ticker.value} - ${criteria.label}`,
            ...getData(ticker, criteria),
          })
        })
      })
      setRows(tableData)
    }
  }, [JSON.stringify(rawData)])

  return (
    <Box>
      {rows && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={10}>
                  <div className={classes["main-header"]}>
                    Selected Data analysis
                  </div>
                  <div className={classes["sub-header"]}>
                    Take a quick look at the selected data to infer meaningful
                    insights
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell align="left">Selected ticker metrics</TableCell>
                <TableCell align="center">Starting</TableCell>
                <TableCell align="center">Minimum</TableCell>
                <TableCell align="center">Average</TableCell>
                <TableCell align="center">Maximum</TableCell>
                <TableCell align="center">Current</TableCell>
                <TableCell align="center">CAGR/Multiple expansion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.tickerLabel}>
                  <TableCell align="left" style={{ color: row.color }}>
                    {row.tickerLabel}
                  </TableCell>
                  <TableCell align="center">{row.start}</TableCell>
                  <TableCell align="center">{row.min}</TableCell>
                  <TableCell align="center">{row.avg}</TableCell>
                  <TableCell align="center">{row.max}</TableCell>
                  <TableCell align="center">{row.end}</TableCell>
                  {row?.multiple ? (
                    <TableCell align="center">
                      {row.cagr}{" "}
                      <span className={classes["multiple"]}>
                        ({row.multiple})
                      </span>
                    </TableCell>
                  ) : (
                    <TableCell align="center">{row.cagr}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default DataAnanlysis

DataAnanlysis.propTypes = {
  rawData: PropTypes.array.isRequired,
  selectedCriterias: PropTypes.array.isRequired,
  selectedTickers: PropTypes.array.isRequired,
}
