import { Box, Divider } from "@mui/material"
import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"

const Results = ({ calcResult, discountRate }) => {
  const classes = useStyles()

  const displayValue = (value, ext = "%", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: true,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  const getColorClass = (value, compareValue) => {
    return value < compareValue ? "error-txt" : "success-txt"
  }

  return (
    <Box>
      {/* Divider */}
      <Box display={"flex"} width="100%" className={classes.divider}>
        <Divider sx={{ borderBottomWidth: 2 }} />
      </Box>
      {/* Intrinsic Value */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Intrinsic Value</Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(
              calcResult?.bull?.otherData?.intrinsicValue,
              "$",
              false
            )}
          </b>
        </Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(
              calcResult?.normal?.otherData?.intrinsicValue,
              "$",
              false
            )}
          </b>
        </Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(
              calcResult?.bear?.otherData?.intrinsicValue,
              "$",
              false
            )}
          </b>
        </Box>
      </Box>
      {/* Future stock price */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Future stock price</Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(calcResult?.bull?.otherData?.sharePrice, "$", false)}
          </b>
        </Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(
              calcResult?.normal?.otherData?.sharePrice,
              "$",
              false
            )}
          </b>
        </Box>
        <Box className={classes["res-value"]}>
          <b>
            {displayValue(calcResult?.bear?.otherData?.sharePrice, "$", false)}
          </b>
        </Box>
      </Box>
      {/* Expected Rate of Return */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Expected Rate of Return</Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.otherData?.expCagr,
              discountRate
            )}
          >
            <b>{displayValue(calcResult?.bull?.otherData?.expCagr)}</b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.otherData?.expCagr,
              discountRate
            )}
          >
            <b>{displayValue(calcResult?.normal?.otherData?.expCagr)}</b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.otherData?.expCagr,
              discountRate
            )}
          >
            <b>{displayValue(calcResult?.bear?.otherData?.expCagr)}</b>
          </span>
        </Box>
      </Box>
      {/* Future value */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Future value of $10,000</Box>
        <Box className={classes["res-value"]}>
          <span>
            <b>
              {displayValue(
                calcResult?.bull?.otherData?.futureValue,
                "$",
                false
              )}
            </b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span>
            <b>
              {displayValue(
                calcResult?.normal?.otherData?.futureValue,
                "$",
                false
              )}
            </b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span>
            <b>
              {displayValue(
                calcResult?.bear?.otherData?.futureValue,
                "$",
                false
              )}
            </b>
          </span>
        </Box>
      </Box>
      {/* Margin Of Safety */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Margin Of Safety</Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.otherData?.marginOfSafety,
              0
            )}
          >
            <b>{displayValue(calcResult?.bull?.otherData?.marginOfSafety)}</b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.otherData?.marginOfSafety,
              0
            )}
          >
            <b>{displayValue(calcResult?.normal?.otherData?.marginOfSafety)}</b>
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.otherData?.marginOfSafety,
              0
            )}
          >
            <b>{displayValue(calcResult?.bear?.otherData?.marginOfSafety)}</b>
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default Results
