import React from "react"
import PropTypes from "prop-types"
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material"

import { palette } from "../../../../utils/Palette"
import { useParams } from "react-router-dom"
import useStyles from "../styles"

const Recommendations = ({ recommendations }) => {
  const classes = useStyles()
  const { tickerId } = useParams()
  const rows = [
    {
      display: "Strong Buy",
      key: "strongBuy",
      color: "score-5-bg",
    },
    {
      display: "Buy",
      key: "buy",
      color: "score-4-bg",
    },
    {
      display: "Hold",
      key: "hold",
      color: "score-3-bg",
    },
    {
      display: "Sell",
      key: "sell",
      color: "score-1-bg",
    },
    {
      display: "Strong Sell",
      key: "strongSell",
      color: "score-2-bg",
    },
  ]

  if (!recommendations) {
    return <Box></Box>
  }

  const { buy, hold, sell, strongBuy, strongSell } = recommendations
  const total = buy + hold + sell + strongBuy + strongSell

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <div className={classes["main-header"]}>
                  Recent Recommendations
                </div>
                <div className={classes["sub-header"]}>
                  Based on {total} analysts offering recommendations for{" "}
                  {tickerId} in the last 3 months.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={`${row.key}`}>
                <TableCell style={{ borderBottom: "none" }}>
                  <Grid container display="flex" justifyContent="center">
                    <Grid item xs={3} sm={2}>
                      {row.display}
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                    >
                      <div className="mr1" style={{ width: "20px" }}>
                        {recommendations[row.key]}
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          className={classes["recommend-div"]}
                          style={{
                            width: `${
                              (recommendations[row.key] / total) * 100
                            }%`,
                            backgroundColor: palette[row.color],
                            height: "8px",
                          }}
                        ></div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Recommendations

Recommendations.propTypes = {
  analystEstimates: PropTypes.array,
}
