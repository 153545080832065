import React, { useState, useContext, useEffect } from "react"
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { GoogleLogin } from "react-google-login"
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material"
import { gapi } from "gapi-script"

import useStyles from "./styles"
import AuthContext from "../../context/auth"
import { signIn, signUp } from "../../services/core"
import ReactGA from "react-ga4"

function Login() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  }
  const [open, setOpen] = useState(false)
  const [isSignup, setIsSignup] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const { setLoggedInUser } = useContext(AuthContext)
  const [formData, setFormData] = useState(initialState)

  const handleShowPassword = () => setShowPassword(!showPassword)

  const switchMode = () => {
    setFormData({ ...initialState })
    setIsSignup((prevIsSignup) => !prevIsSignup)
    setShowPassword(false)
  }

  useEffect(() => {
    toast.dismiss()
    gapi?.load("client:auth2", () => {
      gapi?.client.init({
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        scope: "email",
      })
    })
  })

  const onGoogleLoginError = (response) => {
    // Send a custom event
    ReactGA.event({
      category: "Login",
      action: "login_failure",
      // label: ticker.symbol, // optional
    })
    toast.dismiss()
    toast.error("Unable to login. Please try after sometime.")
  }

  // const refreshTokenSetup = (res) => {
  //   // Timing to renew access token
  //   let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000

  //   const refreshToken = async () => {
  //     const newAuthRes = await res.reloadAuthResponse()
  //     refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000

  //     // saveUserToken(newAuthRes.access_token);  <-- save new token

  //     // Setup the other timer after the first one
  //     setTimeout(refreshToken, refreshTiming)
  //   }

  //   // Setup first refresh timer
  //   setTimeout(refreshToken, refreshTiming)
  // }

  const onGoogleLoginSuccess = async (response) => {
    toast.dismiss()
    window.sessionStorage.setItem("tokenId", response.tokenId)
    window.sessionStorage.setItem(
      "currentUser",
      JSON.stringify(response.profileObj)
    )
    setLoggedInUser(response.profileObj)
    await signUp(
      {
        firstName: response.profileObj?.givenName,
        lastName: response.profileObj?.familyName,
        imageUrl: response.profileObj?.imageUrl,
        email: response.profileObj?.email,
      },
      "GOOGLE"
    )
    // Send a custom event
    ReactGA.event({
      category: "Login",
      action: "login_success",
      // label: ticker.symbol, // optional
    })
    // refreshTokenSetup(response)
    navigate("/dashboard")
  }

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault()
      let response
      if (isSignup) response = await signUp(formData)
      else response = await signIn(formData)

      if (response?.status === 200) {
        setOpen(false)
        window.sessionStorage.setItem(
          "currentUser",
          JSON.stringify(response?.data?.user)
        )
        window.sessionStorage.setItem("tokenId", response?.data?.token)
        setLoggedInUser(response?.data?.user)
        !isSignup && navigate("/dashboard")
        setTimeout(() => {
          isSignup && toast.success("User resgistration success.")
        })
        setFormData(initialState)
      } else
        toast.error(
          response?.data?.msg || "Unable to login. Please try again later."
        )
    } catch (err) {
      toast.error("Unable to login. Please try again later.")
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <Box>
      {/* Enable to have signup and login action dialog */}
      {/* <Button variant="contained" onClick={() => setOpen(true)}>
        Login
      </Button> */}
      <GoogleLogin
        clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
        buttonText="Login"
        onSuccess={onGoogleLoginSuccess}
        onFailure={onGoogleLoginError}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            variant="contained"
            className={classes["login-btn-1"]}
          >
            Login
          </Button>
        )}
      />
      <GoogleLogin
        clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
        buttonText="Try for Free"
        onSuccess={onGoogleLoginSuccess}
        onFailure={onGoogleLoginError}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            variant="contained"
          >
            Try for Free
          </Button>
        )}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box width={"400px"}>
          <Box className={classes["google-btn"]}>
            <Lock className={classes["lock-logo"]} />
            <GoogleLogin
              clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={onGoogleLoginSuccess}
              onFailure={onGoogleLoginError}
              cookiePolicy={"single_host_origin"}
            />
          </Box>
          <Box className={classes.divider}>
            <Divider style={{ width: "40%" }} />
            <span>{"Or"}</span>
            <Divider style={{ width: "40%" }} />
          </Box>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              {isSignup && (
                <Box className="mb1" display="flex" flexDirection="row">
                  <TextField
                    name="firstName"
                    label="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    margin="dense"
                    fullWidth
                    autoFocus
                    variant="standard"
                    className="mr2"
                  />
                  <TextField
                    name="lastName"
                    label="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    margin="dense"
                    variant="standard"
                    className="ml2"
                    fullWidth
                  />
                </Box>
              )}
              <TextField
                autoFocus
                autoComplete="off"
                margin="dense"
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                variant="standard"
                fullWidth
                onChange={handleChange}
                className="mb3"
              />
              <TextField
                margin="dense"
                autoComplete="off"
                id="name"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={formData.password}
                variant="standard"
                onChange={handleChange}
                className="mb3"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {isSignup && (
                <TextField
                  autoComplete="off"
                  margin="dense"
                  id="name"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                />
              )}
              <DialogActions className={classes["login-dialog-actions"]}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes["login-btn"]}
                >
                  {isSignup ? "Sign Up" : "Login"}
                </Button>
                <Typography className={classes["sign-up"]} onClick={switchMode}>
                  {!isSignup ? (
                    <>
                      {"Don't have an account?  "}
                      <Link className="cursor-pointer">Sign Up</Link>
                    </>
                  ) : (
                    <>
                      {"Already have an account?  "}
                      <Link className="cursor-pointer">Sign In</Link>
                    </>
                  )}
                </Typography>
              </DialogActions>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  )
}

export default Login
