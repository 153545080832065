import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const MarginsProfitability = ({ data }) => {
  const classes = useStyles()
  const [rows] = useState([
    {
      title: "Gross Margin",
      key: "grossProfitMarginTtm",
      defToolTip: {
        key: "gross_margin",
        type: "Overview",
      },
    },
    {
      title: "Operating Margin",
      key: "operatingProfitMarginTtm",
      defToolTip: {
        key: "operating_margin",
        type: "Overview",
      },
    },
    {
      title: "Net Profit Margin",
      key: "netProfitMarginTtm",
      defToolTip: {
        key: "net_profit_margin",
        type: "Overview",
      },
    },
    {
      title: "Free Cash Flow Margin",
      key: "freeCashFlowMarginTtm",
      defToolTip: {
        key: "free_cashflow_margin",
        type: "Overview",
      },
    },
    {
      title: "FCF to NET Income margin",
      key: "freeCashFlowToNetIncomeTtm",
      defToolTip: {
        key: "fct_to_net_income_margin",
        type: "Overview",
      },
    },
  ])

  // To display formatted value
  const displayValue = (value, ext = "%", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: true,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>
                  Margins/Profitability
                </div>
                <div className={classes["sub-header"]}>
                  Calculated based on trailing 12 month values
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="right">
                  {displayValue(data[row.key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default MarginsProfitability

MarginsProfitability.propTypes = {
  data: PropTypes.object.isRequired,
}
