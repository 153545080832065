import React, { useState } from "react"
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  ClickAwayListener,
} from "@mui/material"
import PropTypes from "prop-types"
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  BarChart,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { styled } from "@mui/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { TextThousandSeparator } from "../../../../../utils/NumberFormatters"

import useStyles from "../styles"
import Line from "./TooltipChart"
import Term from "../../../../../utils/Term"

const Row = ({
  rowData,
  config,
  flags,
  displayDataType,
  displayDataConversionType,
  masterRowData,
  masterHeaders,
}) => {
  const classes = useStyles()
  const [openTooltipKey, setOpenTooltipKey] = useState(null)
  const { tickerId } = useParams()

  const displayValue = (value, ext = "", isSuffix = false, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  const checkForNegativeDisplay = (value, config) => {
    if ((config.isNegValue && value) || value < 0)
      return (
        <span className={"error-txt"}>
          {"( "}
          {displayValue(Math.abs(value), "", true)}
          {config.suffix && value ? config.suffix : ""}
          {" )"}
        </span>
      )
    else
      return (
        <span>
          {displayValue(value, "", true)}
          {config.suffix && value ? config.suffix : ""}
        </span>
      )
  }

  // Custom tooltip with HTML
  const ChartTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
      maxWidth: 900,
      height: 550,
      border: `1px solid ${theme.palette["grey-3"]}`,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette["primary-bg"],
    },
  }))

  if (!rowData?.length || !rowData) return <></>
  return (
    <StyledTableRow
      className={classes.row}
      key={config.key}
      sx={{ "& > *": { borderBottom: "unset" } }}
    >
      <TableCell component="th" scope="row" className={classes.sticky}>
        <Box className={classes["row-headers"]}>
          <span>
            {config?.isCollapsable && (
              <IconButton
                className={classes["collapse-icon"]}
                size="small"
                onClick={() =>
                  config.flagMethod({
                    ...flags,
                    [config.flag]: !flags[config.flag],
                  })
                }
              >
                {flags[config.flag] ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            )}
            <span
              className={`${classes["info-text"]} ${classes[config.class]}`}
            >
              {config?.title}
            </span>
          </span>
          <span className="ml2">
            <Term config={config?.defToolTip} />
          </span>
          {!config?.isCollapsable && (
            <ClickAwayListener onClickAway={() => setOpenTooltipKey(null)}>
              <div>
                <ChartTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setOpenTooltipKey(null)}
                  open={openTooltipKey === config.key}
                  placement="right"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Box className={classes["tooltip-chart"]}>
                      <Line
                        dataSet={masterRowData}
                        labels={masterHeaders}
                        config={{
                          title: config.title,
                          tickerId: tickerId,
                          displayDataType: displayDataType,
                          suffix: config.suffix,
                          isNumber: config.isNumber,
                          displayDataConversionType: displayDataConversionType,
                        }}
                      />
                    </Box>
                  }
                >
                  <BarChart
                    onClick={() => setOpenTooltipKey(config.key)}
                    className={classes["chart-icon"]}
                  />
                </ChartTooltip>
              </div>
            </ClickAwayListener>
          )}
        </Box>
      </TableCell>
      {rowData?.map((value, index) => (
        <TableCell key={`${index}${new Date()}`} align="right" scope="row">
          <span
            className={`${classes["data-text"]} ${classes[config.dataClass]}`}
          >
            {!config?.isCollapsable
              ? checkForNegativeDisplay(value, config)
              : ""}
          </span>
        </TableCell>
      ))}
    </StyledTableRow>
  )
}

export default Row

Row.defaultProps = {
  rowData: [],
  config: {},
  flags: {},
  headers: [],
  displayDataType: "Annual",
}

Row.propTypes = {
  rowData: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  displayDataType: PropTypes.string.isRequired,
}
