export const yaxisLabel = (value) => {
  value = Number(value)
  if (value < 1000) return `$${value}`
  if (value >= 1000 && value < 1000000) {
    value = (value / 1000).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}K`
  } else if (value >= 1000000 && value < 1000000000) {
    value = (value / 1000000).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`
  } else if (value >= 1000000000 && value < 1000000000000) {
    value = (value / 1000000000).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}B`
  } else {
    value = (value / 1000000000000).toFixed(2)
    return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}T`
  }
}

export const yaxisLabel2 = (value) => {
  value = Number(value)
  if (value < 999) return `${value}`
  value = (value / 1000).toFixed(2)
  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}k`
}

export const thousandSeparator = (value) => {
  value = Number(value)
  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}
