import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette["grey-4"]}`,
    padding: "1.2rem",
    borderRadius: "8px",
  },
  "loader-container": {
    border: `1px solid ${theme.palette["grey-4"]}`,
    padding: "1.2rem",
    borderRadius: "8px",
    height: "55vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "radio-col": {
    height: "55vh",
    overflowY: "scroll",
    textAlign: "justify",
  },
  header: {
    fontWeight: "500 !important",
    fontSize: "20px !important",
    textAlign: "center",
    paddingBottom: "12px",
    borderBottom: `1px solid ${theme.palette["grey-4"]}`,
  },
  "content-txt": {
    border: `1px solid ${theme.palette["grey-4"]}`,
    padding: "1.2rem",
    borderRadius: "8px",
    height: "55vh",
    overflowY: "scroll",
    textAlign: "justify",
  },
}))
