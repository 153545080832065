import React from "react"
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material"
import PropTypes from "prop-types"
import { ContentCopy, CompareArrows } from "@mui/icons-material"
import { toast } from "react-toastify"
import clsx from "clsx"

import useStyles from "../styles"

const Header = ({
  displayCurrency,
  displayDataType,
  setDisplayDataType,
  displayDataConversionType,
  setDisplayDataConversionType,
  showCurrencyConverter,
  setShowCurrencyConverter,
  currencyOpt,
  setCurrencyOpt,
  reverseData,
}) => {
  const classes = useStyles()

  const copyTable = () => {
    const elTable = document.querySelector("table")

    let range, sel

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange()
      sel = window.getSelection()
      // unselect any element in the page
      sel.removeAllRanges()

      try {
        range.selectNodeContents(elTable)
        sel.addRange(range)
      } catch (e) {
        range.selectNode(elTable)
        sel.addRange(range)
      }

      document.execCommand("copy")
    }
    toast.info("Copied the Balance Sheet statement.")
  }

  return (
    <>
      <Box className={classes["header-container"]}>
        {displayDataConversionType === "raw" && (
          <Typography
            className={classes["info-text"]}
            paddingBottom="12px"
            xs={12}
            md={"flex"}
          >
            Reported Currency is in <b>{displayCurrency}</b> (Millions) except
            for per share figures.
          </Typography>
        )}
        {displayDataConversionType === "percentage" && (
          <Typography
            className={classes["info-text"]}
            paddingBottom="12px"
            xs={12}
            md={"flex"}
          >
            Items expressed as percentage of assets for assets related figures
            and as percentage of liabilities for liabilities related figures.
          </Typography>
        )}
        <Box className={classes["right-content"]}>
          <Tooltip title="Switch order of columns" arrow placement="top">
            <CompareArrows
              onClick={reverseData}
              className={clsx(classes["reverse-btn"], "mr3")}
            />
          </Tooltip>
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={displayDataType}
            onChange={(e) => setDisplayDataType(e.target.value)}
            xs={12}
            md={"flex"}
          >
            <Tooltip title="Switch to annual" arrow placement="top">
              <ToggleButton
                value="annualData"
                key="annualData"
                className={classes["toggle-btn"]}
              >
                {"Annual"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to quarterly" arrow placement="top">
              <ToggleButton
                value="quarterlyData"
                key="quarterlyData"
                className={classes["toggle-btn"]}
              >
                {"Quarterly"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to trailing 12 months" arrow placement="top">
              <ToggleButton
                value="ttmData"
                key="ttmData"
                className={classes["toggle-btn"]}
              >
                {"TTM"}
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Box padding={"0 24px 0 24px"}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={displayDataConversionType}
              onChange={(e) => setDisplayDataConversionType(e.target.value)}
              xs={12}
              md={"flex"}
            >
              <Tooltip title="Switch to amounts" arrow placement="top">
                <ToggleButton
                  value="raw"
                  key="raw"
                  className={classes["toggle-btn"]}
                >
                  {"$"}
                </ToggleButton>
              </Tooltip>
              ,
              <Tooltip title="Switch to percentage view" arrow placement="top">
                <ToggleButton
                  value="percentage"
                  key="percentage"
                  className={classes["toggle-btn"]}
                >
                  {"%"}
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
          {showCurrencyConverter && (
            <Box padding={"0 12px 0 0"}>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={currencyOpt}
                onChange={(e) => setCurrencyOpt(e.target.value)}
                xs={12}
                md={"flex"}
              >
                <Tooltip
                  title={`Switch to ${displayCurrency}`}
                  arrow
                  placement="top"
                >
                  <ToggleButton
                    value={displayCurrency}
                    key={displayCurrency}
                    className={classes["toggle-btn"]}
                  >
                    {displayCurrency}
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Switch to USD" arrow placement="top">
                  <ToggleButton
                    value="USD"
                    key="USD"
                    className={classes["toggle-btn"]}
                  >
                    {"USD"}
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Box>
          )}
          <Tooltip title="Copy Income Statement" arrow placement="top">
            <ContentCopy onClick={copyTable} className={classes["copy-btn"]} />
          </Tooltip>
        </Box>
      </Box>
    </>
  )
}

export default Header

Header.propTypes = {
  displayCurrency: PropTypes.string.isRequired,
  displayDataType: PropTypes.string.isRequired,
  setDisplayDataType: PropTypes.func.isRequired,
  displayDataConversionType: PropTypes.string.isRequired,
  setDisplayDataConversionType: PropTypes.func.isRequired,
  showCurrencyConverter: PropTypes.bool.isRequired,
  setShowCurrencyConverter: PropTypes.func.isRequired,
  currencyOpt: PropTypes.string.isRequired,
  setCurrencyOpt: PropTypes.func.isRequired,
}
