import React, { useEffect, useState } from "react"
import { Box, Divider, Paper, Typography } from "@mui/material"
import "react-toastify/dist/ReactToastify.css"
import { useParams } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import { toast } from "react-toastify"
import { format } from "date-fns"

import useStyles from "./styles"
import { getPolicies } from "../../services/core"
import Loading from "../Loading"

const Guides = () => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const { type } = useParams()
  const [data, setData] = useState("")
  const titles = {
    "privacy-policy": "Privacy Policy",
    "terms-of-service": "Terms of Service",
    disclaimer: "Disclaimer",
  }

  useEffect(() => {
    document.title = `${titles[type]} - Guides - Lets Value Invest`
    const getData = async () => {
      try {
        setIsLoading(true)
        const resp = await getPolicies(type)
        setData(resp?.data)
        setIsLoading(false)
      } catch (err) {
        toast.warn("Unable to fetch policies. Please try again.")
        setIsLoading(false)
      }
    }
    getData()
  }, [type])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box padding={"3rem"} display="flex" width="100%" flexDirection={"row"}>
      <Paper elevation={4} className={classes["guide-box"]}>
        <Typography className={classes["header"]} padding="2rem">
          {titles[type]}
          <Divider
            className={classes["divider"]}
            sx={{ borderBottomWidth: 5 }}
          />
        </Typography>
        <Typography className={classes["date"]} padding="2rem">
          {`Last updated at ${format(
            new Date(data?.updatedAt || new Date()),
            "MMM d, yyyy"
          )}`}
        </Typography>
        <Box padding={"2rem"} paddingTop="1rem">
          <ReactMarkdown>{data?.description}</ReactMarkdown>
        </Box>
      </Paper>
    </Box>
  )
}

export default Guides
