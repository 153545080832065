import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  LocalOffer,
  SignalCellularAlt,
  ThumbDownOffAltRounded,
  ThumbUpOffAltOutlined,
  MonitorHeart,
  Check,
  CheckCircleOutline,
  WarningAmberOutlined,
  ErrorOutline,
} from "@mui/icons-material"
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
  Typography,
} from "@mui/material"

import { isBankInsuranceOrREIT } from "../../../../../utils/KeyInsightsScores"

import useStyles from "../../styles"
import { palette } from "../../../../../utils/Palette"
import ReactGA from "react-ga4"

const SimpleView = ({ keyInsights, overview }) => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/key-insights/simple" })
  const classes = useStyles()
  const [positiveCount, setPositiveCount] = useState(0)
  let allRows = [
    {
      isHeader: true,
      title: "Growth Rates",
      icon: <SignalCellularAlt />,
    },
    {
      isHeader: false,
      title: "Revenue has grown in the last 5 years?",
      keys: ["growth", "revenue", "isGrowing"],
    },
    {
      isHeader: false,
      title: "Net income has grown in the last 5 years?",
      keys: ["growth", "netIncome", "isGrowing"],
    },
    {
      isHeader: false,
      title: "Free cash flow has grown in the last 5 years?",
      keys: ["growth", "freeCashFlow", "isGrowing"],
    },
    {
      isHeader: true,
      title: "Financial Health",
      icon: <MonitorHeart />,
    },
    {
      isHeader: false,
      title: "Current ratio is adequate for the company? ( > 1.5 )",
      keys: ["financialHealth", "currentRatio", "isHealthy"],
    },
    {
      isHeader: false,
      title:
        "Long term debt can be paid off with average of last 5 years free cash flow in less than 5 years",
      keys: ["financialHealth", "longTermDebtToFiveYrAvgFCF", "isHealthy"],
    },
    {
      isHeader: true,
      title: "Valuation",
      icon: <LocalOffer />,
    },
    {
      isHeader: false,
      title:
        "Current P/E ratio is NOT extreme (>40) and is lower than the average P/E ratio of the recent past.",
      keys: ["valuation", "priceEarningsRatio", "isUndervalued"],
    },
    {
      isHeader: false,
      title:
        "Current P/FCF ratio is NOT extreme (>40) and is lower than the average P/FCF ratio of the recent past.",
      keys: ["valuation", "priceToFreeCashFlowsRatio", "isUndervalued"],
    },
    {
      isHeader: true,
      title: "Profitability & Management",
      icon: <Check />,
    },
    {
      isHeader: false,
      title:
        "The business has earned above average return on capital employed (>15%) in the recent past.",
      keys: [
        "returnsProfitabilityAndManagement",
        "returnOnCapitalEmployed",
        "isAboveAverage",
      ],
    },
    {
      isHeader: false,
      title:
        "The gross profit margin of the business has been above average (> 45%) in the recent past.",
      keys: [
        "returnsProfitabilityAndManagement",
        "grossProfitMargin",
        "isAboveAverage",
      ],
    },
    {
      isHeader: false,
      title:
        "Management has been buying back and hence reducing the shares outstanding?",
      keys: [
        "returnsProfitabilityAndManagement",
        "shareBuyBack",
        "isHappening",
      ],
    },
  ]

  let financeSpecificRows = [
    {
      isHeader: true,
      title: "Growth Rates",
      icon: <SignalCellularAlt />,
    },
    {
      isHeader: false,
      title: "Revenue has grown in the last 5 years?",
      keys: ["growth", "revenue", "isGrowing"],
    },
    {
      isHeader: false,
      title: "Net income has grown in the last 5 years?",
      keys: ["growth", "netIncome", "isGrowing"],
    },
    {
      isHeader: false,
      title: "Book value per share has grown in the last 5 years?",
      keys: ["growth", "bvps", "isGrowing"],
    },
    {
      isHeader: true,
      title: "Financial Health",
      icon: <MonitorHeart />,
    },
    {
      isHeader: false,
      title: "Current ratio is adequate for the company? ( > 2.5 )",
      keys: ["financialHealth", "currentRatio", "isHealthy"],
    },
    {
      isHeader: false,
      title: "Debt to equity ratio is reasonable.",
      keys: ["financialHealth", "debtEquityRatio", "isHealthy"],
    },
    {
      isHeader: true,
      title: "Valuation",
      icon: <LocalOffer />,
    },
    {
      isHeader: false,
      title:
        "Current P/E ratio is NOT extreme (>25) and is lower than the average P/E ratio of the recent past.",
      keys: ["valuation", "priceEarningsRatio", "isUndervalued"],
    },
    {
      isHeader: false,
      title:
        "Current P/B ratio is NOT extreme (>5) and is lower than the average P/B ratio of the recent past.",
      keys: ["valuation", "priceToBookRatio", "isUndervalued"],
    },
    {
      isHeader: true,
      title: "Profitability & Management",
      icon: <Check />,
    },
    {
      isHeader: false,
      title:
        "The business has earned above average return on equity (>10%) in the recent past.",
      keys: [
        "returnsProfitabilityAndManagement",
        "returnOnEquity",
        "isAboveAverage",
      ],
    },
    {
      isHeader: false,
      title:
        "The net profit margin of the business has been above average in the recent past.",
      keys: [
        "returnsProfitabilityAndManagement",
        "netProfitMargin",
        "isAboveAverage",
      ],
    },
    {
      isHeader: false,
      title:
        "Management has been buying back and hence reducing the shares outstanding?",
      keys: [
        "returnsProfitabilityAndManagement",
        "shareBuyBack",
        "isHappening",
      ],
    },
  ]

  const [rows] = useState(
    isBankInsuranceOrREIT(
      overview?.profile?.sector,
      overview?.profile?.industry
    )
      ? financeSpecificRows
      : allRows
  )

  useEffect(() => {
    if (!keyInsights) return
    let posCount = 0
    rows.forEach((row) => {
      let flag = keyInsights
      row?.keys?.forEach((key) => {
        flag = flag[key]
        if (flag === true) posCount += 1
      })
    })
    setPositiveCount(posCount)
  }, [JSON.stringify(keyInsights)])

  const getOverallScoreClass = (score) => {
    if (score <= 5) return palette["error-dark"]
    if (score < 8) return palette["warning-dark"]
    return palette["success-dark"]
  }

  const getMessage = (score) => {
    if (score <= 5) return <ErrorOutline className={classes["header-icon"]} />
    if (score < 8)
      return <WarningAmberOutlined className={classes["header-icon"]} />
    return <CheckCircleOutline className={classes["header-icon"]} />
  }

  if (!keyInsights) return <></>

  return (
    <Grid container justifyContent="center">
      <Grid item cs={12} sm={6} md={5}>
        <Typography
          className={classes.header}
          style={{
            color: getOverallScoreClass(positiveCount),
          }}
        >
          <span>
            {overview?.name} has passed {positiveCount} out of 10 automated
            checks
          </span>
          {getMessage(positiveCount)}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {rows.map((row) => {
                let flag = keyInsights
                row?.keys?.forEach((key) => (flag = flag[key]))
                if (row.isHeader)
                  return (
                    <TableRow key={row.title}>
                      <TableCell align="left" colSpan={2}>
                        <span className={classes["header-row"]}>
                          {row?.icon}
                          <span className={classes.title}>{row?.title}</span>
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                return (
                  <TableRow key={row.title}>
                    <TableCell align="left">
                      <span className={classes["data-row"]}>{row.title}</span>
                    </TableCell>
                    <TableCell align="right">
                      {flag ? (
                        <ThumbUpOffAltOutlined
                          style={{ color: palette["success-dark"] }}
                        />
                      ) : (
                        <ThumbDownOffAltRounded
                          style={{ color: palette["error-dark"] }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default SimpleView

SimpleView.propTypes = {
  keyInsights: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
}
