import React, { useState } from "react"
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TablePagination,
} from "@mui/material"
import { format } from "date-fns"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"

const MutualFundHolders = ({ data }) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box>
      <span className={classes["main-header"]}>Top Mutual Fund Holders:</span>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.header}>Holder</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  # of shares held
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  % of Shares Outstanding Held
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  Total value of shares held (MM)
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>
                  Date Reported
                </Typography>
              </TableCell>
              {/*  <TableCell align="center">
                <Typography className={classes.header}>
                  Change in # of Shares Held
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  % Change in # of Shares Held
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  % of Firm's Portfolio
                </Typography>
              </TableCell> */}
            </TableRow>
            {data?.length &&
              data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((shareholder, index) => (
                  <TableRow key={`${index}-${new Date()}`}>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {shareholder.holderName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        {shareholder.noOfShares}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={shareholder.percentageOwned}
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={
                            shareholder.value?.split(",").join("") / 1000000
                          }
                          prefix={"$"}
                          suffix={false}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={
                            (shareholder.change /
                              (shareholder.shares - shareholder.change)) *
                            100
                          }
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={shareholder.weightPercent}
                          prefix={""}
                          suffix={"%"}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </TableCell> */}
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {shareholder.dateReported}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default MutualFundHolders

MutualFundHolders.propTypes = {
  data: PropTypes.array.isRequired,
}
