import { Box, useMediaQuery } from "@mui/material"
import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Loading from "../../Loading"
import AppDataContext from "../../../context/data"
import { getValuationRef } from "../../../services/ticker"
import ReferenceTable from "./components/ReferenceTable"
import Calculator from "./components/Calculator"
import { getForexPrice } from "../../../services/core"

const Valuation = () => {
  const { tickerId } = useParams()
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(false)
  const [displayCurrency, setDisplayCurrency] = useState("USD")
  const [currencyOpt, setCurrencyOpt] = useState("USD")
  const [forexPrice, setForexPrice] = useState({})
  const [displayData, setDisplayData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { valuationRef, setValuationRef, overview } = useContext(AppDataContext)
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"))

  const getCurrencyConversionData = (data, price) => {
    if (!data) return {}
    return {
      ...data,
      debtScenario: {
        netDebt: (data?.debtScenario?.netDebt * price).toFixed(2),
        totalCash: (data?.debtScenario?.totalCash * price).toFixed(2),
        totalDebt: (data?.debtScenario?.totalDebt * price).toFixed(2),
      },
      freeCashFlow: {
        ...data.freeCashFlow,
        ttm: (data?.freeCashFlow?.ttm * price).toFixed(2),
      },
      netIncome: {
        ...data.netIncome,
        ttm: (data?.netIncome?.ttm * price).toFixed(2),
      },
      revenue: {
        ...data.revenue,
        ttm: (data?.revenue?.ttm * price).toFixed(2),
      },
    }
  }

  useEffect(() => {
    setIsLoading(true)
    setDisplayData(null)
    const getValuationRefData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getValuationRef(tickerId)
        let exchangeRateResp
        if (resp?.data?.reportedCurrency !== "USD") {
          setDisplayCurrency(resp?.data?.reportedCurrency)
          exchangeRateResp = await getForexPrice(
            `${resp?.data?.reportedCurrency}USD`
          )
          setForexPrice(exchangeRateResp?.data[0] || {})
          setShowCurrencyConverter(true)
          setCurrencyOpt(resp?.data?.reportedCurrency)
          setDisplayData({
            ...resp.data,
            tickerId,
            currentPrice:
              resp.data?.currentPrice / exchangeRateResp?.data[0]?.price,
          })
        } else {
          setDisplayData({ ...resp.data, tickerId })
        }
        setValuationRef({ ...resp.data, tickerId })

        setIsLoading(false)
      } catch (err) {
        setValuationRef(null)
        setIsLoading(false)
        toast.error("Unable to get key insights. Please try after sometime.")
      }
    }

    getValuationRefData()
  }, [JSON.stringify(overview), tickerId])

  useEffect(() => {
    if (currencyOpt === "USD" && showCurrencyConverter) {
      setDisplayData(getCurrencyConversionData(valuationRef, forexPrice?.price))
    } else if (showCurrencyConverter) {
      setDisplayData({
        ...valuationRef,
        currentPrice: valuationRef?.currentPrice / forexPrice?.price,
      })
    } else {
      setDisplayData(valuationRef)
    }
  }, [currencyOpt, valuationRef, showCurrencyConverter])

  if (isLoading || !displayData) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box display={"flex"} flexDirection={largeScreen ? "row" : "column"}>
      <Box width={largeScreen ? "60%" : "100%"}>
        <Calculator
          displayCurrency={displayCurrency}
          showCurrencyConverter={showCurrencyConverter}
          setShowCurrencyConverter={setShowCurrencyConverter}
          valuationRefData={displayData}
          currencyOpt={currencyOpt}
          setCurrencyOpt={setCurrencyOpt}
          tickerId={tickerId}
        />
      </Box>
      <Box
        paddingLeft={largeScreen ? "2rem" : "0px"}
        width={largeScreen ? "40%" : "100%"}
      >
        {displayData && <ReferenceTable valuationRefData={displayData} />}
      </Box>
    </Box>
  )
}

export default Valuation
