import React from "react"
import {
  Box,
  Typography,
  Input,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import PropTypes from "prop-types"

import useStyles from "../styles"
import {
  InputThousandSeparator,
  TextThousandSeparator,
} from "../../../utils/NumberFormatters"

const Calculator = ({
  calculatorParams,
  setCalculatorParams,
  frequency,
  errMsg,
}) => {
  const classes = useStyles()

  const displayValue = (value, ext = "%", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  return (
    <Box>
      <Grid container direction="row">
        <Grid item xs={12} className={classes["calculator-header-text"]}>
          <Typography>
            <b>Compound Interest Calculator</b>
          </Typography>
        </Grid>
        {/* Initial investment */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>Initial Investment</Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={calculatorParams.initialVal}
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              prefix: "$",
              allowNegative: false,
              onChange: (event) => {
                setCalculatorParams({
                  ...calculatorParams,
                  initialVal: Number(event.target.value),
                })
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Contributions */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>Contributions</Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={calculatorParams.contribution}
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              prefix: "$",
              allowNegative: false,
              onChange: (event) => {
                setCalculatorParams({
                  ...calculatorParams,
                  contribution: Number(event.target.value),
                })
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Contributions frequency */}
        <Grid item xs={7} md={4}>
          <Typography className={classes.labels}></Typography>
        </Grid>
        <Grid item xs={5} md={5} className={classes["contribution-type"]}>
          <RadioGroup
            row
            value={calculatorParams.contributionType}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                contributionType: event.target.value,
              })
            }
          >
            <FormControlLabel value="M" control={<Radio />} label="Monthly" />
            <FormControlLabel value="Y" control={<Radio />} label="Annually" />
          </RadioGroup>
        </Grid>
        <Grid item xs={0} md={3} />
        {/* Number of years to investment */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            Number of years you want to invest for
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            type="number"
            value={calculatorParams.noOfYears}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                noOfYears: Number(event.target.value),
              })
            }
            inputProps={{
              style: { textAlign: "center" },
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Number of years to stay invested */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            Number of years you want to stay invested for
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            type="number"
            value={calculatorParams.noOfYearsInvested}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                noOfYearsInvested: Number(event.target.value),
              })
            }
            inputProps={{
              style: { textAlign: "center" },
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Estimated Rate of Return */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            Estimated Rate of Return
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={calculatorParams.rateOfReturn}
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              suffix: "%",
              allowNegative: false,
              onChange: (event) => {
                setCalculatorParams({
                  ...calculatorParams,
                  rateOfReturn: Number(event.target.value),
                })
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        {/* Compounding Frequency */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>
            Compounding Frequency
          </Typography>
        </Grid>
        <Grid item xs={5} md={5}>
          <RadioGroup
            row
            value={calculatorParams.compoundingFreq}
            onChange={(event) =>
              setCalculatorParams({
                ...calculatorParams,
                compoundingFreq: event.target.value,
              })
            }
          >
            <FormControlLabel value="D" control={<Radio />} label="Daily" />
            <FormControlLabel value="M" control={<Radio />} label="Monthly" />
            <FormControlLabel value="Y" control={<Radio />} label="Annually" />
          </RadioGroup>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        {/* Rate Variance */}
        <Grid item xs={7} md={5}>
          <Typography className={classes.labels}>Rate Variance</Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            value={calculatorParams.rateVar}
            inputComponent={InputThousandSeparator}
            inputProps={{
              style: { textAlign: "center" },
              suffix: "%",
              allowNegative: false,
              onChange: (event) => {
                setCalculatorParams({
                  ...calculatorParams,
                  rateVar: Number(event.target.value),
                })
              },
              thousandSeparator: true,
            }}
          />
        </Grid>
        <Grid item xs={0} md={4} />
        <Grid item xs={12}>
          <Typography className="error-txt">{errMsg}</Typography>
        </Grid>
        {/* Compounded value */}
        <Grid item xs={12}>
          <Typography className={classes["compound-calc-text"]}>
            Starting with{" "}
            <b>{displayValue(calculatorParams.initialVal, "$", false)}</b> and
            contributing{" "}
            <b>{displayValue(calculatorParams.contribution, "$", false)}</b>{" "}
            <b>{frequency[calculatorParams.contributionType]}</b>, you would
            make a total contribution of{" "}
            <b>{displayValue(calculatorParams.total, "$", false)}</b> over{" "}
            <b>{calculatorParams.noOfYears}</b> years. Your investment would
            grow to the following amounts at various annual interest rates if
            you compounded <b>{frequency[calculatorParams.compoundingFreq]}</b>{" "}
            and remained invested for{" "}
            <b>{calculatorParams.noOfYearsInvested}</b> years:
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calculator

Calculator.propTypes = {
  calculatorParams: PropTypes.object.isRequired,
  setCalculatorParams: PropTypes.func.isRequired,
}
