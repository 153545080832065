import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "header-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  "row-container": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
  },
  symbol: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
    whiteSpace: "nowrap",
  },
  exchange: {
    fontSize: "14px !important",
    color: theme.palette["grey-2"],
    paddingLeft: 8,
    whiteSpace: "nowrap",
  },
  price: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    whiteSpace: "nowrap",
  },
  priceChange: {
    fontSize: "14px !important",
    paddingLeft: 8,
    whiteSpace: "nowrap",
  },
  priceChangePer: {
    fontSize: "14px !important",
    paddingLeft: 8,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    whiteSpace: "nowrap",
  },
}))
