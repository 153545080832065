import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../../utils/Palette"
import { yaxisLabel, thousandSeparator } from "../../../../utils/charts"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
defaults.font.family = palette.fontFamily

const Barchart = ({ values }) => {
  const [chartData, setChartData] = useState(null)

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return yaxisLabel(value)
          },
        },
      },
    },
    interaction: {
      mode: "x",
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (item) {
            return `${item.dataset.label}: $${item.formattedValue}`
          },
        },
      },
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Future Balance $${thousandSeparator(
          values.slice(-1)[0].futureVal
        )}`,
      },
    },
    layout: {
      padding: 10,
    },
  }

  useEffect(
    () => {
      setChartData({
        labels: values.map((val) => val.year),
        datasets: [
          {
            label: "Total",
            data: values.map((val) => parseInt(val.total)),
            backgroundColor: palette.purple,
          },
          {
            label: "Interest",
            data: values.map(
              (val) => parseInt(val.futureVal) - parseInt(val.total)
            ),
            backgroundColor: palette["success-dark"],
          },
        ],
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(values), values]
  )

  return (
    <Box width="100%" height="100%">
      {chartData && <Bar options={options} data={chartData} />}
    </Box>
  )
}

export default Barchart

Barchart.propTypes = {
  values: PropTypes.array.isRequired,
}
