import { Backdrop, Box, CircularProgress, Slider } from "@mui/material"
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"
import { Line } from "react-chartjs-2"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import randomcolor from "randomcolor"
import { format } from "date-fns"
import { enUS } from "date-fns/locale"
import "chartjs-adapter-date-fns"
import { min } from "lodash"
import DataAnanlysis from "./../DataAnanlysis"
import useStyles from "./../../styles"
import { palette } from "../../../../utils/Palette"
import { thousandSeparator } from "../../../../utils/charts"
import { defaults } from "chart.js"

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)
defaults.font.family = palette.fontFamily

function valuetext(value) {
  return `${value}°C`
}
function Chart({ rawData, selectedCriterias, selectedTickers, isLoading }) {
  const classes = useStyles()
  const [chartData, setChartData] = useState(null)
  const [minValue, setMinValue] = useState(0)
  const [maxValue] = useState(new Date().getFullYear())
  const [rangeValue, setRangeValue] = useState([0, new Date().getFullYear()])
  const [rangeChanged, setRangeChanged] = useState(false)
  const minDistance = 1
  const handleRangeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }
    setRangeChanged(true)
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], rangeValue[1] - minDistance)
        setRangeValue([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setRangeValue([clamped - minDistance, clamped])
      }
    } else {
      setRangeValue(newValue)
    }
  }

  const getData = (ticker, criteria) => {
    const tickerData = rawData.filter(
      (data) => data.tickerId === ticker.value
    )[0]
    return tickerData[criteria.headerKey][criteria.value].data
      .map((data) => {
        return {
          // x: data.date,
          x: format(new Date(data.date), "yyyy-MM-dd"),
          y: data[criteria.value],
        }
      })
      .reverse()
  }

  useEffect(() => {
    const formattedData = []
    const startingYears = []
    setChartData(null)
    if (
      selectedCriterias.length &&
      selectedTickers.length &&
      rawData.length &&
      selectedTickers.length === rawData.length
    ) {
      selectedTickers.forEach((ticker) => {
        selectedCriterias.forEach((criteria) => {
          const borderColor = randomcolor({
            luminosity: "dark",
          })
          // logic to generate same color every time based on ticker and criteria
          //let baseString = (ticker += criteria)
          /* let tickerValue = 0
          for (var i = 0; i < ticker.value.length; i++) {
            tickerValue += ticker.value.charCodeAt(i) - 64
          }
          let criteriaValue = 0
          for (var j = 0; j < criteria.value.length; j++) {
            criteriaValue += criteria.value.charCodeAt(i) - 64
          }
          let colorHex = tickerValue + "" + criteriaValue
          const diff = 6 - colorHex.length
          for (let k = 0; k < diff; k++) {
            colorHex += colorHex[k]
          }
          const borderColor = "#" + colorHex
          console.log("for" + ticker.value + ": " + borderColor) */
          // get minValue to be set for the first time
          const data = getData(ticker, criteria)
          startingYears.push(new Date(data[0].x).getFullYear())
          let filteredData
          if (minValue === 0) {
            setMinValue(new Date(data[0].x).getFullYear())
            setRangeValue([
              new Date(data[0].x).getFullYear(),
              new Date().getFullYear(),
            ])
          } else {
            if (!rangeChanged && min(startingYears) < rangeValue[0]) {
              setMinValue(min(startingYears))
              setRangeValue([min(startingYears), maxValue])
            }
            setRangeChanged(false)
            filteredData = data.filter(
              (d) =>
                d.x.slice(0, d.x.indexOf("-")) <= rangeValue[1] &&
                d.x.slice(0, d.x.indexOf("-")) >= rangeValue[0]
            )
          }
          formattedData.push({
            label: `${ticker.value} - ${criteria.label}`,
            data: minValue === 0 ? data : filteredData,
            borderColor: borderColor,
            backgroundColor: borderColor,
            yAxisID: criteria.suffix ? "ratio" : "y",
            prefix: criteria.prefix,
            suffix: criteria.suffix,
            tickerId: ticker.value,
            criteriaValue: criteria.value,
          })
        })
      })
      // account for difference in data points by adding null values
      formattedData.forEach((data, index) => {
        if (index < formattedData.length - 1) {
          if (
            formattedData[index].data.length >
            formattedData[index + 1].data.length
          ) {
            let difference =
              formattedData[index].data.length -
              formattedData[index + 1].data.length
            for (let i = 0; i < difference; i += 1) {
              formattedData[index + 1].data.unshift({
                x: null,
                y: formattedData[index].data[i].y,
              })
            }
          } else {
            let difference =
              formattedData[index + 1].data.length -
              formattedData[index].data.length
            for (let i = 0; i < difference; i += 1) {
              formattedData[index].data.unshift({
                x: formattedData[index + 1].data[i].x,
                y: null,
              })
            }
          }
        }
      })
      setChartData({
        datasets: formattedData,
      })
    }
  }, [
    JSON.stringify(rawData),
    JSON.stringify(selectedCriterias),
    JSON.stringify(selectedTickers),
    rangeValue,
  ])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        pointStyle: "line",
      },
    },
    scales: {
      x: {
        type: "time",
        adapters: {
          date: {
            locale: enUS,
          },
        },
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
          callback: function (value, index, ticks) {
            return format(new Date(ticks[index]?.value), "MM/yyyy")
          },
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
      y: {
        type: "linear",
        position: "left",
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          maxTicksLimit: 10,
          // Include a dollar sign in the ticks
          callback: function (value) {
            if (value / 1000000 > 1)
              return thousandSeparator(value / 1000000) + " Mil"
            return value.toFixed(2)
          },
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
      ratio: {
        type: "linear",
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          maxTicksLimit: 10,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value.toFixed(2)
          },
          font: {
            family: `'${palette.fontFamily}', 'Arial', 'sans-serif'`,
          },
        },
      },
    },
    interaction: {
      mode: "x",
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (item) {
            return format(new Date(item[0].raw.x), "MMM d, yyyy")
          },
          label: function (item) {
            const newLineArray = []
            let value = item.raw.y
            if (item.dataset.prefix) {
              value =
                value / 1000000 > 1
                  ? thousandSeparator(value / 1000000) + " Mil"
                  : value
              newLineArray.push(
                item.dataset.label + ": " + item.dataset.prefix + "" + value
              )
            } else if (item.dataset.suffix) {
              newLineArray.push(
                item.dataset.label + ": " + value + "" + item.dataset.suffix
              )
            } else {
              value =
                value / 1000000 > 1
                  ? thousandSeparator(value / 1000000) + " Mil"
                  : value
              newLineArray.push(item.dataset.label + ": " + value)
            }
            return newLineArray
          },
          labelTextColor: function (item) {
            return item.dataset.borderColor
          },
        },
        mode: "index",
        intersect: false,
        padding: 10,
        backgroundColor: "white",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        titleColor: "black",
        titleFont: {
          family: "'Montserrat', 'Arial', sans-serif",
        },
        bodyFont: {
          family: "'Montserrat', 'Arial', sans-serif",
        },
        yAlign: "bottom",
      },
      title: {
        display: false,
      },
      legend: {
        display: true,
        labels: {
          font: {
            size: 14,
            family: "'Montserrat', 'Arial', sans-serif",
          },
        },
      },
    },
    layout: {
      padding: 10,
    },
  }

  return (
    <>
      {chartData && (
        <Box>
          <Box height="400px" paddingTop="3rem">
            <Line options={options} data={chartData} />
          </Box>
          <Box width="100%">
            <Slider
              size="medium"
              getAriaLabel={() => "Minimum distance shift"}
              value={rangeValue}
              onChange={handleRangeChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              min={minValue}
              max={maxValue}
              disableSwap
              marks
              color="success"
            />
          </Box>
          <Box item xs={12} sm={8} md={6} className={classes["data-analysis"]}>
            <DataAnanlysis
              rawData={chartData.datasets}
              selectedCriterias={selectedCriterias}
              selectedTickers={selectedTickers}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      )}
      {!chartData ? (
        <Box
          sx={{
            p: { xs: 2, md: 10 },
            m: { xs: 2, md: 5 },
            border: "1px dashed grey",
          }}
        >
          To get started, add one or more ticker symbols to the graph. Once you
          have added the ticker symbols, select one or more criteria to see the
          historical performance.
        </Box>
      ) : (
        ""
      )}
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  )
}

export default Chart

Chart.propTypes = {
  rawData: PropTypes.array.isRequired,
  selectedCriterias: PropTypes.array.isRequired,
  selectedTickers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
