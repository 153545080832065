import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "card-1": {
    width: "100%",
    backgroundColor: theme.palette["green-1"],
    borderRadius: "1.5rem",
    padding: "3rem 3rem",
  },
  "card-2": {
    width: "100%",
    padding: "3rem 0",
  },
  header: {
    fontSize: "2.5rem !important",
    fontWeight: "600 !important",
  },
  description: {
    paddingTop: "1rem",
    color: theme.palette["text-2"],
    fontSize: "20px !important",
  },
  "bullet-icon": {
    fontSize: "2.5rem !important",
    marginRight: "1rem",
  },
  bullet: {
    fontSize: "1.5rem !important",
    color: theme.palette["text-2"],
  },
  "text-row": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "author-name": {
    color: theme.palette["grey-1"],
    marginLeft: "3rem",
  },
  "custom-link": {
    margin: 0,
    color: "#3F51B5",
    textDecoration: "underline",
    WebkitTextDecoration: "underline",
    cursor: "pointer",
    textDecorationColor: "rgba(63, 81, 181, 0.4)",
  },
  image: {
    paddingRight: "0px",
  },
}))
