import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "financials-opts": {
    paddingBottom: "24px",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  "opt-label": {
    fontSize: "14px !important",
    minWidth: "9rem",
    marginRight: "1rem !important",
    marginTop: "4px !important",
    backgroundColor: `${theme.palette.white} !important`,
    border: `1px solid ${theme.palette["grey-3"]} !important`,
  },
  "active-opt": {
    backgroundColor: `${theme.palette["primary-bg"]} !important`,
    color: `${theme.palette.purple} !important`,
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
  },
  "content-txt": {
    color: theme.palette["grey-2"],
    textAlign: "justify",
  },
  type: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px !important",
  },
  "content-txt-2": {
    color: theme.palette["grey-2"],
  },
  sale: {
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: theme.palette["error-dark"],
    color: theme.palette.white,
    minWidth: "75px",
    textAlign: "center",
  },
  purchase: {
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: theme.palette["success-dark"],
    color: theme.palette.white,
    minWidth: "75px",
    textAlign: "center",
  },
  others: {
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: theme.palette["grey-3"],
    color: theme.palette.white,
    minWidth: "75px",
    textAlign: "center",
  },
  "main-header": {
    fontWeight: "bold !important",
    textAlign: "left",
    flexBasis: "25%",
    flexGrow: 0,
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  "table-container": {
    border: `1px solid ${theme.palette["grey-3"]}`,
    boxShadow: "none",
  },
}))
