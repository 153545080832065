import React from "react"
import PropTypes from "prop-types"
import { Grid, Typography, Box, Tooltip, IconButton } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import GrowthRates from "./components/GrowthRates"
import ManagementEffectiveness from "./components/ManagementEffectiveness"
import FinancialHealth from "./components/FinancialHealth"
import MarginsProfitability from "./components/MarginsProfitability"
import ValuationMultiples from "./components/ValuationMultiples"
import Dividend from "./components/Dividend"
import Moat from "./components/Moat"
import BusinessQuality from "./components/BusinessQuality"
import useStyles from "../../styles"
import ReactGA from "react-ga4"
import {
  getRatingTemplate,
  getScoreTemplate,
} from "../../../../../utils/KeyInsightsScores"

const DetailedView = ({ keyInsights, overview }) => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/key-insights/detailed" })
  const classes = useStyles()
  if (!keyInsights) return <></>

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={{ xs: 1, sm: 2, md: 5 }}
      style={{ padding: "0 1rem" }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "18px",
              mr: 1,
            }}
          >
            Overall Score:
          </Box>
          {/* <Rating
            name="text-feedback"
            value={keyInsights?.overallScore}
            readOnly
            precision={0.01}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          /> */}
          <Box sx={{ ml: 1 }}>
            {getRatingTemplate(keyInsights?.overallScore, classes)}
          </Box>
          <Box sx={{ ml: 1 }}>
            {getScoreTemplate(keyInsights?.overallScore, classes)}
          </Box>
          <Typography variant="caption" display="block" sx={{ pb: 0 }}>
            <i>( Based on {keyInsights?.noOfInsights} unique key insights )</i>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: -3, mb: -2 }}>
        <Typography className={classes["info-text"]} xs={12} md={"flex"}>
          All yearly records are as of last financial year and all TTM records
          are as of last quarterly results declared by the company.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GrowthRates keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ManagementEffectiveness
          keyInsights={keyInsights}
          overview={overview}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FinancialHealth keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MarginsProfitability keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValuationMultiples keyInsights={keyInsights} overview={overview} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {keyInsights?.moat?.score && (
          <Moat keyInsights={keyInsights} overview={overview} />
        )}
      </Grid>
      {keyInsights?.businessQuality && (
        <Grid item xs={12} sm={6}>
          <BusinessQuality keyInsights={keyInsights} overview={overview} />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {keyInsights?.dividend?.score && (
          <Dividend keyInsights={keyInsights} overview={overview} />
        )}
      </Grid>
    </Grid>
  )
}

export default DetailedView

DetailedView.propTypes = {
  keyInsights: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
}
