import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "app-header": {
    fontWeight: "600 !important",
    fontSize: "32px !important",
    padding: "0 32px",
    cursor: "pointer",
  },
  "main-tabs": {
    padding: "6px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: `${theme.palette.white} !important`,
    width: "100%",
    color: `${theme.palette.black} !important`,
    "MuiButton-text": {
      color: `${theme.palette.black} !important`,
    },
  },
  "home-tab": {
    color: `${theme.palette.black} !important`,
    fontWeight: "400 !important",
    fontSize: "18px !important",
    cursor: "pointer",
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
  },
  "calculators-tab": {
    cursor: "pointer",
    color: `${theme.palette.black} !important`,
    backgroundColor: theme.palette.lightGrey,
    fontWeight: "400 !important",
    fontSize: "18px !important",
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
  },
  "indexes-tab": {
    cursor: "pointer",
    color: `${theme.palette.black} !important`,
    backgroundColor: theme.palette.lightGrey,
    fontWeight: "400 !important",
    fontSize: "18px !important",
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: `${theme.palette.lightGrey} !important`,
    },
  },
  "try-for-free": {
    marginLeft: "32px !important",
  },
  "super-text": {
    color: `green !important`,
    paddingLeft: "5px",
    fontWeight: "bold",
    fontStyle: "italic",
    fontSize: "10px",
    paddingBottom: "5px",
  },
}))
