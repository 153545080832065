import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"

import AllTransactions from "./AllTransactions"
import TransactionsSummary from "./TransactionsSummary"
import ReactGA from "react-ga4"

const InsiderTransactions = ({ data }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/ownerships/insider-transactions",
  })
  const { tickerId } = useParams()

  useEffect(() => {
    document.title = `${tickerId} - Ownerships - Insider Transactions - Lets Value Invest`
  })
  return (
    <Box>
      <TransactionsSummary data={data.summary} />
      <AllTransactions data={data.insiderTransactions} />
    </Box>
  )
}

export default InsiderTransactions

InsiderTransactions.propTypes = {
  data: PropTypes.object.isRequired,
}
