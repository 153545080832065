import React, { useContext, useEffect, useState } from "react"
import {
  Box,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Grid,
  TablePagination,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { IosShare, Clear } from "@mui/icons-material"
import { format } from "date-fns"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import { getSECFilings } from "../../../services/ticker"
import Loading from "../../Loading"
import ReactGA from "react-ga4"

const SecFilings = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/sec-filings",
  })
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const [secFilingsMasterData, setSecFilingsMasterData] = useState(null)
  const [secFilingsData, setSecFilingsData] = useState(null)
  const [filingType, setFilingType] = useState("")
  const [filingTypesList, setFilingTypesList] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const { secFilings, setSecFilings, overview } = useContext(AppDataContext)
  const classes = useStyles()

  const getSecFilingsData = async () => {
    toast.dismiss()
    try {
      // get data only if context is empty and save into context
      const resp = await getSECFilings(tickerId)
      setSecFilingsData({ data: resp.data, tickerId })
      setSecFilingsMasterData({ data: resp.data, tickerId })
      setSecFilings({ data: resp.data, tickerId })
      getFilingTypes(resp.data)
      setIsLoading(false)
    } catch (err) {
      setSecFilingsData(null)
      setSecFilingsMasterData(null)
      setSecFilings(null)
      setIsLoading(false)
      toast.error("Unable to get sec filings. Please try after sometime.")
    }
  }

  const getFilingTypes = (data) => {
    const types = data.map((ele) => ele.type)
    setFilingTypesList(Array.from(new Set([...types])))
  }

  const filterFilings = (type) => {
    setFilingType(type)
    handleChangePage(null, 1)
    handleChangeRowsPerPage({ target: { value: 25 } })
    if (type)
      setSecFilingsData({
        data: secFilingsMasterData?.data?.filter(
          (filing) => filing.type === type
        ),
        tickerId,
      })
    else
      setSecFilingsData({
        data: secFilingsMasterData?.data,
        tickerId,
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    document.title = `${tickerId} - SEC Filings - Lets Value Invest`
    setIsLoading(true)
    setSecFilingsData(null)
    // check if we have data already in context
    if (secFilings?.tickerId === tickerId) {
      setSecFilingsData(secFilings)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getSecFilingsData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <Select
            value={filingType}
            displayEmpty
            onChange={(event) => filterFilings(event.target.value)}
            label="Filter by form type"
            sx={{
              "& .MuiSelect-icon": {
                display: filingType ? "none" : "",
              },
            }}
            endAdornment={
              <IconButton
                sx={{ display: filingType ? "" : "none" }}
                onClick={() => filterFilings("")}
              >
                <Clear />
              </IconButton>
            }
          >
            <MenuItem value="">
              <em>{"Filter by form type"}</em>
            </MenuItem>
            {filingTypesList?.map((type) => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={0} sm={1}></Grid>
      <Grid item xs={12} sm={8} md={6}>
        <TableContainer>
          <Table>
            <TableBody>
              {/* Industry */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.header}>Type</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Filing Date
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Accepted Date
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>Details</Typography>
                </TableCell>
              </TableRow>
              {secFilingsData?.data?.length &&
                secFilingsData?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((filing, index) => (
                    <TableRow key={`${index}-${new Date()}`}>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {filing.type}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {format(new Date(filing.fillingDate), "MM/dd/yyyy")}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {format(new Date(filing.acceptedDate), "MM/dd/yyyy")}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Link target="_blank" href={filing?.link}>
                          <IosShare />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={secFilingsData?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  )
}

export default SecFilings
