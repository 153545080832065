import { Box, Chip } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import useStyles from "./styles"
import IncomeStatement from "./IncomeStatement"
import CashFlowStatement from "./CashFlowStatement"
import BalanceSheet from "./BalanceSheet"
import Ratios from "./Ratios"
import Summary from "./Summary"
import RevenueSegments from "./RevenueSegments"
import Loading from "../../Loading"
import {
  getIncomeStatement,
  getBalanceSheet,
  getRatios,
  getCashFlowStatement,
  getKeyFinancials,
  getProsAndCons,
  getRevenueSegments,
} from "../../../services/ticker"
import AppDataContext from "../../../context/data"
import ProsAndCons from "./ProsAndCons"

const tabs = [
  "income-statement",
  "balance-sheet",
  "cash-flow-statement",
  "ratios",
  "key-financials",
  "pros-cons",
  "revenue-segments",
]

const serviceCallMapper = {
  1: getIncomeStatement,
  2: getBalanceSheet,
  3: getCashFlowStatement,
  4: getRatios,
  5: getKeyFinancials,
  6: getProsAndCons,
  7: getRevenueSegments,
}

const Financials = () => {
  const [financialData, setFinancialData] = useState({})
  const [financialsOpt, setFinancialsOpt] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const classes = useStyles()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    incomeStatment,
    setIncomeStatment,
    balanceSheet,
    setBalanceSheet,
    cashFlowStatment,
    setCashFlowStatment,
    ratios,
    setRatios,
    keyFinancials,
    setKeyFinancials,
    prosAndCons,
    setProsAndCons,
    revenueSegments,
    setRevenueSegments,
  } = useContext(AppDataContext)

  const setContext = (contextId, data) => {
    switch (contextId) {
      case 1:
        setIncomeStatment({ ...data, tickerId })
        break
      case 2:
        setBalanceSheet({ ...data, tickerId })
        break
      case 3:
        setCashFlowStatment({ ...data, tickerId })
        break
      case 4:
        setRatios({ ...data, tickerId })
        break
      case 5:
        setKeyFinancials({ ...data, tickerId })
        break
      case 6:
        setProsAndCons({ ...data, tickerId })
        break
      case 7:
        setRevenueSegments({ ...data, tickerId })
        break
      default:
        setIncomeStatment({ ...data, tickerId })
    }
  }

  const getDataFromContext = (contextId) => {
    switch (contextId) {
      case 1:
        return incomeStatment
      case 2:
        return balanceSheet
      case 3:
        return cashFlowStatment
      case 4:
        return ratios
      case 5:
        return keyFinancials
      case 6:
        return prosAndCons
      case 7:
        return revenueSegments
      default:
        return incomeStatment
    }
  }

  useEffect(() => {
    setFinancialsOpt(1)
    const tabIndex = tabs.indexOf(pathname.split("/").at(-1))
    if (tabIndex > -1) setFinancialsOpt(tabIndex + 1)
    if (serviceCallMapper[tabIndex + 1]) {
      setIsLoading(true)
      const getFinancialsData = async () => {
        toast.dismiss()
        try {
          // get data only if context is empty and save into context
          const resp = await serviceCallMapper[tabIndex + 1](tickerId)
          setFinancialData(resp.data)
          setContext(tabIndex + 1, resp.data)
          setIsLoading(false)
        } catch (err) {
          setFinancialData({})
          setContext(tabIndex + 1, {})
          setIsLoading(false)
          toast.error("Unable to get data. Please try after sometime.")
        }
      }
      // check if we have data already in context
      const dataFromContext = getDataFromContext(tabIndex + 1)
      if (dataFromContext?.tickerId === tickerId) {
        setFinancialData(dataFromContext)
        setIsLoading(false)
        return
      }
      getFinancialsData()
    }
  }, [pathname, tickerId])

  const updateFinancialData = (opt) => {
    navigate(`/dashboard/ticker/${tickerId}/financials/${opt}`)
  }

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box>
      <Box className={classes["financials-opts"]}>
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 1 && classes["active-opt"]
          }`}
          label="Income Statement"
          onClick={() => updateFinancialData("income-statement")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 2 && classes["active-opt"]
          }`}
          label="Balance Sheet"
          onClick={() => updateFinancialData("balance-sheet")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 3 && classes["active-opt"]
          }`}
          label="Cash Flow Statement"
          onClick={() => updateFinancialData("cash-flow-statement")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 4 && classes["active-opt"]
          }`}
          label="Ratios"
          onClick={() => updateFinancialData("ratios")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 5 && classes["active-opt"]
          }`}
          label="Summary View"
          onClick={() => updateFinancialData("key-financials")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 6 && classes["active-opt"]
          }`}
          label={
            <span>
              Pros & Cons<sup className={`${classes["super-text"]}`}>NEW</sup>
            </span>
          }
          onClick={() => updateFinancialData("pros-cons")}
        />
        <Chip
          className={`${classes["opt-label"]} ${
            financialsOpt === 7 && classes["active-opt"]
          }`}
          label="Revenue Segments"
          onClick={() => updateFinancialData("revenue-segments")}
        />
      </Box>
      {financialData ? (
        <>
          {financialsOpt === 1 && (
            <IncomeStatement incomeStatement={financialData} />
          )}
          {financialsOpt === 2 && (
            <BalanceSheet balanceSheetStatement={financialData} />
          )}
          {financialsOpt === 3 && (
            <CashFlowStatement cashFlowStatement={financialData} />
          )}
          {financialsOpt === 4 && <Ratios ratios={financialData} />}
          {financialsOpt === 5 && <Summary keyFinancials={financialData} />}
          {financialsOpt === 6 && <ProsAndCons prosAndCons={financialData} />}
          {financialsOpt === 7 && (
            <RevenueSegments revenueSegments={financialData} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </Box>
  )
}

export default Financials
