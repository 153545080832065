import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material"
import { sum } from "lodash"

import useStyles from "./styles"
import Rating from "./components/Rating"
import ReactGA from "react-ga4"

const RevenueSegments = ({ revenueSegments }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/financials/revenue-segments",
  })
  const { tickerId } = useParams()
  const [displayDataType, setDisplayDataType] = useState("annual")
  const [displayData, setDisplayData] = useState(null)
  const classes = useStyles()

  const getData = (data, dataType, type) => {
    return {
      Total: sum(Object.values(Object.values(data?.[dataType]?.[type])?.[0])),
      ...Object.values(data?.[dataType]?.[type])?.[0],
    }
  }

  useEffect(() => {
    document.title = `${tickerId} - Financials - Revenue Segment - Lets Value Invest`
    setDisplayData(null)
    setTimeout(() => {
      setDisplayData({
        geographicSegments: {
          annual: {
            date: Object.keys(
              revenueSegments?.["geographicSegments"]?.["annual"]
            )?.[0],
            data: getData(revenueSegments, "geographicSegments", "annual"),
          },
          quarterly: {
            date: Object.keys(
              revenueSegments?.["geographicSegments"]?.["quarterly"]
            )?.[0],
            data: getData(revenueSegments, "geographicSegments", "quarterly"),
          },
        },
        revenueSegments: {
          annual: {
            date: Object.keys(
              revenueSegments?.["productSegments"]?.["annual"]
            )?.[0],
            data: getData(revenueSegments, "productSegments", "annual"),
          },
          quarterly: {
            date: Object.keys(
              revenueSegments?.["productSegments"]?.["quarterly"]
            )?.[0],
            data: getData(revenueSegments, "productSegments", "quarterly"),
          },
        },
      })
    })
  }, [displayDataType])

  if (!displayData) return <></>

  return (
    <Box>
      <Box justifyContent={"end"} display="flex">
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={displayDataType}
          onChange={(e) => setDisplayDataType(e.target.value)}
          xs={12}
          md={"flex"}
        >
          <Tooltip title="Switch to annual" arrow placement="top">
            <ToggleButton
              value="annual"
              key="annual"
              className={classes["toggle-btn"]}
            >
              {"Annual"}
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Switch to quarterly" arrow placement="top">
            <ToggleButton
              value="quarterly"
              key="quarterly"
              className={classes["toggle-btn"]}
            >
              {"Quarterly"}
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
      <Grid container columnSpacing={2} marginTop="1rem">
        <Grid item xs={12} sm={6}>
          <TableContainer
            component={Paper}
            className={classes["table-container"]}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes["main-header"]}>
                      Geographic Segments Breakdown
                    </div>
                    <div className={classes["sub-header"]}>
                      As of last{" "}
                      {displayDataType === "annual" ? "annual year" : "quarter"}{" "}
                      ended at{" "}
                      {displayData?.geographicSegments?.[displayDataType]?.date}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(
                  displayData?.geographicSegments?.[displayDataType]?.data
                ).map((key) => {
                  return (
                    <TableRow>
                      <TableCell className={classes["table-cell"]}>
                        <Rating
                          value={
                            (displayData?.geographicSegments?.[displayDataType]
                              ?.data[key] /
                              displayData?.geographicSegments?.[displayDataType]
                                ?.data.Total) *
                            100
                          }
                          rawValue={
                            (displayData?.geographicSegments?.[displayDataType]
                              ?.data[key] /
                              displayData?.geographicSegments?.[displayDataType]
                                ?.data.Total) *
                            100
                          }
                          title={`${key}: ${
                            Math.round(
                              displayData?.geographicSegments?.[displayDataType]
                                ?.data[key] / 1000000
                            ) / 1000
                          }B USD`}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer
            component={Paper}
            className={classes["table-container"]}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className={classes["main-header"]}>
                      Product Segments Breakdown
                    </div>
                    <div className={classes["sub-header"]}>
                      As of last{" "}
                      {displayDataType === "annual" ? "annual year" : "quarter"}{" "}
                      ended at{" "}
                      {displayData?.revenueSegments?.[displayDataType]?.date}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(
                  displayData?.revenueSegments?.[displayDataType]?.data
                ).map((key) => {
                  return (
                    <TableRow>
                      <TableCell className={classes["table-cell"]}>
                        <Rating
                          value={
                            (displayData?.revenueSegments?.[displayDataType]
                              ?.data[key] /
                              displayData?.revenueSegments?.[displayDataType]
                                ?.data.Total) *
                            100
                          }
                          rawValue={
                            (displayData?.revenueSegments?.[displayDataType]
                              ?.data[key] /
                              displayData?.revenueSegments?.[displayDataType]
                                ?.data.Total) *
                            100
                          }
                          title={`${key}: ${
                            Math.round(
                              displayData?.revenueSegments?.[displayDataType]
                                ?.data[key] / 1000000
                            ) / 1000
                          }B USD`}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RevenueSegments

RevenueSegments.propTypes = {
  revenueSegments: PropTypes.object.isRequired,
}
