import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "header-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "12px",
    flexWrap: "wrap",
  },
  "row-headers": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
  },
  "info-text": {
    color: `${theme.palette["grey-2"]} !important`,
    fontSize: "14px",
  },
  "data-text": {
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  "collapse-icon": {
    padding: "0 4px 0 0 !important",
    marginLeft: "-6px",
    color: `${theme.palette.purple} !important`,
  },
  "chart-icon": {
    cursor: "pointer",
    color: `${theme.palette.purple} !important`,
    border: `1px solid ${theme.palette["primary-bg"]} !important`,
    borderRadius: "5px",
  },
  "collapse-row-1": {
    paddingLeft: "16px",
  },
  "collapse-row-2": {
    paddingLeft: "32px",
  },
  "collapse-row-3": {
    paddingLeft: "60px",
  },
  "collapse-row-4": {
    paddingLeft: "72px",
  },
  "collapse-row-5": {
    paddingLeft: "78px",
  },
  "collapse-row-6": {
    paddingLeft: "40px",
  },
  "collapse-row-1-bold": {
    paddingLeft: "24px",
    fontWeight: "bold !important",
  },
  "collapse-row-2-bold": {
    paddingLeft: "40px",
    fontWeight: "bold !important",
  },
  "total-row": {
    paddingLeft: "72px",
    fontWeight: "bold !important",
  },
  "total-row-2": {
    paddingLeft: "60px",
    fontWeight: "bold !important",
  },
  "tooltip-chart": {
    display: "flex",
    width: "900px",
    height: "550px",
    alignItems: "center",
    justifyContent: "center",
  },
  "toggle-btn": {
    textTransform: "capitalize !important",
    fontSize: "14px !important",
    minWidth: "2rem !important",
  },
  "right-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "copy-btn": {
    cursor: "pointer",
    color: `${theme.palette.purple}`,
    marginLeft: "1rem",
  },
  "reverse-btn": {
    cursor: "pointer",
    color: `${theme.palette.purple}`,
    border: `1px solid ${theme.palette["grey-3"]}`,
    borderRadius: "5px",
    padding: "7px",
    display: "flex",
    width: "50px !important", // Adjust width as needed
    height: "40px !important", // Adjust height as needed
  },
  "bold-text": {
    fontWeight: "bold !important",
  },
  "total-expenses": {
    paddingLeft: "28px",
    fontWeight: "bold !important",
  },
  sticky: {
    position: "sticky",
    left: 0,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.up("xs")]: {
      minWidth: "6rem",
    },
  },
  row: {
    "&:hover": {
      backgroundColor: `${theme.palette["light-grey-1"]} !important`,
    },
  },
}))
