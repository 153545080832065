import React from "react"
import NumberFormat from "react-number-format"
import PropTypes from "prop-types"

export const InputThousandSeparator = React.forwardRef(
  function InputThousandSeparator(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        decimalScale={2}
        getInputRef={ref}
        allowNegative={true}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          })
        }}
      />
    )
  }
)

InputThousandSeparator.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export const TextThousandSeparator = React.forwardRef(
  function TextThousandSeparator(props, ref) {
    const { onChange, ...other } = props

    return <NumberFormat {...other} decimalScale={2} displayType="text" />
  }
)

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K" // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M" // convert to M for number from > 1 million
  } else if (num < 900) {
    return num // if value < 1000, nothing to do
  }
}
