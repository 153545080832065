import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import Term from "../../../../utils/Term"

const PriceMultiples = ({ data }) => {
  const classes = useStyles()
  const [rows] = useState([
    {
      title: "P/S Ratio",
      key: "priceToSalesRatioTtm",
      defToolTip: {
        key: "price_to_sales_ratio",
        type: "Overview",
      },
    },
    {
      title: "P/E Ratio",
      key: "priceEarningsRatioTtm",
      defToolTip: {
        key: "price_to_earnings_ratio",
        type: "Overview",
      },
    },
    {
      title: "P/B Ratio",
      key: "priceToBookRatioTtm",
      defToolTip: {
        key: "net_income",
        type: "Overview",
      },
    },
    {
      title: "P/Operating FCF Ratio",
      key: "priceToOperatingCashFlowsRatioTtm",
      defToolTip: {
        key: "price_to_ocf_ratio",
        type: "Overview",
      },
    },
    {
      title: "P/Fcf Ratio",
      key: "priceToFreeCashFlowsRatioTtm",
      defToolTip: {
        key: "price_to_fcf_ratio",
        type: "Overview",
      },
    },
  ])

  // To display formatted value
  const displayValue = (value, ext = "x", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: false,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes["main-header"]}>Price Multiples</div>
                <div className={classes["sub-header"]}>
                  Based on current share price and TTM per share values
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-header"]} align="left">
                  <Box display={"flex"} alignItems="center">
                    {row.title}
                    <span className="ml1 mt1">
                      <Term config={row.defToolTip} />
                    </span>
                  </Box>
                </TableCell>
                <TableCell className={classes["row-value"]} align="right">
                  {displayValue(data[row.key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PriceMultiples

PriceMultiples.propTypes = {
  data: PropTypes.object.isRequired,
}
