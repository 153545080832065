import * as React from "react"
import Button from "@mui/material/Button"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { useState } from "react"
import VideoPopup from "./videoPopup" // Import the popup component

function VideoIconButton({ videoId }) {
  const [openPopup, setOpenPopup] = useState(false)

  const handleButtonClick = () => {
    setOpenPopup(true)
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<PlayArrowIcon />}
        sx={{ marginTop: "20px", marginBottom: "20px" }}
        onClick={handleButtonClick}
      >
        How it Works?
      </Button>
      <VideoPopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        videoId={videoId}
      />
    </>
  )
}

export default VideoIconButton
