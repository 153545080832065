import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "../../styles"
import { TextThousandSeparator } from "../../../../../utils/NumberFormatters"
import {
  getRatingTemplate,
  getScoreTemplate,
} from "../../../../../utils/KeyInsightsScores"
import clsx from "clsx"

const Metrics = ({ data, config, isFirst }) => {
  const classes = useStyles()
  const [rows] = useState(config.metrics)

  // To display formatted value
  const displayValue = (
    value,
    ext = "",
    thousandSeparator = false,
    isSuffix = true
  ) => {
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
      />
    )
  }

  if (!data) return <></>

  return (
    <Box className={classes["big-numbers"]}>
      {/* Display data */}
      <TableContainer component={Paper} className={classes["table-container"]}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} className={"h12"}>
                <Box className={classes["table-header"]}>
                  <span>
                    <div className={classes["main-header"]}></div>
                  </span>
                  <span>{getScoreTemplate(data?.score, classes)}</span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">10Yr</TableCell>
              <TableCell align="center">5Yr</TableCell>
              <TableCell align="center">3Yr</TableCell>
              <TableCell align="center">TTM</TableCell>
              <TableCell align="center">Rating</TableCell>
            </TableRow>
            {rows?.map((row, index) => (
              <TableRow key={`${row.title}${index}`}>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(data?.[row.key]?.tenYear, row.ext)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(data?.[row.key]?.fiveYear, row.ext)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(data?.[row.key]?.threeYear, row.ext)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {displayValue(data?.[row.key]?.ttm, row.ext)}
                </TableCell>
                <TableCell className={classes["row-value"]} align="center">
                  {getScoreTemplate(
                    Math.round(data?.[row.key]?.score * 100) / 100,
                    classes
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Metrics

Metrics.propTypes = {
  config: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isFirst: PropTypes.bool.isRequired,
}
