import React, { useEffect, useState } from "react"
import {
  Box,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TablePagination,
} from "@mui/material"
import { format } from "date-fns"
import PropTypes from "prop-types"
import { Clear } from "@mui/icons-material"

import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"

const AllTransactions = ({ data }) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [transactionType, setTransactionType] = useState("")
  const [transactionTypesList, setTransactionTypesList] = useState(null)
  const [filteredData, setFilteredData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getTransactionTypeClass = (type) => {
    if (type.includes("Sale")) return "sale"
    if (type.includes("Purchase")) return "purchase"
    return "others"
  }

  useEffect(() => {
    const types = data.map((ele) =>
      ele.transactionType?.split("-")?.[1]?.toUpperCase()
    )
    setTransactionTypesList(Array.from(new Set([...types])))
    setFilteredData(data)
  }, [data])

  return (
    <Box>
      <div className={classes["main-header"]}>Insider transactions:</div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <Select
          value={transactionType}
          displayEmpty
          onChange={(event) => {
            setTransactionType(event.target.value)
            setFilteredData(
              data.filter(
                (ele) =>
                  ele.transactionType?.split("-")?.[1]?.toUpperCase() ===
                  event.target.value
              )
            )
            setPage(0)
          }}
          label="Filter by form type"
          sx={{
            "& .MuiSelect-icon": {
              display: transactionType ? "none" : "",
            },
          }}
          endAdornment={
            <IconButton
              sx={{ display: transactionType ? "" : "none" }}
              onClick={() => {
                setTransactionType("")
                setPage(0)
                setFilteredData(data)
              }}
            >
              <Clear />
            </IconButton>
          }
        >
          <MenuItem value="">
            <em>{"Filter by transaction type"}</em>
          </MenuItem>
          {transactionTypesList?.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.header}>
                  Insider/Title
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>
                  Transaction Date
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>Filing Date</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}># of shares</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  Transaction Type
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>Price</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>Value</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>Filing Type</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.header}>
                  # of shares owned
                </Typography>
              </TableCell>
            </TableRow>
            {filteredData?.length &&
              filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((transaction, index) => (
                  <TableRow key={`${index}-${new Date()}`}>
                    <TableCell align="left">
                      <Typography
                        component={"span"}
                        className={classes["content-txt"]}
                      >
                        {transaction.reportingName}
                        <div>{transaction.typeOfOwner}</div>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {format(
                          new Date(transaction.transactionDate),
                          "MM/dd/yyyy"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {format(new Date(transaction.filingDate), "MM/dd/yyyy")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={transaction.securitiesTransacted}
                          prefix={""}
                          suffix={false}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.type}>
                      <div
                        className={
                          classes[
                            getTransactionTypeClass(transaction.transactionType)
                          ]
                        }
                      >
                        {transaction?.transactionType
                          ?.split("-")?.[1]
                          ?.toUpperCase()}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={transaction.price}
                          prefix={"$"}
                          suffix={false}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={
                            transaction.securitiesTransacted * transaction.price
                          }
                          prefix={"$"}
                          suffix={false}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        {"Form"} {transaction.formType}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes["content-txt-2"]}>
                        <TextThousandSeparator
                          value={transaction.securitiesOwned}
                          prefix={""}
                          suffix={false}
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredData.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default AllTransactions

AllTransactions.propTypes = {
  data: PropTypes.array.isRequired,
}
