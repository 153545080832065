import { format } from "date-fns"

export const getUsdValues = (cashFlowStatementData) => {
  // default data
  const defaultData = {
    cfOperatingActivities: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    depreciationAndAmortization: Array.apply(null, Array(10)),
    deferredIncomeTax: Array.apply(null, Array(10)),
    stockBasedCompensation: Array.apply(null, Array(10)),
    changeInWorkingCapital: Array.apply(null, Array(10)),
    accountsReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    accountsPayables: Array.apply(null, Array(10)),
    otherWorkingCapital: Array.apply(null, Array(10)),
    otherNonCashItems: Array.apply(null, Array(10)),
    netCashProvidedByOperatingActivities: Array.apply(null, Array(10)),
    cashFlowFromInvestingActivities: Array.apply(null, Array(10)),
    investmentsInPropertyPlantAndEquipment: Array.apply(null, Array(10)),
    acquisitionsNet: Array.apply(null, Array(10)),
    purchasesOfInvestments: Array.apply(null, Array(10)),
    salesMaturitiesOfInvestments: Array.apply(null, Array(10)),
    otherInvestingActivites: Array.apply(null, Array(10)),
    netCashUsedForInvestingActivites: Array.apply(null, Array(10)),
    netCashFlowsFromFinancingActivities: Array.apply(null, Array(10)),
    debtRepayment: Array.apply(null, Array(10)),
    commonStockRepurchased: Array.apply(null, Array(10)),
    dividendsPaid: Array.apply(null, Array(10)),
    otherFinancingActivites: Array.apply(null, Array(10)),
    netCashUsedProvidedByFinancingActivities: Array.apply(null, Array(10)),
    netChangeInCash: Array.apply(null, Array(10)),
    cashAtBeginningOfPeriod: Array.apply(null, Array(10)),
    cashAtEndOfPeriod: Array.apply(null, Array(10)),
    freeCashFlowHeader: Array.apply(null, Array(10)),
    operatingCashFlow: Array.apply(null, Array(10)),
    capitalExpenditure: Array.apply(null, Array(10)),
    freeCashFlow: Array.apply(null, Array(10)),
    adjustedFreeCashFlow: Array.apply(null, Array(10)),
    // supplementaryData: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  cashFlowStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      cashFlowStatementData.length > 10 ? cashFlowStatementData.length - 1 : 9
    defaultData.cfOperatingActivities[maxIndex - index] = 0
    defaultData.netIncome[maxIndex - index] = data?.netIncome / 1000000
    defaultData.depreciationAndAmortization[maxIndex - index] =
      data?.depreciationAndAmortization / 1000000
    defaultData.deferredIncomeTax[maxIndex - index] =
      data?.deferredIncomeTax / 1000000
    defaultData.stockBasedCompensation[maxIndex - index] =
      data?.stockBasedCompensation / 1000000
    defaultData.changeInWorkingCapital[maxIndex - index] =
      data.changeInWorkingCapital / 1000000
    defaultData.accountsReceivables[maxIndex - index] =
      data.accountsReceivables / 1000000
    defaultData.inventory[maxIndex - index] = data?.inventory / 1000000
    defaultData.accountsPayables[maxIndex - index] =
      data?.accountsPayables / 1000000
    defaultData.otherWorkingCapital[maxIndex - index] =
      data?.otherWorkingCapital / 1000000
    defaultData.otherNonCashItems[maxIndex - index] =
      data?.otherNonCashItems / 1000000
    defaultData.netCashProvidedByOperatingActivities[maxIndex - index] =
      data?.netCashProvidedByOperatingActivities / 1000000
    defaultData.cashFlowFromInvestingActivities[maxIndex - index] = 0
    defaultData.investmentsInPropertyPlantAndEquipment[maxIndex - index] =
      data?.investmentsInPropertyPlantAndEquipment / 1000000
    defaultData.acquisitionsNet[maxIndex - index] =
      data?.acquisitionsNet / 1000000
    defaultData.purchasesOfInvestments[maxIndex - index] =
      data?.purchasesOfInvestments / 1000000
    defaultData.salesMaturitiesOfInvestments[maxIndex - index] =
      data?.salesMaturitiesOfInvestments / 1000000
    defaultData.otherInvestingActivites[maxIndex - index] =
      data?.otherInvestingActivites / 1000000
    defaultData.netCashUsedForInvestingActivites[maxIndex - index] =
      data?.netCashUsedForInvestingActivites / 1000000
    defaultData.netCashFlowsFromFinancingActivities[maxIndex - index] = 0
    defaultData.debtRepayment[maxIndex - index] = data?.debtRepayment / 1000000
    defaultData.commonStockRepurchased[maxIndex - index] =
      data?.commonStockRepurchased / 1000000
    defaultData.dividendsPaid[maxIndex - index] = data?.dividendsPaid / 1000000
    defaultData.otherFinancingActivites[maxIndex - index] =
      data?.otherFinancingActivites / 1000000
    defaultData.netCashUsedProvidedByFinancingActivities[maxIndex - index] =
      data?.netCashUsedProvidedByFinancingActivities / 1000000
    defaultData.netChangeInCash[maxIndex - index] =
      data?.netChangeInCash / 1000000
    defaultData.cashAtBeginningOfPeriod[maxIndex - index] =
      data?.cashAtBeginningOfPeriod / 1000000
    defaultData.cashAtEndOfPeriod[maxIndex - index] =
      data?.cashAtEndOfPeriod / 1000000
    defaultData.freeCashFlowHeader[maxIndex - index] = 0
    defaultData.operatingCashFlow[maxIndex - index] =
      data?.operatingCashFlow / 1000000
    defaultData.capitalExpenditure[maxIndex - index] =
      data?.capitalExpenditure / 1000000
    defaultData.freeCashFlow[maxIndex - index] = data?.freeCashFlow / 1000000
    defaultData.adjustedFreeCashFlow[maxIndex - index] =
      (data?.freeCashFlow - data?.stockBasedCompensation) / 1000000
  })
  return { tempHeaders, defaultData }
}

export const getUsdPercentValues = (cashFlowStatementData) => {
  // default data
  const defaultData = {
    cfOperatingActivities: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    depreciationAndAmortization: Array.apply(null, Array(10)),
    deferredIncomeTax: Array.apply(null, Array(10)),
    stockBasedCompensation: Array.apply(null, Array(10)),
    changeInWorkingCapital: Array.apply(null, Array(10)),
    accountsReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    accountsPayables: Array.apply(null, Array(10)),
    otherWorkingCapital: Array.apply(null, Array(10)),
    otherNonCashItems: Array.apply(null, Array(10)),
    netCashProvidedByOperatingActivities: Array.apply(null, Array(10)),
    cashFlowFromInvestingActivities: Array.apply(null, Array(10)),
    investmentsInPropertyPlantAndEquipment: Array.apply(null, Array(10)),
    acquisitionsNet: Array.apply(null, Array(10)),
    purchasesOfInvestments: Array.apply(null, Array(10)),
    salesMaturitiesOfInvestments: Array.apply(null, Array(10)),
    otherInvestingActivites: Array.apply(null, Array(10)),
    netCashUsedForInvestingActivites: Array.apply(null, Array(10)),
    netCashFlowsFromFinancingActivities: Array.apply(null, Array(10)),
    debtRepayment: Array.apply(null, Array(10)),
    commonStockRepurchased: Array.apply(null, Array(10)),
    dividendsPaid: Array.apply(null, Array(10)),
    otherFinancingActivites: Array.apply(null, Array(10)),
    netCashUsedProvidedByFinancingActivities: Array.apply(null, Array(10)),
    netChangeInCash: Array.apply(null, Array(10)),
    cashAtBeginningOfPeriod: Array.apply(null, Array(10)),
    cashAtEndOfPeriod: Array.apply(null, Array(10)),
    freeCashFlowHeader: Array.apply(null, Array(10)),
    operatingCashFlow: Array.apply(null, Array(10)),
    capitalExpenditure: Array.apply(null, Array(10)),
    freeCashFlow: Array.apply(null, Array(10)),
    adjustedFreeCashFlow: Array.apply(null, Array(10)),
    // supplementaryData: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  cashFlowStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      cashFlowStatementData.length > 10 ? cashFlowStatementData.length - 1 : 9
    defaultData.cfOperatingActivities[maxIndex - index] = 0
    defaultData.netIncome[maxIndex - index] =
      (data?.netIncome / data?.netCashProvidedByOperatingActivities) * 100
    defaultData.depreciationAndAmortization[maxIndex - index] =
      (data?.depreciationAndAmortization /
        data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.deferredIncomeTax[maxIndex - index] =
      (data?.deferredIncomeTax / data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.stockBasedCompensation[maxIndex - index] =
      (data?.stockBasedCompensation /
        data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.changeInWorkingCapital[maxIndex - index] =
      (data.changeInWorkingCapital /
        data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.accountsReceivables[maxIndex - index] =
      (data.accountsReceivables / data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.inventory[maxIndex - index] =
      (data?.inventory / data?.netCashProvidedByOperatingActivities) * 100
    defaultData.accountsPayables[maxIndex - index] =
      (data?.accountsPayables / data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.otherWorkingCapital[maxIndex - index] =
      (data?.otherWorkingCapital / data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.otherNonCashItems[maxIndex - index] =
      (data?.otherNonCashItems / data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.netCashProvidedByOperatingActivities[maxIndex - index] =
      (data?.netCashProvidedByOperatingActivities /
        data?.netCashProvidedByOperatingActivities) *
      100
    defaultData.cashFlowFromInvestingActivities[maxIndex - index] = 0
    defaultData.investmentsInPropertyPlantAndEquipment[maxIndex - index] =
      (data?.investmentsInPropertyPlantAndEquipment /
        data?.netCashUsedForInvestingActivites) *
      100
    defaultData.acquisitionsNet[maxIndex - index] =
      (data?.acquisitionsNet / data?.netCashUsedForInvestingActivites) * 100
    defaultData.purchasesOfInvestments[maxIndex - index] =
      (data?.purchasesOfInvestments / data?.netCashUsedForInvestingActivites) *
      100
    defaultData.salesMaturitiesOfInvestments[maxIndex - index] =
      (data?.salesMaturitiesOfInvestments /
        data?.netCashUsedForInvestingActivites) *
      100
    defaultData.otherInvestingActivites[maxIndex - index] =
      (data?.otherInvestingActivites / data?.netCashUsedForInvestingActivites) *
      100
    defaultData.netCashUsedForInvestingActivites[maxIndex - index] =
      (data?.netCashUsedForInvestingActivites /
        data?.netCashUsedForInvestingActivites) *
      100
    defaultData.netCashFlowsFromFinancingActivities[maxIndex - index] = 0
    defaultData.debtRepayment[maxIndex - index] =
      (data?.debtRepayment / data?.netCashUsedProvidedByFinancingActivities) *
      100
    defaultData.commonStockRepurchased[maxIndex - index] =
      (data?.commonStockRepurchased /
        data?.netCashUsedProvidedByFinancingActivities) *
      100
    defaultData.dividendsPaid[maxIndex - index] =
      (data?.dividendsPaid / data?.netCashUsedProvidedByFinancingActivities) *
      100
    defaultData.otherFinancingActivites[maxIndex - index] =
      (data?.otherFinancingActivites /
        data?.netCashUsedProvidedByFinancingActivities) *
      100
    defaultData.netCashUsedProvidedByFinancingActivities[maxIndex - index] =
      (data?.netCashUsedProvidedByFinancingActivities /
        data?.netCashUsedProvidedByFinancingActivities) *
      100
    defaultData.netChangeInCash[maxIndex - index] =
      data?.netChangeInCash / 1000000
    defaultData.cashAtBeginningOfPeriod[maxIndex - index] =
      data?.cashAtBeginningOfPeriod / 1000000
    defaultData.cashAtEndOfPeriod[maxIndex - index] =
      data?.cashAtEndOfPeriod / 1000000
    defaultData.freeCashFlowHeader[maxIndex - index] = 0
    defaultData.operatingCashFlow[maxIndex - index] =
      (data?.operatingCashFlow / data?.operatingCashFlow) * 100
    defaultData.capitalExpenditure[maxIndex - index] =
      (data?.capitalExpenditure / data?.operatingCashFlow) * 100
    defaultData.freeCashFlow[maxIndex - index] =
      (data?.freeCashFlow / data?.operatingCashFlow) * 100
    defaultData.adjustedFreeCashFlow[maxIndex - index] =
      ((data?.freeCashFlow - data?.stockBasedCompensation) /
        data?.operatingCashFlow) *
      100
  })
  return { tempHeaders, defaultData }
}

export const getConversionValues = (cashFlowStatementData, price) => {
  // default data
  const defaultData = {
    cfOperatingActivities: Array.apply(null, Array(10)),
    netIncome: Array.apply(null, Array(10)),
    depreciationAndAmortization: Array.apply(null, Array(10)),
    deferredIncomeTax: Array.apply(null, Array(10)),
    stockBasedCompensation: Array.apply(null, Array(10)),
    changeInWorkingCapital: Array.apply(null, Array(10)),
    accountsReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    accountsPayables: Array.apply(null, Array(10)),
    otherWorkingCapital: Array.apply(null, Array(10)),
    otherNonCashItems: Array.apply(null, Array(10)),
    netCashProvidedByOperatingActivities: Array.apply(null, Array(10)),
    cashFlowFromInvestingActivities: Array.apply(null, Array(10)),
    investmentsInPropertyPlantAndEquipment: Array.apply(null, Array(10)),
    acquisitionsNet: Array.apply(null, Array(10)),
    purchasesOfInvestments: Array.apply(null, Array(10)),
    salesMaturitiesOfInvestments: Array.apply(null, Array(10)),
    otherInvestingActivites: Array.apply(null, Array(10)),
    netCashUsedForInvestingActivites: Array.apply(null, Array(10)),
    netCashFlowsFromFinancingActivities: Array.apply(null, Array(10)),
    debtRepayment: Array.apply(null, Array(10)),
    commonStockRepurchased: Array.apply(null, Array(10)),
    dividendsPaid: Array.apply(null, Array(10)),
    otherFinancingActivites: Array.apply(null, Array(10)),
    netCashUsedProvidedByFinancingActivities: Array.apply(null, Array(10)),
    netChangeInCash: Array.apply(null, Array(10)),
    cashAtBeginningOfPeriod: Array.apply(null, Array(10)),
    cashAtEndOfPeriod: Array.apply(null, Array(10)),
    freeCashFlowHeader: Array.apply(null, Array(10)),
    operatingCashFlow: Array.apply(null, Array(10)),
    capitalExpenditure: Array.apply(null, Array(10)),
    freeCashFlow: Array.apply(null, Array(10)),
    adjustedFreeCashFlow: Array.apply(null, Array(10)),
    // supplementaryData: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  cashFlowStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      cashFlowStatementData.length > 10 ? cashFlowStatementData.length - 1 : 9
    defaultData.cfOperatingActivities[maxIndex - index] = 0
    defaultData.netIncome[maxIndex - index] =
      (data?.netIncome / 1000000) * price
    defaultData.depreciationAndAmortization[maxIndex - index] =
      (data?.depreciationAndAmortization / 1000000) * price
    defaultData.deferredIncomeTax[maxIndex - index] =
      (data?.deferredIncomeTax / 1000000) * price
    defaultData.stockBasedCompensation[maxIndex - index] =
      (data?.stockBasedCompensation / 1000000) * price
    defaultData.changeInWorkingCapital[maxIndex - index] =
      (data.changeInWorkingCapital / 1000000) * price
    defaultData.accountsReceivables[maxIndex - index] =
      (data.accountsReceivables / 1000000) * price
    defaultData.inventory[maxIndex - index] =
      (data?.inventory / 1000000) * price
    defaultData.accountsPayables[maxIndex - index] =
      (data?.accountsPayables / 1000000) * price
    defaultData.otherWorkingCapital[maxIndex - index] =
      (data?.otherWorkingCapital / 1000000) * price
    defaultData.otherNonCashItems[maxIndex - index] =
      (data?.otherNonCashItems / 1000000) * price
    defaultData.netCashProvidedByOperatingActivities[maxIndex - index] =
      (data?.netCashProvidedByOperatingActivities / 1000000) * price
    defaultData.cashFlowFromInvestingActivities[maxIndex - index] = 0
    defaultData.investmentsInPropertyPlantAndEquipment[maxIndex - index] =
      (data?.investmentsInPropertyPlantAndEquipment / 1000000) * price
    defaultData.acquisitionsNet[maxIndex - index] =
      (data?.acquisitionsNet / 1000000) * price
    defaultData.purchasesOfInvestments[maxIndex - index] =
      (data?.purchasesOfInvestments / 1000000) * price
    defaultData.salesMaturitiesOfInvestments[maxIndex - index] =
      (data?.salesMaturitiesOfInvestments / 1000000) * price
    defaultData.otherInvestingActivites[maxIndex - index] =
      (data?.otherInvestingActivites / 1000000) * price
    defaultData.netCashUsedForInvestingActivites[maxIndex - index] =
      (data?.netCashUsedForInvestingActivites / 1000000) * price
    defaultData.netCashFlowsFromFinancingActivities[maxIndex - index] = 0
    defaultData.debtRepayment[maxIndex - index] =
      (data?.debtRepayment / 1000000) * price
    defaultData.commonStockRepurchased[maxIndex - index] =
      (data?.commonStockRepurchased / 1000000) * price
    defaultData.dividendsPaid[maxIndex - index] =
      (data?.dividendsPaid / 1000000) * price
    defaultData.otherFinancingActivites[maxIndex - index] =
      (data?.otherFinancingActivites / 1000000) * price
    defaultData.netCashUsedProvidedByFinancingActivities[maxIndex - index] =
      (data?.netCashUsedProvidedByFinancingActivities / 1000000) * price
    defaultData.netChangeInCash[maxIndex - index] =
      (data?.netChangeInCash / 1000000) * price
    defaultData.cashAtBeginningOfPeriod[maxIndex - index] =
      (data?.cashAtBeginningOfPeriod / 1000000) * price
    defaultData.cashAtEndOfPeriod[maxIndex - index] =
      (data?.cashAtEndOfPeriod / 1000000) * price
    defaultData.freeCashFlowHeader[maxIndex - index] = 0
    defaultData.operatingCashFlow[maxIndex - index] =
      (data?.operatingCashFlow / 1000000) * price
    defaultData.capitalExpenditure[maxIndex - index] =
      (data?.capitalExpenditure / 1000000) * price
    defaultData.freeCashFlow[maxIndex - index] =
      (data?.freeCashFlow / 1000000) * price
    defaultData.adjustedFreeCashFlow[maxIndex - index] =
      ((data?.freeCashFlow - data?.stockBasedCompensation) / 1000000) * price
  })
  return { tempHeaders, defaultData }
}
