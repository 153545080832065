import React, { useEffect, useState } from "react"
import { Box, Grid, Stack, Chip } from "@mui/material"
import "react-toastify/dist/ReactToastify.css"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { format } from "date-fns"
import useStyles from "./../../styles"
import { getBooks } from "../../../../services/core"
import Loading from "../../../Loading"
import BookCard from "./BookCard"

const BeginnersList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const { type } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true)
        const resp = await getBooks()
        setData(resp?.data)
        setIsLoading(false)
      } catch (err) {
        toast.warn("Unable to fetch books data. Please try again.")
        setIsLoading(false)
      }
    }
    getData()
  }, [type])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Grid container spacing={2}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: "40px", paddingLeft: "20px" }}
      >
        <Chip label="Beginner's List" color="primary" variant="outlined" />
      </Stack>
      {data?.map((book, index) => (
        <Grid item key={index}>
          <BookCard {...book} />
        </Grid>
      ))}
    </Grid>
  )
}

export default BeginnersList
