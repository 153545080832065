import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material"

import { TextThousandSeparator } from "../../../../utils/NumberFormatters"
import useStyles from "../styles"

const ExecutivesTable = ({ keyExecutives }) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography className={classes["header"]}>Key Executives</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Compensation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keyExecutives?.map((row) => (
              <TableRow key={`${row.name}-${row.title}`}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell align="center">
                  {row.pay ? (
                    <TextThousandSeparator
                      value={row.pay}
                      prefix={""}
                      suffix={" USD"}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  ) : (
                    "NA"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ExecutivesTable

ExecutivesTable.propTypes = {
  keyExecutives: PropTypes.array.isRequired,
}
