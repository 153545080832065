import React, { useState, useEffect, useContext } from "react"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import Loading from "../../Loading"
import useStyles from "./styles"
import { getKeyInsights } from "../../../services/ticker"
import AppDataContext from "../../../context/data"
import SimpleView from "./components/SimpleView"
import DetailedView from "./components/DetailedView"

const KeyInsights = () => {
  const classes = useStyles()
  const { tickerId } = useParams()
  const [displayType, setDisplayType] = useState("SIMPLE")
  const [isLoading, setIsLoading] = useState(false)
  const [keyInsightsData, setKeyInsightsData] = useState(null)
  const { keyInsights, setKeyInsights, overview } = useContext(AppDataContext)

  useEffect(() => {
    document.title = `${tickerId} - Key Insights - Lets Value Invest`
    setIsLoading(true)
    setDisplayType("SIMPLE")
    setKeyInsightsData(null)
    const getKeyInsightsData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getKeyInsights(tickerId)
        setKeyInsightsData({ ...resp.data?.keyInsightsData, tickerId })
        setKeyInsights({ ...resp.data?.keyInsightsData, tickerId })
        setIsLoading(false)
      } catch (err) {
        setKeyInsightsData(null)
        setKeyInsights(null)
        setIsLoading(false)
        toast.error("Unable to get key insights. Please try after sometime.")
      }
    }
    // check if we have data already in context
    if (keyInsights?.tickerId === tickerId) {
      setKeyInsightsData(keyInsights)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getKeyInsightsData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box>
      <Box className={classes["type-container"]}>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={displayType}
          onChange={(e) => setDisplayType(e.target.value)}
          xs={12}
          md={"flex"}
        >
          <ToggleButton
            value="SIMPLE"
            key="SIMPLE"
            className={classes["toggle-btn"]}
          >
            {"SIMPLE"}
          </ToggleButton>
          ,
          <ToggleButton
            value="DETAILED"
            key="DETAILED"
            className={classes["toggle-btn"]}
          >
            {"DETAILED"}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {keyInsightsData && (
        <Box marginTop="2rem">
          {displayType === "SIMPLE" ? (
            <SimpleView
              keyInsights={keyInsightsData?.simpleView}
              overview={overview}
            />
          ) : (
            <DetailedView keyInsights={keyInsightsData} overview={overview} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default KeyInsights
