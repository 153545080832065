import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  // Common styles
  "calculator-container": {
    padding: "2rem 0rem",
    width: "100%",
  },
  "calculator-header-text": {
    margin: "20px 0 !important",
    textAlign: "center",
  },
  labels: {
    color: theme.palette["grey-2"],
    marginBottom: "12px !important",
  },

  // CAGR
  "cagr-val": {
    marginTop: "20px !important",
  },
  "donut-chart": {
    display: "flex",
    justifyContent: "center",
    width: "20rem",
    height: "20rem",
  },

  // Compound
  "compound-header": {
    marginBottom: "10px !important",
  },
  "author-name": {
    color: theme.palette["grey-2"],
  },
  "contribution-type": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  "compound-calc-text": {
    marginTop: "16px !important",
    textAlign: "justify",
  },
  "compound-val-table": {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px !important",
  },
}))
