import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "login-dialog-actions": {
    padding: "20px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    padding: "12px 20px 0 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "lock-logo": {
    margin: "32px 16px 26px 16px",
    padding: "8px",
    borderRadius: "50%",
    fontSize: "2rem !important",
    backgroundColor: theme.palette.purple,
    color: theme.palette.white,
  },
  "google-btn": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "1rem",
  },
  "sign-up": {
    paddingTop: "1rem",
  },
  "login-btn": {
    backgroundColor: `${theme.palette.purple} !important`,
    color: `${theme.palette.white} !important`,
  },
  "login-btn-1": {
    marginRight: "1.5rem !important",
  },
}))
