export const palette = {
  fontFamily: "Montserrat",
  "chart-font-family": "Rajdhani",
  backgroundColorApp: "#ffffff",
  white: "#ffffff",
  purple: "#3F51B5",
  black: "#333333",
  "grey-1": "#424242",
  "grey-2": "#757575",
  "grey-3": "#BDBDBD",
  "grey-4": "#f0f0f0",
  lightGrey: "#FAFAFA",
  "light-grey-1": "#F5F5F5",
  dove: "#65A3E1",
  primary: {
    main: "#3F51B5",
  },
  "error-dark": "#d32f2f",
  "success-dark": "#388e3c",
  "warning-dark": "#ff9966",
  "error-bg-1": "#FBD1CE",
  "success-bg-1": "#C7E7CF",
  text: {
    primary: "#333333",
  },
  "green-1": "#CDDC39",
  "text-2": "#3f51b5",
  "primary-bg": "#E8F0FE",
  "score-5-bg": "#3D9141",
  "score-4-bg": "#8BC34A",
  "score-3-bg": "#A9A9A9",
  "score-2-bg": "#F44336",
  "score-1-bg": "#F56666",
  "tot-score-5-bg": "#E6F4EA",
  "tot-score-4-bg": "#E7FFF3",
  "tot-score-3-bg": "#F6F6F6",
  "tot-score-2-bg": "#FFDCCF",
  "tot-score-1-bg": "#FCE8E6",
  "purple-2": "#4051b5",
  "bar-graph-green-yellow": "#ADFF2F",
  "bar-graph-lime-green": "#32CD32",
  "bar-graph-deep-pink": "#FF1493",
  "bar-graph-tomato": "#FF6347",
  "bar-graph-khaki": "#F0E68C",
  "bar-graph-violet": "#EE82EE",
  "bar-graph-red": "#FF0000",
  news: "#202124",
}
