import { Divider, Box, Typography } from "@mui/material"
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined"
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined"
import useStyles from "../styles"
import { TextThousandSeparator } from "../../../../utils/NumberFormatters"

const ExpectedReturns = ({
  calcResult,
  discountRate,
  currentPrice,
  tickerId,
}) => {
  const classes = useStyles()

  const displayValue = (value, ext = "%", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: true,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  const getColorClass = (value, compareValue) => {
    return value < compareValue ? "error-txt" : "success-txt"
  }

  return (
    <Box>
      {/* Divider */}
      <Box className={classes.divider}>
        <Divider sx={{ borderBottomWidth: 2 }} />
      </Box>
      {/* Entry Price */}
      <Box display={"flex"} className="mb4" width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>Entry Price</Box>
        <Box flexBasis={"75%"} flexGrow="0" textAlign={"center"}>
          <b>Expected annual rate of return for {tickerId}</b>
        </Box>
      </Box>
      {/* 50% down */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            50%{" "}
            <ArrowDownwardOutlinedIcon fontSize="extrasmall"></ArrowDownwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[0]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[0]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[0]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[0]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[0]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[0]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[0]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* 25% down */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            25%{" "}
            <ArrowDownwardOutlinedIcon fontSize="extrasmall"></ArrowDownwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[1]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[1]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[1]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[1]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[1]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[1]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[1]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* 10% down */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            10%{" "}
            <ArrowDownwardOutlinedIcon fontSize="extrasmall"></ArrowDownwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[2]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[2]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[2]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[2]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[2]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[2]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[2]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* 5% down */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            5%{" "}
            <ArrowDownwardOutlinedIcon fontSize="extrasmall"></ArrowDownwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[3]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[3]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[3]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[3]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[3]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[3]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[3]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* Current pricee */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            <strong>Current price</strong>
          </Typography>
          <strong>{displayValue(currentPrice, "$", false)}</strong>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.otherData?.expCagr,
              discountRate
            )}
          >
            {displayValue(calcResult?.bull?.otherData?.expCagr, "%", true)}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.otherData?.expCagr,
              discountRate
            )}
          >
            {displayValue(calcResult?.normal?.otherData?.expCagr, "%", true)}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.otherData?.expCagr,
              discountRate
            )}
          >
            {displayValue(calcResult?.bear?.otherData?.expCagr, "%", true)}
          </span>
        </Box>
      </Box>
      {/* 5% up */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            5%{" "}
            <ArrowUpwardOutlinedIcon fontSize="extrasmall"></ArrowUpwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[4]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[4]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[4]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[4]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[4]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[4]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[4]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* 10% up */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            10%{" "}
            <ArrowUpwardOutlinedIcon fontSize="extrasmall"></ArrowUpwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[5]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[5]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[5]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[5]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[5]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[5]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[5]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
      {/* 25% up */}
      <Box display={"flex"} width="100%" flexDirection={"row"}>
        <Box className={classes["attr-name"]}>
          <Typography
            variant="caption"
            display="line"
            gutterbotto="true"
            className="pr2"
          >
            25%{" "}
            <ArrowUpwardOutlinedIcon fontSize="extrasmall"></ArrowUpwardOutlinedIcon>
          </Typography>
          {displayValue(
            calcResult?.bull?.expectedReturns[6]?.sharePrice,
            "$",
            false
          )}
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bull?.expectedReturns[6]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bull?.expectedReturns[6]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.normal?.expectedReturns[6]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.normal?.expectedReturns[6]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
        <Box className={classes["res-value"]}>
          <span
            className={getColorClass(
              calcResult?.bear?.expectedReturns[6]?.cagr,
              discountRate
            )}
          >
            {displayValue(
              calcResult?.bear?.expectedReturns[6]?.cagr,
              "%",
              true
            )}
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default ExpectedReturns
