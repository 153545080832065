import axios from "axios"

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_API}`

axios.interceptors.request.use((config) => {
  async function setHeader() {
    const newConfig = { ...config }
    newConfig.headers.Authorization = `Bearer ${
      window.sessionStorage.getItem("tokenId") || ""
    }`
    return newConfig
  }
  return setHeader()
})
