import React from "react"
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material"
import PropTypes from "prop-types"
import { ContentCopy, CompareArrows } from "@mui/icons-material"
import { toast } from "react-toastify"
import clsx from "clsx"

import useStyles from "../../styles"

const Header = ({
  displayCurrency,
  displayDataType,
  setDisplayDataType,
  displayDataConversionType,
  setDisplayDataConversionType,
  showCurrencyConverter,
  setShowCurrencyConverter,
  currencyOpt,
  setCurrencyOpt,
  reverseData,
}) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes["header-container"]}>
        <Box className={classes["right-content"]}>
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={displayDataType}
            onChange={(e) => setDisplayDataType(e.target.value)}
            xs={12}
            md={"flex"}
          >
            <Tooltip title="Switch to annual" arrow placement="top">
              <ToggleButton
                value="annualData"
                key="annualData"
                className={classes["toggle-btn"]}
              >
                {"Annual"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to quarterly" arrow placement="top">
              <ToggleButton
                value="quarterlyData"
                key="quarterlyData"
                className={classes["toggle-btn"]}
              >
                {"Quarterly"}
              </ToggleButton>
            </Tooltip>
            ,
            <Tooltip title="Switch to trailing 12 months" arrow placement="top">
              <ToggleButton
                value="ttmData"
                key="ttmData"
                className={classes["toggle-btn"]}
              >
                {"TTM"}
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          {showCurrencyConverter && (
            <Box padding={"0 12px 0 0"}>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={currencyOpt}
                onChange={(e) => setCurrencyOpt(e.target.value)}
                xs={12}
                md={"flex"}
              >
                <Tooltip
                  title={`Switch to ${displayCurrency}`}
                  arrow
                  placement="top"
                >
                  <ToggleButton
                    value={displayCurrency}
                    key={displayCurrency}
                    className={classes["toggle-btn"]}
                  >
                    {displayCurrency}
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Switch to USD" arrow placement="top">
                  <ToggleButton
                    value="USD"
                    key="USD"
                    className={classes["toggle-btn"]}
                  >
                    {"USD"}
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Header

Header.propTypes = {
  displayCurrency: PropTypes.string.isRequired,
  displayDataType: PropTypes.string.isRequired,
  setDisplayDataType: PropTypes.func.isRequired,
  displayDataConversionType: PropTypes.string.isRequired,
  setDisplayDataConversionType: PropTypes.func.isRequired,
  showCurrencyConverter: PropTypes.bool.isRequired,
  setShowCurrencyConverter: PropTypes.func.isRequired,
  currencyOpt: PropTypes.string.isRequired,
  setCurrencyOpt: PropTypes.func.isRequired,
  reverseData: PropTypes.func.isRequired,
}
