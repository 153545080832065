import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  header: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    padding: "12px 0 !important",
  },
  "content-txt": {
    color: theme.palette["grey-2"],
    textAlign: "justify",
  },
  "load-btn": {
    backgroundColor: `${theme.palette.purple} !important`,
    color: `${theme.palette.white} !important`,
    marginTop: "1.5rem !important",
  },
}))
