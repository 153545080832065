import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "main-container": {
    padding: "3rem 2rem",
    width: "100%",
  },
  "header-text": {
    fontSize: "18px",
    textAlign: "justify",
    paddingBottom: "1rem",
  },
  "selection-container": {
    display: "flex",
    justifyContent: "space-between",
  },
  "selection-header": {
    fontSize: "14px !important",
    textAlign: "left",
    paddingBottom: "4px",
    color: theme.palette["grey-2"],
  },
  "data-analysis": {
    marginTop: "1rem !important",
  },
  "main-header": {
    fontSize: "18px",
  },
  "sub-header": {
    color: theme.palette["grey-2"],
    fontSize: "14px",
  },
  ticker: {
    display: "inherit",
  },
  multiple: {
    fontStyle: "italic",
    color: theme.palette["grey-2"],
  },
}))
