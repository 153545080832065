import { Box, Typography } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from "react-select/async"

import useStyles from "../../styles"
import { palette } from "../../../../utils/Palette"

const CriteriaSearch = ({ criterias, setSelectedCriterias }) => {
  const classes = useStyles()

  const searchCriteria = (searchTxt, callback) => {
    const options = []
    criterias.forEach((criteria) => {
      options.push({
        ...criteria,
        options: criteria?.options?.filter((option) =>
          option?.label?.toLowerCase().includes(searchTxt.toLowerCase())
        ),
      })
    })
    callback(options)
  }

  return (
    <Box>
      <Typography className={classes["selection-header"]}>
        Add one or more criteria...
      </Typography>
      <AsyncSelect
        cacheOptions
        defaultOptions={criterias}
        onChange={(options) => setSelectedCriterias(options)}
        loadOptions={searchCriteria}
        isMulti
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: palette.purple,
          },
        })}
      />
    </Box>
  )
}

export default CriteriaSearch

CriteriaSearch.propTypes = {
  criterias: PropTypes.array.isRequired,
  setSelectedCriterias: PropTypes.func.isRequired,
}
