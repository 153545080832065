import { React, useState } from "react"
import ReactMarkdown from "react-markdown"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Link,
  Grid,
  IconButton,
} from "@mui/material"

const BookCard = ({
  title,
  author,
  imageURL,
  description,
  readMoreTexts,
  externalURL,
  ...otherProps
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription)
  }
  return (
    <Box className="p6">
      <Grid container direction="row">
        <Grid item xs={12} md={2}>
          <Box>
            <Link href={externalURL} target="_blank" rel="noopener noreferrer">
              <CardMedia
                component="img"
                sx={{ width: 150, height: 225 }}
                alt={title}
                image={imageURL}
                title={title}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                <Link
                  href={externalURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </Link>
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                By {author}
              </Typography>
              <Typography
                variant="body1"
                color="text.primary"
                component="span"
                sx={{ display: "inline-block", whitespace: "nowrap" }}
              >
                <ReactMarkdown>{description}</ReactMarkdown>
                {showFullDescription && (
                  <ReactMarkdown>{readMoreTexts}</ReactMarkdown>
                )}
                {readMoreTexts?.length > 0 && (
                  <Link onClick={toggleDescription}>
                    {showFullDescription ? "Show less" : "Read more"}
                    <IconButton
                      size="small"
                      edge="end"
                      aria-label="Toggle description"
                    >
                      {showFullDescription ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Link>
                )}
              </Typography>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
  /* return (
    <Grid container spacing={2} direction="row">
      <Grid item key={title} xs={6} sm={4} md={3}>
        <Card>
          <Link href={externalURL} target="_blank" rel="noopener noreferrer">
            <CardMedia
              component="img"
              alt={title}
              image={imageURL}
              title={title}
            />
          </Link>
          <CardContent>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body2" color="text.secondary">
              By {author}
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) */
}

export default BookCard
