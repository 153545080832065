import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TablePagination,
} from "@mui/material"
import { format } from "date-fns"
import { ArrowDownward, ArrowUpward, Remove } from "@mui/icons-material"

import useStyles from "../styles"

const HistoricalGrades = ({ upgradesDowngrades }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getAction = (type) => {
    switch (type) {
      case "hold":
        return (
          <Typography display={"flex"}>
            <Remove />
            <span className="ml2">Maintain</span>
          </Typography>
        )
      case "upgrade":
        return (
          <Typography display={"flex"}>
            <ArrowUpward />
            <span className="ml2">Upgrade</span>
          </Typography>
        )
      case "downgrade":
        return (
          <Typography display={"flex"}>
            <ArrowDownward />
            <span className="ml2">Downgrade</span>
          </Typography>
        )
      default:
        return (
          <Typography display={"flex"}>
            <Remove />
            <span className="ml2">Maintain</span>
          </Typography>
        )
    }
  }

  return (
    <Box>
      <Typography className={classes["main-header"]}>
        Analyst Historical Grades
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.header}>Date</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>
                  Grading Company
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>
                  Previous Grade
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>Action</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.header}>New Grade</Typography>
              </TableCell>
            </TableRow>
            {upgradesDowngrades?.length ? (
              upgradesDowngrades
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`${index}-${new Date()}`}>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {row.publishedDate
                          ? format(new Date(row.publishedDate), "MM/dd/yyyy")
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {row.gradingCompany}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {row.previousGrade}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component={"span"}
                        className={classes["content-txt"]}
                      >
                        {getAction(row.action)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes["content-txt"]}>
                        {row.newGrade}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {"No Records Found."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {upgradesDowngrades?.length ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={upgradesDowngrades?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}

export default HistoricalGrades

HistoricalGrades.propTypes = {
  upgradesDowngrades: PropTypes.array,
}
