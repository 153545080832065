import React, { useEffect, useState } from "react"
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { defaults } from "chart.js"

import { palette } from "../../../utils/Palette"
import useStyles from "../styles"

ChartJS.register(ArcElement, Title, Tooltip, Legend)
defaults.font.family = palette.fontFamily

const DoughnutChart = ({ presentValue, futureValue, cagr }) => {
  const [chartData, setChartData] = useState(null)
  const classes = useStyles()

  const options = {
    responsive: true,
    // plugins: {
    //   tooltip: {
    //     callbacks: {
    //       label: function (item) {
    //         return `${item.dataset.label}: $${item.formattedValue}`
    //       },
    //     },
    //   },
    //   legend: {
    //     position: "top",
    //   },
    //   title: {
    //     display: true,
    //     text: `Future Balance $${thousandSeparator(
    //       values.slice(-1)[0].futureVal
    //     )}`,
    //   },
    // },
    layout: {
      padding: 10,
    },
    labels: ["Present value", "Future Value"],
    cutout: "70%",
  }

  useEffect(
    () => {
      setChartData({
        labels: ["Present value", "Future Value"],
        datasets: [
          {
            labels: ["Present value", "Future Value"],
            data: [presentValue, futureValue],
            backgroundColor: [palette.purple, palette["score-5-bg"]],
          },
        ],
        text: `CAGR: ${cagr}%`,
      })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [presentValue, futureValue, cagr]
  )

  return (
    <Box className={classes["donut-chart"]} position={"relative"}>
      {chartData && <Doughnut options={options} data={chartData} />}
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "50%",
          left: 0,
          textAlign: "center",
          marginTop: "10px",
          lineHeight: "20px",
        }}
      >
        <b>{`CAGR: ${cagr}%`}</b>
      </div>
    </Box>
  )
}

export default DoughnutChart

DoughnutChart.propTypes = {
  presentValue: PropTypes.number.isRequired,
  futureValue: PropTypes.number.isRequired,
  cagr: PropTypes.any.isRequired,
}
