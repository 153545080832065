import React, { useContext, useEffect, useState } from "react"
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ReactMarkdown from "react-markdown"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import { getEarningCallTranscripts } from "../../../services/ticker"
import Loading from "../../Loading"
import ReactGA from "react-ga4"

const Transcripts = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/transcripts",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false)
  const [curTranscript, setCurTranscript] = useState(null)
  const [curTranscriptDate, setCurTranscriptDate] = useState(null)
  const { tickerId } = useParams()
  const { earningsCallTranscripts, setEarningsCallTranscripts, overview } =
    useContext(AppDataContext)
  const classes = useStyles()

  useEffect(() => {
    document.title = `${tickerId} - Transcripts - Lets Value Invest`
    setIsLoading(true)
    const getApiData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getEarningCallTranscripts(tickerId)
        setEarningsCallTranscripts({ ...resp.data, tickerId })
        setCurTranscript({
          ...resp.data?.defaultTranscript?.[0],
          content: resp.data?.defaultTranscript?.[0]?.content,
        })
        setCurTranscriptDate(resp.data?.datesOfAvailableTrabscripts?.[0]?.[2])
        setIsLoading(false)
      } catch (err) {
        setEarningsCallTranscripts(null)
        setCurTranscript(null)
        setCurTranscriptDate(null)
        setIsLoading(false)
        toast.error(
          "Unable to get transcript information. Please try after sometime."
        )
      }
    }
    // check if we have data already in context
    if (earningsCallTranscripts?.tickerId === tickerId) {
      setEarningsCallTranscripts({ ...earningsCallTranscripts })
      setCurTranscriptDate(
        earningsCallTranscripts?.datesOfAvailableTrabscripts?.[0]?.[2]
      )
      setCurTranscript({
        ...earningsCallTranscripts?.defaultTranscript?.[0],
        content: earningsCallTranscripts?.defaultTranscript?.[0]?.content,
      })
      setIsLoading(false)
      return
    }
    overview?.tickerId && getApiData()
  }, [JSON.stringify(overview), tickerId])

  const getCurTranscript = async (newVal) => {
    setIsTranscriptLoading(true)
    setCurTranscriptDate(newVal)
    const data = earningsCallTranscripts.datesOfAvailableTrabscripts?.filter(
      (transcriptData) => transcriptData.includes(newVal)
    )?.[0]
    toast.dismiss()
    try {
      // get data only if context is empty and save into context
      const resp = await getEarningCallTranscripts(
        tickerId,
        `quarter=${data?.[0]}&year=${data?.[1]}`
      )
      setCurTranscript({
        ...resp.data[0],
        content: resp.data?.[0]?.content,
      })
      setIsTranscriptLoading(false)
    } catch (err) {
      setCurTranscript(null)
      setIsTranscriptLoading(false)
      toast.error(
        "Unable to get transcript information. Please try after sometime."
      )
    }
  }

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={3}>
          <Box className={classes.container}>
            <Typography className={classes.header}>
              Available Transcripts
            </Typography>
            <Box className={classes["radio-col"]}>
              <RadioGroup
                row
                value={curTranscriptDate}
                onChange={(event) => getCurTranscript(event.target.value)}
              >
                {earningsCallTranscripts?.datesOfAvailableTrabscripts?.map(
                  (transcript) => {
                    return (
                      <FormControlLabel
                        value={transcript[2]}
                        control={<Radio />}
                        label={`${transcript[1]} Q${transcript[0]} ${transcript[2]}`}
                      />
                    )
                  }
                )}
              </RadioGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          {isTranscriptLoading && (
            <Box className={classes["loader-container"]}>
              <Loading />
            </Box>
          )}
          {!isTranscriptLoading && (
            <Box className={classes.container}>
              <Typography className={classes.header}>
                {curTranscript?.year} Q{curTranscript?.quarter}
              </Typography>
              <Box className={classes["radio-col"]}>
                <ReactMarkdown>{curTranscript?.content}</ReactMarkdown>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Transcripts
