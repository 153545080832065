import React, { useState } from "react"
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Grid,
  TablePagination,
} from "@mui/material"
import { format } from "date-fns"

import useStyles from "./styles"
import { TextThousandSeparator } from "../../../utils/NumberFormatters"

const DividendHistory = ({ dividendsData }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6}>
        <TableContainer>
          <div className={classes["main-header-2"]}>Dividend History:</div>
          <Table>
            <TableBody>
              {/* Industry */}
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Ex-dividend Date
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Cash Amount
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Record Date
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.header}>
                    Payment Date
                  </Typography>
                </TableCell>
              </TableRow>
              {dividendsData?.length ? (
                dividendsData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((dividend, index) => (
                    <TableRow key={`${index}-${new Date()}`}>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {dividend.date
                            ? format(new Date(dividend.date), "MM/dd/yyyy")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          <TextThousandSeparator
                            value={dividend.dividend}
                            prefix={"$"}
                            suffix={false}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {dividend.recordDate
                            ? format(
                                new Date(dividend.recordDate),
                                "MM/dd/yyyy"
                              )
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes["content-txt"]}>
                          {dividend.paymentDate
                            ? format(
                                new Date(dividend.paymentDate),
                                "MM/dd/yyyy"
                              )
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {"No Records Found."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dividendsData?.length ? (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={dividendsData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

export default DividendHistory
