import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"

const StyledDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "50%", // Set desired width (adjust as needed)
    height: "fit-content", // Allow content to dictate height
    backgroundColor: theme.palette.background.paper, // Set background color
    boxShadow: theme.shadows[5], // Add optional shadow
  },
}))

const StyledIframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "315px", // Adjust height as needed
  border: "none",
  borderRadius: theme.shape.borderRadius,
}))

function VideoPopup({ open, onClose, videoId }) {
  return (
    <StyledDialogBox open={open} onClose={onClose}>
      <DialogContent sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
      <DialogContent>
        <StyledIframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="How it Works Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </DialogContent>
    </StyledDialogBox>
  )
}

export default VideoPopup
