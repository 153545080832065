import React, { useEffect } from "react"
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Row,
  Col,
  Image,
} from "@mui/material"
import {
  Equalizer,
  Calculate,
  Adjust,
  ReceiptLong,
  Insights,
  CompareArrows,
  FormatQuote,
} from "@mui/icons-material"

import InsightsIcon from "@mui/icons-material/Insights"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined"
import Accordion from "./faq"
import ReactGA from "react-ga4"

import useStyles from "./styles"
import MetaTags from "../MetaTags"
import VideoIconButton from "./videoButton"

const HomeDashboard = () => {
  ReactGA.send({ hitType: "pageview", page: "/home" })
  const classes = useStyles()

  // for meta tags
  const title = "Landing Page - Lets Value Invest"
  const description =
    "Lets Value Invest is a value investing analysis and learning platform. It has been designed to help its users learn and apply the value investing principles and be able to analyze better, invest smarter with a long term horizon and achieve long term compounding benefits."
  const imageUrl = "https://letsvalueinvest.com/Assets/key-insight-share.png"

  useEffect(() => {}, [])

  return (
    <Box width="100%" padding={"2rem"}>
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      {/* Green card */}
      <Grid container className={classes["card-1"]}>
        <Grid item xs={12} sm={12} md={5}>
          <Typography className={classes["header"]}>
            Learn Value Investing and <br />
            Become a Better Investor.
          </Typography>
          <Typography className={classes["description"]}>
            Focus on the core fundamentals that matter most, learn to value a
            business, and become a better investor with our value investing
            analysis and learning platform.
          </Typography>
          <br />
          <Typography component={"span"} fontSize={"1.1rem"}>
            <FormatQuote />
            All intelligent investing is value investing – acquiring more than
            you are paying for. You must value the business in order to value
            the stock.
            <FormatQuote />
            <div className={classes["author-name"]}>
              <i> - Charlie Munger</i>
            </div>
          </Typography>
          <VideoIconButton videoId="lQCHPAr2dO0" />
        </Grid>
        <Grid item sm={0} md={1}></Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes["text-row"]}>
            <Equalizer className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              20 Yrs detailed financials, Revenue Segments breakdown
            </span>
          </Typography>
          <Typography paddingTop="18px" className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Intrinsic value estimator using multiple ways
            </span>
          </Typography>
          <Typography paddingTop="18px" className={classes["text-row"]}>
            <InsightsIcon className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Simple and detailed key insights
            </span>
          </Typography>
          {/* <Typography paddingTop="18px" className={classes["text-row"]}>
            <PlaylistAddCheck className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Built in value investor's checklist
            </span>
          </Typography> */}
          <Typography paddingTop="18px" className={classes["text-row"]}>
            <Adjust className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Analysts price targets, estimates and recommendations
            </span>
          </Typography>
          <Typography paddingTop="18px" className={classes["text-row"]}>
            <ReceiptLong className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Ownerships, SEC filings, insider transactions, Dividends,
              Quarterly Call Trabscripts and more..
            </span>
          </Typography>
          <Typography paddingTop="18px" className={classes["text-row"]}>
            <CompareArrowsIcon className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Compare and visualize companies based on 20 Yrs of historical data
            </span>
          </Typography>
        </Grid>
      </Grid>
      {/* Our value card */}
      <Grid container className={classes["card-2"]} justifyContent="center">
        <Grid item xs={12} sm={8} md={7} justifyContent="center">
          <Card className="p6">
            <Typography fontSize={24}>
              Our value investing analysis and learning platform is a
              comprehensive resource for anyone who wants to learn and apply the
              principles of value investing.
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Intrinsic cal card */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            Simple Intrinsic Value Estimator
          </Typography>
          <Typography component={"span"} variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Make your own assumptions and see realtime calculations
              </li>
              <li className="pt4">
                3 valuation scenarios - Bull, Base, and Bear
              </li>
              <li className="pt4">
                Range of intrinsic values and expected CAGRs to help you make
                informed decisions
              </li>
              <li className="pt4">5, 7 or 10 Yrs analysis </li>
              <li className="pt4">Multiple valuation methods to choose from</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <Box
            width={"90%"}
            height={"90%"}
            component="img"
            alt=""
            src={`${window.location.origin}/Assets/valuation.png`}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Crisp Stock Analysis */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Insights className={classes["bullet-icon"]} />
            Detailed Key Insights
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Key financial metrics grouped logically and rated based on last
                10 years of data
              </li>
              <li className="pt4">
                Helps you find out the stronger and the weaker areas so you can
                drill down further
              </li>
              <li className="pt4">
                Overall insight score reflecting overall quality of the business
              </li>
              <li className="pt4">Helps you make better informed decision</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <Box
            width={"90%"}
            height={"90%"}
            component="img"
            alt=""
            src={`${window.location.origin}/Assets/key-insights.png`}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Stocks Visualizer */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <CompareArrows className={classes["bullet-icon"]} />
            Stocks Visualizer
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Compare a company's historical performance with regards to
                selected metric(s)
              </li>
              <li className="pt4">
                Compare many companies against a given key metrics
              </li>
              <li className="pt4">
                Comparison over 30 years of data (If available) and over 90
                financial metrics
              </li>
              <li className="pt4">
                Helps you visualize if the company is undervalued historically
                or with repect to another company given a metric
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <Box
            width={"100%"}
            height={"100%"}
            component="img"
            alt=""
            src={`${window.location.origin}/Assets/visualizer.png`}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Peers Comparison */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <CompareArrows className={classes["bullet-icon"]} />
            Compare with Peers
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Compare with one or more peers side by side based on key insight
                scores
              </li>
              <li className="pt4">Simple and detailed views for comparison</li>
              <li className="pt4">
                Helps you compare the valuation and quality of a company to
                those of its peers
              </li>
              <li className="pt4">Helps you make better investment choices</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <Box
            width={"100%"}
            height={"100%"}
            component="img"
            alt=""
            src={`${window.location.origin}/Assets/peers.png`}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Analysts price tarkets, estimates and recommendations */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Adjust className={classes["bullet-icon"]} />
            Analysts price targets, estimates and recommendations
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Analysts price targets over next 12-18 months
              </li>
              <li className="pt4">
                Recent recommendations indicating if the stock is a Buy or Sell
              </li>
              <li className="pt4">
                Revenue and price estimates for next 2 years
              </li>
              <li className="pt4">
                Analysts provided Historical grades for the stock
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <img
            item
            src={`${window.location.origin}/Assets/analysts1.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
          <img
            item
            src={`${window.location.origin}/Assets/analysts2.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
          <img
            item
            src={`${window.location.origin}/Assets/analysts3.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Ownnership structure, insider trading, SEC filings and more.. */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} lg={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <ReceiptLong className={classes["bullet-icon"]} />
            Ownnership structure, insider trading, SEC filings and more..
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Current shareholding pattern helping you know who are the big
                owners
              </li>
              <li className="pt4">
                Recent insider trades - helps you find out what the insiders are
                upto
              </li>
              <li className="pt4">
                SEC Filings - links you directly to the original filings in the
                sec.gov website
              </li>
              <li className="pt4">
                Historical dividend information - if the company pays dividend
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <img
            item
            src={`${window.location.origin}/Assets/holders-breakdown.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
          <img
            item
            src={`${window.location.origin}/Assets/top-insti-holders.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
          <img
            item
            src={`${window.location.origin}/Assets/insider-trades.png`}
            alt=""
            height={"500px"}
            width={"280px"}
            className={classes["image"]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Simple and intuitive calculators */}
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12} md={5}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            Simple and Intuitive Calculators
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            <ul>
              <li className="pt4">
                Most important tool for all value investors
              </li>
              <li className="pt4">
                Easy to use and understand Compound interest and CAGR
                calculators
              </li>
              <li className="pt4">
                Visualize how the investment grows over time with compound
                interest.
              </li>
              <li className="pt4">
                Automatically calculates a variance above and below your assumed
                interest rate
              </li>
              <li className="pt4">
                Simple CAGR calculator : Calculate the compound annual growth
                rate (CAGR) of an investment
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Box
            width={"100%"}
            height={"100%"}
            component="img"
            alt=""
            src={`${window.location.origin}/Assets/calculator.png`}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" className={classes["text-row"]}>
            <QuestionAnswerOutlinedIcon className={classes["bullet-icon"]} />
            Frequently asked questions (FAQs)
          </Typography>{" "}
          <br />
          <Accordion />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      <Box padding={"32px"} display="flex" justifyContent={"center"}>
        <Typography variant="h5" className={classes["text-row"]}>
          Learn to analyze better, invest smarter and achieve long term
          compounding
        </Typography>
      </Box>
    </Box>
  )
}

export default HomeDashboard
