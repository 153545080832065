import React from "react"
import PropTypes from "prop-types"
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Grid,
} from "@mui/material"

import useStyles from "../../styles"
import TickerHeader from "../TickerHeader"
import Rating from "../Rating"
import ReactGA from "react-ga4"

const SimpleView = ({ data }) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/ticker/peers/simple",
  })
  const classes = useStyles()
  const rows = [
    {
      title: "",
      keys: "tickerId",
      isTickerHeader: true,
    },
    {
      title: "Overall Score",
      keys: "overallScore",
    },
    {
      title: "Growth Rates",
      keys: "growthRates",
    },
    {
      title: "Managment Effectiveness",
      keys: "managementEffectiveness",
    },
    {
      title: "Financial Health",
      keys: "financialHealth",
    },
    {
      title: "Margins/Profitability",
      keys: "profitability",
    },
    {
      title: "Relative Valuations",
      keys: "valuationMultiples",
    },
    {
      title: "Moat",
      keys: "moat",
    },
    {
      title: "Dividend Info",
      keys: "dividend",
    },
  ]

  const getRatingTemplate = (score) => {
    if (!score) return 0
    return score * 20
  }

  if (!data?.length) return <></>

  return (
    <Grid container justifyContent="start" marginTop={4}>
      <Grid item sm={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.title}>
                    <TableCell align="left" width={"200px"}>
                      <span className={classes["header-row"]}>
                        <span className={classes.title}>{row?.title}</span>
                      </span>
                    </TableCell>
                    {data.map((record) => {
                      if (row.isTickerHeader) {
                        return (
                          <TableCell
                            width={"200px"}
                            align="left"
                            key={`${row.title}-${record.tickerId}`}
                          >
                            <TickerHeader data={record?.quoteResponse} />
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell
                          align="left"
                          width={"200px"}
                          key={`${row.title}-${record.tickerId}`}
                        >
                          <Rating
                            value={getRatingTemplate(
                              record[row.keys]?.["score"] >= 0
                                ? record[row.keys]?.["score"]
                                : record[row.keys]
                            )}
                            rawValue={
                              record[row.keys]?.["score"] >= 0
                                ? record[row.keys]?.["score"]
                                : record[row.keys]
                            }
                          />
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default SimpleView

SimpleView.propTypes = {
  data: PropTypes.array.isRequired,
  overview: PropTypes.object.isRequired,
}
