import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "home-container": {
    height: "100%",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  "header-container": {
    position: "fixed",
    top: "0",
    zIndex: "10",
    width: "100%",
  },
  "content-container": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "90px",
  },
  "footer-container": {
    width: "100%",
  },
  "dashboard-container": {
    width: "100%",
    backgroundColor: theme.palette["green-1"],
    borderRadius: "25px",
    padding: "2rem 3rem",
  },
  header: {
    fontSize: "48px !important",
    fontWeight: "600 !important",
  },
  description: {
    paddingTop: "1rem",
    color: theme.palette["text-2"],
    fontSize: "20px !important",
  },
  "bullet-icon": {
    fontSize: "32px !important",
    marginRight: "1rem",
  },
  bullet: {
    fontSize: "26px !important",
    color: theme.palette["text-2"],
  },
}))
