import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  header: {
    fontWeight: "500 !important",
    fontSize: "18px !important",
    padding: "12px 0 !important",
  },
  "content-txt": {
    color: theme.palette["grey-2"],
    textAlign: "justify",
  },
}))
