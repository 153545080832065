import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Alert } from "@mui/material"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { orderBy } from "lodash"

import useStyles from "./styles"
import AppDataContext from "../../../context/data"
import { getAnalystsDataByTickerId } from "../../../services/ticker"
import Loading from "../../Loading"
import RevenueEstimates from "./components/RevenueEstimates"
import EPSEstimates from "./components/EPSEstimates"
import HistoricalGrades from "./components/HistoricalGrades"
import Recommendations from "./components/Recommendations"
import PriceTargets from "./components/PriceTargets"
import ReactGA from "react-ga4"

const Profile = () => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/analysts" })
  const [isLoading, setIsLoading] = useState(false)
  const { tickerId } = useParams()
  const [analysisData, setAnalystsData] = useState(null)
  const { analysts, setAnalysts, overview } = useContext(AppDataContext)
  const classes = useStyles()

  useEffect(() => {
    document.title = `${tickerId} - Analysts - Lets Value Invest`
    setIsLoading(true)
    setAnalystsData(null)
    const getAnalystsData = async () => {
      toast.dismiss()
      try {
        // get data only if context is empty and save into context
        const resp = await getAnalystsDataByTickerId(tickerId)
        setAnalystsData({ ...resp.data, tickerId })
        setAnalysts({ ...resp.data, tickerId })
        setIsLoading(false)
      } catch (err) {
        setAnalystsData(null)
        setAnalysts(null)
        setIsLoading(false)
        toast.error(
          "Unable to get analysts information. Please try after sometime."
        )
      }
    }
    // check if we have data already in context
    if (analysts?.tickerId === tickerId) {
      setAnalystsData(analysts)
      setIsLoading(false)
      return
    }
    overview?.tickerId && getAnalystsData()
  }, [JSON.stringify(overview), tickerId])

  if (isLoading) {
    return (
      <Box className="loader">
        <Loading />
      </Box>
    )
  }

  return (
    <Box className={classes["ticker-container"]}>
      <Grid item xs={12} sm={12}>
        <Alert severity="info" className={classes.alert}>
          <p>
            Analysts' price targets and recommendations can be a helpful tool
            for value investors, but they should only be used as one part of the
            investment due diligence process. Value investors should also
            develop a deep understanding of the business and the quality of
            management, review the company's financials and regulatory filings,
            and calculate their own intrinsic value with a reasonable margin of
            safety.
          </p>
          <p>
            Looking at price targets and analysts' recommendations to buy or
            sell a stock is more like trading and has nothing to do with value
            investing. Analysts' price targets are often based on short-term
            factors, such as current market conditions, and they can change
            quickly. Additionally, analysts' recommendations are often
            influenced by conflicts of interest, such as the analyst's employer
            having a financial relationship with the company being analyzed.
          </p>
        </Alert>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={4}>
        <Grid item xs={12} md={6}>
          <PriceTargets
            priceTargets={analysisData?.priceTarget[0]}
            historicalPrices={orderBy(
              analysisData?.historicalPrices?.historical,
              "date",
              "desc"
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Recommendations
            recommendations={analysisData?.upgradesDowngradesConsensus[0]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueEstimates
            analystEstimates={orderBy(
              analysisData?.analystEstimates || [],
              "date"
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EPSEstimates
            analystEstimates={orderBy(
              analysisData?.analystEstimates || [],
              "date"
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <HistoricalGrades
            upgradesDowngrades={analysisData?.upgradesDowngrades}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Profile
