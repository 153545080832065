import { format } from "date-fns"

export const getUsdValues = (balanceSheetStatementData) => {
  // default data
  const defaultData = {
    assets: Array.apply(null, Array(10)),
    curAssets: Array.apply(null, Array(10)),
    cash: Array.apply(null, Array(10)),
    cashEquivalents: Array.apply(null, Array(10)),
    shortInvestments: Array.apply(null, Array(10)),
    totalCash: Array.apply(null, Array(10)),
    netReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    otherCurrentAssets: Array.apply(null, Array(10)),
    totalCurrentAssets: Array.apply(null, Array(10)),
    nonCurrentAssets: Array.apply(null, Array(10)),
    propertyPlantEquipment: Array.apply(null, Array(10)),
    propertyPlantEquipmentNet: Array.apply(null, Array(10)),
    longTermInvestments: Array.apply(null, Array(10)),
    goodwill: Array.apply(null, Array(10)),
    intangibleAssets: Array.apply(null, Array(10)),
    taxAssets: Array.apply(null, Array(10)),
    otherNonCurrentAssets: Array.apply(null, Array(10)),
    totalNonCurrentAssets: Array.apply(null, Array(10)),
    totalAssets: Array.apply(null, Array(10)),
    lsEquity: Array.apply(null, Array(10)),
    liabilities: Array.apply(null, Array(10)),
    curLiabilities: Array.apply(null, Array(10)),
    shortTermDebt: Array.apply(null, Array(10)),
    deferredRevenue: Array.apply(null, Array(10)),
    accountPayables: Array.apply(null, Array(10)),
    taxPayables: Array.apply(null, Array(10)),
    otherCurrentLiabilities: Array.apply(null, Array(10)),
    totalCurrentLiabilities: Array.apply(null, Array(10)),
    nonCurrentLiabilities: Array.apply(null, Array(10)),
    longTermDebt: Array.apply(null, Array(10)),
    deferredTaxLiabilitiesNonCurrent: Array.apply(null, Array(10)),
    deferredRevenueNonCurrent: Array.apply(null, Array(10)),
    capitalLeaseObligations: Array.apply(null, Array(10)),
    minorityInterest: Array.apply(null, Array(10)),
    otherNonCurrentLiabilities: Array.apply(null, Array(10)),
    totalNonCurrentLiabilities: Array.apply(null, Array(10)),
    otherLiabilities: Array.apply(null, Array(10)),
    totalLiabilities: Array.apply(null, Array(10)),
    stockHolderEquity: Array.apply(null, Array(10)),
    preferredStock: Array.apply(null, Array(10)),
    commonStock: Array.apply(null, Array(10)),
    retainedEarnings: Array.apply(null, Array(10)),
    accumulatedOtherComprehensiveIncomeLoss: Array.apply(null, Array(10)),
    totalStockholdersEquity: Array.apply(null, Array(10)),
    totalLiabilitiesAndStockholdersEquity: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  balanceSheetStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      balanceSheetStatementData.length > 10
        ? balanceSheetStatementData.length - 1
        : 9
    defaultData.assets[maxIndex - index] = 0
    defaultData.curAssets[maxIndex - index] = 0
    defaultData.cash[maxIndex - index] = 0
    defaultData.cashEquivalents[maxIndex - index] =
      data?.cashAndCashEquivalents / 1000000
    defaultData.shortInvestments[maxIndex - index] =
      data?.shortTermInvestments / 1000000
    defaultData.totalCash[maxIndex - index] =
      data?.cashAndShortTermInvestments / 1000000
    defaultData.netReceivables[maxIndex - index] =
      data?.netReceivables / 1000000
    defaultData.inventory[maxIndex - index] = data?.inventory / 1000000
    defaultData.otherCurrentAssets[maxIndex - index] =
      data?.otherCurrentAssets / 1000000
    defaultData.totalOtherIncomeExpensesNet[maxIndex - index] =
      data.totalOtherIncomeExpensesNet / 1000000
    defaultData.totalOperatingExpenses[maxIndex - index] =
      data.operatingExpenses / 1000000
    defaultData.totalCurrentAssets[maxIndex - index] =
      data?.totalCurrentAssets / 1000000
    defaultData.nonCurrentAssets[maxIndex - index] = 0
    defaultData.propertyPlantEquipment[maxIndex - index] = 0
    defaultData.propertyPlantEquipmentNet[maxIndex - index] =
      data?.propertyPlantEquipmentNet / 1000000
    defaultData.longTermInvestments[maxIndex - index] =
      data?.longTermInvestments / 1000000
    defaultData.goodwill[maxIndex - index] = data?.goodwill / 1000000
    defaultData.intangibleAssets[maxIndex - index] =
      data?.intangibleAssets / 1000000
    defaultData.taxAssets[maxIndex - index] = data?.taxAssets / 1000000
    defaultData.otherNonCurrentAssets[maxIndex - index] =
      data?.otherNonCurrentAssets / 1000000
    defaultData.totalNonCurrentAssets[maxIndex - index] =
      data?.totalNonCurrentAssets / 1000000
    defaultData.totalAssets[maxIndex - index] = data?.totalAssets / 1000000
    defaultData.lsEquity[maxIndex - index] = 0
    defaultData.liabilities[maxIndex - index] = 0
    defaultData.curLiabilities[maxIndex - index] = 0
    defaultData.shortTermDebt[maxIndex - index] = data?.shortTermDebt / 1000000
    defaultData.deferredRevenue[maxIndex - index] =
      data?.deferredRevenue / 1000000
    defaultData.accountPayables[maxIndex - index] =
      data?.accountPayables / 1000000
    defaultData.taxPayables[maxIndex - index] = data?.taxPayables / 1000000
    defaultData.otherCurrentLiabilities[maxIndex - index] =
      data?.otherCurrentLiabilities / 1000000
    defaultData.totalCurrentLiabilities[maxIndex - index] =
      data?.totalCurrentLiabilities / 1000000
    defaultData.nonCurrentLiabilities[maxIndex - index] = 0
    defaultData.longTermDebt[maxIndex - index] = data?.longTermDebt / 1000000
    defaultData.deferredTaxLiabilitiesNonCurrent[maxIndex - index] =
      data?.deferredTaxLiabilitiesNonCurrent / 1000000
    defaultData.deferredRevenueNonCurrent[maxIndex - index] =
      data?.deferredRevenueNonCurrent / 1000000
    defaultData.capitalLeaseObligations[maxIndex - index] =
      data?.capitalLeaseObligations / 1000000
    defaultData.minorityInterest[maxIndex - index] =
      data?.minorityInterest / 1000000
    defaultData.otherNonCurrentLiabilities[maxIndex - index] =
      data?.otherNonCurrentLiabilities / 1000000
    defaultData.totalNonCurrentLiabilities[maxIndex - index] =
      data?.totalNonCurrentLiabilities / 1000000
    defaultData.otherLiabilities[maxIndex - index] =
      data?.otherLiabilities / 1000000
    defaultData.totalLiabilities[maxIndex - index] =
      data?.totalLiabilities / 1000000
    defaultData.stockHolderEquity[maxIndex - index] = 0
    defaultData.commonStock[maxIndex - index] = data?.commonStock / 1000000
    defaultData.preferredStock[maxIndex - index] =
      data?.preferredStock / 1000000
    defaultData.retainedEarnings[maxIndex - index] =
      data?.retainedEarnings / 1000000
    defaultData.accumulatedOtherComprehensiveIncomeLoss[maxIndex - index] =
      data?.accumulatedOtherComprehensiveIncomeLoss / 1000000
    defaultData.totalStockholdersEquity[maxIndex - index] =
      data?.totalStockholdersEquity / 1000000
    defaultData.totalLiabilitiesAndStockholdersEquity[maxIndex - index] =
      data?.totalLiabilitiesAndStockholdersEquity / 1000000
  })
  return { tempHeaders, defaultData }
}

export const getUsdPercentValues = (balanceSheetStatementData) => {
  // default data
  const defaultPerData = {
    assets: Array.apply(null, Array(10)),
    curAssets: Array.apply(null, Array(10)),
    cash: Array.apply(null, Array(10)),
    cashEquivalents: Array.apply(null, Array(10)),
    shortInvestments: Array.apply(null, Array(10)),
    totalCash: Array.apply(null, Array(10)),
    netReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    otherCurrentAssets: Array.apply(null, Array(10)),
    totalCurrentAssets: Array.apply(null, Array(10)),
    nonCurrentAssets: Array.apply(null, Array(10)),
    propertyPlantEquipment: Array.apply(null, Array(10)),
    propertyPlantEquipmentNet: Array.apply(null, Array(10)),
    longTermInvestments: Array.apply(null, Array(10)),
    goodwill: Array.apply(null, Array(10)),
    intangibleAssets: Array.apply(null, Array(10)),
    taxAssets: Array.apply(null, Array(10)),
    otherNonCurrentAssets: Array.apply(null, Array(10)),
    totalNonCurrentAssets: Array.apply(null, Array(10)),
    totalAssets: Array.apply(null, Array(10)),
    lsEquity: Array.apply(null, Array(10)),
    liabilities: Array.apply(null, Array(10)),
    curLiabilities: Array.apply(null, Array(10)),
    shortTermDebt: Array.apply(null, Array(10)),
    deferredRevenue: Array.apply(null, Array(10)),
    otherCurrentLiabilities: Array.apply(null, Array(10)),
    totalCurrentLiabilities: Array.apply(null, Array(10)),
    nonCurrentLiabilities: Array.apply(null, Array(10)),
    longTermDebt: Array.apply(null, Array(10)),
    deferredTaxLiabilitiesNonCurrent: Array.apply(null, Array(10)),
    deferredRevenueNonCurrent: Array.apply(null, Array(10)),
    otherNonCurrentLiabilities: Array.apply(null, Array(10)),
    totalNonCurrentLiabilities: Array.apply(null, Array(10)),
    totalLiabilities: Array.apply(null, Array(10)),
    stockHolderEquity: Array.apply(null, Array(10)),
    commonStock: Array.apply(null, Array(10)),
    retainedEarnings: Array.apply(null, Array(10)),
    accumulatedOtherComprehensiveIncomeLoss: Array.apply(null, Array(10)),
    totalStockholdersEquity: Array.apply(null, Array(10)),
    totalLiabilitiesAndStockholdersEquity: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  balanceSheetStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      balanceSheetStatementData.length > 10
        ? balanceSheetStatementData.length - 1
        : 9
    defaultPerData.assets[maxIndex - index] = 0
    defaultPerData.curAssets[maxIndex - index] = 0
    defaultPerData.cash[maxIndex - index] = 0
    defaultPerData.totalAssets[maxIndex - index] = 100
    defaultPerData.cashEquivalents[maxIndex - index] =
      (data?.cashAndCashEquivalents / data?.totalAssets) * 100
    defaultPerData.shortInvestments[maxIndex - index] =
      (data?.shortTermInvestments / data?.totalAssets) * 100
    defaultPerData.totalCash[maxIndex - index] =
      (data?.cashAndShortTermInvestments / data?.totalAssets) * 100
    defaultPerData.netReceivables[maxIndex - index] =
      (data?.netReceivables / data?.totalAssets) * 100
    defaultPerData.inventory[maxIndex - index] =
      (data?.inventory / data?.totalAssets) * 100
    defaultPerData.otherCurrentAssets[maxIndex - index] =
      (data?.otherCurrentAssets / data?.totalAssets) * 100
    defaultPerData.totalOtherIncomeExpensesNet[maxIndex - index] =
      (data.totalOtherIncomeExpensesNet / data?.totalAssets) * 100
    defaultPerData.totalOperatingExpenses[maxIndex - index] =
      (data.operatingExpenses / data?.totalAssets) * 100
    defaultPerData.totalCurrentAssets[maxIndex - index] =
      (data?.totalCurrentAssets / data?.totalAssets) * 100
    defaultPerData.nonCurrentAssets[maxIndex - index] = 0
    defaultPerData.propertyPlantEquipment[maxIndex - index] = 0
    defaultPerData.propertyPlantEquipmentNet[maxIndex - index] =
      (data?.propertyPlantEquipmentNet / data?.totalAssets) * 100
    defaultPerData.longTermInvestments[maxIndex - index] =
      (data?.longTermInvestments / data?.totalAssets) * 100
    defaultPerData.goodwill[maxIndex - index] =
      (data?.goodwill / data?.totalAssets) * 100
    defaultPerData.intangibleAssets[maxIndex - index] =
      (data?.intangibleAssets / data?.totalAssets) * 100
    defaultPerData.taxAssets[maxIndex - index] =
      (data?.taxAssets / data?.totalAssets) * 100
    defaultPerData.otherNonCurrentAssets[maxIndex - index] =
      (data?.otherNonCurrentAssets / data?.totalAssets) * 100
    defaultPerData.totalNonCurrentAssets[maxIndex - index] =
      (data?.totalNonCurrentAssets / data?.totalAssets) * 100
    defaultPerData.lsEquity[maxIndex - index] = 0
    defaultPerData.liabilities[maxIndex - index] = 0
    defaultPerData.curLiabilities[maxIndex - index] = 0
    defaultPerData.totalLiabilitiesAndStockholdersEquity[maxIndex - index] = 100
    defaultPerData.shortTermDebt[maxIndex - index] =
      (data?.shortTermDebt / data?.totalLiabilitiesAndStockholdersEquity) * 100
    defaultPerData.deferredRevenue[maxIndex - index] =
      (data?.deferredRevenue / data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.otherCurrentLiabilities[maxIndex - index] =
      (data?.otherCurrentLiabilities /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.totalCurrentLiabilities[maxIndex - index] =
      (data?.totalCurrentLiabilities /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.nonCurrentLiabilities[maxIndex - index] = 0
    defaultPerData.longTermDebt[maxIndex - index] =
      (data?.longTermDebt / data?.totalLiabilitiesAndStockholdersEquity) * 100
    defaultPerData.deferredTaxLiabilitiesNonCurrent[maxIndex - index] =
      (data?.deferredTaxLiabilitiesNonCurrent /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.deferredRevenueNonCurrent[maxIndex - index] =
      (data?.deferredRevenueNonCurrent /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.otherNonCurrentLiabilities[maxIndex - index] =
      (data?.otherNonCurrentLiabilities /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.totalNonCurrentLiabilities[maxIndex - index] =
      (data?.totalNonCurrentLiabilities /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.totalLiabilities[maxIndex - index] =
      (data?.totalLiabilities / data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.stockHolderEquity[maxIndex - index] = 0
    defaultPerData.commonStock[maxIndex - index] =
      (data?.commonStock / data?.totalLiabilitiesAndStockholdersEquity) * 100
    defaultPerData.retainedEarnings[maxIndex - index] =
      (data?.retainedEarnings / data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.accumulatedOtherComprehensiveIncomeLoss[maxIndex - index] =
      (data?.accumulatedOtherComprehensiveIncomeLoss /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
    defaultPerData.totalStockholdersEquity[maxIndex - index] =
      (data?.totalStockholdersEquity /
        data?.totalLiabilitiesAndStockholdersEquity) *
      100
  })
  return { tempHeaders, defaultPerData }
}

export const getConversionValues = (balanceSheetStatementData, price) => {
  // default data
  const defaultData = {
    assets: Array.apply(null, Array(10)),
    curAssets: Array.apply(null, Array(10)),
    cash: Array.apply(null, Array(10)),
    cashEquivalents: Array.apply(null, Array(10)),
    shortInvestments: Array.apply(null, Array(10)),
    totalCash: Array.apply(null, Array(10)),
    netReceivables: Array.apply(null, Array(10)),
    inventory: Array.apply(null, Array(10)),
    totalOtherIncomeExpensesNet: Array.apply(null, Array(10)),
    totalOperatingExpenses: Array.apply(null, Array(10)),
    otherCurrentAssets: Array.apply(null, Array(10)),
    totalCurrentAssets: Array.apply(null, Array(10)),
    nonCurrentAssets: Array.apply(null, Array(10)),
    propertyPlantEquipment: Array.apply(null, Array(10)),
    propertyPlantEquipmentNet: Array.apply(null, Array(10)),
    longTermInvestments: Array.apply(null, Array(10)),
    goodwill: Array.apply(null, Array(10)),
    intangibleAssets: Array.apply(null, Array(10)),
    taxAssets: Array.apply(null, Array(10)),
    otherNonCurrentAssets: Array.apply(null, Array(10)),
    totalNonCurrentAssets: Array.apply(null, Array(10)),
    totalAssets: Array.apply(null, Array(10)),
    lsEquity: Array.apply(null, Array(10)),
    liabilities: Array.apply(null, Array(10)),
    curLiabilities: Array.apply(null, Array(10)),
    shortTermDebt: Array.apply(null, Array(10)),
    deferredRevenue: Array.apply(null, Array(10)),
    accountPayables: Array.apply(null, Array(10)),
    taxPayables: Array.apply(null, Array(10)),
    otherCurrentLiabilities: Array.apply(null, Array(10)),
    totalCurrentLiabilities: Array.apply(null, Array(10)),
    nonCurrentLiabilities: Array.apply(null, Array(10)),
    longTermDebt: Array.apply(null, Array(10)),
    deferredTaxLiabilitiesNonCurrent: Array.apply(null, Array(10)),
    deferredRevenueNonCurrent: Array.apply(null, Array(10)),
    capitalLeaseObligations: Array.apply(null, Array(10)),
    minorityInterest: Array.apply(null, Array(10)),
    otherNonCurrentLiabilities: Array.apply(null, Array(10)),
    totalNonCurrentLiabilities: Array.apply(null, Array(10)),
    otherLiabilities: Array.apply(null, Array(10)),
    totalLiabilities: Array.apply(null, Array(10)),
    stockHolderEquity: Array.apply(null, Array(10)),
    preferredStock: Array.apply(null, Array(10)),
    commonStock: Array.apply(null, Array(10)),
    retainedEarnings: Array.apply(null, Array(10)),
    accumulatedOtherComprehensiveIncomeLoss: Array.apply(null, Array(10)),
    totalStockholdersEquity: Array.apply(null, Array(10)),
    totalLiabilitiesAndStockholdersEquity: Array.apply(null, Array(10)),
  }
  let tempHeaders = []
  balanceSheetStatementData?.forEach((data, index) => {
    tempHeaders.unshift(format(new Date(data.date), "MM/dd/yyyy"))
    // data in currency
    const maxIndex =
      balanceSheetStatementData.length > 10
        ? balanceSheetStatementData.length - 1
        : 9
    defaultData.assets[maxIndex - index] = 0
    defaultData.curAssets[maxIndex - index] = 0
    defaultData.cash[maxIndex - index] = 0
    defaultData.cashEquivalents[maxIndex - index] =
      (data?.cashAndCashEquivalents / 1000000) * price
    defaultData.shortInvestments[maxIndex - index] =
      (data?.shortTermInvestments / 1000000) * price
    defaultData.totalCash[maxIndex - index] =
      (data?.cashAndShortTermInvestments / 1000000) * price
    defaultData.netReceivables[maxIndex - index] =
      (data?.netReceivables / 1000000) * price
    defaultData.inventory[maxIndex - index] =
      (data?.inventory / 1000000) * price
    defaultData.otherCurrentAssets[maxIndex - index] =
      (data?.otherCurrentAssets / 1000000) * price
    defaultData.totalOtherIncomeExpensesNet[maxIndex - index] =
      (data.totalOtherIncomeExpensesNet / 1000000) * price
    defaultData.totalOperatingExpenses[maxIndex - index] =
      (data.operatingExpenses / 1000000) * price
    defaultData.totalCurrentAssets[maxIndex - index] =
      (data?.totalCurrentAssets / 1000000) * price
    defaultData.nonCurrentAssets[maxIndex - index] = 0
    defaultData.propertyPlantEquipment[maxIndex - index] = 0
    defaultData.propertyPlantEquipmentNet[maxIndex - index] =
      (data?.propertyPlantEquipmentNet / 1000000) * price
    defaultData.longTermInvestments[maxIndex - index] =
      (data?.longTermInvestments / 1000000) * price
    defaultData.goodwill[maxIndex - index] = (data?.goodwill / 1000000) * price
    defaultData.intangibleAssets[maxIndex - index] =
      (data?.intangibleAssets / 1000000) * price
    defaultData.taxAssets[maxIndex - index] =
      (data?.taxAssets / 1000000) * price
    defaultData.otherNonCurrentAssets[maxIndex - index] =
      (data?.otherNonCurrentAssets / 1000000) * price
    defaultData.totalNonCurrentAssets[maxIndex - index] =
      (data?.totalNonCurrentAssets / 1000000) * price
    defaultData.totalAssets[maxIndex - index] =
      (data?.totalAssets / 1000000) * price
    defaultData.lsEquity[maxIndex - index] = 0
    defaultData.liabilities[maxIndex - index] = 0
    defaultData.curLiabilities[maxIndex - index] = 0
    defaultData.shortTermDebt[maxIndex - index] =
      (data?.shortTermDebt / 1000000) * price
    defaultData.deferredRevenue[maxIndex - index] =
      (data?.deferredRevenue / 1000000) * price
    defaultData.accountPayables[maxIndex - index] =
      (data?.accountPayables / 1000000) * price
    defaultData.taxPayables[maxIndex - index] =
      (data?.taxPayables / 1000000) * price
    defaultData.otherCurrentLiabilities[maxIndex - index] =
      (data?.otherCurrentLiabilities / 1000000) * price
    defaultData.totalCurrentLiabilities[maxIndex - index] =
      (data?.totalCurrentLiabilities / 1000000) * price
    defaultData.nonCurrentLiabilities[maxIndex - index] = 0
    defaultData.longTermDebt[maxIndex - index] =
      (data?.longTermDebt / 1000000) * price
    defaultData.deferredTaxLiabilitiesNonCurrent[maxIndex - index] =
      (data?.deferredTaxLiabilitiesNonCurrent / 1000000) * price
    defaultData.deferredRevenueNonCurrent[maxIndex - index] =
      (data?.deferredRevenueNonCurrent / 1000000) * price
    defaultData.capitalLeaseObligations[maxIndex - index] =
      (data?.capitalLeaseObligations / 1000000) * price
    defaultData.minorityInterest[maxIndex - index] =
      (data?.minorityInterest / 1000000) * price
    defaultData.otherNonCurrentLiabilities[maxIndex - index] =
      (data?.otherNonCurrentLiabilities / 1000000) * price
    defaultData.totalNonCurrentLiabilities[maxIndex - index] =
      (data?.totalNonCurrentLiabilities / 1000000) * price
    defaultData.otherLiabilities[maxIndex - index] =
      (data?.otherLiabilities / 1000000) * price
    defaultData.totalLiabilities[maxIndex - index] =
      (data?.totalLiabilities / 1000000) * price
    defaultData.stockHolderEquity[maxIndex - index] = 0
    defaultData.preferredStock[maxIndex - index] =
      (data?.preferredStock / 1000000) * price
    defaultData.commonStock[maxIndex - index] =
      (data?.commonStock / 1000000) * price
    defaultData.retainedEarnings[maxIndex - index] =
      (data?.retainedEarnings / 1000000) * price
    defaultData.accumulatedOtherComprehensiveIncomeLoss[maxIndex - index] =
      (data?.accumulatedOtherComprehensiveIncomeLoss / 1000000) * price
    defaultData.totalStockholdersEquity[maxIndex - index] =
      (data?.totalStockholdersEquity / 1000000) * price
    defaultData.totalLiabilitiesAndStockholdersEquity[maxIndex - index] =
      (data?.totalLiabilitiesAndStockholdersEquity / 1000000) * price
  })
  return { tempHeaders, defaultData }
}
