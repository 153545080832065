import React from "react"
import PropTypes from "prop-types"
import {
  ThumbDownOffAltRounded,
  ThumbUpOffAltOutlined,
} from "@mui/icons-material"
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material"

import useStyles from "../../Financials/ProsAndCons/styles"
import { palette } from "../../../../utils/Palette"
import ReactGA from "react-ga4"

const ProsAndCons = ({ prosAndCons }) => {
  ReactGA.send({ hitType: "pageview", page: "/ticker/financials/pros-cons" })
  const classes = useStyles()

  if (!prosAndCons) return <></>

  return (
    <Grid container justifyContent="center">
      <Grid item cs={12} sm={12} md={12}>
        <Typography
          className={classes["info-text"]}
          paddingBottom="12px"
          xs={12}
          md={"flex"}
        >
          These pros and cons are automatically generated based on multiple key
          points. Please review them carefully and do your own due diligence.
        </Typography>
      </Grid>
      <Grid item cs={12} sm={6} md={5}>
        <Typography className={classes.header}>
          <span>{prosAndCons.pros.length} Pros</span>
        </Typography>
        <List sx={{ bgcolor: "background.paper" }}>
          {prosAndCons?.pros?.map((row) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ThumbUpOffAltOutlined color="success" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={row.primary} secondary={row.secondary} />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item cs={12} sm={6} md={5}>
        <Typography className={classes.header}>
          {prosAndCons.cons.length} Cons
        </Typography>
        <List sx={{ bgcolor: "background.paper" }}>
          {prosAndCons?.cons?.map((row) => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ThumbDownOffAltRounded color="error" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={row.primary} secondary={row.secondary} />
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default ProsAndCons

ProsAndCons.propTypes = {
  prosAndCons: PropTypes.object.isRequired,
  overview: PropTypes.object.isRequired,
}
