import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

import { TextThousandSeparator } from "../../../utils/NumberFormatters"

const CompoundValTable = ({ calculatorParams }) => {
  const { rateOfReturn, futureVal, rateVar, variance } = calculatorParams

  const rows = [
    {
      rate: rateOfReturn - rateVar,
      amount: variance.leftVar,
      Variance: (variance.leftVar - futureVal).toFixed(2),
    },
    {
      rate: rateOfReturn,
      amount: futureVal,
      Variance: null,
    },
    {
      rate: rateOfReturn + rateVar,
      amount: variance.rightVar,
      Variance: (variance.rightVar - futureVal).toFixed(2),
    },
  ]

  const displayValue = (value, ext = "", isSuffix = true, props) => {
    const formatOpts = {
      thousandSeparator: true,
      fixedDecimalScale: false,
      ...props,
    }
    if (!value) return "-"
    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={formatOpts.thousandSeparator}
        fixedDecimalScale={formatOpts.fixedDecimalScale}
      />
    )
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Interest Rate</TableCell>
              <TableCell align="center">Total Amount</TableCell>
              <TableCell align="center">Variance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {displayValue(row.rate, "%", true)}
                </TableCell>
                <TableCell align="center">
                  {displayValue(row.amount, "$", false)}
                </TableCell>
                <TableCell
                  align="center"
                  className={row.Variance < 0 ? "error-txt" : "success-txt"}
                >
                  {displayValue(parseInt(row.Variance))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default CompoundValTable

CompoundValTable.propTypes = {
  calculatorParams: PropTypes.object.isRequired,
}
