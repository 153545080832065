import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box, Grid, Typography, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import {
  ArrowUpward,
  ArrowDownward,
  FormatQuote,
  InfoOutlined,
} from "@mui/icons-material"
import AppDataContext from "../../../context/data"
import {
  getRatingTemplate,
  getScoreTemplate,
} from "../../../utils/KeyInsightsScores"
import { format } from "date-fns"
import { palette } from "../../../utils/Palette"
import { getClassification } from "../../../utils/misc"

import useStyles from "./styles"
import { TextThousandSeparator } from "../../../utils/NumberFormatters"

const TickerHeader = ({ data }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { quotes } = useContext(AppDataContext)
  const [randomQuote, setRandomQuote] = useState("")
  useEffect(() => {
    // this will make sure quote is displayed only once and that is  when the URL changes
    const randomIndex = Math.floor(Math.random() * quotes.length)
    setRandomQuote(quotes[randomIndex])
  }, [pathname, quotes])

  // To display formatted value
  const displayValue = (
    value,
    ext = "",
    thousandSeparator = true,
    isSuffix = false,
    fixedDecimalScale = false
  ) => {
    if (!value) return "-"
    const absValue = Math.abs(value)
    if (absValue > 999 && absValue <= 1000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000).toFixed(2)
      isSuffix = true
      ext = "K"
    } else if (absValue >= 1000000 && absValue < 1000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000).toFixed(2)
      isSuffix = true
      ext = "M"
    } else if (absValue >= 1000000000 && absValue < 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000).toFixed(2)
      isSuffix = true
      ext = "B"
    } else if (absValue >= 1000000000000) {
      // convert to K for number from > 1000 < 1 million
      value = (value / 1000000000000).toFixed(2)
      isSuffix = true
      ext = "T"
    }

    return (
      <TextThousandSeparator
        value={value}
        prefix={!isSuffix && ext}
        suffix={isSuffix && ext}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
      />
    )
  }

  return (
    <Grid container direction="row">
      <Grid item md={4}>
        <Box className={classes["header-container"]}>
          {data?.image && (
            <Box
              component="img"
              sx={{
                maxHeight: 60,
                maxWidth: 80,
              }}
              alt=""
              src={data?.image}
            />
          )}
          <Box className={classes.container}>
            <Box className={classes["row-container"]}>
              <Typography className={classes.name}>
                {data?.name}
                <span className={classes.symbol}>
                  {data?.tickerId}
                  <span className={classes.exchange}>{data?.exchange}</span>
                </span>
              </Typography>
            </Box>
            <Box className={classes["row-container"]}>
              <Typography className={classes.price}>
                {displayValue(data?.price, "$", true, false, true)}
              </Typography>
              <Typography
                className={`${classes.priceChangePer} ${
                  data?.changesPercentage < 0
                    ? "percentage-down-txt"
                    : "percentage-up-txt"
                }`}
              >
                {data?.changesPercentage > 0 ? (
                  <ArrowUpward sx={{ fontSize: 20 }} className={classes.icon} />
                ) : (
                  <ArrowDownward
                    sx={{ fontSize: 20 }}
                    className={classes.icon}
                  />
                )}
                {data?.changesPercentage > 0 ? "+" : ""}
                {displayValue(data?.changesPercentage, "%", true, true, true)}
              </Typography>
              <Typography
                className={`${classes.priceChange} ${
                  data?.change < 0 ? "error-txt" : "success-txt"
                }`}
              >
                {data?.change > 0 ? `+${data?.change}` : data.change}
              </Typography>
            </Box>
            <Box className={classes.overallScore}>
              <Box sx={{ mt: 1 }}>
                {getRatingTemplate(data?.overallScore, classes)}
              </Box>
              <Box sx={{ ml: 1, mt: 1 }}>
                {getScoreTemplate(data?.overallScore, classes)}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" sx={{ mt: 1, mb: -1 }}>
              <span className={classes.earnings}>
                {new Date(data?.earningsAnnouncement).getTime() >=
                new Date().getTime()
                  ? "Next earning: "
                  : "Last earning: "}
                {data?.earningsAnnouncement
                  ? format(new Date(data?.earningsAnnouncement), "MMMM d, yyyy")
                  : ""}
              </span>
              <span>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <strong>Market Cap: </strong>$
                        {displayValue(data?.bigNumbers?.marketCap)} (
                        {getClassification(data?.profile?.marketCap)})
                      </Typography>
                      <Typography variant="body2">
                        <strong>Enterprise Value: </strong>$
                        {displayValue(data?.bigNumbers?.enterpriseValue)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>52 week price range: </strong>$
                        {displayValue(data?.shareStats?.yearLow)}
                        {" - "}${displayValue(data?.shareStats?.yearHigh)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Earnings Yield: </strong>
                        {displayValue(
                          data?.currentYields?.earningsYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>FCF Yield: </strong>
                        {displayValue(
                          data?.currentYields?.freeCashFlowYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Dividend Yield: </strong>
                        {displayValue(
                          data?.currentYields?.forwardDividendYield,
                          "%",
                          "true",
                          "true",
                          "true"
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {data?.profile?.sector}/{data?.profile?.industry}
                      </Typography>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <InfoOutlined
                    style={{ color: palette["purple-2"], marginLeft: "10px" }}
                    className={"cursor-pointer"}
                  ></InfoOutlined>
                </Tooltip>
              </span>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box>
          <Typography>
            <Box
              sx={{
                p: 2,
                mt: { lg: 3 },
                mb: 1,
                mr: { lg: 5 },
                maxWidth: "lg",
                border: "1px dashed grey",
                backgroundColor: "lightYellow",
              }}
            >
              <FormatQuote />
              {randomQuote?.description}
              <FormatQuote />
              <span className={classes["author-name"]}>
                - {randomQuote?.author}.
              </span>
            </Box>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TickerHeader

TickerHeader.propTypes = {
  data: PropTypes.object.isRequired,
}
